import { Grid, TextField, Zoom } from '@mui/material'
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../contexts/AuthContext'
import { BASE_URL, SOUKW_URL } from '../sharedComponents/Constants';

export default function Profile({ firstAccess }) {
    const { user, token, logout, jwtUser } = useContext(AuthContext);
    const [mc, setMc] = useState(null)
    const [password, setPassword] = useState('');
    const [changingPass, setChangingPass] = useState(firstAccess);

    useEffect(() => {
        async function getData() {
            try {
                const config = {
                    headers: { Authorization: `Bearer ${token}` }
                };
                let results = await axios.get(BASE_URL + '/mcs/' + user.marketCenterId, config);
                setMc(results.data)
            } catch (e) {
                console.error(e);
                if (e?.response?.data?.msg)
                    alert(e?.response?.data?.msg)
                else {
                    alert('Erro de Conexão.')
                    //logout();
                }
            }
        }
        getData();
    }, []);


    const submitPass = async () => {
        if (!password) {
            alert('Preencha a nova senha.');
            return;
        }
        try {
            const config = {
                headers: { Authorization: `Bearer ${token}` }
            };
            let json = { password }
            let res = await axios.patch(BASE_URL + '/auth/password-change/' + user._id, json, config);
            if (res.data) {
                alert('Senha alterada com sucesso!');

                setChangingPass(false);
                window.location.reload();
            }
        } catch (e) {
            console.error(e);
            if (e?.response?.data?.msg)
                alert(e?.response?.data?.msg)
            else {
                alert('Erro de Conexão.')
                setChangingPass(false);
            }
        }
    }

    return (
        <Grid container spacing={0} style={{ height: '100%', marginRight: '75px', textAlign: 'center', }} justifyContent='center'>
            {!firstAccess &&
                < Grid item xs={12}>
                    <h1>{user?.fullName}</h1>
                    <br />
                </Grid>
            }
            {
                firstAccess &&
                <Grid item xs={12}>
                    <h1>Bem vindo ao SouKW, {user?.fullName}! Como este é o seu primeiro acesso, redefina sua senha para continuar!</h1>
                    <br />
                </Grid>
            }

            <Grid item xs={8}>
                <div className='card'>
                    <Grid container spacing={0} style={{ height: '100%', marginRight: '75px', textAlign: 'center', paddingTop: '30px', paddingBottom: '30px' }}>
                        <Grid item xs={6}>
                            <label>Email:</label>
                            <h6>{user?.email}</h6>
                        </Grid>
                        <Grid item xs={6}>
                            <label>KWUID:</label>
                            <h6>{user?.kwuid}</h6>
                        </Grid>
                        <Grid item xs={12}>
                            <br />
                        </Grid>
                        <Grid item xs={6}>
                            <label>Função:</label>
                            <h6>{user?.permissionLevel}</h6>
                        </Grid>
                        <Grid item xs={6}>
                            <label>Market Center:</label>
                            <h6>{mc?.name}</h6>
                        </Grid>
                    </Grid>
                </div>
                <br />
                {!firstAccess &&
                    <button className='btn btnSecondary'
                        onClick={() => setChangingPass(!changingPass)}
                        style={{ marginRight: '5px' }}
                    >
                        {changingPass ? 'Cancelar' : 'Alterar Senha'}
                    </button>
                }
                <Zoom in={changingPass} mountOnEnter unmountOnExit style={{ marginTop: '30px' }}>
                    <br />
                </Zoom>
                <Zoom in={changingPass} style={{ marginTop: '30px' }}>
                    <TextField
                        variant='outlined'
                        label='Nova Senha'
                        color='secondary'
                        type={'password'}
                        style={{ marginTop: '5px', marginBottom: '5px' }}
                        onChange={(e) => setPassword(e.target.value)}
                        value={password}
                    />
                </Zoom>
                <Zoom in={changingPass} mountOnEnter unmountOnExit style={{ marginTop: '30px' }}>
                    <br />
                </Zoom>
                <Zoom in={changingPass}>
                    <button className='btn btnPrimary' onClick={submitPass}>Alterar</button>
                </Zoom>
                <Zoom in={changingPass} mountOnEnter unmountOnExit style={{ marginTop: '30px' }}>
                    <br />
                </Zoom>
                {!firstAccess &&
                    <button className='btn btnPrimary' onClick={logout} style={{ marginTop: '30px' }}>Sair da Conta</button>
                }
            </Grid>
        </Grid >
    )
}
