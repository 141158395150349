import { Grid } from "@mui/material";
import crediHabitar from '../assets/imgs/crediHabitarLogo.png'

export default function FinanciamentoImobiliario() {
    return (
        <>
            {/* ===== CrediHabitar ===== */}
            <Grid container
                xs={9}
                md={8}
                lg={4.8}
                paddingTop={1}
            >
                <div className="imGrid" >
                    <div className="imCard">
                        <img className="imLogo" src={crediHabitar} />
                        <div className="dataLandInfo">
                            <h1 className="imH1">
                                CrediHabitar
                            </h1>
                            <p className="imP">
                                Plataforma multibanco com oferta de crédito imobiliário (financiamento, portabilidade e home equity).
                            </p>
                        </div>
                    </div>
                </div>
                <div className="gridButton">
                    <div className="imGrid">
                        <Grid item xs={10}
                            style={{
                                marginTop: '1rem',
                                marginLeft: '3.5rem'
                            }}>
                            <a target='_blank' href="https://drive.google.com/file/d/1HTJ3nUU72qzq_2qnKHHWobHCM7lHNa3M/view?usp=sharing">
                                <button className='btn btnSecondary'
                                >
                                    Termo de Parceria
                                </button>
                            </a>
                        </Grid>

                        <Grid item xs={2}
                            style={{
                                marginTop: '1rem'
                            }}>
                            <a href="https://painel.credihabitar.com.br/admin/authentication" target='_blank'>
                                <button className='btn btnPrimary'
                                >
                                    Acessar
                                </button>
                            </a>
                        </Grid>
                    </div>
                </div>
            </Grid>
            <div style={{
                width: '4rem'
            }}>
            </div>
        </>
    )
}