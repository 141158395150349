import { Checkbox, Divider, Grid, MenuItem, TextField } from '@mui/material'
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { setRegion } from 'react-geocode';
import { Link } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext'
import NewMcModal from '../views/NewMcModal';
import NewTeamModal from '../views/NewTeamModal';
import { BASE_URL } from '../sharedComponents/Constants';

export default function Adm() {
    const { user, token, logout } = useContext(AuthContext);
    const [regions, setRegions] = useState([]);
    const [mcs, setMcs] = useState([]);
    const [teams, setTeams] = useState([]);

    const [newTeamModalOpen, setNewTeamModalOpen] = useState(false);
    const [newMcModalOpen, setNewMcModalOpen] = useState(false);


    useEffect(() => {
        async function getData() {
            try {
                const config = {
                    headers: { Authorization: `Bearer ${token}` }
                };
                let regions = await axios.get(BASE_URL + '/regions/', config);
                setRegions(regions.data);
                let mcsResult = await axios.get(BASE_URL + '/mcs/', config);
                setMcs(mcsResult.data.data);
                alert(mcs)
                let teamsResult = await axios.get(BASE_URL + '/teams/', config);
                setTeams(teamsResult.data);
            } catch (e) {
                console.error(e);
                if (e?.response?.data?.msg)
                    alert(e?.response?.data?.msg)
                else {
                    alert('Erro de Conexão.')
                    logout();
                }
            }
        }
        getData();
    }, []);


    return (
        <Grid container spacing={0} style={{ height: '100%', width: '100%', textAlign: 'left' }} justifyContent='center'>
            <Grid item xs={12} style={{ textAlign: 'left' }}>
                <h1>Painel administrativo</h1>
            </Grid>
            <Grid item xs={12} sm={12} style={{ textAlign: 'right', marginRight: '50px' }}>
                <button className='btn btnSecondary' onClick={() => setNewTeamModalOpen(!newTeamModalOpen)} style={{ marginRight: '5px' }}>Cadastrar Time</button>
                <button className='btn btnPrimary' onClick={() => setNewMcModalOpen(!newMcModalOpen)}>Cadastrar Market Center</button>
            </Grid>
            <Grid item xs={12} >
                <br />
            </Grid>
            <Grid item xs={12}>
                <h6>Market Centers...</h6>
            </Grid>
            <Grid item xs={12} >
                <br />
            </Grid>
            {mcs?.map(mc => {
                return (<Grid key={mc._id} item xs={12}>
                    <Divider style={{ width: '95%' }} />
                    <div style={{ backgroundColor: '#f8f7f7', width: '95%', padding: '5px' }}>
                        <Link to={'/soukw/teams&mc/editmc/' + mc._id} style={{ textDecoration: 'none', color: '#373f51' }}>
                            <Grid container justifyItems='flex-start' alignItems={'center'} style={{ cursor: 'pointer', paddingTop: '8px', paddingBottom: '8px' }}>
                                <Grid item xs={2}>
                                    <label>Nome: </label>
                                    {mc.name}
                                </Grid>
                                <Grid item xs={1}>
                                    <label>KWID: </label>
                                    {mc.kwId}
                                </Grid>
                                <Grid item xs={2}>
                                    <label>Região: </label>
                                    {regions?.find(r => r._id === mc.regionId)?.name}
                                </Grid>
                                <Grid item xs={3}>
                                    <label>Atualizado: </label>
                                    {new Date(mc.updatedAt).toLocaleDateString('pt-br')}
                                </Grid>
                            </Grid>
                        </Link>
                    </div>
                    <Divider style={{ width: '95%' }} />
                </Grid >)
            })}
            <Grid item xs={12} >
                <br />
            </Grid>
            <Grid item xs={12} >
                <Divider />
            </Grid>
            <Grid item xs={12} >
                <br />
            </Grid>
            <Grid item xs={12}>
                <h6>Times...</h6>
            </Grid>
            <Grid item xs={12} >
                <br />
            </Grid>
            {teams?.map(team => {
                return (<Grid key={team._id} item xs={12}>
                    <Divider style={{ width: '95%' }} />
                    <div style={{ backgroundColor: '#f8f7f7', width: '95%', padding: '5px' }}>
                        <Link to={'/soukw/teams&mc/editteam/' + team._id} style={{ textDecoration: 'none', color: '#373f51' }}>
                            <Grid container justifyItems='flex-start' alignItems={'center'} style={{ cursor: 'pointer', paddingTop: '8px', paddingBottom: '8px' }}>
                                <Grid item xs={3}>
                                    <label>Nome: </label>
                                    {team.name}
                                </Grid>
                                <Grid item xs={2}>
                                    <label>Market Center: </label>
                                    {mcs?.find(mc => mc._id === team.marketCenterId)?.name}
                                </Grid>
                                <Grid item xs={3}>
                                    <label>Atualizado: </label>
                                    {new Date(team.updatedAt).toLocaleDateString('pt-br')}
                                </Grid>
                            </Grid>
                        </Link>
                    </div>
                    <Divider style={{ width: '95%' }} />
                </Grid >)
            })}
            <NewTeamModal open={newTeamModalOpen} close={() => setNewTeamModalOpen(false)} mcs={mcs} />
            <NewMcModal open={newMcModalOpen} close={() => setNewMcModalOpen(false)} regions={regions} />
        </Grid>
    )
}
