import { Grid } from "@mui/material";
import gupoCincoLogo from '../assets/imgs/grupoCincoLogo.png'
import partnerLogo from '../assets/imgs/partnerLogo.png'

export default function PlacasImobiliarias() {
    return (
        <>
            {/* ===== Grupo Cinco ===== */}
            <Grid container
                xs={8}
                md={8}
                lg={5}
                paddingTop={1}
            >
                <div className="imGrid" >
                    <div className="imCard">
                        <img className="imLogo" src={gupoCincoLogo} />
                        <div className="dataLandInfo">
                            <h1 className="imH1">
                                Grupo Cinco
                            </h1>
                            <p className="imP">
                                Produção de placas imobiliárias e adesivos, com QRcode Quires ou não.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="gridButton">
                    <div className="imGrid">
                        <Grid item xs={10}
                            style={{
                                marginTop: '1rem',
                                marginLeft: '3.5rem'
                            }}>
                            <a target='_blank' href="https://drive.google.com/file/d/1ZFgejNuNd5e2qxON_MLI3M4t_c7YqjfK/view?usp=sharing">
                                <button className='btn btnSecondary'
                                >
                                    Termo de Parceria
                                </button>
                            </a>
                        </Grid>

                        <Grid item xs={2}
                            style={{
                                marginTop: '1rem'
                            }}>
                            <a href="https://placasdeimobiliaria.com.br/" target='_blank'>
                                <button className='btn btnPrimary'
                                >
                                    Acessar
                                </button>
                            </a>
                        </Grid>
                    </div>
                </div>
            </Grid>
            <div style={{
                width: '4rem'
            }}>
            </div>

            {/* ===== Letreiros ===== */}
            <Grid container
                xs={8}
                md={8}
                lg={5}
                paddingTop={1}
            >
                <div className="imGrid" >
                    <div className="imCard">
                        <img className="imLogo" src={partnerLogo} />
                        <div className="dataLandInfo">
                            <h1 className="imH1">
                                Letreiros
                            </h1>
                            <p className="imP">
                                Letreiros para fachadas e recepções
                            </p>
                        </div>
                    </div>
                </div>
                <div className="gridButton">
                    <div className="imGrid">
                        <Grid item xs={10}
                            style={{
                                marginTop: '1rem',
                                marginLeft: '3.5rem'
                            }}>
                            <a target='_blank' href="https://drive.google.com/file/d/1tLglUMZbmGYqMal2wibs2dB3voQ-YZXj/view?usp=sharing">
                                <button className='btn btnSecondary'
                                >
                                    Termo de Parceria
                                </button>
                            </a>
                        </Grid>

                        <Grid item xs={2}
                            style={{
                                marginTop: '1rem'
                            }}>
                            <a href="https://wa.me/5511947423570?text=Ol%C3%A1%2C+sou+agente+da+Keller+Williams.+Gostaria+de+solicitar+um+or%C3%A7amento%21" target='_blank'>
                                <button className='btn btnPrimary'
                                >
                                    Acessar
                                </button>
                            </a>
                        </Grid>
                    </div>
                </div>
            </Grid>
            <div style={{
                width: '4rem'
            }}>
            </div>

            {/* ===== Instituto Monitor ===== */}
            <Grid container
                xs={8}
                md={8}
                lg={5}
                paddingTop={1}
            >
                <div className="imGrid" >
                    <div className="imCard">
                        <img className="imLogo" src={partnerLogo} />
                        <div className="dataLandInfo">
                            <h1 className="imH1">
                                Adesivos
                            </h1>
                            <p className="imP">
                                Produção de adesivos para ambiente, conforme manual da marca KW.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="gridButton">
                    <div className="imGrid">
                        <Grid item xs={10}
                            style={{
                                marginTop: '1rem',
                                marginLeft: '3.5rem'
                            }}>
                            <a target='_blank' href="https://drive.google.com/file/d/1G8FD7wqJv9bZxr838OiXXds_dpHVZYHT/view?usp=sharing">
                                <button className='btn btnSecondary'
                                >
                                    Termo de Parceria
                                </button>
                            </a>
                        </Grid>

                        <Grid item xs={2}
                            style={{
                                marginTop: '1rem'
                            }}>
                            <a href="https://wa.me/5511992920301?text=Ol%C3%A1%2C+sou+agente+da+Keller+Williams.+Gostaria+de+solicitar+um+or%C3%A7amento%21" target='_blank'>
                                <button className='btn btnPrimary'
                                >
                                    Acessar
                                </button>
                            </a>
                        </Grid>
                    </div>
                </div>
            </Grid>
            <div style={{
                width: '4rem'
            }}>
            </div>
        </>
    )
}