import { Dialog, DialogTitle, Divider } from "@mui/material";

export default function FilterUsersModal({ open, close, setFilters }) {
    return(
        <div>
            <Dialog open={open} onClose={close} maxWidth='sm'>
            <DialogTitle>
                    <div style={{ width: '500px' }}>
                        <h1>Filtrar Usuários</h1>
                        <Divider style={{ width: '100%' }} />
                    </div>
                </DialogTitle>
            </Dialog>
        </div>
    )
}