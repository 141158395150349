import { Divider, Grid } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import axios from 'axios';
import { BASE_URL, SOUKW_URL } from '../sharedComponents/Constants';

export default function UserHistory() {
    const { token, user, jwtUser, logout, BASE_URL } = useContext(AuthContext);
    const { id } = useParams();
    const navigate = useNavigate();
    const [history, setHistory] = useState([])
    useEffect(() => {
        async function getData() {
            try {
                const config = {
                    headers: { Authorization: `Bearer ${token}` }
                };
                let results = await axios.get(BASE_URL + '/listinglogs/by_user_id/' + id, config);
                let lst = results.data;
                setHistory(lst)
            } catch (e) {
                console.error(e);
                if (e?.response?.data?.msg)
                    alert(e?.response?.data?.msg)
                else {
                    alert('Erro de Conexão.')
                    //logout();
                }

            }
        }
        getData()
    })
    return (
        <Grid container spacing={0} style={{ height: '100%' }} justifyItems='flex-start' alignItems={'flex-start'}>
            <button className='btn btnSecondary' onClick={() => navigate(-1)}>
                Voltar
            </button>
            <Grid item xs={12}>
                <br />
            </Grid>
            <Grid item xs={12} sm={10} style={{ textAlign: 'left', }}>
                <h1>Histórico do Usuário</h1>
            </Grid>
            <Grid item xs={12} >
                <br />
            </Grid>
            {history?.length < 1 && <h2 style={{ fontWeight: 'normal' }}>Histórico vazio.</h2>}
            {history?.map(log => {
                return (<Grid key={log._id} item xs={12}>
                    <Divider style={{ width: '95%' }} />
                    <div style={{ backgroundColor: '#f8f7f7', width: '95%', padding: '5px' }}>
                        <div style={{ textDecoration: 'none', color: '#373f51' }}>
                            <Grid container justifyItems='flex-start' alignItems={'center'} style={{ paddingTop: '8px', paddingBottom: '8px' }}>

                                <Grid item xs={3}>
                                    <small>Data: </small>
                                    <br />
                                    {new Date(log.createdAt).toLocaleDateString('pt-br')}
                                </Grid>
                                <Grid item xs={3}>
                                    <small>Tipo: </small>
                                    <br />
                                    {log.type}
                                    <br />
                                </Grid>
                                <Grid item xs={3}>
                                    <small>Responsável: </small>
                                    <br />
                                    {log.userName}
                                    <br />
                                    <small>ID: </small>
                                    {log.userId}
                                </Grid>
                                <Grid item xs={3}>
                                    <small>ID do Listing: </small>
                                    <br />
                                    <Link to={'/listings/edit-listing/' + log.listingId}>{log.listingId}</Link>
                                </Grid>
                                <Grid item xs={12}>
                                    <br />
                                </Grid>
                                <Grid item xs={12} style={{ textAlign: 'center' }}>
                                    <small>Mensagem: </small>
                                    <br />
                                    {log.text}
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                    <Divider style={{ width: '95%' }} />
                </Grid >)
            })}
        </Grid>
    )
}
