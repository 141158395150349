import { createContext } from 'react';

const XMLListingsContext = createContext( { 
    xmlListings: [],
    updateXmlListings: () => {},
    saveXmlListings: () => {},
    deleteXmlListing: () => {},
    addXmlListing: () => {},
    clearXmlListings: () => {},
    reloadXmlListings: () => {},
    deleteAdd: () => {} } );

export default XMLListingsContext;