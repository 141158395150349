import React, { useState, useContext } from 'react';
import { Dialog, DialogTitle, DialogContent, Button, List, ListItem, ListItemText, TextField } from '@mui/material';
import XMLFilesContext from '../../contexts/XMLFilesContexts';
import { AuthContext } from '../../contexts/AuthContext';

function XMLModal({ open, close, xmlFiles, add }) {
    const {files, selectedFile, setSelectedFile, addFile, removeFile, updateFileList} = useContext( XMLFilesContext )
    const { user, logout, token } = useContext(AuthContext);
    const [newFileName, setNewFileName] = useState( '' );

    const handleFileSelect = (file) => {
        setSelectedFile(file);
        close();
        // Continue with editing logic here...
    };

    const handleCreateNew = async () => {
        const GENERAL_NAME_VALIDATOR = /^([A-Za-z0-9\'\-_\.À-ÖÙ-öù-ÿ]{2,})(\s[A-Za-z0-9\'\-_\.À-ÖÙ-öù-ÿ]{1,})?$/;
        if (newFileName.length === 0) {
            alert( 'Por favor, insira um nome para o arquivo!' );
            return;
        }
        if( files.indexOf( newFileName ) !== -1 ) {
            alert('Já existe um arquivo com esse nome!');
            return;
        }
        if( GENERAL_NAME_VALIDATOR.test( newFileName ) === false ) {
            alert( 'Nome de arquivo com caracteres inválidos!' );
            return;
        }
        setSelectedFile('');
        await add(newFileName, token);
        setNewFileName('');

        //await updateFileList( );
        //close();
        // Continue with creation logic here...
    };

    return (
        <Dialog open={open} onClose={close}>
            <DialogTitle>Select an XML file</DialogTitle>
            <DialogContent>
                <List>
                    {xmlFiles.map(file => (
                        <ListItem key={file} onClick={() => handleFileSelect(file)}>
                            <ListItemText primary={file} />
                        </ListItem>
                    ))}
                </List>
                <TextField
                    placeholder='NovoXML'
                    onChange={(e) => {
                        if( e.target.value.includes(' ') ) {
                            e.target.value = e.target.value.split(' ').join('_');
                        }
                        setNewFileName(e.target.value.trim());
                    }}
                    value={newFileName}
                    fullWidth
                    style={{ marginRight: '5px' }}
                />
                <Button variant="outlined" onClick={handleCreateNew}>Create New XML</Button>
            </DialogContent>
        </Dialog>
    );
}

export default XMLModal;
