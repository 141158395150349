/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-target-blank */
import { Grid, IconButton, Zoom } from '@mui/material'
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import command from '../assets/imgs/video.jpeg'
import { AuthContext } from '../contexts/AuthContext';
import NewTreinamentoCollection from '../views/NewTreinamentoCollection';
import NewTreinamentoVideo from '../views/NewTreinamentoVideo';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import EditIcon from '@mui/icons-material/Edit';
import { BASE_URL, SOUKW_URL } from '../sharedComponents/Constants';

export default function TreinamentosVideos(props) {
    const { jwtUser, token, logout } = useContext(AuthContext);
    const { path } = useParams();
    const [treinamentos, setTreinamentos] = useState([]);
    const navigate = useNavigate()
    const [collection, setCollection] = useState(null);
    const [collections, setCollections] = useState([]);
    const [newTreinamentoModalOpen, setNewTreinamentoModalOpen] = useState(false);
    const [newCollectionModalOpen, setNewCollectionModalOpen] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [treinamentoEdit, setTreinamentoEdit] = useState(null);
    const [collectionEdit, setCollectionEdit] = useState(null);
    const location = useLocation()

    useEffect(() => {
        async function getData() {
            try {
                const config = {
                    headers: { Authorization: `Bearer ${token}` }
                };

                let res = await axios.get(BASE_URL + '/treinamentos/collections/by_id/' + path, config);

                let _id = res.data?._id
                let res3 = await axios.get(BASE_URL + '/treinamentos/collections?parent=' + _id, config);
                if (!_id)
                    navigate(-1)

                let res2 = await axios.get(BASE_URL + '/treinamentos/collections/videos/by_collection/' + _id, config);
                setCollections(res3.data?.sort((a, b) => parseFloat(a.order) - parseFloat(b.order)))
                setTreinamentos(res2.data?.sort((a, b) => parseFloat(a.order) - parseFloat(b.order)))
                setCollection(res.data)
            }
            catch (e) {
                console.error(e);
                if (e?.response?.data?.msg)
                    alert(e?.response?.data?.msg)
                else {
                    alert('Erro de Conexão.')
                    logout();
                }
            }
        }
        getData()
    }, [path])


    const openNewTreinamentoModal = () => {
        setTreinamentoEdit(null);
        setNewTreinamentoModalOpen(true);
    }
    const closeNewTreinamentoModal = () => {
        setNewTreinamentoModalOpen(false);
        setTreinamentoEdit(null);
    }
    const openNewCollectionModal = () => {
        setCollectionEdit(null);
        setNewCollectionModalOpen(true);
    }
    const closeNewCollectionModal = () => {
        setNewCollectionModalOpen(false);
        setCollectionEdit(null);
    }

    const convertType = (type) => {
        let n = '';
        switch (parseInt(type)) {
            case 0: n = 'AGENTE';
                break;
            case 1: n = 'TEAM LEADER';
                break;
            case 2: n = 'MC OP';
                break;
            case 3: n = 'REGIONAL';
                break;
            default: n = ''
        }
        return n
    }


    const submitDelete = async (item, isCol) => {

        try {
            const config = {
                headers: { Authorization: `Bearer ${token}` }
            };
            if (isCol) {
                let res = await axios.delete(BASE_URL + '/treinamentos/collections/' + item?._id, config);
                if (res.data)
                    alert('Deletado com sucesso!');
            }
            else {
                let res = await axios.delete(BASE_URL + '/treinamentos/collections/videos/' + item?._id, config);
                if (res.data)
                    alert('Deletado com sucesso!');
            }
            document.location.reload()
        } catch (e) {
            console.error(e);
            if (e?.response?.data?.msg)
                alert(e?.response?.data?.msg)
            else {
                alert('Erro de Conexão.')
                logout();
            }
        }
    }

    return (
        <div style={{ width: '100%' }} key={Date.now()}>
            <button className='btn btnSecondary' onClick={() => navigate(-1)}>
                Voltar
            </button>
            <Grid item xs={12} >
                <br />
            </Grid>
            <Grid container spacing={0} style={{ height: '100%' }} justifyItems='flex-start' alignItems={'flex-start'}>
                <Grid item xs={12} sm={9} style={{ textAlign: 'left', }}>
                    <h1>{collection?.name}</h1>
                </Grid>
                <Grid item xs={12} >
                    <br />
                </Grid>
                <Grid item xs={12} sm={5} style={{ textAlign: 'left', }}>
                    {collection?.desc}
                </Grid>
                {jwtUser?.permissionLevel.match(/ADMIN|SUPER/) &&
                    <Grid item xs={12} sm={2} style={{ textAlign: 'right', }}>
                        <button className='btn btnSecondary' onClick={() => setEditMode(!editMode)}>{editMode ? 'Cancelar' : 'Editar'}</button>
                    </Grid>
                }
                {jwtUser?.permissionLevel.match(/ADMIN|SUPER/) &&
                    <Grid item xs={12} sm={2} style={{ textAlign: 'right', }}>
                        <button className='btn btnSecondary' onClick={openNewCollectionModal} style={{ marginRight: '5px' }}>Nova Coleção</button>
                    </Grid>
                }
                {jwtUser?.permissionLevel.match(/ADMIN|SUPER/) &&
                    <Grid item xs={12} sm={2} style={{ textAlign: 'right', }}>
                        <button className='btn btnPrimary' onClick={openNewTreinamentoModal}>Novo Vídeo</button>
                    </Grid>
                }
                <Grid item xs={1} >
                    <br />
                </Grid>
                <Grid item xs={12} >
                    <br />
                </Grid>
                <Grid item xs={12} >
                    <Grid container spacing={0} style={{ height: '100%' }} justifyItems='center' alignItems={'center'}>
                        {
                            collections?.map(t => {
                                return (
                                    <Grid key={t._id + '2'} item xs={12} sm={6} md={3} lg={2.1}>
                                        <Zoom in={editMode} style={{ zIndex: 1 }}>
                                            <div style={{ position: 'absolute' }}>
                                                <IconButton
                                                    onClick={() => submitDelete(t, true)}
                                                    style={{
                                                        backgroundColor: '#a7002f',
                                                        color: 'white',
                                                        zIndex: 1,
                                                        marginRight: '2px',
                                                        marginLeft: '2px'
                                                    }}><DeleteForeverRoundedIcon />
                                                </IconButton>
                                                <IconButton
                                                    onClick={() => setCollectionEdit(t)}
                                                    style={{
                                                        zIndex: 1,
                                                        backgroundColor: '#0099a7',
                                                        color: 'white',
                                                        marginRight: '2px',
                                                        marginLeft: '2px'
                                                    }}><EditIcon />
                                                </IconButton>
                                            </div>
                                        </Zoom>
                                        <Link key={t._id} to={'/soukw/training/videos/' + t._id}>
                                            <a href={t.url} target='_blank'>
                                                <div style={{
                                                    height: '220px',
                                                    width: '220px',
                                                    backgroundImage: `url(${command})`,
                                                    backgroundSize: 'cover',
                                                    cursor: 'pointer',
                                                    position: 'relative'
                                                }}>
                                                    <h1 style={{
                                                        fontSize: t.name.length < 25 ? '1.1rem' : '.9rem',
                                                        position: 'absolute',
                                                        left: '15%',
                                                        top: t.name.length < 25 ? '10%' : '2%',
                                                        color: '#ffffff', textTransform: 'capitalize', marginRight: '50px'
                                                    }}>
                                                        <span style={{ color: '#ce011f', }}>
                                                            {convertType(t.role)}
                                                        </span>
                                                        <br />
                                                        {t.name}
                                                    </h1>
                                                </div>
                                            </a>
                                            <br />
                                            <br />
                                        </Link>
                                    </Grid>
                                )
                            })
                        }
                        {
                            treinamentos?.map(t => {
                                return (
                                    <Grid key={t._id + '2'} item xs={12} sm={6} md={3} lg={2.1}>
                                        <Zoom in={editMode} style={{ zIndex: 1 }}>
                                            <div style={{ position: 'absolute' }}>
                                                <IconButton
                                                    onClick={() => submitDelete(t, false)}
                                                    style={{
                                                        backgroundColor: '#a7002f',
                                                        color: 'white',
                                                        zIndex: 1,
                                                        marginRight: '2px',
                                                        marginLeft: '2px'
                                                    }}><DeleteForeverRoundedIcon />
                                                </IconButton>
                                                <IconButton
                                                    onClick={() => setTreinamentoEdit(t)}
                                                    style={{
                                                        zIndex: 1,
                                                        backgroundColor: '#0099a7',
                                                        color: 'white',
                                                        marginRight: '2px',
                                                        marginLeft: '2px'
                                                    }}><EditIcon />
                                                </IconButton>
                                            </div>
                                        </Zoom>
                                        <a href={t.url} target='_blank'>
                                            <div style={{
                                                height: '200px',
                                                width: '200px',
                                                backgroundImage: `url(${command})`,
                                                backgroundSize: 'cover',
                                                cursor: 'pointer',
                                                position: 'relative'
                                            }}>
                                                <h1 style={{
                                                    fontSize: t.name.length < 15 ? '1.1rem' : '.8rem',
                                                    position: 'absolute',
                                                    left: '15%',
                                                    top: t.name.length < 20 ? '10%' : '2%',
                                                    color: '#ffffff',
                                                    textTransform: 'capitalize', marginRight: '50px'
                                                }}>
                                                    <span style={{ color: '#ce011f', }}>
                                                        {convertType(t.role)}
                                                    </span>
                                                    <br />
                                                    {t.name}
                                                </h1>
                                            </div>
                                        </a>
                                        <br />
                                        <br />
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                </Grid>
            </Grid>
            <NewTreinamentoVideo open={newTreinamentoModalOpen || treinamentoEdit} close={closeNewTreinamentoModal} collectionId={collection?._id} treinamento={treinamentoEdit} />
            <NewTreinamentoCollection open={newCollectionModalOpen || collectionEdit} close={closeNewCollectionModal} parentCollectionId={collection?._id} leadershipOnly={collection?.leadershipOnly} collection={collectionEdit} />
        </div>
    )
}
