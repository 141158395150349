/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
import { Container, Grid } from '@mui/material'
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext'
import Loading from '../views/Loading';
import commandImg from '../assets/imgs/command.png'
import agent from '../assets/imgs/agent.png'
import leadershipImg from '../assets/imgs/leadership.png'
import { timeout } from 'q';
import { BASE_URL, SOUKW_URL } from '../sharedComponents/Constants';

export default function TreinamentosHome() {
    const { user, token, logout, jwtUser } = useContext(AuthContext);
    const navigate = useNavigate();
    const [total, setTotal] = useState([]);
    const [command, setCommand] = useState([]);
    const [growthCalls, setGrowthCalls] = useState([]);
    const [others, setOthers] = useState([]);
    const [agents, setAgents] = useState([]);
    const [leadership, setLeadership] = useState([]);
    // const [sistemas, setSistemas] = useState([]);

    const [loading, setLoading] = useState(true);
    useEffect(() => {
        async function getData() {

            try {
                const config = {
                    headers: { Authorization: `Bearer ${token}` }
                };
                let results = await axios.get(BASE_URL + '/treinamentos/command', config);
                let results2 = await axios.get(BASE_URL + '/treinamentos/growth-calls', config);
                let results3 = await axios.get(BASE_URL + '/treinamentos/collections/videos', config);
                // let results4 = await axios.get(BASE_URL + '/treinamentos/sistemas', config);
                let command = results.data;
                let gc = results2.data;
                let others = results3.data
                // let sistemas = results4.data
                let agentsArr = [...gc, ...others].filter(v => v.leadershipOnly === false)
                let leadershipArr = [...gc, ...others].filter(v => v.leadershipOnly === true)
                setAgents(agentsArr.length)
                setLeadership(leadershipArr.length)
                setCommand(command.length);
                setGrowthCalls(gc.length);
                setOthers(others.length);
                setLoading(false);                
                // setSistemas(sistemas.length);
            } catch (e) {
                console.error(e);
                setLoading(false);
                if (e?.response?.data?.msg)
                    alert(e?.response?.data?.msg)
                else {
                    alert('Erro de Conexão.')
                    logout();
                }
            }
        }
        getData();
    }, [])

    if (loading)
        return <Loading />
        // alert(
        //         'Os conteúdos de treinamento da KWSP só podem ser acessados com o email @Kwsp.com.br. Em caso de dúvidas, favor contatar o seu Team Leader!'
        //     );
    return (
        <Grid container spacing={0} style={{ height: '100%', marginRight: '75px', textAlign: 'center' }}>
            <Grid item xs={12} style={{ textAlign: 'left' }}>
                <h1>Bem Vindo, {user?.fullName}!</h1>
            </Grid>
            <Grid item xs={12}>
                <div><br /></div>
            </Grid>
            <Grid item xs={12} style={{ textAlign: 'left' , border:'2px solid black', backgroundColor: 'yellow'}}>
                <h1>IMPORTANTE!</h1>
                <h6>Os conteúdos de treinamento da KWSP só podem ser acessados com o email @kwsp.com.br. Em caso de dúvidas, favor contatar o seu Team Leader!</h6>
            </Grid>
            <Grid item xs={12}>
                <div><br /></div>
            </Grid>
            <Grid item xs={jwtUser.permissionLevelTreinamentos === 'AGENT' ? 6 : 4}>
                <div className='card' style={{ width: '100%', paddingTop: '10px', paddingBottom: '15px', backgroundColor: 'white' }}>
                    <img src={commandImg} />
                    <h6>Sistemas</h6>
                    <h1>{command}</h1>
                    <button className='btn btnSecondary' onClick={() => navigate('/soukw/training/sistemas')} style={{ marginTop: '10px' }}>Ver Todos</button>
                </div>
            </Grid>
            {/* <Grid item xs={jwtUser.permissionLevelTreinamentos === 'AGENT' ? 6 : 4}>
                <div className='card' style={{ width: '100%', paddingTop: '10px', paddingBottom: '15px', backgroundColor: 'white' }}>
                    <img src={commandImg} />
                    <h6>Sistemas</h6>
                    <h1>{sistemas}</h1>
                    <button className='btn btnSecondary' onClick={() => navigate('/treinamentos/sistemas')} style={{ marginTop: '10px' }}>Ver Todos</button>
                </div>
            </Grid> */}
            <Grid item xs={jwtUser.permissionLevelTreinamentos === 'AGENT' ? 6 : 4}>
                <div className='card' style={{ width: '100%', paddingTop: '10px', paddingBottom: '15px' }}>
                    <img src={agent} />
                    <h6>Agentes</h6>
                    <h1>{agents}</h1>
                    <button className='btn btnSecondary' onClick={() => navigate('/soukw/training/agents')} style={{ marginTop: '10px' }}>Ver Todos</button>
                </div>
            </Grid>
            {jwtUser.permissionLevelTreinamentos !== 'AGENT' &&
                <Grid item xs={4}>
                    <div className='card' style={{ width: '100%', paddingTop: '10px', paddingBottom: '15px' }}>
                        <img src={leadershipImg} />
                        <h6>Liderança</h6>
                        <h1>{leadership}</h1>
                        <button className='btn btnSecondary' onClick={() => navigate('/soukw/training/leadership')} style={{ marginTop: '10px' }}>Ver Todos</button>
                    </div>
                </Grid>
            }
            <Grid>
                
            </Grid>
        </Grid >
    )
}
