import { Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControlLabel, MenuItem, TextField } from '@mui/material'
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import Geocode from 'react-geocode';
import { AuthContext } from '../contexts/AuthContext';
import placeholder from '../assets/imgs/no-image.jpg'
import { BASE_URL, SOUKW_URL } from '../sharedComponents/Constants';

export default function NewTreinamentoCollection({ open, close, leadershipOnly, parentCollectionId, collection }) {
    const { token, user, logout } = useContext(AuthContext);

    const [name, setName] = useState('');
    const [desc, setDesc] = useState('');
    const [order, setOrder] = useState(0);

    useEffect(() => {
        setName(collection ? collection.name : '');
        setDesc(collection ? collection.desc : '');
        setOrder(collection ? collection.order : 0);
    }, [collection]);

    const submit = async () => {
        if (!name || order !== order) {
            alert('Preencha todos os campos.');
            return;
        }
        try {
            const config = {
                headers: { Authorization: `Bearer ${token}` }
            };
            let colId = parentCollectionId ? parentCollectionId : -1
            if (!collection) {
                let json = { name, order, desc, leadershipOnly, parentCollectionId: colId }
                console.log(json)
                let res = await axios.post(BASE_URL + '/treinamentos/collections', json, config);
                if (res.data)
                    alert('Cadastro bem sucedido!');
            }
            else {
                let json = { _id: collection?._id, name, order, desc, leadershipOnly, parentCollectionId: colId }
                let res = await axios.patch(BASE_URL + '/treinamentos/collections/' + collection?._id, json, config);
                if (res.data)
                    alert('Editado com sucesso!');
            }
            close();
            document.location.reload()
        } catch (e) {
            console.error(e);
            if (e?.response?.data?.msg)
                alert(e?.response?.data?.msg)
            else {
                alert('Erro de Conexão.')
                logout();
            }
        }
    }
    return (
        <div>
            <Dialog open={open} onClose={close} maxWidth='sm'>
                <DialogTitle>
                    <div style={{ width: '500px' }}>
                        <h1>{collection ? 'Editar' : 'Cadastrar Nova'} Coleção</h1>
                        <Divider style={{ width: '100%' }} />
                    </div>
                </DialogTitle>
                <DialogContent>
                    <h6>Dados da Coleção</h6>
                    <br />
                    <TextField
                        variant='outlined'
                        label='Nome *'
                        color='secondary'
                        fullWidth
                        style={{ marginTop: '5px', marginBottom: '5px' }}
                        onChange={(e) => setName(e.target.value)}
                        value={name}
                    />
                    <TextField
                        variant='outlined'
                        label='Descrição *'
                        color='secondary'
                        multiline
                        minRows={3}
                        maxRows={5}
                        fullWidth
                        style={{ marginTop: '5px', marginBottom: '5px' }}
                        onChange={(e) => setDesc(e.target.value)}
                        value={desc}
                    />
                    <TextField
                        variant='outlined'
                        label='Ordem *'
                        color='secondary'
                        type={'number'}
                        fullWidth
                        style={{ marginTop: '5px', marginBottom: '5px' }}
                        onChange={(e) => setOrder(e.target.value)}
                        value={order}
                    />

                </DialogContent>
                <DialogActions>
                    <button className='btn btnSecondary' onClick={close}>Cancelar</button>
                    <button className='btn btnPrimary' onClick={submit}>{collection ? 'Editar' : 'Cadastrar'}</button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
