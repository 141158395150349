/* eslint-disable no-unused-vars */
import { Autocomplete, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControlLabel, MenuItem, TextField, Zoom } from '@mui/material'
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import Geocode from 'react-geocode';
import { AuthContext } from '../contexts/AuthContext';
import placeholder from '../assets/imgs/no-image.jpg'
import { Link, useNavigate, useParams } from "react-router-dom";
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import { mask, unmask } from 'remask'
import { SOUKW_URL, BASE_URL } from '../sharedComponents/Constants';

export default function EditListing({ open, close }) {
    const { token, user, jwtUser, logout } = useContext(AuthContext);
    const { id } = useParams();
    const navigate = useNavigate();

    const [users, setUsers] = useState([]);
    const [listingAgent, setListingAgent] = useState(``);

    const [cep, setCep] = useState('');
    const [rua, setRua] = useState('');
    const [numero, setNumero] = useState('');
    const [compl, setCompl] = useState('');
    const [geoloc, setGeoloc] = useState('');
    const [bairro, setBairro] = useState('');
    const [cidade, setCidade] = useState('');
    const [estado, setEstado] = useState('');

    const [listCategory, setListCategory] = useState(``);
    const [listType, setListType] = useState(``);
    const [listStatus, setListStatus] = useState(``);
    const [propType, setPropType] = useState(``);
    const [propSubType, setPropSubType] = useState(``);

    const [metragem, setMetragem] = useState('');
    const [nQuartos, setNQuartos] = useState('');
    const [nLavabos, setNLavabos] = useState('');
    const [nBanheiros, setNBanheiros] = useState('');
    const [valor, setValor] = useState('');

    const [nomeCondominio, setNomeCondominio] = useState('');
    const [andarDoImovel, setAndarDoImovel] = useState('');
    const [novoImovel, setNovoImovel] = useState('');
    const [nVagas, setNVagas] = useState('');
    const [construtora, setConstrutora] = useState('');
    const [nUnidades, setNUnidades] = useState('');
    const [nAndares, setNAndares] = useState('');
    const [nTorres, setNTorres] = useState('');

    const [churrasqueira, setChurrasqueira] = useState(false);
    const [deck, setDeck] = useState(false);
    const [acessoDeficientes, setAcessoDeficientes] = useState(false);
    const [doca, setDoca] = useState(false);
    const [jardim, setJardim] = useState(false);
    const [entradaFechada, setEntradaFechada] = useState(false);
    const [estufa, setEstufa] = useState(false);
    const [hidro, setHidro] = useState(false);
    const [patio, setPatio] = useState(false);
    const [lago, setLago] = useState(false);
    const [piscina, setPiscina] = useState(false);
    const [varanda, setVaranda] = useState(false);
    const [vagaTrailer, setVagaTrailer] = useState(false);
    const [quadra, setQuadra] = useState(false);
    const [aspercao, setAspercao] = useState(false);
    const [beiraMar, setBeiraMar] = useState(false);

    const [anoConstrucao, setAnoConstrucao] = useState('');
    const [tamanhoTerreno, setTamanhoTerreno] = useState('');
    const [descricao, setDescricao] = useState('');

    const [status, setStatus] = useState('');

    const [mainPhoto, setMainPhoto] = useState('');
    const [photos, setPhotos] = useState([]);
    const [photosObjArr, setPhotosObjArr] = useState([]);

    const [matricula, setMatricula] = useState('');
    const [iptuAnual, setIptuAnual] = useState('');
    const [condominioMensal, setCondominioMensal] = useState('');

    const [ownerName, setOwnerName] = useState('');
    const [ownerCPF, setOwnerCPF] = useState('');
    const [ownerRG, setOwnerRG] = useState('');
    const [ownerEmail, setOwnerEmail] = useState('');
    const [ownerPhone, setOwnerPhone] = useState('');

    const [expDate, setExpDate] = useState('');

    const [comissionSeller, setComissionSeller] = useState('')

    const [listing, setListing] = useState({});

    const [listingOwner, setListingOwner] = useState('');
    const [listingOwnerMC, setListingOwnerMC] = useState('');
    const [listingTeam, setListingTeam] = useState('');

    const [virtualTours, setVirtualTours] = useState([]);

    const [reprovingMode, setReprovingMode] = useState(false);
    const [motivo, setMotivo] = useState('')

    const [outros, setOutros] = useState([]);
    const [outrosMaped, setOutrosMaped] = useState([]);

    const datePattern = '9999-99-99'
    const cpfPattern = ['999.999.999-99999', '99.999.999/9999-99']
    const rgPattern = ['99.999.999', '99.999.999-S']
    const cepPattern = ['99999-999']

    const userCanEdit = (listingOwner, jwtUser ) => {
        return listingOwner?._id === jwtUser._id  || (jwtUser.permissionLevel === 'ADMIN' || jwtUser.permissionLevel === 'SUPER')
    }

    const maskCurrency = (num = '.') => {
        let initial = String(num).split('.').join('').replace(/[^0-9.]/g, '');
        let formattedNum = initial;
        switch (initial.length) {
            case 1: formattedNum = num;
                break;
            case 2: formattedNum = num;
                break;
            case 3: formattedNum = num;
                break;
            case 4: formattedNum = [formattedNum.slice(0, 1), '.', formattedNum.slice(1)].join('');
                break;
            case 5: formattedNum = [formattedNum.slice(0, 2), '.', formattedNum.slice(2)].join('');
                break;
            case 6: formattedNum = [formattedNum.slice(0, 3), '.', formattedNum.slice(3)].join('');
                break;
            case 7: formattedNum = [formattedNum.slice(0, 1), '.', formattedNum.slice(1, 4), '.', formattedNum.slice(4)].join('');
                break;
            case 8: formattedNum = [formattedNum.slice(0, 2), '.', formattedNum.slice(2, 5), '.', formattedNum.slice(5)].join('');
                break;
            case 9: formattedNum = [formattedNum.slice(0, 3), '.', formattedNum.slice(3, 6), '.', formattedNum.slice(6)].join('');
                break;

        }
        return 'R$ ' + String(formattedNum).replace(/[^0-9.]/g, '').slice(0, 10)
    }
    const unmaskCurrency = (str) => {
        return String(str).replace(/[^0-9]/g, '');
    }

    useEffect(() => {
        async function getData() {
            try {
                const config = {
                    headers: { Authorization: `Bearer ${token}` }
                };
                let results = await axios.get(BASE_URL + '/listings/listing/' + id, config);
                let lst = results.data;
                setListing(lst)
                if (jwtUser?.permissionLevel.match( /ADMIN|SUPER/ ) ) {
                    let users = await axios.get(BASE_URL + '/users/', config);
                    setUsers(users.data)
                } else if (jwtUser.isMcAdmin) {
                    let users = await axios.get(BASE_URL + '/users/by_mc/' + jwtUser?.mcId, config);
                    console.log(jwtUser)
                    setUsers(users.data)
                }

                let results2 = await axios.get(lst?.newListingAgentId !== -1 ? BASE_URL + '/users/by_kwuid/' + lst?.newListingAgentId : BASE_URL + '/users/by_kwuid/' + lst?.list_kw_uid, config);
                let lstOwner = results2.data;
                console.log( "Owner: ", lstOwner )
                if (lstOwner) {
                    setListingOwner(lstOwner)
                    let results3 = await axios.get(BASE_URL + '/mcs/' + lstOwner?.marketCenterId, config);
                    let lstOwnerMC = results3.data;
                    console.log("MC Owner: ", lstOwnerMC )
                    setListingOwnerMC(lstOwnerMC)
                    if (lst?.teamId) {
                        let results4 = await axios.get(BASE_URL + '/teams/' + lst?.teamId, config);
                        let lstTeam = results4.data;
                        setListingTeam(lstTeam)
                    }
                }
                //await getLoc(lst.list_address.postal_code);
                setCep(mask(lst.list_address.postal_code, cepPattern));
                setRua(lst.list_address.street_name);
                setNumero(lst.list_address.street_number);
                setCompl(lst.list_address.unit_number);
                setBairro(lst.bairro);
                setEstado(lst.list_address.state_prov);
                setCidade(lst.list_address.city);

                setListCategory(lst.list_category_id);
                setListType(lst.list_type_id);
                setListStatus(lst.list_status_id);
                setPropType(lst.prop_type_id);
                setPropSubType(lst.prop_subtype_id);

                setMetragem(lst.living_area);
                setNQuartos(lst.total_bed);
                setNLavabos(lst.half_bath ?? 0);
                setNBanheiros(lst.full_bath);
                setValor(maskCurrency(lst.current_list_price));

                setNomeCondominio(lst.structure?.building_name);
                setAndarDoImovel(lst.structure?.unit_level);
                setNovoImovel(lst.structure?.is_new_construction)
                setNVagas(lst.structure?.parking_total)
                setNAndares(lst.structure?.stories);
                setNUnidades(lst.structure?.total_units);
                setNTorres(lst.structure?.total_buildings);
                setConstrutora(lst.structure?.builder_name);

                setChurrasqueira(lst.structure?.exterior_features?.has_barbecue_area)
                setDeck(lst.structure?.exterior_features?.has_deck);
                // setAcessoDeficientes(lst.structure?.exterior_features?.has_disabled_access);
                setDoca(lst.structure?.exterior_features?.has_dock);
                setJardim(lst.structure?.exterior_features?.has_garden);
                setEntradaFechada(lst.structure?.exterior_features?.has_gated_entry);
                setEstufa(lst.structure?.exterior_features?.has_green_house);
                setHidro(lst.structure?.exterior_features?.has_hot_tub_spa);
                setPatio(lst.structure?.exterior_features?.has_patio);
                setLago(lst.structure?.exterior_features?.has_pond);
                setPiscina(lst.structure?.exterior_features?.has_pool);
                setVaranda(lst.structure?.exterior_features?.has_porch);
                setVagaTrailer(lst.structure?.exterior_features?.has_rv_parking);
                setQuadra(lst.structure?.exterior_features?.has_sports_court);
                setAspercao(lst.structure?.exterior_features?.has_sprinkler_system);
                setBeiraMar(lst.structure?.exterior_features?.is_water_front);

                setAnoConstrucao(lst.year_built);
                setTamanhoTerreno(lst.lot_size_area);
                setDescricao(lst.list_desc?.replace('\n', ''));
                setStatus(lst.status);

                setMatricula(lst.matricula);

                setIptuAnual(maskCurrency(Math.floor( lst.iptuAnual )));
                setCondominioMensal(maskCurrency(Math.floor( lst.condominioMensal)));

                setOwnerName(lst.ownerName);
                setOwnerCPF(lst.ownerCPF);
                setOwnerRG(lst.ownerRG);
                setOwnerEmail(lst.ownerEmail);
                setOwnerPhone(lst.ownerPhone);

                setExpDate(lst.contract_expiry_dt);

                setComissionSeller(lst.comissionSeller);

                setVirtualTours(lst.virtual_tours)

                setMainPhoto(lst.photos[0])
                setPhotos(lst.photos.splice(0, 1))
                setMotivo(lst.motive)

                setOutros(lst.informacoesAdicionais)

                if( lst.informacoesAdicionais !== undefined && lst.informacoesAdicionais.length > 0) {
                    //console.log( lst.informacoesAdicionais )
                    let tmp = {}
                    for( let i = 0; i < lst.informacoesAdicionais.length; i++ ){
                        if( i % 2 === 0 )
                            tmp[ lst.informacoesAdicionais[i] ] = lst.informacoesAdicionais[ i+1 ]
                    }
                    console.log( "Temp:")
                    console.log( tmp )
                    setOutrosMaped( tmp )

                }
                //console.log( outrosMaped )
                

                if (lst.motive)
                    setReprovingMode(true);

            } catch (e) {
                console.error(e);
                if (e?.response?.data?.msg)
                    alert(e?.response?.data?.msg)
                else {
                    alert('Erro de Conexão.')
                    // logout();
                }
            }
        }
        getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    const getLoc = async (defaultCep = null) => {
        Geocode.setApiKey('AIzaSyBG0z5RFyaogJihcAmL8A2GmazXLK7lCXc');
        Geocode.setLanguage("pt-br");
        Geocode.setRegion("br");
        Geocode.setLocationType('ROOFTOP');
        if (!defaultCep) {
            if (cep == '')
                return
            try {
                let geo = await Geocode.fromAddress(cep);
                console.log(geo.results[0].address_components)
                setRua(geo.results[0].address_components[1].long_name);
                setBairro(geo.results[0].address_components[2].long_name);
                setCidade(geo.results[0].address_components[3].long_name);
                setEstado(geo.results[0].address_components[4].long_name);
                setGeoloc(geo);
            } catch (e) {
                alert('CEP não encontrado.');
            }
        } else {
            try {
                let geo = await Geocode.fromAddress(defaultCep);
                setRua(geo.results[0].address_components[1].long_name);
                setBairro(geo.results[0].address_components[2].long_name);
                setCidade(geo.results[0].address_components[3].long_name);
                setEstado(geo.results[0].address_components[4].long_name);
                setGeoloc(geo)
            } catch (e) {
                alert('CEP não encontrado.');
            }
        }
    }

    const translateListCategory = (category) => {
        switch (category) {
            case 0:
                return 'Other';
            case 1:
                return 'For Rent';
            case 2:
                return 'For Sale';
            case 3:
                return 'Sold';
            case 4:
                return 'Off Market';
        }
    }
    const translateListType = (type) => {
        switch (type) {
            case 1:
                return 'Prospective';
            case 2:
                return 'MLS Listing';
            case 3:
                return 'Coming Soon';
            case 4:
                return 'Exclusive';
            case 5:
                return 'Open Listing';
            case 6:
                return 'KW Reserve';
        }
    }
    const translateListStatus = (status) => {
        switch (status) {
            case 1:
                return 'Active';
            case 2:
                return 'Pending';
            case 3:
                return 'Sold';
            case 4:
                return 'Active Under Contract';
            case 6:
                return 'Pending Contingent';
            case 12:
                return 'Cancelled';
            case 13:
                return 'Delete';
            case 14:
                return 'Expired';
            case 15:
                return 'Leased';
            case 17:
                return 'Closed';
            case 18:
                return 'Off Market';
        }
    }
    const translatePropType = (propType) => {
        switch (propType) {
            case 1:
                return 'Business Opportunity';
            case 2:
                return 'Commercial';
            case 3:
                return 'Common Interest';
            case 4:
                return 'Farm And Agriculture';
            case 5:
                return 'Lots And Land';
            case 6:
                return 'Manufactured In Park';
            case 7:
                return 'Vacation Ownership';
            case 8:
                return 'Residential';
            case 9:
                return 'Residential Income';
            case 10:
                return 'Other';
        }
    }
    const translatePropSubType = (propSubType) => {
        switch (propSubType) {
            case 1:
                return 'Apartment';
            case 2:
                return 'Boat Slip';
            case 3:
                return 'Cabin';
            case 4:
                return 'Condominium';
            case 5:
                return 'Deeded Parking';
            case 6:
                return 'Duplex';
            case 7:
                return 'Farm';
            case 8:
                return 'Manufactured Home';
            case 9:
                return 'Manufactured On Land';
            case 10:
                return 'Mobile Home';
            case 11:
                return 'Own Your Own';
            case 12:
                return 'Quadruplex';
            case 13:
                return 'Ranch';
            case 14:
                return 'Single Family Attached';
            case 15:
                return 'Single Family Detached';
            case 16:
                return 'Stock Cooperative';
            case 17:
                return 'Timeshare';
            case 18:
                return 'Townhouse';
            case 19:
                return 'Triplex';
            case 20:
                return 'Agriculture';
            case 21:
                return 'Business';
            case 22:
                return 'Hotel-Motel';
            case 23:
                return 'Industrial';
            case 24:
                return 'Mixed Use';
            case 25:
                return 'Multi-Family';
            case 26:
                return 'Office';
            case 27:
                return 'Retail';
            case 28:
                return 'Unimproved Land';
            case 29:
                return 'Warehouse';
            case 30:
                return 'Adult Community';
            case 31:
                return 'Vacation Home';
            case 32:
                return 'Other';
        }
    }
    const submit = async () => {
        try {
            Geocode.setApiKey('AIzaSyBG0z5RFyaogJihcAmL8A2GmazXLK7lCXc');
            Geocode.setLanguage("pt-br");
            Geocode.setRegion("br");
            Geocode.setLocationType('ROOFTOP');
            let preciseGeo = await Geocode.fromAddress(rua + ', ' + numero + ' - ' + bairro + ', ' + cidade + ', ' + estado + ' - ' + cep);
            try {
                const config = {
                    headers: { Authorization: `Bearer ${token}` }
                };
                let list_address = {
                    street_number: numero,
                    street_name: rua,
                    unit_number: compl,
                    city: cidade,
                    state_prov: estado,
                    postal_code: cep,
                    country: 'BR',
                    coordinates_gp: {
                        lat: preciseGeo?.results[0]?.geometry?.location?.lat,
                        lon: preciseGeo?.results[0]?.geometry?.location?.lng,
                    },
                    coordinates_gs: {
                        coordinates: [
                            preciseGeo?.results[0]?.geometry?.location?.lng,
                            preciseGeo?.results[0]?.geometry?.location?.lat,
                        ],
                        type: "Point"
                    },

                }
                let exterior_features = {
                    has_barbecue_area: churrasqueira,
                    has_deck: deck,
                    // has_disabled_access: acessoDeficientes,
                    has_dock: doca,
                    has_garden: jardim,
                    has_gated_entry: entradaFechada,
                    has_green_house: estufa,
                    has_hot_tub_spa: hidro,
                    has_patio: patio,
                    has_pond: lago,
                    has_pool: piscina,
                    has_porch: varanda,
                    has_rv_parking: vagaTrailer,
                    has_sports_court: quadra,
                    has_sprinkler_system: aspercao,
                    is_water_front: beiraMar
                }
                let structure = {
                    building_name: nomeCondominio,
                    unit_level: andarDoImovel,
                    is_new_construction: novoImovel,
                    parking_total: nVagas,
                    has_parking: parseInt(nVagas) > 0,
                    stories: nAndares,
                    total_units: nUnidades,
                    total_buildings: nTorres,
                    exterior_features,
                    builder_name: construtora,
                }
                let json = {
                    current_list_price: unmaskCurrency(valor),
                    list_category_id: listCategory,
                    list_category: translateListCategory(listCategory),
                    list_type_id: listType,
                    list_type: translateListType(listType),
                    list_status_id: listStatus,
                    list_status: translateListStatus(listStatus),
                    prop_type_id: propType,
                    prop_type: translatePropType(propType),
                    prop_subtype_id: propSubType,
                    prop_subtype: translatePropSubType(propSubType),
                    list_address,
                    structure,
                    year_built: anoConstrucao,
                    total_bed: nQuartos,
                    half_bath: parseInt( nLavabos ?? 0 ),
                    full_bath: nBanheiros,
                    total_bath: String((parseInt(nBanheiros) + parseInt(nLavabos))),
                    living_area: metragem,
                    lot_size_area: tamanhoTerreno,
                    list_desc: descricao,
                    contract_expiry_dt: expDate,
                    teamId: listingOwner?.teamId,
                    bairro,
                    matricula,
                    iptuAnual: unmaskCurrency(iptuAnual),
                    condominioMensal: unmaskCurrency(condominioMensal),
                    ownerName,
                    ownerCPF,
                    ownerRG,
                    ownerEmail,
                    ownerPhone,
                    comissionSeller,
                    virtual_tours: virtualTours,
                    newListingAgentId: listingOwner?.kwuid,
                    market_center: listingOwnerMC?.kwId,
                }
                let res = await axios.patch(BASE_URL + '/listings/' + id, json, config);
                alert('Editado com sucesso!');
                navigate(-1)
            } catch (e) {
                console.error(e);
                if (e?.response?.data?.msg)
                    alert(e?.response?.data?.msg)
                else {
                    alert('Erro de Conexão.')
                    logout();
                }
            }
        } catch (e) {
            console.error(e)
            alert('O endereço do imóvel não foi encontrado.');
            return;
        }

    }

    const changeStatus = async (status) => {
        try {
            const config = {
                headers: { Authorization: `Bearer ${token}` }
            };
            let res = await axios.patch(BASE_URL + '/listings/change_status/' + id, { status, motive: motivo }, config);
            switch (status) {
                case 'ATIVO': alert('Aprovado com sucesso!');
                    break;
                case 'REPROVADO': alert('Reprovado com sucesso!');
                    break;
                case 'PENDENTE': alert('Solicitação reenviada com sucesso!');
                    break;
            }
            navigate(-1)
        } catch (e) {
            console.error(e);
            if (e?.response?.data?.msg)
                alert(e?.response?.data?.msg)
            else {
                alert('Erro de Conexão.')
                logout();
            }
        }
    }

    const getCEP = async () => {
        setRua('')
        setBairro('')
        setEstado('')
        setCidade('')
        try {
            let res = await axios.get('https://viacep.com.br/ws/' + String(cep).split('-').join('') + '/json/')
            let data = res.data
            if (data.erro)
                alert('CEP não encontrado.')

            setRua(data.logradouro)
            setBairro(data.bairro)
            setEstado(data.uf)
            setCidade(data.localidade)
        } catch (e) {
            alert('CEP não encontrado.')
        }

    }

    const submitDuplicate = async (listing) => {
        try {
            const config = {
                headers: { Authorization: `Bearer ${token}` }
            };
            let res = await axios.post(BASE_URL + '/listings/clone/' + id, { status, motive: motivo }, config);
            window.open(SOUKW_URL + '/listings/edit-listing/' + res?.data?._id, '_blank')
        } catch (e) {
            console.error(e);
            if (e?.response?.data?.msg)
                alert(e?.response?.data?.msg)
            else {
                alert('Erro de Conexão.')
                logout();
            }
        }

    }

    console.log(jwtUser)
    console.log(jwtUser.permissionLevel.match( /ADMIN|SUPER/ ) ? true : false)
    return (
        <div style={{ marginRight: '80px', }}>
            <div style={{ position: 'relative', marginBottom: '50px' }}>
                <div style={{ position: 'absolute', left: '0' }}>
                    <button className='btn btnSecondary' onClick={() => navigate(-1)}>
                        Voltar
                    </button>
                </div>
                <div style={{ position: 'absolute', right: '300px' }}>
                    <Link to={'/listings/edit-docs/' + listing?._id} style={{ textDecoration: 'none' }}>
                        <button className='btn btnSecondary'>
                           { userCanEdit(listingOwner, jwtUser) ? 'Ver Documentos':'Ver Documentos' }
                            <input type="file" style={{ display: 'none' }} accept="image/*"
                                onChange={(e) => setMainPhoto(e.target.files[0])}
                            />
                        </button>
                    </Link>
                </div>
                <div style={{ position: 'absolute', right: '0' }}>
                    <Link to={'/listings/edit-photos/' + listing?._id} style={{ textDecoration: 'none' }}>
                        <button className='btn btnPrimary'>
                            { userCanEdit(listingOwner, jwtUser)  ? 'Ver Fotos' : 'Ver Fotos'}
                            <input type="file" style={{ display: 'none' }} accept="image/*"
                                onChange={(e) => setMainPhoto(e.target.files[0])}
                            />
                        </button>
                    </Link>
                </div>
            </div>
            <br />
            <div>
                <button className='btn btnSecondary' onClick={() => navigate('/soukw/listings/history/' + listing?._id)} style={{ marginRight: '5px' }}>
                    Ver Histórico
                </button>
                {/* { userCanEdit(listingOwner, jwtUser) &&
                    <button className='btn btnPrimary' onClick={() => submitDuplicate(listing)}>
                        Duplicar Listing
                    </button>
                } */}
            </div>
            <br />
            <div className='card' style={{ padding: '20px', overflowY: 'scroll', height: '84vh' }}>
                <h6>ID</h6>
                <br />
                <TextField
                    variant='outlined'
                    color='secondary'
                    fullWidth
                    disabled
                    style={{ marginTop: '5px', marginBottom: '5px' }}
                    value={listing?._id}
                />
                <br />
                <br />
                {userCanEdit(listingOwner, jwtUser) &&
                    <h6>Agente Vendedor</h6>
                }
                <br />
                {(jwtUser.isMcAdmin || jwtUser.permissionLevel.match( /ADMIN|SUPER/ ) ) &&
                     <Autocomplete
                     disabled
                         options={users}
                         getOptionLabel={(option) => option.fullName}
                         filterSelectedOptions
                         noOptionsText='Nenhum Usuário'
                         value={listingOwner}
                         onChange={(e, newValue) => {
                             setListingOwner(newValue)
                         }}
                         renderInput={(params) => (
                             <TextField
                                 {...params}
                                 label="Agente Vendedor"
                                 placeholder="Agente Vendedor"
                             />
                         )}
                     />
                }
                {userCanEdit(listingOwner, jwtUser) &&
                    <TextField
                        variant='outlined'
                        color='secondary'
                        fullWidth
                        disabled
                        style={{ marginTop: '5px', marginBottom: '5px' }}
                        value={listingOwner?.fullName}
                    />
                }
                <TextField
                    variant='outlined'
                    color='secondary'
                    fullWidth
                    disabled
                    style={{ marginTop: '5px', marginBottom: '5px' }}
                    value={listingOwnerMC?.name}
                />
                <TextField
                    variant='outlined'
                    color='secondary'
                    fullWidth
                    disabled
                    style={{ marginTop: '5px', marginBottom: '5px' }}
                    value={listingTeam?.name}
                />
                <br />
                <br />
                <h6>Endereço</h6>
                <br />
                <br />
                <TextField
                    variant='outlined'
                    label='CEP *'
                    color='secondary'
                    fullWidth
                    style={{ marginTop: '5px', marginBottom: '5px' }}
                    onChange={(e) => setCep(mask(e.target.value, cepPattern))}
                    value={cep}
                    disabled
                    // disabled={listingOwner?._id !== jwtUser._id && (jwtUser.permissionLevel === "ADMIN" || jwtUser.permissionLevel === "SUPER") }
                />
                <button disabled className='btn btnPrimary' onClick={getCEP}>Puxar Endereço Pelo CEP</button>
                <br />
                <br />
                <TextField
                    variant='outlined'
                    label='Rua *'
                    color='secondary'
                    fullWidth
                    style={{ marginTop: '5px', marginBottom: '5px' }}
                    onChange={(e) => setRua(e.target.value)}
                    value={rua}
                    disabled
                    // disabled={listingOwner?._id !== jwtUser._id && jwtUser.permissionLevel.match( /ADMIN|SUPER/ )}
                />
                <TextField
                    variant='outlined'
                    label='Número *'
                    color='secondary'
                    fullWidth
                    style={{ marginTop: '5px', marginBottom: '5px' }}
                    onChange={(e) => setNumero(e.target.value)}
                    value={numero}
                    disabled
                    // disabled={listingOwner?._id !== jwtUser._id && jwtUser.permissionLevel.match( /ADMIN|SUPER/ )}
                />
                <TextField
                    variant='outlined'
                    label='Complemento'
                    color='secondary'
                    fullWidth
                    style={{ marginTop: '5px', marginBottom: '5px' }}
                    onChange={(e) => setCompl(e.target.value)}
                    value={compl}
                    disabled
                    // disabled={listingOwner?._id !== jwtUser._id && jwtUser.permissionLevel.match( /ADMIN|SUPER/ )}
                />
                <TextField
                    variant='outlined'
                    label='Bairro *'
                    color='secondary'
                    fullWidth
                    style={{ marginTop: '5px', marginBottom: '5px' }}
                    onChange={(e) => setBairro(e.target.value)}
                    value={bairro}
                    disabled
                    // disabled={listingOwner?._id !== jwtUser._id && jwtUser.permissionLevel.match( /ADMIN|SUPER/ )}
                />
                <TextField
                    variant='outlined'
                    label='Estado *'
                    color='secondary'
                    fullWidth
                    style={{ marginTop: '5px', marginBottom: '5px' }}
                    onChange={(e) => setEstado(e.target.value)}
                    value={estado}
                    disabled
                    // disabled={listingOwner?._id !== jwtUser._id && jwtUser.permissionLevel.match( /ADMIN|SUPER/ )}
                />
                <TextField
                    variant='outlined'
                    label='Cidade *'
                    color='secondary'
                    fullWidth
                    style={{ marginTop: '5px', marginBottom: '5px' }}
                    onChange={(e) => setCidade(e.target.value)}
                    value={cidade}
                    disabled
                    // disabled={listingOwner?._id !== jwtUser._id && jwtUser.permissionLevel.match( /ADMIN|SUPER/ )}
                />
                <br />
                <br />
                <Divider />
                <br />
                <h6>Tipo de Imóvel</h6>
                <br />
                <p>Categoria *</p>
                <TextField
                    variant='outlined'
                    select
                    color='secondary'
                    fullWidth
                    style={{ marginTop: '5px', marginBottom: '5px' }}
                    onChange={(e) => setListCategory(e.target.value)}
                    disabled
                    // disabled={listingOwner?._id !== jwtUser._id && jwtUser.permissionLevel.match( /ADMIN|SUPER/ )}
                    value={listCategory}
                >
                    <MenuItem value={0}>Outro</MenuItem>
                    <MenuItem value={1}>Locação</MenuItem>
                    <MenuItem value={2}>À Venda</MenuItem>
                    <MenuItem value={3}>Vendido</MenuItem>
                    <MenuItem value={4}>Fora do Mercado</MenuItem>
                </TextField>
                <p>Tipo de Cadastro *</p>
                <TextField
                    variant='outlined'
                    select
                    color='secondary'
                    fullWidth
                    style={{ marginTop: '5px', marginBottom: '5px' }}
                    disabled
                    // disabled={listingOwner?._id !== jwtUser._id && jwtUser.permissionLevel.match( /ADMIN|SUPER/ )}
                    onChange={(e) => setListType(e.target.value)}
                    value={listType}
                >
                    <MenuItem value={1}>Em Negociação</MenuItem>
                    <MenuItem value={2}>MLS Listing</MenuItem>
                    <MenuItem value={3}>Em Breve</MenuItem>
                    <MenuItem value={4}>Com Exclusividade</MenuItem>
                    <MenuItem value={5}>Sem Exclusividade</MenuItem>
                    <MenuItem value={6}>Reservado</MenuItem>
                </TextField>
                <p>Status *</p>
                <TextField
                    variant='outlined'
                    select
                    color='secondary'
                    fullWidth
                    style={{ marginTop: '5px', marginBottom: '5px' }}
                    onChange={(e) => setListStatus(e.target.value)}
                    value={listStatus}
                    disabled
                    // disabled={listingOwner?._id !== jwtUser._id && jwtUser.permissionLevel.match( /ADMIN|SUPER/ )}
                >
                    <MenuItem value={1}>Ativo</MenuItem>
                    <MenuItem value={2}>Pendente</MenuItem>
                    <MenuItem value={3}>Vendido</MenuItem>
                    <MenuItem value={4}>Em Negociação</MenuItem>
                    <MenuItem value={6}>Pendências Contratuais</MenuItem>
                    <MenuItem value={12}>Cancelado (NÂO APARECE NO SITE)</MenuItem>
                    <MenuItem value={13}>Removido</MenuItem>
                    <MenuItem value={14}>Expirado</MenuItem>
                    <MenuItem value={15}>Alugado</MenuItem>
                    <MenuItem value={17}>Vendido</MenuItem>
                    <MenuItem value={18}>Não Disponível (NÂO APARECE NO SITE)</MenuItem> {/* OFF MARKET */}
                </TextField>
                <p>Tipo de Imóvel *</p>
                <TextField
                    variant='outlined'
                    select
                    color='secondary'
                    fullWidth
                    style={{ marginTop: '5px', marginBottom: '5px' }}
                    onChange={(e) => setPropType(e.target.value)}
                    value={propType}
                    disabled
                    // disabled={listingOwner?._id !== jwtUser._id && jwtUser.permissionLevel.match( /ADMIN|SUPER/ )}
                >
                    <MenuItem value={1}>Oportunidade de Investimento (NÂO APARECE NO SITE)</MenuItem>
                    {/* <MenuItem value={2}>Comercial (NÂO APARECE NO SITE)</MenuItem> */}
                    <MenuItem value={3}>Comercial</MenuItem>
                    <MenuItem value={4}>Rural</MenuItem>
                    <MenuItem value={5}>Terreno</MenuItem>
                    <MenuItem value={6}>Casas Modulares</MenuItem>
                    <MenuItem value={7}>Casa de Férias</MenuItem>
                    <MenuItem value={8}>Residencial</MenuItem>
                    <MenuItem value={9}>Oportunidade de Rentabilidade</MenuItem>
                    <MenuItem value={10}>Outro (NÂO APARECE NO SITE)</MenuItem>
                </TextField>
                <p>Subtipo de Imóvel *</p>
                <TextField
                    variant='outlined'
                    select
                    color='secondary'
                    fullWidth
                    style={{ marginTop: '5px', marginBottom: '5px' }}
                    onChange={(e) => setPropSubType(e.target.value)}
                    disabled
                    // disabled={listingOwner?._id !== jwtUser._id && jwtUser.permissionLevel.match( /ADMIN|SUPER/ )}
                    value={propSubType}
                >
                    <MenuItem value={1}>Apartamento</MenuItem>
                    <MenuItem value={2}>Vaga de Barco</MenuItem>
                    <MenuItem value={3}>Chalé</MenuItem>
                    <MenuItem value={4}>Condomínio</MenuItem>
                    <MenuItem value={5}>Vaga de Garagem</MenuItem>
                    <MenuItem value={6}>Duplex</MenuItem>
                    <MenuItem value={7}>Fazenda</MenuItem>
                    <MenuItem value={8}>Casa Modular</MenuItem>
                    <MenuItem value={9}>Casa Modular com Terreno</MenuItem>
                    <MenuItem value={10}>Casa Móvel</MenuItem>
                    <MenuItem value={11}>OYO</MenuItem>
                    <MenuItem value={12}>Quadruplex</MenuItem>
                    <MenuItem value={13}>Rancho</MenuItem>
                    <MenuItem value={14}>Casa Geminada</MenuItem>
                    <MenuItem value={15}>Casa Não Geminada</MenuItem>
                    <MenuItem value={16}>Cooperativa</MenuItem>
                    <MenuItem value={17}>Timeshare</MenuItem>
                    <MenuItem value={18}>Townhouse</MenuItem>
                    <MenuItem value={19}>Triplex</MenuItem>
                    <MenuItem value={20}>Agricultura (NÃO APARECE NO SITE)</MenuItem>
                    <MenuItem value={21}>Negócios (NÃO APARECE NO SITE)</MenuItem>
                    <MenuItem value={22}>Hotel-Motel (NÃO APARECE NO SITE)</MenuItem>
                    <MenuItem value={23}>Industrial (NÃO APARECE NO SITE)</MenuItem>
                    <MenuItem value={24}>Mixed-Use</MenuItem>
                    <MenuItem value={25}>Multi-Family</MenuItem>
                    <MenuItem value={26}>Comercial (NÃO APARECE NO SITE)</MenuItem>
                    <MenuItem value={27}>Loja (NÃO APARECE NO SITE)</MenuItem>
                    <MenuItem value={28}>Terreno Não Urbano</MenuItem>
                    <MenuItem value={29}>Galpão (NÃO APARECE NO SITE)</MenuItem>
                    <MenuItem value={30}>Comunidade Adulta</MenuItem>
                    <MenuItem value={31}>Casa de Férias</MenuItem>
                    <MenuItem value={32}>Outro</MenuItem>
                </TextField>
                <br />
                <br />
                <Divider />
                <br />
                <h6>Dados do Imóvel</h6>
                <br />
                <TextField
                    variant='outlined'
                    label='Metragem *'
                    color='secondary'
                    fullWidth
                    type={'number'}
                    style={{ marginTop: '5px', marginBottom: '5px' }}
                    onChange={(e) => e.target.value >= 0 && setMetragem(e.target.value)}
                    disabled
                    // disabled={listingOwner?._id !== jwtUser._id && jwtUser.permissionLevel.match( /ADMIN|SUPER/ )}
                    value={metragem}
                />
                <TextField
                    variant='outlined'
                    label='Nº de Quartos *'
                    color='secondary'
                    fullWidth
                    style={{ marginTop: '5px', marginBottom: '5px' }}
                    type={'number'}
                    onChange={(e) => e.target.value >= 0 && setNQuartos(e.target.value)}
                    disabled
                    // disabled={listingOwner?._id !== jwtUser._id && jwtUser.permissionLevel.match( /ADMIN|SUPER/ )}
                    value={nQuartos}
                />
                <TextField
                    variant='outlined'
                    label='Nº de Lavabos *'
                    color='secondary'
                    fullWidth
                    type={'number'}
                    style={{ marginTop: '5px', marginBottom: '5px' }}
                    onChange={(e) => e.target.value >= 0 && setNLavabos(e.target.value)}
                    disabled
                    // disabled={listingOwner?._id !== jwtUser._id && jwtUser.permissionLevel.match( /ADMIN|SUPER/ )}
                    value={nLavabos}
                />
                <TextField
                    variant='outlined'
                    label='Nº de Banheiros *'
                    color='secondary'
                    fullWidth
                    type={'number'}
                    style={{ marginTop: '5px', marginBottom: '5px' }}
                    onChange={(e) => e.target.value >= 0 && setNBanheiros(e.target.value)}
                    disabled
                    // disabled={listingOwner?._id !== jwtUser._id && jwtUser.permissionLevel.match( /ADMIN|SUPER/ )}
                    value={nBanheiros}
                />
                <TextField
                    variant='outlined'
                    label='Valor ( R$ ) *'
                    color='secondary'
                    fullWidth
                    style={{ marginTop: '5px', marginBottom: '5px' }}
                    onChange={(e) => setValor(maskCurrency(e.target.value))}
                    disabled
                    // disabled={listingOwner?._id !== jwtUser._id && jwtUser.permissionLevel.match( /ADMIN|SUPER/ )}
                    value={valor}
                />
                <br />
                <br />
                <Divider />
                <br />
                <h6>Estrutura</h6>
                <br />
                <TextField
                    variant='outlined'
                    label='Nome do Condomínio'
                    color='secondary'
                    fullWidth
                    style={{ marginTop: '5px', marginBottom: '5px' }}
                    onChange={(e) => setNomeCondominio(e.target.value)}
                    disabled
                    // disabled={listingOwner?._id !== jwtUser._id && jwtUser.permissionLevel.match( /ADMIN|SUPER/ )}
                    value={nomeCondominio}
                />
                <TextField
                    variant='outlined'
                    label='Andar do Imóvel'
                    color='secondary'
                    fullWidth
                    type={'number'}
                    style={{ marginTop: '5px', marginBottom: '5px' }}
                    onChange={(e) => setAndarDoImovel(e.target.value)}
                    disabled
                    // disabled={listingOwner?._id !== jwtUser._id && jwtUser.permissionLevel.match( /ADMIN|SUPER/ )}
                    value={andarDoImovel}
                />
                <TextField
                    variant='outlined'
                    label='Estado do Imóvel'
                    select
                    color='secondary'
                    fullWidth
                    style={{ marginTop: '5px', marginBottom: '5px' }}
                    onChange={(e) => setNovoImovel(e.target.value)}
                    disabled
                    // disabled={listingOwner?._id !== jwtUser._id && jwtUser.permissionLevel.match( /ADMIN|SUPER/ )}
                    value={novoImovel}
                >
                    <MenuItem value={true}>Novo</MenuItem>
                    <MenuItem value={false}>Usado</MenuItem>
                </TextField>
                <TextField
                    variant='outlined'
                    label='Nº de Vagas'
                    color='secondary'
                    fullWidth
                    type={'number'}
                    style={{ marginTop: '5px', marginBottom: '5px' }}
                    onChange={(e) => e.target.value >= 0 && setNVagas(e.target.value)}
                    disabled
                    // disabled={listingOwner?._id !== jwtUser._id && jwtUser.permissionLevel.match( /ADMIN|SUPER/ )}
                    value={nVagas}
                />
                <TextField
                    variant='outlined'
                    label='Construtora'
                    color='secondary'
                    fullWidth
                    style={{ marginTop: '5px', marginBottom: '5px' }}
                    onChange={(e) => setConstrutora(e.target.value)}
                    disabled
                    // disabled={listingOwner?._id !== jwtUser._id && jwtUser.permissionLevel.match( /ADMIN|SUPER/ )}
                    value={construtora}
                />
                <TextField
                    variant='outlined'
                    label='Total de Unidades'
                    color='secondary'
                    fullWidth
                    type={'number'}
                    style={{ marginTop: '5px', marginBottom: '5px' }}
                    onChange={(e) => e.target.value >= 0 && setNUnidades(e.target.value)}
                    disabled
                    // disabled={listingOwner?._id !== jwtUser._id && jwtUser.permissionLevel.match( /ADMIN|SUPER/ )}
                    value={nUnidades}
                />
                <TextField
                    variant='outlined'
                    label='Nº de Andares'
                    color='secondary'
                    fullWidth
                    type={'number'}
                    style={{ marginTop: '5px', marginBottom: '5px' }}
                    onChange={(e) => e.target.value >= 0 && setNAndares(e.target.value)}
                    disabled
                    // disabled={listingOwner?._id !== jwtUser._id && jwtUser.permissionLevel.match( /ADMIN|SUPER/ )}
                    value={nAndares}
                />
                <TextField
                    variant='outlined'
                    label='Nº de Torres'
                    color='secondary'
                    fullWidth
                    type={'number'}
                    style={{ marginTop: '5px', marginBottom: '5px' }}
                    onChange={(e) => e.target.value >= 0 && setNTorres(e.target.value)}
                    disabled
                    // disabled={listingOwner?._id !== jwtUser._id && jwtUser.permissionLevel.match( /ADMIN|SUPER/ )}
                    value={nTorres}
                />
                <div style={{ display: 'inline-block' }}>
                    <Checkbox
                        color='primary'
                        checked={churrasqueira}
                        onChange={(e) => setChurrasqueira(e.target.checked)}
                        disabled
                        // disabled={listingOwner?._id !== jwtUser._id && jwtUser.permissionLevel.match( /ADMIN|SUPER/ )}
                    />Churrasqueira
                </div>
                <div style={{ display: 'inline-block' }}>
                    <Checkbox
                        color='primary'
                        checked={deck}
                        onChange={(e) => setDeck(e.target.checked)}
                        disabled
                        // disabled={listingOwner?._id !== jwtUser._id && jwtUser.permissionLevel.match( /ADMIN|SUPER/ )}
                    />Deck
                </div>
                <div style={{ display: 'inline-block' }}>
                    <Checkbox
                        color='primary'
                        checked={acessoDeficientes}
                        onChange={(e) => setAcessoDeficientes(e.target.checked)}
                        disabled
                        // disabled={listingOwner?._id !== jwtUser._id && jwtUser.permissionLevel.match( /ADMIN|SUPER/ )}
                    />Acesso Deficientes Físicos
                </div>
                <div style={{ display: 'inline-block' }}>
                    <Checkbox
                        color='primary'
                        checked={doca}
                        onChange={(e) => setDoca(e.target.checked)}
                        disabled
                        // disabled={listingOwner?._id !== jwtUser._id && jwtUser.permissionLevel.match( /ADMIN|SUPER/ )}
                    />Doca
                </div>
                <div style={{ display: 'inline-block' }}>
                    <Checkbox
                        color='primary'
                        checked={jardim}
                        onChange={(e) => setJardim(e.target.checked)}
                        disabled
                        // disabled={listingOwner?._id !== jwtUser._id && jwtUser.permissionLevel.match( /ADMIN|SUPER/ )}
                    />Jardim
                </div>
                <div style={{ display: 'inline-block' }}>
                    <Checkbox
                        color='primary'
                        checked={entradaFechada}
                        onChange={(e) => setEntradaFechada(e.target.checked)}
                        disabled
                        // disabled={listingOwner?._id !== jwtUser._id && jwtUser.permissionLevel.match( /ADMIN|SUPER/ )}
                    />Entrada Fechada
                </div>
                <div style={{ display: 'inline-block' }}>
                    <Checkbox
                        color='primary'
                        checked={estufa}
                        onChange={(e) => setEstufa(e.target.checked)}
                        disabled
                        // disabled={listingOwner?._id !== jwtUser._id && jwtUser.permissionLevel.match( /ADMIN|SUPER/ )}
                    />Estufa
                </div>
                <div style={{ display: 'inline-block' }}>
                    <Checkbox
                        color='primary'
                        checked={hidro}
                        onChange={(e) => setHidro(e.target.checked)}
                        disabled
                        // disabled={listingOwner?._id !== jwtUser._id && jwtUser.permissionLevel.match( /ADMIN|SUPER/ )}
                    />Hidromassagem
                </div>
                <div style={{ display: 'inline-block' }}>
                    <Checkbox
                        color='primary'
                        checked={patio}
                        onChange={(e) => setPatio(e.target.checked)}
                        disabled
                        // disabled={listingOwner?._id !== jwtUser._id && jwtUser.permissionLevel.match( /ADMIN|SUPER/ )}
                    />Pátio
                </div>
                <div style={{ display: 'inline-block' }}>
                    <Checkbox
                        color='primary'
                        checked={lago}
                        onChange={(e) => setLago(e.target.checked)}
                        disabled
                        // disabled={listingOwner?._id !== jwtUser._id && jwtUser.permissionLevel.match( /ADMIN|SUPER/ )}
                    />Laguinho
                </div>
                <div style={{ display: 'inline-block' }}>
                    <Checkbox
                        color='primary'
                        checked={piscina}
                        onChange={(e) => setPiscina(e.target.checked)}
                        disabled
                        // disabled={listingOwner?._id !== jwtUser._id && jwtUser.permissionLevel.match( /ADMIN|SUPER/ )}
                    />Piscina
                </div>
                <div style={{ display: 'inline-block' }}>
                    <Checkbox
                        color='primary'
                        checked={varanda}
                        onChange={(e) => setVaranda(e.target.checked)}
                        disabled
                        // disabled={listingOwner?._id !== jwtUser._id && jwtUser.permissionLevel.match( /ADMIN|SUPER/ )}
                    />Varanda
                </div>
                <div style={{ display: 'inline-block' }}>
                    <Checkbox
                        color='primary'
                        checked={vagaTrailer}
                        onChange={(e) => setVagaTrailer(e.target.checked)}
                        disabled
                        // disabled={listingOwner?._id !== jwtUser._id && jwtUser.permissionLevel.match( /ADMIN|SUPER/ )}
                    />Vaga para Trailer
                </div>
                <div style={{ display: 'inline-block' }}>
                    <Checkbox
                        color='primary'
                        checked={quadra}
                        onChange={(e) => setQuadra(e.target.checked)}
                        disabled
                        // disabled={listingOwner?._id !== jwtUser._id && jwtUser.permissionLevel.match( /ADMIN|SUPER/ )}
                    />Quadra
                </div>
                <div style={{ display: 'inline-block' }}>
                    <Checkbox
                        color='primary'
                        checked={aspercao}
                        onChange={(e) => setAspercao(e.target.checked)}
                        disabled
                        // disabled={listingOwner?._id !== jwtUser._id && jwtUser.permissionLevel.match( /ADMIN|SUPER/ )}
                    />Sistema de Irrigação
                </div>
                <div style={{ display: 'inline-block' }}>
                    <Checkbox
                        color='primary'
                        checked={beiraMar}
                        onChange={(e) => setBeiraMar(e.target.checked)}
                        disabled
                        // disabled={listingOwner?._id !== jwtUser._id && jwtUser.permissionLevel.match( /ADMIN|SUPER/ )}
                    />Beira do Mar
                </div>
                <br />
                <br />
                <Divider />
                <br />
                <h6>Detalhes</h6>
                <br />
                <TextField
                    variant='outlined'
                    label='Ano da Construção'
                    color='secondary'
                    fullWidth
                    type={'number'}
                    style={{ marginTop: '5px', marginBottom: '5px' }}
                    onChange={(e) => e.target.value >= 0 && setAnoConstrucao(e.target.value)}
                    disabled
                    // disabled={listingOwner?._id !== jwtUser._id && jwtUser.permissionLevel.match( /ADMIN|SUPER/ )}
                    value={anoConstrucao}
                />
                <TextField
                    variant='outlined'
                    label='Tamanho do Terreno'
                    color='secondary'
                    fullWidth
                    type={'number'}
                    style={{ marginTop: '5px', marginBottom: '5px' }}
                    onChange={(e) => setTamanhoTerreno(e.target.value)}
                    disabled
                    // disabled={listingOwner?._id !== jwtUser._id && jwtUser.permissionLevel.match( /ADMIN|SUPER/ )}
                    value={tamanhoTerreno}
                />
                <TextField
                    variant='outlined'
                    label='Descrição'
                    color='secondary'
                    fullWidth
                    style={{ marginTop: '5px', marginBottom: '5px' }}
                    onChange={(e) => setDescricao(String(e.target.value).replace('\n', ''))}
                    disabled
                    // disabled={listingOwner?._id !== jwtUser._id && jwtUser.permissionLevel.match( /ADMIN|SUPER/ )}
                    value={descricao}
                />
                <TextField
                    variant='outlined'
                    label='Nº de Matrícula'
                    color='secondary'
                    fullWidth
                    style={{ marginTop: '5px', marginBottom: '5px' }}
                    onChange={(e) => setMatricula(e.target.value)}
                    disabled
                    // disabled={listingOwner?._id !== jwtUser._id && jwtUser.permissionLevel.match( /ADMIN|SUPER/ )}
                    value={matricula}
                />
                <TextField
                    variant='outlined'
                    label='IPTU Anual ( R$ )'
                    color='secondary'
                    number
                    fullWidth
                    style={{ marginTop: '5px', marginBottom: '5px' }}
                    onChange={(e) => setIptuAnual(maskCurrency(e.target.value))}
                    disabled
                    // disabled={listingOwner?._id !== jwtUser._id && jwtUser.permissionLevel.match( /ADMIN|SUPER/ )}
                    value={iptuAnual}
                />
                <TextField
                    variant='outlined'
                    label='Condomínio Mensal ( R$ )'
                    color='secondary'
                    number
                    fullWidth
                    style={{ marginTop: '5px', marginBottom: '5px' }}
                    onChange={(e) => setCondominioMensal(maskCurrency(e.target.value))}
                    disabled
                    // disabled={listingOwner?._id !== jwtUser._id && jwtUser.permissionLevel.match( /ADMIN|SUPER/ )}
                    value={condominioMensal}
                />
                <br />
                <br />
                <Divider />
                <br />
                <h6>Dados do Proprietário</h6>
                <br />
                <TextField
                    variant='outlined'
                    label='Nome Completo do Proprietário'
                    color='secondary'
                    fullWidth
                    style={{ marginTop: '5px', marginBottom: '5px' }}
                    onChange={(e) => setOwnerName(e.target.value)}
                    disabled
                    // disabled={listingOwner?._id !== jwtUser._id && jwtUser.permissionLevel.match( /ADMIN|SUPER/ )}
                    value={ownerName}
                />
                <TextField
                    variant='outlined'
                    label='CPF do Proprietário'
                    color='secondary'
                    fullWidth
                    style={{ marginTop: '5px', marginBottom: '5px' }}
                    onChange={(e) => setOwnerCPF(mask(e.target.value, cpfPattern))}
                    disabled
                    // disabled={listingOwner?._id !== jwtUser._id && jwtUser.permissionLevel.match( /ADMIN|SUPER/ )}
                    value={ownerCPF}
                />
                <TextField
                    variant='outlined'
                    label='RG do Proprietário'
                    color='secondary'
                    fullWidth
                    style={{ marginTop: '5px', marginBottom: '5px' }}
                    onChange={(e) => setOwnerRG(mask(e.target.value, rgPattern))}
                    disabled
                    // disabled={listingOwner?._id !== jwtUser._id && jwtUser.permissionLevel.match( /ADMIN|SUPER/ )}
                    value={ownerRG}
                />
                <TextField
                    variant='outlined'
                    label='Email do Proprietário'
                    color='secondary'
                    fullWidth
                    style={{ marginTop: '5px', marginBottom: '5px' }}
                    onChange={(e) => setOwnerEmail(e.target.value)}
                    disabled
                    // disabled={listingOwner?._id !== jwtUser._id && jwtUser.permissionLevel.match( /ADMIN|SUPER/ )}
                    value={ownerEmail}
                />
                <TextField
                    variant='outlined'
                    label='Telefone do Proprietário'
                    color='secondary'
                    type={'email'}
                    fullWidth
                    style={{ marginTop: '5px', marginBottom: '5px' }}
                    onChange={(e) => setOwnerPhone(e.target.value)}
                    disabled
                    // disabled={listingOwner?._id !== jwtUser._id && jwtUser.permissionLevel.match( /ADMIN|SUPER/ )}
                    value={ownerPhone}
                />
                <br />
                <br />
                <Divider />
                <br />
                <h6>Exclusividade</h6>
                <br />
                <TextField
                    variant='outlined'
                    label='Expiração da Exclusividade ( AAAA-MM-DD )'
                    color='secondary'
                    fullWidth
                    style={{ marginTop: '5px', marginBottom: '5px' }}
                    onChange={(e) => setExpDate(mask(e.target.value, datePattern))}
                    disabled
                    // disabled={listingOwner?._id !== jwtUser._id && jwtUser.permissionLevel.match( /ADMIN|SUPER/ )}
                    value={expDate}
                />
                <br />
                <br />
                <Divider />
                <br />
                <h6>Comissão</h6>
                <br />
                <TextField
                    variant='outlined'
                    label='Comissão ( % )'
                    color='secondary'
                    fullWidth
                    type={'number'}
                    style={{ marginTop: '5px', marginBottom: '5px' }}
                    onChange={(e) => e.target.value >= 0 && setComissionSeller(e.target.value)}
                    disabled
                    // disabled={listingOwner?._id !== jwtUser._id && jwtUser.permissionLevel.match( /ADMIN|SUPER/ )}
                    value={comissionSeller}
                />

                <br />
                <br />

                <Divider />
                <br />
                <h6>Outros</h6>
                <br />
               <TextField
                    variant='outlined'
                    label='Outros'
                    color='secondary'
                    fullWidth
                    style={{ marginTop: '5px', marginBottom: '5px' }}
                    onChange={(e) => setOutros(e.target.value)}
                    // disabled={!userCanEdit(listingOwner, jwtUser)}
                    disabled
                    value={outros}
                />
                {
                    Object.keys(outrosMaped).map( el => {
                        if (outrosMaped[`${el}`] !== "" || outrosMaped[`${el}`] !== undefined || outrosMaped[`${el}`] !== " ")
                            return(                <TextField
                            variant='outlined'
                            label={el}
                            color='secondary'
                            fullWidth
                            style={{ marginTop: '5px', marginBottom: '5px' }}
                            onChange={(e) => setOutros(e.target.value)}
                            disabled
                            value={outrosMaped[`${el}`]}
                        />)
                    })
                }
                <br />
                <br />

                {/* <Divider />
                <br />
                <h6>Vídeos / Tours Virtuais</h6>
                <br />
                <button
                    className='btn btnSecondary'
                    onClick={() => setVirtualTours([...virtualTours, {
                        "vt_short_desc": "",
                        "vt_updated_at": new Date().toISOString(),
                        "vt_url": "",
                        "vt_url_branded": true
                    }])}
                    style={{ marginTop: '5px', marginRight: '5px' }}
                >
                    Adicionar
                </button>
                {virtualTours.map((vt, i) => {
                    return (
                        <>
                            <br />
                            <br />
                            <label>Vídeo {i + 1}</label>
                            <TextField
                                variant='outlined'
                                label='Descrição'
                                color='secondary'
                                fullWidth
                                style={{ marginTop: '5px', marginBottom: '5px' }}
                                onChange={(e) => {
                                    let arr = [...virtualTours]
                                    arr[i].vt_short_desc = e.target.value
                                    setVirtualTours(arr)
                                }}
                                // disabled={listingOwner?._id !== jwtUser._id && jwtUser.permissionLevel.match( /ADMIN|SUPER/ )}
                                value={vt.vt_short_desc}
                            />
                            <br />
                            <TextField
                                variant='outlined'
                                label='URL'
                                color='secondary'
                                fullWidth
                                style={{ marginTop: '5px', marginBottom: '5px' }}
                                onChange={(e) => {
                                    let arr = [...virtualTours]
                                    arr[i].vt_url = e.target.value
                                    setVirtualTours(arr)
                                }}
                                // disabled={listingOwner?._id !== jwtUser._id && jwtUser.permissionLevel.match( /ADMIN|SUPER/ )}
                                value={vt.vt_url}
                            />
                            <button
                                className='btn btnSecondary'
                                style={{ marginTop: '5px', marginRight: '5px', borderColor: '#a7002f', color: '#a7002f' }}
                                onClick={() => {
                                    let arr = [...virtualTours]
                                    arr.splice(i, 1)
                                    setVirtualTours(arr)
                                }}
                            >
                                Remover
                            </button>
                            {i !== virtualTours.length - 1 &&
                                <>
                                    <br />
                                    <br />
                                    <Divider />
                                    <br />
                                </>
                            }
                        </>
                    )
                })} */}
                {/* {(listingOwner?._id === jwtUser._id || jwtUser.permissionLevel.match( /ADMIN|SUPER/ ))  &&
                    <div style={{ textAlign: 'right' }}>
                        <button
                            className='btn btnPrimary'
                            onClick={submit}
                            style={{ marginTop: '5px', marginRight: '5px' }}
                        >
                            {listing?.status == 'REPROVADO' ? 'Solicitar Reavaliação' : 'Salvar alterações'}
                        </button>
                    </div>
                } */}
                <Divider style={{ marginTop: '25px', marginBottom: '25px' }} />
                {/* { (jwtUser?.isMcAdmin || jwtUser?.isTeamAdmin || jwtUser.permissionLevel.match( /ADMIN|SUPER/ ))  && listing?.status === 'PENDENTE' &&
                    <div style={{ textAlign: 'right' }}>
                        <button
                            className={reprovingMode ? 'btn btnSecondary' : 'btn btnPrimary'}
                            style={{ marginTop: '5px', marginBottom: '50px', marginRight: '5px', backgroundColor: reprovingMode ? '' : '#a7002f', borderColor: '#a7002f', color: reprovingMode ? '#a7002f' : '' }}
                            onClick={() => setReprovingMode(!reprovingMode)}
                        >
                            {reprovingMode ? 'Cancelar' : 'Reprovar'}
                        </button>
                        <button
                            className='btn btnPrimary'
                            onClick={() => changeStatus('ATIVO')}
                            style={{ marginTop: '5px', marginBottom: '50px', backgroundColor: '#00a75c', borderColor: '#00a75c' }}
                        >
                            Aprovar
                        </button>

                    </div>
                } */}
                {/* <Zoom in={(jwtUser?.isMcAdmin || jwtUser?.isTeamAdmin || jwtUser.permissionLevel.match( /ADMIN|SUPER/ )  ) && reprovingMode}>
                    <div style={{ textAlign: 'right' }}>
                        <TextField
                            variant='outlined'
                            label='Motivo *'
                            color='secondary'
                            // disabled={status !== 'PENDENTE'}
                            style={{ marginTop: '5px', marginBottom: '5px', width: '50%' }}
                            onChange={(e) => setMotivo(e.target.value)}
                            value={motivo}
                            disabled
                        />
                        <br />

                        {(jwtUser?.isMcAdmin || jwtUser?.isTeamAdmin || jwtUser?.permissionLevel.match( /ADMIN|SUPER/ ) && status === 'PENDENTE' ) &&
                            <button
                                className={reprovingMode ? 'btn btnSecondary' : 'btn btnPrimary'}
                                style={{ marginTop: '5px', marginBottom: '50px', marginRight: '5px', backgroundColor: '#a7002f', borderColor: '#a7002f', color: 'white' }}
                                onClick={() => changeStatus('REPROVADO')}
                            >
                                Reprovar
                            </button>
                        }
                    </div>
                </Zoom> */}

            </div>
        </div>
    )
}
