import { Divider, FormControl, FormControlLabel, Grid, Switch, Radio, RadioGroup, Slider, TextField, Zoom } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../contexts/AuthContext';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Loading from '../views/Loading';
import EditIntegracaoPortalZAP from '../views/EditIntegracaoLotePortalZAP';
import ListingItem from '../sharedComponents/listings/ListingItem';
import ListingListHeader from '../sharedComponents/listings/ListingListHeader';
import ListingPageHeader from '../sharedComponents/listings/ListingPageHeader';
import FilterListingModalV2 from '../views/filterModal/FilterListingModalV2';
import listingInMemory from '../libs/services/listingInMemory';
import XMLFilesProvider from '../contexts/XMLFilesProvider';
import XMLListingsContext from '../contexts/XMLListingsContext';
import { BASE_URL, SOUKW_URL } from '../sharedComponents/Constants';
import XMLModal from '../views/selectXMLModal/XMLModal';
import DeleteLoteIntegracaoPortal from '../views/DeleteLoteIntegracaoPortal'
//import XMLFilesProvider from '../contexts/XMLFilesProvider';
import XMLFilesContext from '../contexts/XMLFilesContexts';



export default function ListingsCommand() {

    const { files, selectedFile, setSelectedFile, addFile, removeFile, updateFileList,
        xmlListings,
        updateXmlListings,
        saveXmlListings,
        deleteXmlListing,
        addXmlListing,
        clearXmlListings,
        reloadXmlListings,
        clearListings,
        deleteAdd,
        xmlCurrentFileSize,
        listingsInsideXML
        } = useContext( XMLFilesContext )

    const [XMLModalOpen, setXMLModalOpen] = useState(false);
    const [deleteLote, setDeleteLoteOpen ] = useState(false);
    const [editIntegracaoPortalZAP, setEditIntegracaoPortalZAPOpen] = useState(false);
    const { user, logout, token, jwtUser } = useContext(AuthContext);
    const [listings, setListings] = useState([]);
    const [users, setUsers] = useState([]);
    const [mcs, setMcs] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(1);
    const [filteringMode, setFilteringMode] = useState(false);
    const [enderecoFilter, setEnderecoFilter] = useState('');
    const [bairroFilter, setBairroFilter] = useState('');
    const [cityFilter, setCityFilter] = useState('');
    const [metragemFilter, setMetragemFilter] = useState([1, 1000]);
    const [valorFilter, setValorFilter] = useState([1000, 50000000]);
    const [filters, setFilters] = useState({});
    const [loading, setLoading] = useState(true);
    const [checkedItems, setCheckedItems] = useState({});
    const [allSelected, setAllSelected] = useState( false )
    const [allFilterSelected, setAllFilterSelected] = useState( false )
    const [selectedCount, setSelectedCount] = useState( 0 )
    //const [xmlSize, setXMLSize] = useState( 0 );
    


    //TODO: Regra de negócio - Time agentes membros de time não criam listings, apenas os ADMs do time podem criar.

    const saveAllFiltered = async ( ) => {
        let filtroLocal = {...filters}
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };

        //alert('Filtro: ', filtroLocal)
        filtroLocal.page = '1';
        let url = '/integracaoZapImoveis/getMyListings?'
        Object.keys( filtroLocal ).forEach( k => {
                if( k === 'bairro' ) {
                    url = url + '&neighbourhood=' + filtroLocal.bairro
                } else if( k === 'itemsPerPage'){
                    url = url + '&itemsPerPage=' + '10000'
                }else {
                    url = url + `&${k}=${filtroLocal[k]}`
                }
        })
        updateXmlListings( url );
        saveXmlListings( );
    }

    const handleChecked = (index) => () => {
        console.log("XMLListingsContext: handleChecked")
       // console.log("Delete function: ", deleteXmlListing)
      if( checkedItems[index] ){
        //deleteXmlListing( index );
        setSelectedCount( prev => prev - 1)
      } else {
        console.log( "Adding listing: ", XMLListingsContext )
        console.log( "files: ", files )
        addXmlListing( listings[index] )
        //addXmlListing( listings[index] )
        setSelectedCount( prev => prev + 1)
      }
      setCheckedItems(prev =>  ({...prev, [index]: !prev[index]}) );
      console.log("XML file: ", XMLFilesContext )
    };
  
    const selectAll = () => {
        if( allSelected ){
            return unselectAll( )
        }
      const allCheckedItems = listings.reduce((result, item, index) => {
        //listingInMemory[index].checked = true
       // item.checked = true
       addXmlListing( listings[index] )
        result[index] = true;
        return result;
      }, {});
      setAllSelected( true )
      setCheckedItems(allCheckedItems);
      setSelectedCount( listings.length )
     // reloadXmlListings( listings  )
      //await listingInMemory.saveListings( token )
    };

    const selectAllFilter = async () => {
        if( allFilterSelected )
            return unselectAll( )
      const allCheckedItems = listings.reduce((result, item, index) => {
        result[index] = true;
        return result;
      }, {});
      setAllFilterSelected( true );
      setAllSelected( true );
      setCheckedItems(allCheckedItems);
      setSelectedCount( total );
      reloadXmlListings( listings  );
    };

    const unselectAll = () => {
        const allCheckedItems = listings.reduce((result, item, index) => {
            //listingInMemory[index].checked = false
            //item.checked = false
            result[index] = false;
            return result;
          }, {});
          //clearXmlListings( )
        setSelectedCount( 0 )
        setAllSelected( false )
        setAllFilterSelected( false )
        setCheckedItems({});
    }

    
    function openFilterListingModal() {
        setFilteringMode(true)
    }

    function closeFilterListingModal() {
        setFilteringMode(false)
    }

    const openDeleteLote = ( ) => {
        setDeleteLoteOpen( true )
    }

    const closeDeleteLote = ( ) => {
        setDeleteLoteOpen( false )
    }
    const openEditIntegracaoPortalZAP = () => {
        setEditIntegracaoPortalZAPOpen( true )
    }
    const closeEditIntegracaoPortalZAP = () => {
        setEditIntegracaoPortalZAPOpen(false)
    }

    const closeXMLModal = ( ) => {
        setXMLModalOpen(false)
    }

    const openXMLModal = ( ) => {
        setXMLModalOpen(true)
    }
    
    useEffect(() => {
        const params = new URLSearchParams(window.location.search) // id=123
        let page = params.get('page')
        if (!page)
            page = 1
        setPage(page)
    }, [])

    useEffect(() => {
        async function getData() {
            try {
                setPage(1)
            } catch (e) {
                console.error(e);
                setLoading(false);
                if (e?.response?.data?.msg)
                    alert(e?.response?.data?.msg)
                else {
                    alert('Erro de Conexão.')
                    logout();
                }
            }
        }
        getData();
    //}, [])
    }, [filters])

    useEffect(() => {
        //Adicionar filtro por Agente/Tipo de Imóvel/Status/Preço/Quartos/Banheiros/Vagas
        
        async function getData() {
            setLoading(true);
            try {
                const config = {
                    headers: { Authorization: `Bearer ${token}` }
                };
                //changed here
                console.log( "The filters are:", filters )
                let url = BASE_URL + '/integracaoZapImoveis/getMyListings?page=' + page
                Object.keys( filters ).forEach( k => {
                        if( k === 'bairro' ) {
                            url = url + '&neighbourhood=' + filters.bairro
                        } else {
                            url = url + `&${k}=${filters[k]}`
                        }
                })

                url += `&selectedFile=${selectedFile}`

                let results = await axios.get(
                    url,
                    config
                );
                console.log(url)
                console.log( results.data )
                setListings(results.data?.data?.reverse());
                setTotal(results.data?.totalItems)
                setTotalPages(results.data?.totalPages)
                setPage(results.data?.page)
                setLoading(false);
//                await updateFileList(user.permissionLevel, user.marketCenterId, token)
                await updateFileList(jwtUser, token)

            } catch (e) {
                console.error(e);
                setLoading(false);
                if (e?.response?.data?.msg)
                    alert(e?.response?.data?.msg)
                else {
                    alert('Erro de Conexão.')
                    logout();
                }
            }
        }
        getData();
   // }, [])
    }, [filters, page, xmlCurrentFileSize, listingsInsideXML])

    useEffect(() => {
        async function getData() {
            try {
                const config = {
                    headers: { Authorization: `Bearer ${token}` }
                };
                let users = await axios.get(BASE_URL + '/users', config)
                let mcs = await axios.get(BASE_URL + '/mcs', config)
                //let xmlSize = await axios.get(BASE_URL + '/integracaoZapImoveis/getXMLSize', config)
                //console.log( 'xmlSize:' , xmlSize)
                setUsers(users.data)
                setMcs(mcs.data)
                //setXMLSize( xmlSize.data.xmlSize )

            } catch (e) {
                console.error(e);
                if (e?.response?.data?.msg)
                    alert(e?.response?.data?.msg)
                else {
                    alert('Erro de Conexão.')
                    logout();
                }

            }
        }
        getData()
    //}, [])
    }, [listings])

    return (
                <Grid container 
                    spacing={0} 
                    style={{ height: '100%' }} 
                    justifyItems='flex-start' 
                    alignItems={'flex-start'}
                >
                    <Grid item xs={12} sm={12} style={{ textAlign: 'left' , border:'2px solid black', backgroundColor: 'silver', padding: '0.5rem', margin: '1rem'} }>
                        <p>Associados da Keller Williams possuem 20% de desconto na contratação de novos pacotes com a OLX/ZAP/VivaReal e R$ 2000 de bonus ao contratar o plano da Apto, consulte a liderança de seu Market Center para mais informações.</p>
                    </Grid>
                    <Grid item xs={12}>
                            <ListingPageHeader pageTitle="Integração Portais" actionFunction={openEditIntegracaoPortalZAP} />
                    </Grid>
                    <Grid container                             
                            spacing={0} 
                            style={{ height: '100%' }} 
                            justifyItems='center' 
                            alignItems={'center'} >
                        <Grid item xs={4}>
                            <button className='btn btnSecondary' onClick={setDeleteLoteOpen}>Excluir Anúncios Selecionados</button>
                        </Grid>
                        <Grid item xs={2}>
                            <button className='btn btnSecondary' onClick={ openXMLModal } >
                                Selecionar XML
                            </button>
                        </Grid>
                        <Grid item xs={2}>
                            <p>XML: {selectedFile}</p>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} >
                        <Grid container 
                            spacing={0} 
                            style={{ height: '100%' }} 
                            justifyItems='flex-start' 
                            alignItems={'flex-start'}
                        >
                            <Grid item xs={12} sm={12} style={{paddingBottom: '1rem'}} >
                                <button className='btn btnSecondary' onClick={() => {
                                    setEnderecoFilter('')
                                    setBairroFilter('')
                                    setCityFilter('')
                                    if (filteringMode)
                                        setFilters({})
                                    setFilteringMode(!filteringMode)
                                }}>
                                    {filteringMode ? 'Cancelar' : 'Filtrar'}
                                </button>
                                {Object.keys(filters).length > 0 &&
                                    <button className='btn btnSecondary' onClick={() => {
                                        //setEnderecoFilter('')
                                        //setBairroFilter('')
                                        //setCityFilter('')
                                        //setValorFilter('')
                                        //setMetragemFilter([1, 1000])
                                        setFilters({})
                                        //setFilteringMode(!filteringMode)
                                    }}>
                                        Limpar Filtros
                                </button> }
                                <Switch 
                                    checked={allSelected}
                                    onChange={selectAll}
                                />Selecionar todos da página |
                                <Switch 
                                    checked={allFilterSelected}
                                    onChange={selectAllFilter}
                                />Selecionar todos do filtro |
                                <span> Total de Listings no filtro: {total} | Total de listings selecionados: {selectedCount}</span>
                            </Grid>
                            
                        </Grid>
                    </Grid>

                    <ListingListHeader headers={[
                        {text: "Selecionar", size: 1},
                        {text: "Foto", size: 2},
                        {text: "Endereço", size: 2},
                        {text: "Exclusividade?", size: 1}, 
                        {text: "Criação", size: 1},
                        {text: "Valor", size: 1},
                        {text: "Agente", size: 2},
                        {text: "Editar", size: 1}, 
                        {text: "Anuncio está no XML?", size: 1}]} />
                    {
                        loading && <Loading />
                    }
                    {
                        !loading && (listings?.map((listing, i) => {
                            return ( <ListingItem
                                        key={i}
                                        listing={listing}
                                        backgroundColor={ i % 2 ? 'white' : '#fafafa'}
                                        checked={checkedItems[i] || false}
                                        onChecked={handleChecked(i)}
                                    /> ) 
                        } ) || <p>No listings found</p>)
                    }
                    {
                        listings.length === 0 && <Grid item xs={12} style={{ 
                            textAlign: 'center', 
                            marginTop: '100px', 
                            }}
                        >
                            <h2 style={{ fontWeight: 'normal' }}>
                                Nenhum listing encontrado.
                            </h2>
                        </Grid>

                    }
                    <Grid item xs={12}>
                        <br />
                    </Grid>
                    <Grid item xs={12}>
                        <Zoom in={page > 1}>
                            <Link to={'/soukw/portals?page=' + String(parseInt(page) - 1)} 
                                style={{ 
                                    textDecoration: 'none', 
                                    color: '#373f51' 
                                }}
                            >
                                <button className='btn btnSecondary' onClick={() => {
                                    setLoading(true)
                                    setPage(parseInt(page) - 1)
                                }}>Anterior</button>
                            </Link>
                        </Zoom>
                        <span style={{ margin: '5px' }}>
                            Página {page} de {totalPages}
                        </span>
                        <Zoom in={page < totalPages}>
                            <Link to={'/soukw/portals?page=' + String(parseInt(page) + 1)} 
                                style={{ 
                                    textDecoration: 'none', 
                                    color: '#373f51' 
                                    }}
                                >
                                <button className='btn btnSecondary' onClick={() => {
                                    setLoading(true)
                                    setPage(parseInt(page) + 1)
                                }}>Próxima</button>
                            </Link>
                        </Zoom>
                    </Grid>
                    <EditIntegracaoPortalZAP open={editIntegracaoPortalZAP} close={closeEditIntegracaoPortalZAP} />
                    <FilterListingModalV2 open={filteringMode} close={closeFilterListingModal} setFilters={setFilters} />
                    <XMLModal open={ XMLModalOpen } close={ closeXMLModal } xmlFiles={files} add={addFile}/>
                    <DeleteLoteIntegracaoPortal open={ deleteLote } close={ closeDeleteLote } file={selectedFile} />
                </Grid > 

    )
}
