/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable default-case */
import {
    Autocomplete,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControlLabel,
    MenuItem,
    TextField
} from '@mui/material'
import TextModal from '../sharedComponents/listings/TextModal'
import PrimaryOwnerForm from '../sharedComponents/listings/PrimaryOwnerForm';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import Geocode from 'react-geocode';
import { AuthContext } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import SingleFileUpload from '../sharedComponents/SingleFileUpload';
import {
    DATE_VALIDATION,
    CEL_PHONE_VALIDATION,
    FULL_NAME_VALIDATION,
    EMAIL_VALIDATION,
    UF_VALIDATOR,
    GENERAL_NAME_VALIDATOR,
    VALIDA_CPF
} from '../libs/validators'
//Refactor de categorizações
import {
    translateListCategory,
    translateListType,
    translateListStatus,
    translatePropType,
    translatePropSubType
} from '../sharedComponents/listings/translatings'
import { maskCurrency, unmaskCurrency } from '../libs/utils';
import AddressForm from '../sharedComponents/listings/AddressForm';
import PropertyTypeForm from '../sharedComponents/listings/PropertyTypeForm'
import { isUndefined } from 'lodash';
import { SOUKW_URL, BASE_URL } from '../sharedComponents/Constants';

function NewListingCaptacao({ open, close }) {
    const { token, user, logout, jwtUser } = useContext(AuthContext);
    const navigate = useNavigate();

    const [users, setUsers] = useState([]);


    //Proprietário:
    const [ownerName, setOwnerName] = useState('');
    const [ownerCPF, setOwnerCPF] = useState('');
    const [ownerEmail, setOwnerEmail] = useState('');
    const [ownerType, setOwnerType] = useState('Pessoa Física'); //PJ ou PF
    const [ownerPhone, setOwnerPhone] = useState('');
    const [ownerToken, setOwnerToken] = useState('email'); //Assinatura por e-mail ou whatsapp
    const [matriculaImovel, setMatriculaImovel] = useState(''); //Matricula do imovel
    const [numeroContribuinte, setNumeroContribuinte] = useState('') //Número do Contribuinte IPTU
    const [cartorioRegistroImovel, setCartorioRegistroImovel] = useState(''); //Cartorio de Matricula do imovel
    const [contratoData, setContratoData] = useState('');
    const [contratoFimData, setContratoFimData] = useState('');
    const [comission, setComission] = useState('6%');
    const [termoDeVenda, setTermoDeVenda] = useState(false);
    const [fileTermoDeVenda, setFileTermoDeVenda] = useState({});
    const [fileIPTU, setFileIPTU] = useState({});
    const [fileMatricula, setFileMatricula] = useState({});

    const onTermoUpload = (fileObject) => {
        console.log('loading file Termo')
        console.log(fileObject.name)
        setFileTermoDeVenda(fileObject)
    }
    const onIPTUUpload = (fileObject) => {
        console.log('loading file IPTU')
        console.log(fileObject)
        console.log(fileObject.name)
        setFileIPTU(fileObject)
    }
    const onMatriculaUpload = (fileObject) => {
        console.log('loading file')
        console.log(fileObject)
        setFileMatricula(fileObject)
    }

    const [cep, setCep] = useState('');
    const [rua, setRua] = useState('');
    const [numero, setNumero] = useState('');
    const [compl, setCompl] = useState('');
    const [geoloc, setGeoloc] = useState('');
    const [bairro, setBairro] = useState('');
    const [cidade, setCidade] = useState('');
    const [estado, setEstado] = useState('');

    const [listCategory, setListCategory] = useState(null);
    const [listType, setListType] = useState(null);
    const [listStatus, setListStatus] = useState(null);
    const [propType, setPropType] = useState(null);
    const [propSubType, setPropSubType] = useState(null);

    const [metragem, setMetragem] = useState('');
    const [nQuartos, setNQuartos] = useState('');
    const [nLavabos, setNLavabos] = useState(0);
    const [nBanheiros, setNBanheiros] = useState(0);
    const [valor, setValor] = useState('');

    const [nomeCondominio, setNomeCondominio] = useState('');
    const [andarDoImovel, setAndarDoImovel] = useState('');
    const [novoImovel, setNovoImovel] = useState('');
    const [nVagas, setNVagas] = useState('');
    const [construtora, setConstrutora] = useState('');
    const [nUnidades, setNUnidades] = useState('');
    const [nAndares, setNAndares] = useState('');
    const [nTorres, setNTorres] = useState('');

    const [churrasqueira, setChurrasqueira] = useState(false);
    const [deck, setDeck] = useState(false);
    const [acessoDeficientes, setAcessoDeficientes] = useState(false);
    const [doca, setDoca] = useState(false);
    const [jardim, setJardim] = useState(false);
    const [entradaFechada, setEntradaFechada] = useState(false);
    const [estufa, setEstufa] = useState(false);
    const [hidro, setHidro] = useState(false);
    const [patio, setPatio] = useState(false);
    const [lago, setLago] = useState(false);
    const [piscina, setPiscina] = useState(false);
    const [varanda, setVaranda] = useState(false);
    const [vagaTrailer, setVagaTrailer] = useState(false);
    const [quadra, setQuadra] = useState(false);
    const [aspercao, setAspercao] = useState(false);
    const [beiraMar, setBeiraMar] = useState(false);

    const [anoConstrucao, setAnoConstrucao] = useState('');
    const [tamanhoTerreno, setTamanhoTerreno] = useState('');
    const [descricao, setDescricao] = useState('');
    const [sendDisable, setSendDisable] = useState(true)

    const [listingOwner, setListingOwner] = useState(user);

    const enableSend = () => {
        //esta função bloqueia o envio se algum campo não estiver preenchido
        let states = [
            [ownerName, 'Preencher Nome'],
            [ownerCPF, 'Preencher CPF'],
            [ownerEmail, 'Preencher email'],
            [ownerType, 'Preencher tipo'],
            [ownerPhone, 'Preencher Telefone do Proprietário'],
            [ownerToken, 'Preencher Token'],
            [matriculaImovel, 'Preencher Matrícula'],
            [numeroContribuinte, 'Preencher Número do contribuinte( IPTU )'],
            [cartorioRegistroImovel, 'Informar Cartório onde imóvel foi registrado'],
            //[fileIPTU, 'Comporvante de IPTU e matrícula devem ser enviados'],
            //[fileMatricula, 'Comporvante de IPTU e matrícula devem ser enviados'],
            [cep, 'Preencher CEP'],
            [rua, 'Preencher Rua'],
            [numero, 'Preencher Número do Imóvel'],
            [bairro, 'Preencher Bairro'],
            [cidade, 'Preencher Cidade'],
            [estado, 'Preencher Estado'],
            [comission, 'Informar Comissao'],
            [unmaskCurrency(valor), 'Preencher valor de venda do imóvel']
        ]
        console.log(`Termo venda: ${termoDeVenda}`)
        if (termoDeVenda) {
            states.push([(typeof fileTermoDeVenda.get === 'function' && fileTermoDeVenda.get('doc') !== undefined), 'Carregar termo de venda ou desmarcar opção "Já tenho termo de venda"'])
        }
        console.log(`status: ${states}`)

        console.log('State test');
        for (let i = 0; i < states.length; i++) {
            if (!states[i][0]) {
                console.log(`${states[i][0]} ${states[i][1]}`)
                return [false, states[i][1]]
            }
        }
        return [true]
    }

    useEffect(() => {
        async function getData() {
            const config = {
                headers: { Authorization: `Bearer ${token}` }
            };
            if (jwtUser?.permissionLevel === 'ADMIN') {
                let users = await axios.get(BASE_URL + '/users/', config);
                setUsers(users.data)
            } else if (jwtUser.isMcAdmin) {
                let users = await axios.get(BASE_URL + '/users/by_mc/' + jwtUser?.mcId, config);
                setUsers(users.data)
            }
        }
        getData()
    }, [BASE_URL, jwtUser.isMcAdmin, jwtUser?.mcId, jwtUser?.permissionLevel, token])

    const fileUpload = async (data, path) => {
        console.log("Will upload: " + path)
        console.log(data);
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "multipart/form-data",
            },
        };

        let mainURL = BASE_URL + "/preListings/"
        //let formData = new FormData();

        try {
            let res = await axios.patch(mainURL + path, data, config)
            return res.data;
        } catch (err) {
            console.log(err);
            return `Não pode efetual o upload para ${mainURL}${path}`
        }
    }

    const submit = async () => {
        console.log('submiting')
        let canSend = enableSend();
        if (!canSend[0]) {
            alert(`Erro no formulário: ${canSend[1]}`)
            return;
        }
        try {
            Geocode.setApiKey('AIzaSyBG0z5RFyaogJihcAmL8A2GmazXLK7lCXc');
            Geocode.setLanguage("pt-br");
            Geocode.setRegion("br");
            Geocode.setLocationType('ROOFTOP');
            let preciseGeo = await Geocode.fromAddress(rua + ', ' + numero + ' - ' + bairro + ', ' + cidade + ', ' + estado + ' - ' + cep)
            try {
                const config = {
                    headers: { Authorization: `Bearer ${token}` }
                };
                let mcRes = listingOwner ? await axios.get(BASE_URL + '/mcs/' + listingOwner.marketCenterId, config) : await axios.get(BASE_URL + '/mcs/my/data', config);
                let regionRes = await axios.get(BASE_URL + '/regions/data', config);
                let mc = mcRes.data;
                let region = regionRes.data;
                console.log(mc)
                console.log(region)
                //Cria contrato
                let contrato = {
                    data: contratoData,
                    dataFim: contratoFimData,
                    matricula: matriculaImovel,
                    numeroContribuinte: numeroContribuinte,
                    cartorio: cartorioRegistroImovel,
                    existeTermo: termoDeVenda ? 'SIM' : 'NÃO',
                    comissao: comission

                };

                console.log("Contrato ok")
                console.log(contrato)

                let proprietario = {
                    fullName: ownerName,
                    cpf: ownerCPF,
                    email: ownerEmail,
                    tipo: ownerType,//PJ ou PF
                    phone: ownerPhone,
                    token: ownerToken,
                };

                console.log("Proprietario ok")
                let list_address = {
                    street_number: numero,
                    street_name: rua,
                    unit_number: compl,
                    city: cidade,
                    state_prov: estado,
                    country: region?.countryCode,
                    postal_code: cep,
                    coordinates_gp: {
                        lat: preciseGeo?.results[0]?.geometry?.location?.lat,
                        lon: preciseGeo?.results[0]?.geometry?.location?.lng,
                    },
                    coordinates_gs: {
                        coordinates: [
                            preciseGeo?.results[0]?.geometry?.location?.lng,
                            preciseGeo?.results[0]?.geometry?.location?.lat,
                        ],
                        type: "Point"
                    },

                }
                console.log("Endereços ok")
                let exterior_features = {
                    has_barbecue_area: churrasqueira,
                    has_deck: deck,
                    has_disabled_access: acessoDeficientes,
                    has_dock: doca,
                    has_garden: jardim,
                    has_gated_entry: entradaFechada,
                    has_green_house: estufa,
                    has_hot_tub_spa: hidro,
                    has_patio: patio,
                    has_pond: lago,
                    has_pool: piscina,
                    has_porch: varanda,
                    has_rv_parking: vagaTrailer,
                    has_sports_court: quadra,
                    has_sprinkler_system: aspercao,
                    is_water_front: beiraMar
                }
                console.log("Exterior ok")
                let structure = {
                    building_name: nomeCondominio,
                    unit_level: andarDoImovel,
                    is_new_construction: novoImovel,
                    parking_total: nVagas,
                    has_parking: parseInt(nVagas) > 0,
                    stories: nAndares,
                    total_units: nUnidades,
                    total_buildings: nTorres,
                    exterior_features,
                    builder_name: construtora,
                }
                console.log("Structure ok")
                let json = {
                    proprietario,
                    contrato,
                    matricula: contrato.matricula,
                    comissionSeller: +contrato.comissao.split('%')[0],
                    source_system_name: "KW Sourced",
                    market_center: mc.kwId,
                    kww_region: region.kwId,
                    mls_id: region.mlsId,
                    mls_name: region.mlsName,
                    currency_code: region.currencyCode,
                    manual_entry: true,
                    kw_agent: true,
                    list_kw_uid: listingOwner ? parseInt(listingOwner.kwuid) : parseInt(user.kwuid),
                    kw_mc: true,
                    kw_listing: true,
                    is_kww_listing: true,
                    version: "2.0.0",
                    current_list_price: unmaskCurrency(valor),
                    list_category_id: listCategory,
                    list_category: translateListCategory(listCategory),
                    list_type_id: listType,
                    list_type: translateListType(listType),
                    list_status_id: listStatus,
                    list_status: translateListStatus(listStatus),
                    prop_type_id: propType,
                    prop_type: translatePropType(propType),
                    prop_subtype_id: propSubType,
                    prop_subtype: translatePropSubType(propSubType),
                    list_address,
                    structure,
                    bairro,
                    year_built: anoConstrucao,
                    total_bed: nQuartos,
                    half_bath: nLavabos,
                    full_bath: nBanheiros,
                    total_bath: String((parseInt(nBanheiros) + parseInt(nLavabos))),
                    living_area: metragem,
                    living_area_units: region.units,
                    lot_size_area: tamanhoTerreno,
                    lot_size_units: region.units,
                    list_desc: descricao.replace(/\n/, /\#\s\s\#/),
                    teamId: listingOwner ? listingOwner.teamId : user.teamId,
                    //fileTermoDeVenda,
                    contract_expiry_dt: contrato.dataFim.split(/\-|\.|\//).join('-'),
                    ownerName: proprietario.fullName,
                    ownerCPF: proprietario.cpf,
                    ownerEmail: proprietario.email,
                    ownerPhone: proprietario.phone
                }
                console.log("Json OK")
                console.log('****************** UNITS:' + region.units)
                console.log(json)
                let res = await axios.post(BASE_URL + '/preListings', json, config);
                console.log(res)
                console.log('Termo de venda');
                console.log(fileTermoDeVenda)
                let resTermo = await fileUpload(fileTermoDeVenda, 'docTermo/' + res.data._id)
                console.log(resTermo)
                let resMatricula = await fileUpload(fileMatricula, 'docMatricula/' + res.data._id)
                console.log(resMatricula)
                let resIPTU = await fileUpload(fileIPTU, 'docIPTU/' + res.data._id)
                console.log(resIPTU)
                alert('Pré-Cadastro bem sucedido! Finalize o cadastro do Listing assim que tiver o termo de captação assinado.');
                navigate('/listings/edit-pre-cadastro/' + res.data._id)


            } catch (e) {
                console.error(e);
                if (e?.response?.data?.msg)
                    alert(e?.response?.data?.msg)
                else if (e?.response?.data?.error)
                    alert(e?.response?.data?.error)
                else {
                    alert('Erro de Conexão.')
                    //logout();
                }
            }

        } catch (e) {
            alert('O endereço do imóvel não foi encontrado.');
            return;
        }

    }

    return (
        <div>
            <Dialog open={open} onClose={close} maxWidth='sm'>
                <DialogTitle>
                    <div style={{ width: '500px' }}>
                        <h1>Pré Cadastro de Listing</h1>
                        <Divider style={{ width: '100%' }} />
                    </div>
                </DialogTitle>
                <DialogContent>
                    <br />
                    {(jwtUser.isMcAdmin || (jwtUser.permissionLevel === 'ADMIN' || jwtUser.permissionLevel === 'SUPER')) &&
                        <Autocomplete
                            options={users}
                            getOptionLabel={(option) => option.fullName}
                            filterSelectedOptions
                            noOptionsText='Nenhum Usuário'
                            value={listingOwner}
                            onChange={(e, newValue) => {
                                setListingOwner(newValue)
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Agente Vendedor"
                                    placeholder="Agente Vendedor"
                                />
                            )}
                        />
                    }
                    <Divider />
                    <PrimaryOwnerForm
                        setOwnerCPF={setOwnerCPF}
                        setOwnerEmail={setOwnerEmail}
                        setOwnerName={setOwnerName}
                        setOwnerPhone={setOwnerPhone}
                        setOwnerType={setOwnerType}
                    />
                    <br />
                    <Divider />
                    <h3>Contrato</h3>
                    <br />
                    <TextModal
                        label='Matrícula do Imóvel *'
                        holder='Informar Matrícula e Cartório'
                        f={setMatriculaImovel}
                    />
                    <TextModal
                        label='Cartório de Registro *'
                        holder='Informar Cartório de Registro do Imóvel'
                        f={setCartorioRegistroImovel}
                    />
                    <label>
                        <TextModal
                            label='Número do Contribuinte (IPTU) *'
                            holder='Informar Número do Contribuinte'
                            f={setNumeroContribuinte}
                        />
                        Comprovante de Matrícula
                        <SingleFileUpload onFileUpload={onMatriculaUpload} />
                    </label>
                    <label>
                        Espelho do IPTU
                        <SingleFileUpload onFileUpload={onIPTUUpload} />
                    </label>

                    <br />
                    <br />
                    <Autocomplete
                        options={['email', 'whatsapp']}
                        getOptionLabel={(option) => option}
                        filterSelectedOptions
                        //noOptionsText='Nenhum Usuário'
                        value={ownerToken}
                        onChange={(e, newValue) => {
                            setOwnerToken(newValue)
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Meio de assinatura"
                                placeholder="email ou whatsapp"
                            />
                        )}
                    />
                    <br />
                    <br />
                    <label>
                        Data de Início de Contrato *<br />
                        <input id="date" type="date" value={contratoData} onChange={
                            (e) => setContratoData(e.target.value)} />
                    </label>
                    <br />
                    <label>
                        Data de término do Contrato *<br />
                        <input id="date" type="date" value={contratoFimData} onChange={
                            (e) => setContratoFimData(e.target.value)} />
                    </label>
                    <br />
                    <br />
                    <div style={{ display: 'inline-block' }}>
                        <p>Já tenho o termo de venda<Checkbox
                            color='primary'
                            checked={termoDeVenda}
                            onChange={(e) => {
                                let val = !termoDeVenda
                                setTermoDeVenda(val)
                            }}
                        /></p>
                        {termoDeVenda && <SingleFileUpload onFileUpload={onTermoUpload} />}
                    </div>
                    <TextField
                        variant='outlined'
                        label='Valor *'
                        color='secondary'
                        fullWidth
                        style={{ marginTop: '5px', marginBottom: '5px' }}
                        onChange={(e) => setValor(maskCurrency(e.target.value))}
                        value={valor}
                    />
                    <TextModal
                        label='Percentual da Comissão *'
                        holder='6%'
                        f={setComission}
                        validator={(v) => !/^\d+(\.\d)?\s?\%$/.test(v)}
                    />
                    <br />
                    <br />
                    <Divider />
                    <AddressForm
                        cep={cep}
                        setCep={setCep}
                        rua={rua}
                        setRua={setRua}
                        numero={numero}
                        setNumero={setNumero}
                        compl={compl}
                        setCompl={setCompl}
                        bairro={bairro}
                        setBairro={setBairro}
                        estado={estado}
                        setEstado={setEstado}
                        cidade={cidade}
                        setCidade={setCidade}
                    />
                    <Divider />
                    <PropertyTypeForm
                        listCategory={listCategory}
                        setListCategory={setListCategory}
                        listType={listType}
                        setListType={setListType}
                        listStatus={listStatus}
                        setListStatus={setListStatus}
                        propType={propType}
                        setPropType={setPropType}
                        propSubType={propSubType}
                        setPropSubType={setPropSubType}
                    />
                    <Divider />
                    <br />
                    <h6>Detalhes</h6>
                    <br />
                    <TextField
                        label='Descrição'
                        color='secondary'
                        fullWidth
                        multiline
                        minRows={3}
                        maxRows={3}
                        style={{ marginTop: '5px', marginBottom: '5px' }}
                        onChange={(e) => setDescricao(String(e.target.value))}
                        value={descricao}
                    />

                </DialogContent>
                <DialogActions>
                    <button className='btn btnSecondary' onClick={close}>Cancelar</button>
                    <button className='btn btnPrimary' onClick={submit}>Cadastrar</button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
export default NewListingCaptacao;