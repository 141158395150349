import { Divider, Grid, IconButton, TextField, Zoom } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../contexts/AuthContext';
import NewListingModal from '../views/NewListingModal';
import placeholder from '../assets/imgs/no-image.jpg'
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import Loading from '../views/Loading';
import LazyLoad from "react-lazyload";
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import FilterListingModal from '../views/FilterListingModal';
import { BASE_URL, SOUKW_URL } from '../sharedComponents/Constants';

export default function Pending() {
    const { user, logout, token } = useContext(AuthContext);
    const [newListingModalOpen, setNewListingModalOpen] = useState(false);
    const [listings, setListings] = useState([]);
    const [users, setUsers] = useState([]);
    const [mcs, setMcs] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(1);
    const [filteringMode, setFilteringMode] = useState(false);
    const [enderecoFilter, setEnderecoFilter] = useState('');
    const [bairroFilter, setBairroFilter] = useState('');
    const [cityFilter, setCityFilter] = useState('');

    const [filters, setFilters] = useState({});


    const [loading, setLoading] = useState(true);

    const openNewListingModal = () => {
        setNewListingModalOpen(true)
    }
    const closeNewListingModal = () => {
        setNewListingModalOpen(false)
    }
    const closeFilterListingModal = () => {
        setFilteringMode(false)
    }

    useEffect(() => {
        const params = new URLSearchParams(window.location.search) // id=123
        let page = params.get('page')
        if (!page)
            page = 1
        setPage(page)
    }, [])

    useEffect(() => {
        async function getData() {
            try {
                setPage(1)
            } catch (e) {
                console.error(e);
                setLoading(false);
                if (e?.response?.data?.msg)
                    alert(e?.response?.data?.msg)
                else {
                    alert('Erro de Conexão.')
                    logout();
                }
            }
        }
        getData();
    }, [filters])

    useEffect(() => {
        async function getData() {
            setLoading(true);
            try {
                const config = {
                    headers: { Authorization: `Bearer ${token}` }
                };
                let url = BASE_URL + '/listings/myListings?status=REPROVADO&page=' + page
                if (filters.city)
                    url = url + '&city=' + filters.city
                if (filters.bairro)
                    url = url + '&neighbourhood=' + filters.bairro
                if (filters.address)
                    url = url + '&address=' + filters.address
                if (filters.condominio)
                    url = url + '&condominio=' + filters.condominio
                if (filters.construtora)
                    url = url + '&construtora=' + filters.construtora
                if (filters.valorMin && filters.valorMin !== 1000)
                    url = url + '&valorMin=' + filters.valorMin
                if (filters.valorMax && filters.valorMax !== 50000000)
                    url = url + '&valorMax=' + filters.valorMax
                if (filters.metragemMin && filters.metragemMin !== 1)
                    url = url + '&metragemMin=' + filters.metragemMin
                if (filters.metragemMax && filters.metragemMax !== 1000)
                    url = url + '&metragemMax=' + filters.metragemMax
                if (filters.quartos && parseInt(filters.quartos) !== 1)
                    url = url + '&quartos=' + filters.quartos
                if (filters.banheiros && parseInt(filters.banheiros) !== 1)
                    url = url + '&banheiros=' + filters.banheiros
                if (filters.vagas && parseInt(filters.vagas) !== 0)
                    url = url + '&vagas=' + filters.vagas
                let results = await axios.get(
                    url,
                    config
                );
                setListings(results.data?.data?.reverse());
                setTotal(results.data?.totalItems)
                setTotalPages(results.data?.totalPages)
                setPage(results.data?.page)
                setLoading(false);
            } catch (e) {
                console.error(e);
                setLoading(false);
                if (e?.response?.data?.msg)
                    alert(e?.response?.data?.msg)
                else {
                    alert('Erro de Conexão.')
                    logout();
                }
            }
        }
        getData();
        console.log(page)
    }, [filters, page])


    useEffect(() => {
        async function getData() {
            try {
                const config = {
                    headers: { Authorization: `Bearer ${token}` }
                };
                let usersResults = await axios.get(BASE_URL + '/users', config)
                let mcsResults = await axios.get(BASE_URL + '/mcs', config)
                setUsers(usersResults.data)
                setMcs(mcsResults.data.data)

            } catch (e) {
                console.error(e);
                if (e?.response?.data?.msg)
                    alert(e?.response?.data?.msg)
                else {
                    alert('Erro de Conexão.')
                    logout();
                }

            }
        }
        getData()
    }, [])


    const maskCurrency = (num = '.') => {
        let initial = String(num).split('.').join('').replace(/[^0-9.]/g, '');
        let formattedNum = initial;
        switch (initial.length) {
            case 1: formattedNum = num;
                break;
            case 2: formattedNum = num;
                break;
            case 3: formattedNum = num;
                break;
            case 4: formattedNum = [
                formattedNum.slice(0, 1), '.', formattedNum.slice(1)].join('');
                break;
            case 5: formattedNum = [
                formattedNum.slice(0, 2), '.', formattedNum.slice(2)].join('');
                break;
            case 6: formattedNum = [
                formattedNum.slice(0, 3), '.', formattedNum.slice(3)].join('');
                break;
            case 7: formattedNum = [
                formattedNum.slice(0, 1), '.', formattedNum.slice(1, 4), '.',
                formattedNum.slice(4)].join('');
                break;
            case 8: formattedNum = [
                formattedNum.slice(0, 2), '.', formattedNum.slice(2, 5), '.',
                formattedNum.slice(5)].join('');
                break;
            case 9: formattedNum = [
                formattedNum.slice(0, 3), '.', formattedNum.slice(3, 6), '.',
                formattedNum.slice(6)].join('');
                break;

        }
        return 'R$ ' + String(formattedNum)
            .replace(/[^0-9.]/g, '')
            .slice(0, 10)
    }

    const deleteListings = async (id) => {
        try {
            const config = {
                headers: { Authorization: `Bearer ${token}` }
            };
            let res = await axios.delete(BASE_URL + '/listings/' + id, config);
            alert('Listing removido com sucesso!');
            document.location.reload();
        } catch (e) {
            console.error(e);
            if (e?.response?.data?.msg)
                alert(e?.response?.data?.msg)
            else {
                alert('Erro de Conexão.')
                logout();
            }
        }
    }

    return (
        <Grid container spacing={0} style={{ height: '100%' }}
            justifyItems='flex-start' alignItems={'flex-start'}>
            <Grid item xs={12} sm={10} style={{ textAlign: 'left', }}>
                <h1>Listings Reprovados</h1>
            </Grid>
            <Grid item xs={12} >
                <Grid container spacing={0} style={{ height: '100%' }} justifyItems='flex-start' alignItems={'flex-start'}>
                    <Grid item xs={12} >
                        <br />
                    </Grid>
                    <Grid item xs={12} sm={12} >
                        <button className='btn btnSecondary' onClick={() => {
                            setEnderecoFilter('')
                            setBairroFilter('')
                            setCityFilter('')
                            if (filteringMode)
                                setFilters({})
                            setFilteringMode(!filteringMode)
                        }}>
                            {filteringMode ? 'Cancelar' : 'Filtrar'}
                        </button>
                        {Object.keys(filters).length > 0 &&
                            <button className='btn btnSecondary' onClick={() => {
                                setFilters({})
                                setPage(1)
                            }}>
                                Limpar Filtros
                            </button>
                        }
                    </Grid>
                    <Zoom in={false} mountOnEnter unmountOnExit>
                        <div>
                            <Grid item xs={12} >
                                <br />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    placeholder='Filtrar Endereço...'
                                    onChange={(e) => setEnderecoFilter(e.target.value)}
                                    value={enderecoFilter}
                                    style={{ marginRight: '5px' }}
                                />
                                <TextField
                                    placeholder='Filtrar Bairro...'
                                    onChange={(e) => setBairroFilter(e.target.value)}
                                    value={bairroFilter}
                                    style={{ marginRight: '5px' }}
                                />
                                <TextField
                                    placeholder='Filtrar Cidade...'
                                    onChange={(e) => setCityFilter(e.target.value)}
                                    value={cityFilter}
                                    style={{ marginRight: '5px' }}
                                />
                                <button className='btn btnPrimary' style={{ marginRight: '5px' }} onClick={() => {
                                    let json = {
                                        address: enderecoFilter,
                                        bairro: bairroFilter,
                                        city: cityFilter
                                    }
                                    if (enderecoFilter === '')
                                        delete json.address
                                    if (bairroFilter === '')
                                        delete json.bairro
                                    if (cityFilter === '')
                                        delete json.city
                                    if (json == {})
                                        return
                                    setFilters(json)
                                }}>
                                    Filtrar
                                </button>
                                <Zoom in={filters != {}}>
                                    <button className='btn btnSecondary' onClick={() => {
                                        setEnderecoFilter('')
                                        setBairroFilter('')
                                        setCityFilter('')
                                        setFilters({})
                                        setFilteringMode(!filteringMode)
                                    }}>
                                        Limpar Filtros
                                    </button>

                                </Zoom>
                            </Grid>
                        </div>
                    </Zoom>
                </Grid>
            </Grid>
            <Grid item xs={12} >
                <br />
            </Grid>
            <Grid container justifyItems='flex-start' alignItems={'center'} style={{
                width: '95%',
                cursor: 'pointer',
                padding: '5px',
                borderTop: '2px  solid #d9d9d9', borderRight: '2px  solid #d9d9d9', borderLeft: '2px  solid #d9d9d9',
                borderRadius: '5px 5px 0px 0px'
            }}>
                <Grid item xs={2}>
                    Foto
                </Grid>
                <Grid item xs={2}>
                    Endereço
                </Grid>
                <Grid item xs={2}>
                    Condomínio
                </Grid>
                <Grid item xs={2}>
                    Valor
                </Grid>
                <Grid item xs={2}>
                    Criado Em
                </Grid>
                <Grid item xs={1}>
                    Agente
                </Grid>
                <Grid item xs={1}>
                    Market Center
                </Grid>
            </Grid>
            {
                loading && <Loading />
            }
            {!loading && listings.map(listing => {
                return (<Grid key={listing._id} item xs={12}>
                    <Divider style={{ width: '95%' }} />
                    <div style={{ backgroundColor: '#f8f7f7', width: '95%', }}>
                        <Grid container justifyItems='flex-start' alignItems={'center'} >
                            <Grid item xs={2} style={{ cursor: 'pointer' }}>
                                <Link to={'/listings/edit-listing/' + listing._id} style={{ textDecoration: 'none', color: '#373f51' }}>
                                    <LazyLoad height={'75'} once placeholder={<Loading />}>
                                        <img src={listing.photos?.length > 0 ? listing.photos[0]?.ph_url : placeholder} width='125px' height='75px' style={{ objectFit: 'cover' }} />
                                    </LazyLoad>
                                </Link>
                            </Grid>
                            <Grid item xs={2}>
                                <Link to={'/listings/edit-listing/' + listing._id} style={{ textDecoration: 'none', color: '#373f51' }}>
                                    {listing.list_address?.street_name}, {listing.list_address?.street_number} - {listing.list_address?.city}
                                </Link>
                            </Grid>
                            <Grid item xs={2}>
                                <Link to={'/listings/edit-listing/' + listing._id} style={{ textDecoration: 'none', color: '#373f51' }}>
                                    {listing.structure?.building_name}
                                </Link>
                            </Grid>
                            <Grid item xs={2}>
                                <Link to={'/listings/edit-listing/' + listing._id} style={{ textDecoration: 'none', color: '#373f51' }}>
                                    {maskCurrency(listing.current_list_price)}
                                </Link>
                            </Grid>
                            <Grid item xs={2}>
                                <Link to={'/listings/edit-listing/' + listing._id} style={{ textDecoration: 'none', color: '#373f51' }}>
                                    {new Date(listing.createdAt).toLocaleDateString('pt-br')}
                                </Link>
                            </Grid>
                            <Grid item xs={1}>
                                <Link to={'/listings/edit-listing/' + listing._id} style={{ textDecoration: 'none', color: '#373f51' }}>
                                    {listing.newListingAgentId !== -1 ? users?.find(u => String(u.kwuid) === String(listing.newListingAgentId))?.fullName : users?.find(u => String(u.kwuid) === String(listing.list_kw_uid))?.fullName}
                                </Link>
                            </Grid>
                            <Grid item xs={1}>
                                <Link to={'/listings/edit-listing/' + listing._id} style={{ textDecoration: 'none', color: '#373f51' }}>
                                    {mcs?.find(mc => String(mc.kwId) === String(listing.market_center))?.name}
                                </Link>
                                {/* <IconButton
                                    onClick={() => deleteListings(listing._id)}
                                    style={{
                                        marginLeft: '5px',
                                        zIndex: 9999999,
                                        backgroundColor: '#a7002f',
                                        color: 'white',
                                    }}><DeleteForeverRoundedIcon />
                                </IconButton> */}
                            </Grid>
                            <Grid item xs={12} style={{ textAlign: 'center', backgroundColor: '#ffe7e6' }}>
                                Motivo:
                            </Grid>
                            <Grid item xs={12} style={{ textAlign: 'center', marginBottom: '5px', backgroundColor: '#ffe7e6' }}>
                                {listing.motive}
                            </Grid>
                        </Grid>
                    </div>
                    <Divider style={{ width: '95%' }} />
                </Grid >)
            })}
            {
                listings.length === 0 && <Grid item xs={12} style={{ textAlign: 'center', marginTop: '100px', }}>
                    <h2 style={{ fontWeight: 'normal' }}>Nenhum listing encontrado.</h2>
                </Grid>

            }
            <Grid item xs={12}>
                <br />
            </Grid>
            <Grid item xs={12}>
                <Zoom in={page > 1}>
                    <Link to={'/listings/rejected?page=' + String(parseInt(page) - 1)} style={{ textDecoration: 'none', color: '#373f51' }}>
                        <button className='btn btnSecondary' onClick={() => {
                            setLoading(true)
                            setPage(parseInt(page) - 1)
                        }}>Anterior</button>
                    </Link>
                </Zoom>
                <span style={{ margin: '5px' }}>
                    Página {page} de {totalPages}
                </span>
                <Zoom in={page < totalPages}>
                    <Link to={'/listings/rejected?page=' + String(parseInt(page) + 1)} style={{ textDecoration: 'none', color: '#373f51' }}>
                        <button className='btn btnSecondary' onClick={() => {
                            setLoading(true)
                            setPage(parseInt(page) + 1)
                        }}>Próxima</button>
                    </Link>
                </Zoom>
            </Grid>
            <NewListingModal open={newListingModalOpen} close={closeNewListingModal} />
            <FilterListingModal open={filteringMode} close={closeFilterListingModal} setFilters={setFilters} />
        </Grid >
    )
}
