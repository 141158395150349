
export const filterObject = {
    agente: '',
    tipoListing: '',
    listingId: '',
    address: '',
    numero: '',
    unidade: '',
    bairro: '',
    city: '',
    tipoImovel: '',
    itemsPerPage: '',
    condominio: '',
    construtura: '',
    metragem: [1, 10000],
    valor: [1000, 50000000],
    quartos: 1,
    banheiros: 1,
    vagas: 0,
    orderByDate: true,
    anunciados: false,
    naoAnunciados: false,
    anunciadosNoArquivo: false,
    naoAnunciadosNoArquivo: false
}

export const reduceFilters = ( filters, action ) => {
    let actions = Object.keys( filterObject )
    let state = { ...filters }
    console.log( action.type )
    if( actions.indexOf( action.type ) > -1 ) {
        
        state[ action.type ] = action.payload
        console.log( state[ action.type ], action.payload)
    }
    //limpa os filtros
    if( action.type === 'clearFilters' ){
        state = {...filterObject}
    }

    return state
}

export const submit = async (filters) => {

    let [valorMin, valorMax] = filters.valor;
    let [metragemMin, metragemMax] = filters.metragem;

    let json = { 
        valorMin,
        valorMax,
        metragemMin,
        metragemMax,
        ... filters
    }

    //clear empty keys
    let keys = Object.keys( json );
    keys.forEach( k => {
        if( json[k] === '' )
            delete json[ k ]
    })


    //clear not used valorFilter
    if (filters.valor[0] === 1000 && filters.valor[1] === 50000000) {
        delete json.valorMin
        delete json.valorMax
    }
    //clear not user metragemFilter
    if (filters.metragem[0] === 1 &&  filters.metragem[1] === 1000) {
        delete json.metragemMin
        delete json.metragemMax
    }

    if( filters.vagas === 0)
        delete json.vagas

    //we do not need valor and metragem any more
    delete json.valor
    delete json.metragem

    if( !filters.anunciados )
        delete json.anunciados
    //return nothing if json is empty
    if (Object.keys( json ).length < 1)
        return {}

    console.log("Estou no modal de filtro")
    console.log( json )
    return json
}