import axios from 'axios';
import {BASE_URL} from '../constants'
//const BASE_URL = 'https://soukw.com.br:3000/grossMC';

export const getGrowthData = async ( token ) => {
  try {
    const response = await axios.get(`${BASE_URL}/grossMC`, {
      headers: { Authorization: `Bearer ${token}` }
    });
    return( response.data.data )
  } catch( err ) {
    console.error(err);
    alert("Falha ao obter Growth Data");
  }
  
}

export const saveGrowthData = async ( token, data ) => {
  console.log("Sending data: ", data)
  try {
    const response = await axios.post(`${BASE_URL}/grossMC`, data, {
      headers: { Authorization: `Bearer ${token}` }
    });
    return( response.data )
  } catch( err ) {
    console.error(err);
    alert("Falha ao salvar Growth Data");
  }
  
}

export const updateGrowthData = async ( token, data ) => {
  try {
    const response = await axios.put(`${BASE_URL}/grossMC/${data.id}`, data, {
      headers: { Authorization: `Bearer ${token}` }
    });
    return( response.data )
  } catch( err ) {
    console.error(err);
    alert("Falha ao salvar Growth Data");
  }
  
}

export const deleteGrowthData = async ( token, id ) => {
  try {
    const response = await axios.delete(`${BASE_URL}/grossMC/${id}`, {
      headers: { Authorization: `Bearer ${token}` }
    });
    console.log( "Growth deleted: ", response.data )
    return( response.data )
  } catch( err ) {
    console.error(err);
    alert("Falha ao deletar Growth Data");
  }
  
}

// export const getGrowthData = async () => {
//   //const response = await axios.get(BASE_URL);
//   //console.log( response.data )
//   return [
//     {
//       "id": "ce1dcb59-db72-4b25-b71c-1d74821a5478",
//       "Editor": "example@example.com",
//       "DataEdicao": "2024-03-11",
//       "Contatos": 3,
//       "Reunioes": 9,
//       "Contratacoes": 4,
//       "Timestamp": "2024-03-22T07:23:03.676996",
//       "AgentLevel": "R0",
//       "MarketCenter": "KWFrema"
//     },
//     {
//       "id": "b9e110d4-1cfb-4263-ab37-9fb9132d067z",
//       "Editor": "example@example.com",
//       "DataEdicao": "2023-04-16T07:23:03.677142",
//       "Contatos": 4,
//       "Reunioes": 2,
//       "Contratacoes": 0,
//       "Timestamp": "2024-03-22T07:23:03.677181",
//       "AgentLevel": "R0",
//       "MarketCenter": "KWFG"
//     },
//     {
//       "id": "5b0ff1c1-396e-4f17-beca-8f4ae88ba5dm",
//       "Editor": "example@example.com",
//       "DataEdicao": "2023-06-09T07:23:03.677222",
//       "Contatos": 6,
//       "Reunioes": 10,
//       "Contratacoes": 10,
//       "Timestamp": "2024-03-22T07:23:03.677231",
//       "AgentLevel": "R2",
//       "MarketCenter": "KWFrema"
//     },
//     {
//       "id": "6e8e0396-1e6c-4248-aa04-01ecd8c551b4",
//       "Editor": "example@example.com",
//       "DataEdicao": "2023-05-24T07:23:03.677398",
//       "Contatos": 1,
//       "Reunioes": 3,
//       "Contratacoes": 2,
//       "Timestamp": "2024-03-22T07:23:03.677425",
//       "AgentLevel": "R1",
//       "MarketCenter": "KWComVC"
//     },
//     {
//       "id": "725362c5-98c8-4c5f-afb7-0b1e13f05cb3",
//       "Editor": "example@example.com",
//       "DataEdicao": "2023-06-06T07:23:03.677465",
//       "Contatos": 6,
//       "Reunioes": 4,
//       "Contratacoes": 9,
//       "Timestamp": "2024-03-22T07:23:03.677476",
//       "AgentLevel": "R0",
//       "MarketCenter": "KWFrema"
//     },
//     {
//       "id": "9983e2c3-60c8-4f0a-a387-8390fd8bf9a8",
//       "Editor": "example@example.com",
//       "DataEdicao": "2023-11-29T07:23:03.677499",
//       "Contatos": 10,
//       "Reunioes": 5,
//       "Contratacoes": 10,
//       "Timestamp": "2024-03-22T07:23:03.677509",
//       "AgentLevel": "R2",
//       "MarketCenter": "KWComVC"
//     },
//     {
//       "id": "62d61fd0-d950-43b6-9c02-b09e92ce70ef",
//       "Editor": "example@example.com",
//       "DataEdicao": "2023-09-09T07:23:03.677548",
//       "Contatos": 8,
//       "Reunioes": 9,
//       "Contratacoes": 2,
//       "Timestamp": "2024-03-22T07:23:03.677557",
//       "AgentLevel": "R2",
//       "MarketCenter": "KWFrema"
//     },
//     {
//       "id": "991036db-af53-43c7-ad93-87285ac93935",
//       "Editor": "example@example.com",
//       "DataEdicao": "2023-07-22T07:23:03.677576",
//       "Contatos": 0,
//       "Reunioes": 7,
//       "Contratacoes": 5,
//       "Timestamp": "2024-03-22T07:23:03.677585",
//       "AgentLevel": "R1",
//       "MarketCenter": "KWFG"
//     },
//     {
//       "id": "b1e80df7-0ce0-4a76-b974-7d22e901d1e1",
//       "Editor": "example@example.com",
//       "DataEdicao": "2023-10-22T07:23:03.677883",
//       "Contatos": 10,
//       "Reunioes": 7,
//       "Contratacoes": 3,
//       "Timestamp": "2024-03-22T07:23:03.677890",
//       "AgentLevel": "R1",
//       "MarketCenter": "KWComVC"
//     },
//     {
//       "id": "37fe54d6-e01b-48e8-b159-39737caa2961",
//       "Editor": "example@example.com",
//       "DataEdicao": "2023-11-29T07:23:03.677913",
//       "Contatos": 6,
//       "Reunioes": 5,
//       "Contratacoes": 8,
//       "Timestamp": "2024-03-22T07:23:03.677918",
//       "AgentLevel": "R2",
//       "MarketCenter": "KWFrema"
//     },
//     {
//       "id": "d5cebbe7-565f-4f48-8707-3518bd822ee1",
//       "Editor": "example@example.com",
//       "DataEdicao": "2024-01-01T07:23:03.677929",
//       "Contatos": 7,
//       "Reunioes": 4,
//       "Contratacoes": 7,
//       "Timestamp": "2024-03-22T07:23:03.677934",
//       "AgentLevel": "R2",
//       "MarketCenter": "KWComVC"
//     },
//     {
//         "id": "ce1dcb59-db72-4b25-b71c-1d74821a547x",
//         "Editor": "example@example.com",
//         "DataEdicao": "2023-03-23T07:23:03.676790",
//         "Contatos": 3,
//         "Reunioes": 9,
//         "Contratacoes": 4,
//         "Timestamp": "2024-03-22T07:23:03.676996",
//         "AgentLevel": "R0",
//         "MarketCenter": "KWFrema"
//       },
//       {
//         "id": "b9e110d4-1cfb-4263-ab37-9fb9132d0679",
//         "Editor": "example@example.com",
//         "DataEdicao": "2023-04-16T07:23:03.677142",
//         "Contatos": 4,
//         "Reunioes": 2,
//         "Contratacoes": 0,
//         "Timestamp": "2024-03-22T07:23:03.677181",
//         "AgentLevel": "R0",
//         "MarketCenter": "KWFG"
//       },
//       {
//         "id": "5b0ff1c1-396e-4f17-beca-8f4ae88ba5d8",
//         "Editor": "example@example.com",
//         "DataEdicao": "2023-06-09T07:23:03.677222",
//         "Contatos": 6,
//         "Reunioes": 10,
//         "Contratacoes": 10,
//         "Timestamp": "2024-03-22T07:23:03.677231",
//         "AgentLevel": "R2",
//         "MarketCenter": "KWFrema"
//       },
//       {
//         "id": "6e8e0396-1e6c-4248-aa04-01ecd8c551bj",
//         "Editor": "example@example.com",
//         "DataEdicao": "2023-05-24T07:23:03.677398",
//         "Contatos": 1,
//         "Reunioes": 3,
//         "Contratacoes": 2,
//         "Timestamp": "2024-03-22T07:23:03.677425",
//         "AgentLevel": "R1",
//         "MarketCenter": "KWComVC"
//       },
//       {
//         "id": "725362c5-98c8-4c5f-afb7-0b1e13f05cbk",
//         "Editor": "example@example.com",
//         "DataEdicao": "2023-06-06T07:23:03.677465",
//         "Contatos": 6,
//         "Reunioes": 4,
//         "Contratacoes": 9,
//         "Timestamp": "2024-03-22T07:23:03.677476",
//         "AgentLevel": "R0",
//         "MarketCenter": "KWFrema"
//       },
//       {
//         "id": "9983e2c3-60c8-4f0a-a387-8390fd8bf9ai",
//         "Editor": "example@example.com",
//         "DataEdicao": "2023-11-29T07:23:03.677499",
//         "Contatos": 10,
//         "Reunioes": 5,
//         "Contratacoes": 10,
//         "Timestamp": "2024-03-22T07:23:03.677509",
//         "AgentLevel": "R2",
//         "MarketCenter": "KWComVC"
//       }
//     ];
// };

// export const postGrowthData = async (formData) => {
//     const response = await axios.post(BASE_URL, formData);
//     return response.data;
// };