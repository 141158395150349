import { Grid } from "@mui/material";
import aptoLogo from '../assets/imgs/aptoLogo.png'
import olxLogo from '../assets/imgs/olxLogo.png'

export default function PortaisImobiliarios() {
    return (
        <>
            {/* ===== Portal APTO ===== */}
            <Grid container
                xs={8}
                md={8}
                lg={5}
                paddingTop={1}
            >
                <div className="imGrid" >
                    <div className="imCard">
                        <img className="imLogo" src={aptoLogo} />
                        <div className="dataLandInfo">
                            <h1 className="imH1">
                                APTO
                            </h1>
                            <p className="imP">
                                O Apto é uma plataforma de lançamentos imobiliários.
                                Que possibilita que as empresas anunciem imóveis de lançamento e recebam leads mais qualificados.

                            </p>
                        </div>
                    </div>
                </div>
                <div className="gridButton">
                    <div className="imGrid">
                        <Grid item xs={10}
                            style={{
                                marginTop: '1rem',
                                marginLeft: '3.5rem'
                            }}>
                            <a target='_blank' href="https://drive.google.com/file/d/1Ce83-a8gkGrhZ8JeLepU1vFgHYkspO-l/view?usp=sharing">
                                <button className='btn btnSecondary'
                                >
                                    Termo de Parceria
                                </button>
                            </a>
                        </Grid>

                        <Grid item xs={2}
                            style={{
                                marginTop: '1rem'
                            }}>
                            <a href="https://apto.vc/login" target='_blank'>
                                <button className='btn btnPrimary'
                                >
                                    Acessar
                                </button>
                            </a>
                        </Grid>
                    </div>
                </div>
            </Grid>
            <div style={{
                width: '4rem'
            }}>
            </div>

            {/* ===== PORTAL OLX ===== */}
            <Grid container
                xs={8}
                md={8}
                lg={6}
                paddingTop={1}
            >
                <div className="imGrid" >
                    <div className="imCard">
                        <img className="imLogo" src={olxLogo} />
                        <div className="dataLandInfo">
                            <h1 className="imH1">
                                OLX
                            </h1>
                            <p className="imP">
                                A OLX é a empresa dona dos portais ZAP Imóveis,
                                OLX imóveis, e Vivareal, aumentando assim a visibilidade dos anúncios,
                                e trazendo mais uma possibilidade de divulgação de imóveis.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="gridButton">
                    <div className="imGrid">
                        <Grid item xs={10}
                            style={{
                                marginTop: '1rem',
                                marginLeft: '3.5rem'
                            }}>
                            <a target='_blank' href="https://drive.google.com/file/d/10EtjWoimTRlKC2ifqpcGqCwTTfLZymaY/view?usp=sharing">
                                <button className='btn btnSecondary'
                                >
                                    Termo de Parceria
                                </button>
                            </a>
                        </Grid>

                        <Grid item xs={2}
                            style={{
                                marginTop: '1rem'
                            }}>
                            <a href="https://conta.olx.com.br/acesso" target='_blank'>
                                <button className='btn btnPrimary'
                                >
                                    Acessar
                                </button>
                            </a>
                        </Grid>
                    </div>
                </div>
            </Grid>
            <div style={{
                width: '4rem'
            }}>
            </div>
        </>
    )
}