import { Grid } from "@mui/material";
import celerLogo from '../assets/imgs/celerLogo.png'

export default function parceriaDecorators() {
    return (
        <>
            {/* ===== CELER ===== */}
            <Grid container
                xs={9}
                md={8}
                lg={4.8}
                paddingTop={1}
            >
                <div className="imGrid" >
                    <div className="imCard">
                        <img className="imLogo" src={celerLogo} />
                        <div className="dataLandInfo">
                            <h1 className="imH1">
                                Celer
                            </h1>
                            <p className="imP">
                                A Celer atua com serviços de decoração virtual para ambientes (home staging),
                                onde a partir da decoração virtual em 3 fotos, há aumento de 50% na geração de leads.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="gridButton">
                    <div className="imGrid">
                        <Grid item xs={10}
                            style={{
                                marginTop: '1rem',
                                marginLeft: '3.5rem'
                            }}>
                            <a target='_blank' href="https://drive.google.com/file/d/1mMbrte9I1oO3Cg80AVnJc-7klvfMP60b/view?usp=sharing">
                                <button className='btn btnSecondary'
                                >
                                    Termo de Parceria
                                </button>
                            </a>
                        </Grid>

                        <Grid item xs={2}
                            style={{
                                marginTop: '1rem'
                            }}>
                            <a href="https://celer.imb.br/auth/login" target='_blank'>
                                <button className='btn btnPrimary'
                                >
                                    Acessar
                                </button>
                            </a>
                        </Grid>

                        {/* <Grid item xs={10}
                            style={{
                                marginTop: '1rem',
                                marginLeft: '3.5rem'
                            }}>
                            <a target='_blank' href="https://docs.google.com/spreadsheets/d/1134gXjhmPQuniI3YFzgYiN-4sWIi4oEs/edit?usp=sharing&ouid=118284388945904448345&rtpof=true&sd=true">
                                <button className='btn btnSecondary'
                                >
                                    Formulário Briefing
                                </button>
                            </a>
                        </Grid> */}
                    </div>
                </div>
            </Grid>
            <div style={{
                width: '4rem'
            }}>
            </div>

        </>
    )
}