import { Grid, TextField } from '@mui/material'
import love from '../assets/imgs/love.png'
import kw from '../assets/imgs/kwred.png'
import React, { useContext, useState } from 'react'
import axios from 'axios'
import { AuthContext } from '../contexts/AuthContext'
import { BASE_URL, SOUKW_URL } from '../sharedComponents/Constants'

export default function SetEmail() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const { setToken, setUser, setJWTUser } = useContext(AuthContext)

    const submit = async () => {
        try {
            if (!email.includes('@kwsp.com.br')) {
                alert('O email precisa ser @kwsp.com.br')
                return
            }



        } catch (e) {
            console.error(e);
            if (e?.response?.data?.msg)
                alert(e?.response?.data?.msg)
            else {
                alert('Erro de Conexão.');
            }
        }
    }
    return (
        <div style={{ backgroundColor: '#eceff4', height: '100vh' }}>
            <Grid container alignItems={'center'} justifyContent={'center'} style={{ height: '100%' }}>
                <div className='card' style={{ width: 'auto', height: '500px' }}>

                    <div xs={4} style={{ display: 'inline-block', backgroundImage: `url(${love})`, width: '400px', height: '100%', backgroundSize: "cover" }}>
                    </div>
                    <div style={{ display: 'inline-block', width: '400px', height: '100%', marginLeft: '50px', marginRight: '50px' }}>
                        <img src={kw} style={{ width: '50px' }} />
                        <br />
                        <h1>Bem vindo ao SouKW</h1>
                        <h6>Se você já cadastrou seu Email @kwsp, preencha aqui qual foi o email que você escolheu.</h6>
                        <br />
                        Se ainda não cadastrou, aguarde o recebimento de um email para cadastrar. Após cadastrar volte aqui, e digite o email cadastrado.
                        <br />
                        <br />
                        <TextField
                            label='Email KWSP'
                            fullWidth
                            type={'email'}
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                        />
                        <br />
                        <br />
                        <br />
                        <button className='btn btnPrimary' onClick={submit}>Cadastrar</button>
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                    </div>
                </div>
            </Grid >
        </div >
    )
}
