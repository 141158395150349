import { 
    Box, Switch, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, FormControlLabel, Grid, MenuItem, Radio, RadioGroup, Slider, TextField } from '@mui/material'
import axios from 'axios';
import React, { useContext, useEffect, useState, useReducer } from 'react'
import ModalBlankLine from '../../sharedComponents/listings/ModalBlankLine';
import { maskCurrency } from '../../libs/utils';
import { filterObject, reduceFilters, submit } from './filterLogic';
import TextModal from './TextModal';

export default function FilterListingModal({ open, close, setFilters }) {

    const [filters, dispatchFilters] = useReducer( reduceFilters, filterObject )

    console.log( "Modal Filters: ", filters )


    return (
        <div>
            <Dialog open={open} onClose={close} maxWidth='sm'>
                <DialogTitle>
                    <div style={{ width: '500px' }}>
                        <h1>Filtrar Listings</h1>
                        <Divider style={{ width: '100%' }} />
                    </div>
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={0} alignItems={'center'} justifyContent={'center'} >
                        <ModalBlankLine />
                        < Grid item xs={12}>
                            <label>Listings por página<br/>
                                <Slider
                                    defaultValue={10}
                                    valueLabelDisplay="auto"
                                    step={10}
                                    marks
                                    min={20}
                                    max={200}
                                    value={filters.itemsPerPage}
                                    onChange={(e) => dispatchFilters({type: 'itemsPerPage', payload: e.target.value }) }
                                />
                            </label>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                    placeholder='Agente - nome, kwid, email'
                                    onChange={(e) => dispatchFilters({type: 'agente', payload: e.target.value })}
                                    value={filters.agente}
                                    style={{ marginRight: '5px' }}
                            />
                            <ModalBlankLine />
                            <Switch 
                                checked={filters.orderByDate}
                                onChange={(e) => dispatchFilters({type: 'orderByDate', payload: !filters.orderByDate })}
                            />Ordenar por data
                            <ModalBlankLine />
                            <Switch 
                                checked={filters.anunciados}
                                onChange={(e) => {
                                    console.log("Switch value: ", e.target.value )
                                    dispatchFilters({type: 'anunciados', payload: !filters.anunciados })
                                }}
                            />Anunciados em qualquer XML
                        </Grid>
                        <ModalBlankLine />
                        <Grid item xs={12}>
                            <Switch 
                                checked={filters.naoAnunciados}
                                onChange={(e) => {
                                    console.log("Switch value: ", e.target.value )
                                    dispatchFilters({type: 'naoAnunciados', payload: !filters.naoAnunciados })
                                }}
                            />Não Anunciados em nenhum XML
                            <ModalBlankLine />
                            <Switch 
                                checked={filters.anunciadosNoArquivo}
                                onChange={(e) => {
                                    console.log("Switch value: ", e.target.value )
                                    dispatchFilters({type: 'anunciadosNoArquivo', payload: !filters.anunciadosNoArquivo })
                                }}
                            />Anunciados no XML Selecionado 
                        </Grid>
                        <ModalBlankLine />
                        <Grid item xs={12}>
                            <Switch 
                                checked={filters.naoAnunciadosNoArquivo}
                                onChange={(e) => {
                                    console.log("Switch value: ", e.target.value )
                                    dispatchFilters({type: 'naoAnunciadosNoArquivo', payload: !filters.naoAnunciadosNoArquivo })
                                }}
                            />Não Anunciados no XML Selecionado
                        </Grid>
                        <ModalBlankLine />
                        <Grid item xs={12}>
                                <TextField
                                    placeholder='Encontrar listing por ID (mls_number ou command)...'
                                    onChange={(e) => dispatchFilters({type: 'listingId', payload: e.target.value })}
                                    value={filters.listingId}
                                    fullWidth
                                    style={{ marginRight: '5px' }}
                                />
                        </Grid>
                            <ModalBlankLine />

                        <Grid item xs={12} >
                            <TextField 
                                variant='outlined'
                                select
                                color='secondary'
                                fullWidth
                                style={{ marginTop: '5px', marginBottom: '5px' }}
                                helperText='Exclusividade?' 
                                value={filters.tipoListing}
                                onChange={(e) => dispatchFilters({type: 'tipoListing', payload: e.target.value })}
                            >
                                <MenuItem value={4}>Com Exclusividade</MenuItem>
                                <MenuItem value={5}>Sem Exclusividade</MenuItem>
                            </TextField>
                        </Grid>
                        <ModalBlankLine />
                        <Grid item xs={12} >
                            <TextField
                                variant='outlined'
                                select
                                color='secondary'
                                fullWidth
                                style={{ marginTop: '5px', marginBottom: '5px' }}
                                helperText="Apartamento, Casa, Escritório..."
                                onChange={(e) => dispatchFilters({type: 'tipoImovel', payload: e.target.value })}
                                value={filters.tipoImovel}
                            >
                                <MenuItem value={1}>Apartamento</MenuItem>
                                <MenuItem value={6}>Duplex</MenuItem>
                                <MenuItem value={19}>Triplex</MenuItem>
                                <MenuItem value={12}>Quadruplex</MenuItem>
                                <MenuItem value={15}>Casa</MenuItem>
                                <MenuItem value={14}>Casa Geminada/Sobrada</MenuItem>
                                <MenuItem value={4}>Casa em Condomínio</MenuItem>
                                <MenuItem value={28}>Terreno</MenuItem>
                                <MenuItem value={21}>Escritório</MenuItem>
                                <MenuItem value={27}>Loja</MenuItem>
                                <MenuItem value={23}>Industrial</MenuItem>

                            </TextField>
                        </Grid>
                        <ModalBlankLine />
                        <TextModal 
                            filtro='address'
                            title='Endereço' 
                            dispatchFunction={dispatchFilters}
                            initialValue={filters.address} 
                        />
                        <ModalBlankLine />
                        <Grid item xs={12}>
                        <Grid container spacing={0} alignItems={'center'} justifyContent={'center'} xs={12}>
                            <Grid item xs={6}>
                                <TextModal 
                                    filtro='numero'
                                    title='Número' 
                                    dispatchFunction={dispatchFilters}
                                    initialValue={filters.numero} 
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextModal 
                                    filtro='unidade'
                                    title='Complemento' 
                                    dispatchFunction={dispatchFilters}
                                    initialValue={filters.unidade} 
                                />
                            </Grid>
                        </Grid>
                        <ModalBlankLine />
                        </Grid>
                        <ModalBlankLine />
                        <TextModal 
                            filtro='bairro'
                            title='Bairro' 
                            dispatchFunction={dispatchFilters}
                            initialValue={filters.bairro} 
                        />
                        <ModalBlankLine />
                        <TextModal 
                            filtro='city'
                            title='Cidade' 
                            dispatchFunction={dispatchFilters}
                            initialValue={filters.city} 
                        />

                            <ModalBlankLine />
                        <Grid item xs={12}>
                            <TextField
                                placeholder='Filtrar Nome do Condomínio...'
                                label='Filtrar Nome do Condomínio...'
                                onChange={(e) => dispatchFilters({type: 'condominio', payload: e.target.value })}
                                value={filters.condominio}
                                fullWidth
                                style={{ marginRight: '5px' }}
                            />
                        </Grid>
                            <ModalBlankLine />
                        <Grid item xs={12}>
                            <TextField
                                placeholder='Filtrar Construtora...'
                                label='Filtrar Construtora...'
                                onChange={(e) => dispatchFilters({type: 'construtora', payload: e.target.value })}
                                value={filters.construtora}
                                fullWidth
                                style={{ marginRight: '5px' }}
                            />
                        </Grid>
                            <ModalBlankLine />
                        <Grid item xs={12}>
                            Valor:
                        </Grid>
                            <ModalBlankLine />
                            <ModalBlankLine />
                        <Grid item xs={10} style={{ textAlign: 'center' }}>
                            <Box sx={{ width: '100%', textAlign: 'center' }} alignItems='center' justifyContent={'center'} display='flex'>
                                <Slider
                                    value={filters.valor}
                                    onChange={(e) => dispatchFilters({type: 'valor', payload: e.target.value })}
                                    color='secondary'
                                    valueLabelDisplay="on"
                                    valueLabelFormat={s => maskCurrency(s)}
                                    min={1000}
                                    max={100000000}
                                />
                            </Box>
                        </Grid>
                            <ModalBlankLine />
                        <Grid item xs={12}>
                            Metragem:
                        </Grid> 
                            <ModalBlankLine />
                            <ModalBlankLine />
                        <Grid item xs={10} style={{ textAlign: 'center' }}>
                            <Box sx={{ width: '100%', textAlign: 'center' }} alignItems='center' justifyContent={'center'} display='flex'>
                                <Slider
                                    value={filters.metragem}
                                    onChange={(e) => dispatchFilters({type: 'metragem', payload: e.target.value })}
                                    color='secondary'
                                    valueLabelDisplay="on"
                                    valueLabelFormat={s => s + 'm²'}
                                    min={1}
                                    max={10000}
                                />
                            </Box>
                        </Grid>
                            <ModalBlankLine />
                        <Grid item xs={12}>
                            Quartos:
                        </Grid>
                        <Grid item xs={11}>
                            <FormControl>
                                <RadioGroup
                                    row
                                    value={filters.quartos}
                                    onChange={(e) => dispatchFilters({type: 'quartos', payload: e.target.value })}
                                >
                                    <FormControlLabel value={1} control={<Radio />} label="1+" />
                                    <FormControlLabel value={2} control={<Radio />} label="2+" />
                                    <FormControlLabel value={3} control={<Radio />} label="3+" />
                                    <FormControlLabel value={4} control={<Radio />} label="4+" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                            <ModalBlankLine />
                        <Grid item xs={12}>
                            Banheiros:
                        </Grid>
                        <Grid item xs={11}>
                            <FormControl>
                                <RadioGroup
                                    row
                                    value={filters.banheiros}
                                    onChange={(e) => dispatchFilters({type: 'baneiros', payload: e.target.value })}
                                >
                                    <FormControlLabel value={1} control={<Radio />} label="1+" />
                                    <FormControlLabel value={2} control={<Radio />} label="2+" />
                                    <FormControlLabel value={3} control={<Radio />} label="3+" />
                                    <FormControlLabel value={4} control={<Radio />} label="4+" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                            <ModalBlankLine />
                </Grid>
                </DialogContent>
                <DialogActions>
                    <button className='btn btnSecondary' onClick={close}>Cancelar</button>
                    <button className='btn btnSecondary' onClick={( e ) => dispatchFilters({type: 'clearFilters', payload: '' })}>Limpar</button>
                    <button className='btn btnPrimary' onClick={( e ) => {
                        console.log( filters )
                        setFilters( filters )
                        close()
                        }}>
                        Filtrar
                    </button>
                </DialogActions>
            </Dialog>
        </div >
    )
}