import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../contexts/AuthContext';
import axios from 'axios';
import { Divider, Grid } from '@mui/material';
import { BASE_URL, SOUKW_URL } from '../sharedComponents/Constants';

export default function UpdatesBoard() {
    const { user, token, logout } = useContext(AuthContext);
    const [updates, setUpdates] = useState([])
    useEffect(() => {
        async function getData() {

            try {
                const config = {
                    headers: { Authorization: `Bearer ${token}` }
                };
                let resultsT = await axios.get(BASE_URL + '/updates/', config);
                setUpdates(resultsT.data)
            }
            catch (e) {
                console.error(e);
                if (e?.response?.data?.msg)
                    alert(e?.response?.data?.msg)
                else {
                    alert('Erro de conexão buscando as novidades do SouKW.')
                }
            }
        }
        getData()
    }, [])

    return (

        <div className='card' style={{ padding: '15px', overflowY: 'scroll' }}>
            <h1>Novidades do SouKW</h1>

            <br />
            {updates?.map((update, i) => {
                return (
                    <Grid container style={{ textAlign: 'left', }}>
                        <Grid item xs={12}>
                            <h1 style={{ color: '#0099a7', fontSize: '1.2rem' }}>{new Date(update.createdAt).toLocaleDateString('pt-br')}</h1>
                        </Grid>
                        <Grid item xs={12}>
                            <h1 style={{ fontSize: '1.3rem' }}>{String(update.title).toUpperCase()}</h1>
                        </Grid>
                        <Grid item xs={12}>
                            <h6 style={{ fontSize: '1.0rem' }}>{update.text}</h6>
                        </Grid>
                        <Grid item xs={12}>
                            <br />
                            {i < update.length &&
                                <Divider />
                            }
                        </Grid>
                    </Grid>

                )
            })}
        </div >
    )
}
