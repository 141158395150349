import { Autocomplete, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControlLabel, Grid, MenuItem, TextField } from '@mui/material'
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../contexts/AuthContext';
import { BASE_URL, SOUKW_URL } from '../sharedComponents/Constants';

export default function NewMcModal({ open, close, regions }) {
    const { token, user, logout } = useContext(AuthContext);
    const [teamName, setTeamName] = useState('');
    const [teamMcId, setTeamMcId] = useState('');

    const [mcName, setMcName] = useState('');
    const [mcKwId, setMcKwId] = useState('');
    const [mcRegionId, setMcRegionId] = useState('');
    const [admins, setAdmins] = useState([]);
    const [divideViewByTeams, setDivideViewByTeams] = useState(false);

    const [users, setUsers] = useState([]);

    useEffect(() => {
        async function getData() {
            try {
                const config = {
                    headers: { Authorization: `Bearer ${token}` }
                };
                let userRes = await axios.get(BASE_URL + '/users/', config);
                setUsers(userRes.data);

            } catch (e) {
                console.error(e);
                if (e?.response?.data?.msg)
                    alert(e?.response?.data?.msg)
                else {
                    alert('Erro de Conexão.')
                    logout();
                }
            }
        }
        getData();
    }, [])

    const submitMc = async () => {
        try {
            const config = {
                headers: { Authorization: `Bearer ${token}` }
            };
            let adminIds = admins.map(a => a._id)
            let json = {
                name: mcName,
                kwId: mcKwId,
                regionId: mcRegionId,
                hasReviewsOnListings: true,
                divideViewByTeams,
                admins: adminIds,
            }
            let mcRes = await axios.post(BASE_URL + '/mcs/', json, config);
            alert('Cadastro de time bem sucedido!');
            document.location.reload();
        } catch (e) {
            console.error(e);
            if (e?.response?.data?.msg)
                alert(e?.response?.data?.msg)
            else {
                alert('Erro de Conexão.')
                logout();
            }
        }
    }
    return (
        <div>
            <Dialog open={open} onClose={close} maxWidth='sm'>
                <DialogTitle>
                    <div style={{ width: '500px' }}>
                        <h1>Cadastrar Market Center</h1>
                        <Divider style={{ width: '100%' }} />
                    </div>
                </DialogTitle>
                <DialogContent><Grid container spacing={0}>
                    <Grid item xs={12}>
                        <br />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            label='Nome do MC *'
                            variant='outlined'
                            fullWidth
                            onChange={(e) => setMcName(e.target.value)}
                            value={mcName}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <br />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label='KWUID do MC *'
                            variant='outlined'
                            fullWidth
                            onChange={(e) => setMcKwId(e.target.value)}
                            value={mcKwId}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <br />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label='Região *'
                            select
                            variant='outlined'
                            fullWidth
                            onChange={(e) => setMcRegionId(e.target.value)}
                            value={mcRegionId}
                        >
                            {
                                regions?.map(r => {
                                    return (
                                        <MenuItem value={r._id}>{r.name}</MenuItem>
                                    )
                                })
                            }
                        </TextField>
                    </Grid>
                    <Grid item xs={12}>
                        <br />
                    </Grid>
                    <Grid item xs={12}>
                        <Autocomplete
                            multiple
                            options={users}
                            getOptionLabel={(option) => option.fullName}
                            filterSelectedOptions
                            noOptionsText='Nenhum Usuário'
                            value={admins}
                            onChange={(e, newValue) => setAdmins(newValue)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Administradores"
                                    placeholder="Administradores"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <br />
                    </Grid>
                    <Grid item xs={12}>
                        <div style={{ display: 'inline-block' }}>
                            <Checkbox
                                color='primary'
                                checked={divideViewByTeams}
                                onChange={(e) => setDivideViewByTeams(e.target.checked)}
                            />Dividir visualização por times
                        </div>
                    </Grid>
                </Grid>
                </DialogContent>
                <DialogActions>
                    <button className='btn btnSecondary' onClick={close}>Cancelar</button>
                    <button className='btn btnPrimary' onClick={submitMc}>
                        Cadastrar MC
                    </button>
                </DialogActions>
            </Dialog>
        </div>
    )
}