import { Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControlLabel, MenuItem, TextField } from '@mui/material'
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import Geocode from 'react-geocode';
import { AuthContext } from '../contexts/AuthContext';
import placeholder from '../assets/imgs/no-image.jpg'
import { useNavigate } from 'react-router-dom';
import { BASE_URL, SOUKW_URL } from '../sharedComponents/Constants';

export default function NewTreinamentoGrowthCall({ open, close, call }) {
    const navigate = useNavigate()
    const { token, user, logout } = useContext(AuthContext);

    const [day, setDay] = useState('');
    const [url, setUrl] = useState('');
    const [month, setMonth] = useState('');
    const [year, setYear] = useState('');
    const [type, setType] = useState('');
    const [leadershipOnly, setLeadershipOnly] = useState(false);

    useEffect(() => {
        setDay(call ? call.day : '');
        setUrl(call ? call.url : '');
        setMonth(call ? call.month : '');
        setYear(call ? call.year : '');
        setType(call ? call.type : '');
        setLeadershipOnly(call ? call.leadershipOnly : false);

    }, [call])

    const monthNames = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"];

    const submit = async () => {
        if (!day || !url || month === '' || !year) {
            alert('Preencha todos os campos.');
            return;
        }
        try {
            const config = {
                headers: { Authorization: `Bearer ${token}` }
            };

            if (!call) {
                let json = { day, url, month, year, type, leadershipOnly }
                let res = await axios.post(BASE_URL + '/treinamentos/growth-calls', json, config);
                if (res.data)
                    alert('Cadastro bem sucedido!');
            } else {
                let json = { _id: call?._id, day, url, month, year, type, leadershipOnly }

                let res = await axios.patch(BASE_URL + '/treinamentos/growth-calls/' + call?._id, json, config);
                if (res.data)
                    alert('Editado com sucesso!');
                navigate('/treinamentos/growth-calls/' + year + '/' + monthNames[month].toLowerCase() + '?type=' + type, { replace: true })
            }
            close();
            document.location.reload()
        } catch (e) {
            console.error(e);
            if (e?.response?.data?.msg)
                alert(e?.response?.data?.msg)
            else {
                alert('Erro de Conexão.')
                logout();
            }
        }
    }

    const iterYears = () => {
        let now = new Date().getFullYear();
        let optionsArr = []
        for (let i = now; i >= 2022; i--) {
            optionsArr.push(i);
        }
        return optionsArr;
    }
    return (
        <div>
            <Dialog open={open} onClose={close} maxWidth='sm'>
                <DialogTitle>
                    <div style={{ width: '500px' }}>
                        <h1>{call ? 'Editar' : 'Cadastrar Nova'} Call</h1>
                        <Divider style={{ width: '100%' }} />
                    </div>
                </DialogTitle>
                <DialogContent>
                    <h6>Dados do Treinamento</h6>
                    <br />
                    <TextField
                        variant='outlined'
                        select
                        label='Função *'
                        color='secondary'
                        fullWidth
                        style={{ marginTop: '5px', marginBottom: '5px' }}
                        onChange={(e) => setType(e.target.value)}
                        value={type}
                    >
                        <MenuItem value={0}>Agente</MenuItem>
                        <MenuItem value={1}>Team Leader</MenuItem>
                        <MenuItem value={2}>MC OP</MenuItem>
                        <MenuItem value={3}>Regional</MenuItem>
                    </TextField>
                    <TextField
                        variant='outlined'
                        label='Dia *'
                        color='secondary'
                        type='number'
                        fullWidth
                        style={{ marginTop: '5px', marginBottom: '5px' }}
                        onChange={(e) => {
                            if (e.target.value <= 31 && e.target.value >= 0) {
                                setDay(e.target.value)
                            }
                        }}
                        value={day}
                    />
                    <TextField
                        variant='outlined'
                        select
                        label='Mês *'
                        color='secondary'
                        fullWidth
                        style={{ marginTop: '5px', marginBottom: '5px' }}
                        onChange={(e) => setMonth(e.target.value)}
                        value={month}
                    >
                        <MenuItem value={0}>Janeiro</MenuItem>
                        <MenuItem value={1}>Fevereiro</MenuItem>
                        <MenuItem value={2}>Março</MenuItem>
                        <MenuItem value={3}>Abril</MenuItem>
                        <MenuItem value={4}>Maio</MenuItem>
                        <MenuItem value={5}>Junho</MenuItem>
                        <MenuItem value={6}>Julho</MenuItem>
                        <MenuItem value={7}>Agosto</MenuItem>
                        <MenuItem value={8}>Setembro</MenuItem>
                        <MenuItem value={9}>Outubro</MenuItem>
                        <MenuItem value={10}>Novembro</MenuItem>
                        <MenuItem value={11}>Dezembro</MenuItem>
                    </TextField>
                    <TextField
                        variant='outlined'
                        select
                        label='Ano *'
                        color='secondary'
                        fullWidth
                        style={{ marginTop: '5px', marginBottom: '5px' }}
                        onChange={(e) => setYear(e.target.value)}
                        value={year}
                    >
                        {
                            iterYears().map(year => {
                                return (
                                    <MenuItem value={year}>{year}</MenuItem>

                                )
                            })
                        }
                    </TextField>
                    <TextField
                        variant='outlined'
                        label='URL *'
                        color='secondary'
                        fullWidth
                        style={{ marginTop: '5px', marginBottom: '5px' }}
                        onChange={(e) => setUrl(e.target.value)}
                        value={url}
                    />

                    <TextField
                        variant='outlined'
                        select
                        label='Permissionamento *'
                        color='secondary'
                        fullWidth
                        style={{ marginTop: '5px', marginBottom: '5px' }}
                        onChange={(e) => setLeadershipOnly(e.target.value)}
                        value={leadershipOnly}
                    >
                        <MenuItem value={false}>Todos</MenuItem>
                        <MenuItem value={true}>Somente Liderança</MenuItem>
                    </TextField>

                </DialogContent>
                <DialogActions>
                    <button className='btn btnSecondary' onClick={close}>Cancelar</button>
                    <button className='btn btnPrimary' onClick={submit}>Cadastrar</button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
