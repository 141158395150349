const translateListCategory = (category) => {

    let categories = [
        'Other', //0
        'For Rent', //1
        'For Sale', //2
        'Sold', //3
        'Off Market'  //4
    ]
    
    return categories.indexOf(category)
}
const translateListType = (type) => {
    let translationType = [
        'Prospective', //1
        'MLS Listing', //2
        'Coming Soon', //3
        'Exclusive',  //4
        'Open Listing', //5
        'KW Reserve' //6
    ]
    return translationType.indexOf( type ) + 1
}

const translateListStatus = (status) => {
    let translateList = [
        'Active', //1
        'Pending', //2
        'Sold', //3
        'Active Under Contract', //4
        'Pending Contingent', //5
        'Cancelled', //6
        'Delete', //7
        'Expired', //8
        'Leased', //9
        'Closed', //10
        'Off Market'//11
    ]

    return translateList.indexOf( status ) + 1
}

const translatePropType = (propType) => {
    let typesList = [
        'Business Opportunity', //1
        'Commercial',
        'Common Interest',
        'Farm And Agriculture',
        'Lots And Land', //5
        'Manufactured In Park',
        'Vacation Ownership',
        'Residential',
        'Residential Income',
       'Other' //10
    ]

    return typesList.indexOf( propType ) + 1
}
const translatePropSubType = (propSubType) => {
    let typesList = [
        'Apartment', //1
        'Boat Slip', //2
        'Cabin', //3
        'Condominium', //4
        'Deeded Parking', //5
        'Duplex', //6
        'Farm', //7
        'Manufactured Home', //8
        'Manufactured On Land', //9
        'Mobile Home', //10
        'Own Your Own', //11
        'Quadruplex', //12
        'Ranch', //13
        'Single Family Attached', //14
        'Single Family Detached', //15
        'Stock Cooperative',//16
        'Timeshare',
        'Townhouse',
        'Triplex',
        'Agriculture', //20
        'Business',
        'Hotel-Motel',
        'Industrial',
        'Mixed Use',
        'Multi-Family', //25
        'Office',
        'Retail',
        'Unimproved Land',
        'Warehouse',
        'Adult Community', //30
        'Vacation Home',
        'Other',
    ]

    return typesList.indexOf( propSubType ) + 1;
}

module.exports = {
    translateListCategory,
    translateListType,
    translateListStatus,
    translatePropType,
    translatePropSubType
}