import { Divider, Grid } from '@mui/material'
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../contexts/AuthContext';
import NewTreinamentoGrowthCall from '../views/NewTreinamentoGrowthCall';
import bg from '../assets/imgs/video.jpeg'
import { Link, useNavigate } from 'react-router-dom';
import { BASE_URL, SOUKW_URL } from '../sharedComponents/Constants';

export default function TreinamentosGrowthCalls({ type = 0 }) {
    const { jwtUser, token, logout } = useContext(AuthContext);
    const [newTreinamentoModalOpen, setNewTreinamentoModalOpen] = useState(false);
    const [jsonArr, setJsonArr] = useState([]);
    const navigate = useNavigate()

    const openNewTreinamentoModal = () => {
        setNewTreinamentoModalOpen(true);
    }
    const closeNewTreinamentoModal = () => {
        setNewTreinamentoModalOpen(false);
    }

    useEffect(() => {
        async function getData() {
            try {
                const config = {
                    headers: { Authorization: `Bearer ${token}` }
                };
                let res = await axios.get(BASE_URL + '/treinamentos/growth-calls/home?type=' + type, config);
                setJsonArr(res.data)
            }
            catch (e) {
                console.error(e);
                if (e?.response?.data?.msg)
                    alert(e?.response?.data?.msg)
                else {
                    alert('Erro de Conexão.')
                    logout();
                }
            }
        }
        getData()
    }, [])

    const convertType = (type) => {
        let n = '';
        switch (type) {
            case 0: n = 'AGENTE';
                break;
            case 1: n = 'TEAM LEADER';
                break;
            case 2: n = 'MC OP';
                break;
            case 3: n = 'REGIONAL';
                break;
        }
        return n
    }
    const monthNames = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"];


    return (
        <div style={{ width: '100%' }}>
            <Grid container spacing={0} style={{ height: '100%' }} justifyItems='flex-start' alignItems={'flex-start'}>

                <button className='btn btnSecondary' onClick={() => navigate(-1)}>
                    Voltar
                </button>
                <Grid item xs={12} >
                    <br />
                </Grid>
                <Grid item xs={12} sm={9} style={{ textAlign: 'left', }}>
                    <h1>Growth Calls {convertType(type)}</h1>
                </Grid>
                {jwtUser?.permissionLevel.match(/ADMIN|SUPER/) &&
                    <Grid item xs={12} sm={1} style={{ textAlign: 'right', }}>
                        <button className='btn btnPrimary' onClick={openNewTreinamentoModal}>Novo Vídeo</button>
                    </Grid>
                }
                <Grid item xs={1} >
                    <br />
                </Grid>
                <Grid item xs={12} >
                    <br />
                </Grid>
                <Grid item xs={12} >
                    <Grid container spacing={0} style={{ height: '100%' }} justifyItems='center' alignItems={'center'}>

                        {
                            jsonArr?.map((json, i) => {
                                return (
                                    <Grid item key={i} xs={12}>
                                        <Grid container spacing={0} style={{ height: '100%' }} justifyItems='center' alignItems={'center'}>
                                            <Grid item xs={12}>
                                                <h1>{json.year}</h1>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <br />
                                            </Grid>
                                            {
                                                json.months?.map(month => {
                                                    return (
                                                        <Grid key={month} item xs={12} sm={6} md={3} lg={2.1}>
                                                            <Link to={'/soukw/training/growth-calls/' + json.year + '/' + (monthNames[month] ? monthNames[month].toLowerCase() : '') + '?type=' + type}>
                                                                <div style={{
                                                                    height: '200px',
                                                                    width: '200px',
                                                                    backgroundImage: `url(${bg})`,
                                                                    backgroundSize: 'cover',
                                                                    cursor: 'pointer',
                                                                    position: 'relative'
                                                                }}>
                                                                    <h1 style={{ fontSize: '1.1rem', position: 'absolute', left: '15%', top: '25%', color: '#ffffff', textTransform: 'capitalize', marginRight: '50px' }}>
                                                                        {/* {new Date(new Date().setMonth(month)).toLocaleString('pt-br', { month: 'long' })} */}
                                                                        {monthNames[month] ? monthNames[month] : ''}
                                                                    </h1>
                                                                </div>
                                                            </Link>
                                                            <Grid item xs={12} >
                                                                <br />
                                                            </Grid>
                                                        </Grid>
                                                    )
                                                })
                                            }
                                            <Grid item xs={12}>
                                                <br />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Divider />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                </Grid>
            </Grid>
            <NewTreinamentoGrowthCall open={newTreinamentoModalOpen} close={closeNewTreinamentoModal} />
        </div>
    )
}
