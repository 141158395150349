const translations ={
    noRowsLabel: 'Não há linhas.',
    noResultsOverlayLabel: 'Não há linhas',
  
    // Density selector toolbar button text
    toolbarDensity: 'Densidade',
    toolbarDensityLabel: 'Denso',
    toolbarDensityCompact: 'Compacto',
    toolbarDensityStandard: 'Padrão',
    toolbarDensityComfortable: 'Confortável',
  
    // Columns selector toolbar button text
    toolbarColumns: 'Colunas',
    toolbarColumnsLabel: 'Selecionar Colunas',
  
    // Filters toolbar button text
    toolbarFilters: 'Filtros',
    toolbarFiltersLabel: 'Exibir filtros',
    toolbarFiltersTooltipHide: 'Ocultar filtros',
    toolbarFiltersTooltipShow: 'Exibir filtros',
    toolbarFiltersTooltipActive: (count) =>
      count !== 1 ? `${count} filtros ativos` : `${count} filtros ativos`,
  
    // Quick filter toolbar field
    toolbarQuickFilterPlaceholder: 'Pesquisar...',
    toolbarQuickFilterLabel: 'Pesquisar',
    toolbarQuickFilterDeleteIconLabel: 'Limpar',
  
    // Export selector toolbar button text
    toolbarExport: 'Exportar',
    toolbarExportLabel: 'Exportar',
    toolbarExportCSV: 'Baixar CSV',
    toolbarExportPrint: 'Imprimir',
    toolbarExportExcel: 'Baixar como Excel',
  
    // Columns management text
    columnsManagementSearchTitle: 'Pesquisar',
    columnsManagementNoColumns: 'No columns',
    columnsManagementShowHideAllText: 'Show/Hide All',
  
    // Filter panel text
    filterPanelAddFilter: 'Adicionar Filtro',
    filterPanelRemoveAll: 'Remover Todos',
    filterPanelDeleteIconLabel: 'Delete',
    filterPanelLogicOperator: 'Operador Lógico',
    filterPanelOperator: 'Operador',
    filterPanelOperatorAnd: 'E/AND',
    filterPanelOperatorOr: 'OU/Or',
    filterPanelColumns: 'Colunas',
    filterPanelInputLabel: 'Valor',
    filterPanelInputPlaceholder: 'Valor do Filtro',
  
    // Filter operators text
    filterOperatorContains: 'contém',
    filterOperatorEquals: 'igual a',
    filterOperatorStartsWith: 'começa com',
    filterOperatorEndsWith: 'termina com',
    filterOperatorIs: 'é',
    filterOperatorNot: 'não é',
    filterOperatorAfter: 'posterior a',
    filterOperatorOnOrAfter: 'igual ou posterior a',
    filterOperatorBefore: 'anterior a',
    filterOperatorOnOrBefore: 'igual ou anterior a',
    filterOperatorIsEmpty: 'vazio',
    filterOperatorIsNotEmpty: 'não é vazio',
    filterOperatorIsAnyOf: 'algum destes',
    'filterOperator=': '=',
    'filterOperator!=': '!=',
    'filterOperator>': '>',
    'filterOperator>=': '>=',
    'filterOperator<': '<',
    'filterOperator<=': '<=',
  
    // Header filter operators text
    headerFilterOperatorContains: 'Contém',
    headerFilterOperatorEquals: 'Igual',
    headerFilterOperatorStartsWith: 'Inicia com',
    headerFilterOperatorEndsWith: 'Termina com',
    headerFilterOperatorIs: 'É',
    headerFilterOperatorNot: 'Não é',
    headerFilterOperatorAfter: 'Após a',
    headerFilterOperatorOnOrAfter: 'Is on or after',
    headerFilterOperatorBefore: 'Antes de',
    headerFilterOperatorOnOrBefore: 'Igual ou anterior a',
    headerFilterOperatorIsEmpty: 'Vazio',
    headerFilterOperatorIsNotEmpty: 'Não é vazio',
    headerFilterOperatorIsAnyOf: 'Algum destes',
    'headerFilterOperator=': 'É igual',
    'headerFilterOperator!=': 'Não é igual',
    'headerFilterOperator>': 'Maior que',
    'headerFilterOperator>=': 'Maior ou igual a',
    'headerFilterOperator<': 'Menor que',
    'headerFilterOperator<=': 'Menor que ou igual a',
  
    // Filter values text
    filterValueAny: 'todos',
    filterValueTrue: 'verdadeiro',
    filterValueFalse: 'Falso',
  
    // Column menu text
    columnMenuLabel: 'Menu',
    columnMenuShowColumns: 'Exibir colunas',
    columnMenuManageColumns: 'Gerenciar Colunas',
    columnMenuFilter: 'Filtro',
    columnMenuHideColumn: 'Ocultar Coluna',
    columnMenuUnsort: 'Remover Ordenação',
    columnMenuSortAsc: 'Ordenação ASC',
    columnMenuSortDesc: 'Ordenação DESC',
  
    // Column header text
    columnHeaderFiltersTooltipActive: (count) =>
      count !== 1 ? `${count} filtros ativos` : `${count} filtros ativos`,
    columnHeaderFiltersLabel: 'Exibir Filtros',
    columnHeaderSortIconLabel: 'Ordenar',
  
    // Rows selected footer text
    footerRowSelected: (count) =>
      count !== 1
        ? `${count.toLocaleString()} linhas selecionadas`
        : `${count.toLocaleString()} linhas selecionadas`,
  
    // Total row amount footer text
    footerTotalRows: 'Total de linhas:',
  
    // Total visible row amount footer text
    footerTotalVisibleRows: (visibleCount, totalCount) =>
      `${visibleCount.toLocaleString()} de ${totalCount.toLocaleString()}`,
  
    // Checkbox selection text
    checkboxSelectionHeaderName: 'Selecionar checkbox',
    checkboxSelectionSelectAllRows: 'Selecionar todas as linhas',
    checkboxSelectionUnselectAllRows: 'Remover seleção',
    checkboxSelectionSelectRow: 'Selecionar linha',
    checkboxSelectionUnselectRow: 'Remover seleção da linha',
  
    // Boolean cell text
    booleanCellTrueLabel: 'sim',
    booleanCellFalseLabel: 'não',
  
    // Actions cell more text
    actionsCellMore: 'mais',
  
    // Column pinning text
    pinToLeft: 'Fixar a esquerda',
    pinToRight: 'Fixar a direita',
    unpin: 'desfixar',
  
    // Tree Data
    treeDataGroupingHeaderName: 'Agrupar',
    treeDataExpand: 'ver filhos',
    treeDataCollapse: 'ocultar filhos',
  
    // Grouping columns
    groupingColumnHeaderName: 'Agrupar',
    groupColumn: (name) => `Agrupar por ${name}`,
    unGroupColumn: (name) => `Parar agrupamento por ${name}`,
  
    // Master/detail
    detailPanelToggle: 'Painel de detalhes',
    expandDetailPanel: 'Expandir',
    collapseDetailPanel: 'Recolher',
  
    // Used core components translation keys
    MuiTablePagination: {},
  
    // Row reordering text
    rowReorderingHeaderName: 'Salvar linha',
  
    // Aggregation
    aggregationMenuItemHeader: 'Agregar',
    aggregationFunctionLabelSum: 'soma',
    aggregationFunctionLabelAvg: 'média',
    aggregationFunctionLabelMin: 'min',
    aggregationFunctionLabelMax: 'max',
    aggregationFunctionLabelSize: 'tamanho',
}

export default translations;