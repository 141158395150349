/* eslint-disable no-unused-vars */
import { Autocomplete, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControlLabel, MenuItem, TextField, Zoom } from '@mui/material'
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import Geocode from 'react-geocode';
import { AuthContext } from '../contexts/AuthContext';
import { Link, useNavigate, useParams } from "react-router-dom";
import { mask, unmask } from 'remask';
import SingleFileUpload from '../sharedComponents/SingleFileUpload';
import { SOUKW_URL, BASE_URL } from '../sharedComponents/Constants';


export default function EditPreListing({ open, close }) {
    const { token, user, jwtUser, logout } = useContext(AuthContext);
    //const userCanNotEdit = (listingOwner?._id !== jwtUser._id && (jwtUser.permissionLevel === 'ADMIN' || jwtUser.permissionLevel === 'SUPER' ))
    const { id } = useParams();
    const navigate = useNavigate();

    const [users, setUsers] = useState([]);
    const [listingAgent, setListingAgent] = useState(null);


        //Proprietário:

        const [ownerToken, setOwnerToken] = useState( 'email' ); //Assinatura por e-mail ou whatsapp
        const [matriculaImovel, setMatriculaImovel] = useState( '' ); //Matricula do imovel
        const [numeroContribuinte, setNumeroContribuinte] = useState( '' );
        const [ contratoData, setContratoData] = useState( '' );
        const [termoDeVenda, setTermoDeVenda] = useState( false );
        const [fileTermoDeVenda, setFileTermoDeVenda] = useState( {} );
        const [fileTermoVendaURL, setFileTermoDeVendaURL] = useState('')
        const [cartorioRegistroImovel, setCartorioRegistroImovel] = useState( '' ); //Cartorio de Matricula do imovel
        const [fileIPTU, setFileIPTU] = useState( {} );
        const [fileIPTUURL, setFileIPTUURL] = useState('')
        const [fileMatricula, setFileMatricula] = useState( {} );
        const [fileMatriculaURL, setFileMatriculaURL] = useState( {} );

        const onTermoUpload = ( fileObject ) => {
            console.log('loading file Termo')
            console.log( fileObject.name )
            setFileTermoDeVenda( fileObject )
        }
        const onIPTUUpload = ( fileObject ) => {
            console.log('loading file IPTU')
            console.log( fileObject )
            console.log( fileObject.name )
            setFileIPTU( fileObject )
        }
        const onMatriculaUpload = ( fileObject ) => {
            console.log('loading file')
            console.log( fileObject )
            setFileMatricula( fileObject )
        }

        const fileUpload = async (data, path) => {
            console.log( "Will upload: " + path )
            console.log( data );
            const config = {
                headers: { Authorization: `Bearer ${token}`,
                    "Content-Type": "multipart/form-data",
                },
            };
            let mainURL = BASE_URL + "/preListings/"
            //let mainURL = "http://127.0.0.1:3001/preListings/"
            //let formData = new FormData();
            
            try{
                let res = await axios.patch(mainURL + path, data, config )
                return res.data;
            } catch( err ) {
                console.log( err );
                return `Não pode efetual o upload para ${mainURL}${path}`
            }
        }
    

    const [cep, setCep] = useState('');
    const [rua, setRua] = useState('');
    const [numero, setNumero] = useState('');
    const [compl, setCompl] = useState('');
    const [geoloc, setGeoloc] = useState('');
    const [bairro, setBairro] = useState('');
    const [cidade, setCidade] = useState('');
    const [estado, setEstado] = useState('');

    const [listCategory, setListCategory] = useState('');
    const [listType, setListType] = useState('');
    const [listStatus, setListStatus] = useState('');
    const [propType, setPropType] = useState('');
    const [propSubType, setPropSubType] = useState('');

    const [metragem, setMetragem] = useState('');
    const [nQuartos, setNQuartos] = useState('');
    const [nLavabos, setNLavabos] = useState(0);
    const [nBanheiros, setNBanheiros] = useState('');
    const [valor, setValor] = useState('');

    const [nomeCondominio, setNomeCondominio] = useState('');
    const [andarDoImovel, setAndarDoImovel] = useState('');
    const [novoImovel, setNovoImovel] = useState('');
    const [nVagas, setNVagas] = useState('');
    const [construtora, setConstrutora] = useState('');
    const [nUnidades, setNUnidades] = useState('');
    const [nAndares, setNAndares] = useState('');
    const [nTorres, setNTorres] = useState('');

    const [churrasqueira, setChurrasqueira] = useState(false);
    const [deck, setDeck] = useState(false);
    const [acessoDeficientes, setAcessoDeficientes] = useState(false);
    const [doca, setDoca] = useState(false);
    const [jardim, setJardim] = useState(false);
    const [entradaFechada, setEntradaFechada] = useState(false);
    const [estufa, setEstufa] = useState(false);
    const [hidro, setHidro] = useState(false);
    const [patio, setPatio] = useState(false);
    const [lago, setLago] = useState(false);
    const [piscina, setPiscina] = useState(false);
    const [varanda, setVaranda] = useState(false);
    const [vagaTrailer, setVagaTrailer] = useState(false);
    const [quadra, setQuadra] = useState(false);
    const [aspercao, setAspercao] = useState(false);
    const [beiraMar, setBeiraMar] = useState(false);

    const [anoConstrucao, setAnoConstrucao] = useState('');
    const [tamanhoTerreno, setTamanhoTerreno] = useState('');
    const [descricao, setDescricao] = useState('');

    const [status, setStatus] = useState('');

    const [mainPhoto, setMainPhoto] = useState(``);
    const [photos, setPhotos] = useState([]);
    const [photosObjArr, setPhotosObjArr] = useState([]);
    const [docs, setDocs] = useState([]);

    const [matricula, setMatricula] = useState('');
    const [iptuAnual, setIptuAnual] = useState('');
    const [condominioMensal, setCondominioMensal] = useState('');

    const [ownerName, setOwnerName] = useState('');
    const [ownerCPF, setOwnerCPF] = useState('');
    const [ownerRG, setOwnerRG] = useState('');
    const [ownerEmail, setOwnerEmail] = useState('');
    const [ownerPhone, setOwnerPhone] = useState('');
    const [ownerType, setOwnerType] = useState( 'Pessoa Física' ); //PJ ou PF


    const [expDate, setExpDate] = useState('');

    const [comissionSeller, setComissionSeller] = useState('')

    const [listing, setListing] = useState({});

    const [listingOwner, setListingOwner] = useState('');
    const [listingOwnerMC, setListingOwnerMC] = useState('');
    const [listingTeam, setListingTeam] = useState('');

    const [virtualTours, setVirtualTours] = useState([]);

    const [reprovingMode, setReprovingMode] = useState(false);
    const [motivo, setMotivo] = useState('')

    const [_id, setId] = useState('')


    const datePattern = '9999-99-99'
    const cpfPattern = ['999.999.999-99999', '99.999.999/9999-99']
    const rgPattern = ['99.999.999', '99.999.999-S']
    const cepPattern = ['99999-999']
    const maskCurrency = (num = '.') => {
        let initial = String(num).split('.').join('').replace(/[^0-9.]/g, '');
        let formattedNum = initial;
        switch (initial.length) {
            case 1: formattedNum = num;
                break;
            case 2: formattedNum = num;
                break;
            case 3: formattedNum = num;
                break;
            case 4: formattedNum = [formattedNum.slice(0, 1), '.', formattedNum.slice(1)].join('');
                break;
            case 5: formattedNum = [formattedNum.slice(0, 2), '.', formattedNum.slice(2)].join('');
                break;
            case 6: formattedNum = [formattedNum.slice(0, 3), '.', formattedNum.slice(3)].join('');
                break;
            case 7: formattedNum = [formattedNum.slice(0, 1), '.', formattedNum.slice(1, 4), '.', formattedNum.slice(4)].join('');
                break;
            case 8: formattedNum = [formattedNum.slice(0, 2), '.', formattedNum.slice(2, 5), '.', formattedNum.slice(5)].join('');
                break;
            case 9: formattedNum = [formattedNum.slice(0, 3), '.', formattedNum.slice(3, 6), '.', formattedNum.slice(6)].join('');
                break;

        }
        return 'R$ ' + String(formattedNum).replace(/[^0-9.]/g, '').slice(0, 10)
    }
    const unmaskCurrency = (str) => {
        return String(str).replace(/[^0-9]/g, '');
    }

    useEffect(() => {
        async function getData() {
            try {
                const config = {
                    headers: { Authorization: `Bearer ${token}` }
                };
                let results = await axios.get(BASE_URL + '/preListings/' + id, config);
                let lst = results.data;
                setListing(lst)
                if (jwtUser?.permissionLevel === 'ADMIN') {
                    let users = await axios.get(BASE_URL + '/users/', config);
                    setUsers(users.data)
                } else if (jwtUser.isMcAdmin) {
                    let users = await axios.get(BASE_URL + '/users/by_mc/' + jwtUser?.mcId, config);
                    console.log(jwtUser)
                    setUsers(users.data)
                }

                let results2 = await axios.get(lst?.newListingAgentId !== -1 ? BASE_URL + '/users/by_kwuid/' + lst?.newListingAgentId : BASE_URL + '/users/by_kwuid/' + lst?.list_kw_uid, config);
                let lstOwner = results2.data;
                if (lstOwner) {
                    setListingOwner(lstOwner)
                    let results3 = await axios.get(BASE_URL + '/mcs/' + lstOwner?.marketCenterId, config);
                    let lstOwnerMC = results3.data;
                    console.log( lstOwnerMC )
                    setListingOwnerMC(lstOwnerMC)
                    if (lst?.teamId) {
                        let results4 = await axios.get(BASE_URL + '/teams/' + lst?.teamId, config);
                        let lstTeam = results4.data;
                        setListingTeam(lstTeam)
                    }
                }
                //await getLoc(lst.list_address.postal_code);
                setCep(mask(lst.list_address.postal_code, cepPattern));
                setRua(lst.list_address.street_name);
                setNumero(lst.list_address.street_number);
                setCompl(lst.list_address.unit_number);
                setBairro(lst.bairro);
                setEstado(lst.list_address.state_prov);
                setCidade(lst.list_address.city);

                setListCategory(lst.list_category_id);
                setListType(lst.list_type_id);
                setListStatus(lst.list_status_id);
                setPropType(lst.prop_type_id);
                setPropSubType(lst.prop_subtype_id);

                setMetragem(lst.living_area);
                setNQuartos(lst.total_bed);
                setNLavabos(lst.half_bath ?? 0);
                setNBanheiros(lst.full_bath);
                setValor(maskCurrency(lst.current_list_price));

                setNomeCondominio(lst.structure?.building_name);
                setAndarDoImovel(lst.structure?.unit_level);
                setNovoImovel(lst.structure?.is_new_construction)
                setNVagas(lst.structure?.parking_total)
                setNAndares(lst.structure?.stories);
                setNUnidades(lst.structure?.total_units);
                setNTorres(lst.structure?.total_buildings);
                setConstrutora(lst.structure?.builder_name);

                setChurrasqueira(lst.structure?.exterior_features?.has_barbecue_area)
                setDeck(lst.structure?.exterior_features?.has_deck);
                setAcessoDeficientes(lst.structure?.exterior_features?.has_disabled_access);
                setDoca(lst.structure?.exterior_features?.has_dock);
                setJardim(lst.structure?.exterior_features?.has_garden);
                setEntradaFechada(lst.structure?.exterior_features?.has_gated_entry);
                setEstufa(lst.structure?.exterior_features?.has_green_house);
                setHidro(lst.structure?.exterior_features?.has_hot_tub_spa);
                setPatio(lst.structure?.exterior_features?.has_patio);
                setLago(lst.structure?.exterior_features?.has_pond);
                setPiscina(lst.structure?.exterior_features?.has_pool);
                setVaranda(lst.structure?.exterior_features?.has_porch);
                setVagaTrailer(lst.structure?.exterior_features?.has_rv_parking);
                setQuadra(lst.structure?.exterior_features?.has_sports_court);
                setAspercao(lst.structure?.exterior_features?.has_sprinkler_system);
                setBeiraMar(lst.structure?.exterior_features?.is_water_front);

                setAnoConstrucao(lst.year_built);
                setTamanhoTerreno(lst.lot_size_area);
                setDescricao(lst.list_desc?.replace('\n', ''));
                setStatus(lst.status);

                setMatricula(lst.contrato.matricula);
                setMatriculaImovel(lst.contrato.matricula)
                setNumeroContribuinte(lst.contrato.numeroContribuinte)
                setCartorioRegistroImovel(lst.contrato.cartorio )
                setIptuAnual(maskCurrency(lst.iptuAnual));
                setCondominioMensal(maskCurrency(lst.condominioMensal));

                console.log( lst );

                setOwnerName(lst.proprietario.fullName);
                setOwnerCPF(lst.proprietario.cpf);
                setOwnerRG(lst.proprietario.rg);
                setOwnerEmail(lst.proprietario.email);
                setOwnerPhone(lst.proprietario.phone);
                setOwnerType(lst.proprietario.tipo)
                setOwnerToken( lst.proprietario.token )
                setExpDate(lst.contract_expiry_dt);

                setComissionSeller(lst.comissionSeller);
                setContratoData( lst.contrato.data )
                setVirtualTours(lst.virtual_tours)

                setMainPhoto(lst.photos[0])
                setPhotos(lst.photos)
                setDocs(lst.docs)
                setFileTermoDeVendaURL( lst.contrato.arquivoUrl )
                setFileIPTUURL( lst.contrato.espelhoIPTUURL )
                setFileMatriculaURL( lst.contrato.matriculaURL)
                setMotivo(lst.motive)

                if (lst.motive)
                    setReprovingMode(true);

            } catch (e) {
                console.error(e);
                if (e?.response?.data)
                    alert(e?.response?.data)
                else {
                    alert('Erro de Conexão.')
                    // logout();
                }
            }
        };
        getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const getLoc = async (defaultCep = null) => {
        Geocode.setApiKey('AIzaSyBG0z5RFyaogJihcAmL8A2GmazXLK7lCXc');
        Geocode.setLanguage("pt-br");
        Geocode.setRegion("br");
        Geocode.setLocationType('ROOFTOP');
        if (!defaultCep) {
            if (cep == '')
                return
            try {
                let geo = await Geocode.fromAddress(cep);
                console.log(geo.results[0].address_components)
                setRua(geo.results[0].address_components[1].long_name);
                setBairro(geo.results[0].address_components[2].long_name);
                setCidade(geo.results[0].address_components[3].long_name);
                setEstado(geo.results[0].address_components[4].long_name);
                setGeoloc(geo);
            } catch (e) {
                alert('CEP não encontrado.');
            }
        } else {
            try {
                let geo = await Geocode.fromAddress(defaultCep);
                setRua(geo.results[0].address_components[1].long_name);
                setBairro(geo.results[0].address_components[2].long_name);
                setCidade(geo.results[0].address_components[3].long_name);
                setEstado(geo.results[0].address_components[4].long_name);
                setGeoloc(geo)
            } catch (e) {
                alert('CEP não encontrado.');
            }
        }
    }

    const translateListCategory = (category) => {
        switch (category) {
            case 0:
                return 'Other';
            case 1:
                return 'For Rent';
            case 2:
                return 'For Sale';
            case 3:
                return 'Sold';
            case 4:
                return 'Off Market';
        }
    }
    const translateListType = (type) => {
        switch (type) {
            case 1:
                return 'Prospective';
            case 2:
                return 'MLS Listing';
            case 3:
                return 'Coming Soon';
            case 4:
                return 'Exclusive';
            case 5:
                return 'Open Listing';
            case 6:
                return 'KW Reserve';
        }
    }
    const translateListStatus = (status) => {
        switch (status) {
            case 1:
                return 'Active';
            case 2:
                return 'Pending';
            case 3:
                return 'Sold';
            case 4:
                return 'Active Under Contract';
            case 6:
                return 'Pending Contingent';
            case 12:
                return 'Cancelled';
            case 13:
                return 'Delete';
            case 14:
                return 'Expired';
            case 15:
                return 'Leased';
            case 17:
                return 'Closed';
            case 18:
                return 'Off Market';
        }
    }
    const translatePropType = (propType) => {
        switch (propType) {
            case 1:
                return 'Business Opportunity';
            case 2:
                return 'Commercial';
            case 3:
                return 'Common Interest';
            case 4:
                return 'Farm And Agriculture';
            case 5:
                return 'Lots And Land';
            case 6:
                return 'Manufactured In Park';
            case 7:
                return 'Vacation Ownership';
            case 8:
                return 'Residential';
            case 9:
                return 'Residential Income';
            case 10:
                return 'Other';
        }
    }
    const translatePropSubType = (propSubType) => {
        switch (propSubType) {
            case 1:
                return 'Apartment';
            case 2:
                return 'Boat Slip';
            case 3:
                return 'Cabin';
            case 4:
                return 'Condominium';
            case 5:
                return 'Deeded Parking';
            case 6:
                return 'Duplex';
            case 7:
                return 'Farm';
            case 8:
                return 'Manufactured Home';
            case 9:
                return 'Manufactured On Land';
            case 10:
                return 'Mobile Home';
            case 11:
                return 'Own Your Own';
            case 12:
                return 'Quadruplex';
            case 13:
                return 'Ranch';
            case 14:
                return 'Single Family Attached';
            case 15:
                return 'Single Family Detached';
            case 16:
                return 'Stock Cooperative';
            case 17:
                return 'Timeshare';
            case 18:
                return 'Townhouse';
            case 19:
                return 'Triplex';
            case 20:
                return 'Agriculture';
            case 21:
                return 'Business';
            case 22:
                return 'Hotel-Motel';
            case 23:
                return 'Industrial';
            case 24:
                return 'Mixed Use';
            case 25:
                return 'Multi-Family';
            case 26:
                return 'Office';
            case 27:
                return 'Retail';
            case 28:
                return 'Unimproved Land';
            case 29:
                return 'Warehouse';
            case 30:
                return 'Adult Community';
            case 31:
                return 'Vacation Home';
            case 32:
                return 'Other';
        }
    }

    const enableSend = ( ) => {
        //esta função bloqueia o envio se algum campo não estiver preenchido
        let states = [
            [ownerName, 'Preencher Nome'], 
            [ownerCPF, 'Preencher CPF'],
            [ownerEmail, 'Preencher email'],
            [ownerType, 'Preencher tipo'],
            [ownerPhone, 'Preencher Telefone do Proprietário'],
            [ownerToken, 'Preencher Token'],
            [matriculaImovel, 'Preencher Matrícula'],
            //[numeroContribuinte, 'Preencher Número do contribuinte( IPTU )'],
            [cartorioRegistroImovel, 'Informar Cartório onde imóvel foi registrado'],
            [cep, 'Preencher CEP'],
            [rua, 'Preencher Rua' ],
            [numero, 'Preencher Número do Imóvel'],
            [bairro, 'Preencher Bairro'],
            [cidade, 'Preencher Cidade'],
            [estado, 'Preencher Estado'],
            [comissionSeller, 'Informar Comissao'],
            [metragem, "Informar metragem"],
            [nQuartos, "informar número de quartos"],
            [nLavabos, "informar número de lavabos"],
            [nBanheiros, "informar número de banheiros"],

            [unmaskCurrency(valor), 'Preencher valor de venda do imóvel']
        ]

        console.log( 'State test' );
        for( let i = 0; i < states.length; i++ ) {
            if( states[i][1].match(/lavabos/i) ) {  //se for lavabos, não precisa preencher
                if(states[i][0] === undefined || states[i][0] === null || states[i][0] === '') {
                    console.log( `${states[i][0]} ${states[i][1]}` )
                    return [false, states[i][1]]
                }
                continue;
            }
            if( !states[i][0] ) {
                
                console.log( `${states[i][0]} ${states[i][1]}` )
                return [false, states[i][1]]
            }
        }
        return [true]
    }
    const submit = async () => {
        try {
            Geocode.setApiKey('AIzaSyBG0z5RFyaogJihcAmL8A2GmazXLK7lCXc');
            Geocode.setLanguage("pt-br");
            Geocode.setRegion("br");
            Geocode.setLocationType('ROOFTOP');
            let preciseGeo = await Geocode.fromAddress(rua + ', ' + numero + ' - ' + bairro + ', ' + cidade + ', ' + estado + ' - ' + cep);
            try {
                const config = {
                    headers: { Authorization: `Bearer ${token}` }
                };
                let list_address = {
                    street_number: numero,
                    street_name: rua,
                    unit_number: compl,
                    city: cidade,
                    state_prov: estado,
                    postal_code: cep,
                    country: 'BR',
                    coordinates_gp: {
                        lat: preciseGeo?.results[0]?.geometry?.location?.lat,
                        lon: preciseGeo?.results[0]?.geometry?.location?.lng,
                    },
                    coordinates_gs: {
                        coordinates: [
                            preciseGeo?.results[0]?.geometry?.location?.lng,
                            preciseGeo?.results[0]?.geometry?.location?.lat,
                        ],
                        type: "Point"
                    },

                }
                let exterior_features = {
                    has_barbecue_area: churrasqueira,
                    has_deck: deck,
                    has_disabled_access: acessoDeficientes,
                    has_dock: doca,
                    has_garden: jardim,
                    has_gated_entry: entradaFechada,
                    has_green_house: estufa,
                    has_hot_tub_spa: hidro,
                    has_patio: patio,
                    has_pond: lago,
                    has_pool: piscina,
                    has_porch: varanda,
                    has_rv_parking: vagaTrailer,
                    has_sports_court: quadra,
                    has_sprinkler_system: aspercao,
                    is_water_front: beiraMar
                }
                let structure = {
                    building_name: nomeCondominio,
                    unit_level: andarDoImovel,
                    is_new_construction: novoImovel,
                    parking_total: nVagas,
                    has_parking: parseInt(nVagas) > 0,
                    stories: nAndares,
                    total_units: nUnidades,
                    total_buildings: nTorres,
                    exterior_features,
                    builder_name: construtora,
                }
                let contrato = {
                    data: contratoData,
                    matricula: matriculaImovel,
                    numeroContribuinte: numeroContribuinte ?? "",
                    cartorio: cartorioRegistroImovel,
                    existeTermo: termoDeVenda ? 'SIM' : 'NÃO',
                    arquivo: fileTermoDeVenda,
                    arquivoUrl: fileTermoVendaURL ?? "",
                    espelhoIPTUURL: fileIPTUURL ?? "",
                    matriculaURL: fileMatriculaURL ?? "",
                 };
 
                 let proprietario = {
                     fullName: ownerName,
                     cpf: ownerCPF,
                     email: ownerEmail,
                     tipo: ownerType,//PJ ou PF
                     phone: ownerPhone,
                     token: ownerToken,
                 };
                let json = {
                    proprietario,
                    contrato,
                    matricula: contrato.matricula,
                    current_list_price: unmaskCurrency(valor),
                    list_category_id: listCategory,
                    list_category: translateListCategory(listCategory),
                    list_type_id: listType,
                    list_type: translateListType(listType),
                    list_status_id: listStatus,
                    list_status: translateListStatus(listStatus),
                    prop_type_id: propType,
                    prop_type: translatePropType(propType),
                    prop_subtype_id: propSubType,
                    prop_subtype: translatePropSubType(propSubType),
                    list_address,
                    structure,
                    year_built: anoConstrucao,
                    total_bed: nQuartos,
                    half_bath: parseInt(nLavabos ?? 0),
                    full_bath: nBanheiros,
                    total_bath: String((parseInt(nBanheiros) + parseInt(nLavabos ?? 0))),
                    living_area: metragem,
                    lot_size_area: tamanhoTerreno,
                    list_desc: descricao,
                    contract_expiry_dt: expDate,
                    teamId: listingOwner?.teamId,
                    bairro,
                    iptuAnual: unmaskCurrency(iptuAnual),
                    condominioMensal: unmaskCurrency(condominioMensal),
                    ownerName,
                    ownerCPF,
                    ownerRG,
                    ownerEmail,
                    ownerPhone,
                    comissionSeller,
                    virtual_tours: virtualTours,
                    newListingAgentId: listingOwner?.kwuid,
                    market_center: listingOwnerMC?.kwId,
                }
                let res = await axios.patch(BASE_URL + '/preListings/' + id, json, config);
                if ( !fileTermoVendaURL && (typeof fileTermoDeVenda.get === 'function' && fileTermoDeVenda.get('doc') !== undefined) ) {
                    let resTermo = await fileUpload( fileTermoDeVenda, 'docTermo/' + res.data._id)
                    console.log( resTermo )
                }
                if ( !fileMatriculaURL && (typeof fileMatricula.get === 'function' && fileMatricula.get('doc') !== undefined) ){
                    let resMatricula = await fileUpload( fileMatricula, 'docMatricula/' + res.data._id)
                    console.log( resMatricula )
                }
                if ( !fileIPTUURL && (typeof fileIPTU.get === 'function' && fileIPTU.get('doc') !== undefined) ){
                    let resIPTU = await fileUpload( fileIPTU, 'docIPTU/' + res.data._id)
                    console.log( resIPTU )
                }
                alert('Editado com sucesso!');
                console.log( res );
            } catch (e) {
                console.error(e);
                if (e?.response?.data?.msg)
                    alert(e?.response?.data?.msg)
                else {
                    alert('Erro de Conexão.')
                    logout();
                }
            }
        } catch (e) {
            console.error(e)
            alert('O endereço do imóvel não foi encontrado.');
            return;
        }

    }

    const submit2 = async () => {
        let canSend = enableSend( );
        if( !canSend[0] ) {
            alert(`Erro no formulário: ${canSend[1]}`)
            return;
        }
        try {
            Geocode.setApiKey('AIzaSyBG0z5RFyaogJihcAmL8A2GmazXLK7lCXc');
            Geocode.setLanguage("pt-br");
            Geocode.setRegion("br");
            Geocode.setLocationType('ROOFTOP');
            let preciseGeo = await Geocode.fromAddress(rua + ', ' + numero + ' - ' + bairro + ', ' + cidade + ', ' + estado + ' - ' + cep);
            try {
                const config = {
                    headers: { Authorization: `Bearer ${token}` }
                };
                let list_address = {
                    street_number: numero,
                    street_name: rua,
                    unit_number: compl,
                    city: cidade,
                    state_prov: estado,
                    postal_code: cep,
                    country: 'BR',
                    coordinates_gp: {
                        lat: preciseGeo?.results[0]?.geometry?.location?.lat,
                        lon: preciseGeo?.results[0]?.geometry?.location?.lng,
                    },
                    coordinates_gs: {
                        coordinates: [
                            preciseGeo?.results[0]?.geometry?.location?.lng,
                            preciseGeo?.results[0]?.geometry?.location?.lat,
                        ],
                        type: "Point"
                    },

                }
                let exterior_features = {
                    has_barbecue_area: churrasqueira,
                    has_deck: deck,
                    has_disabled_access: acessoDeficientes,
                    has_dock: doca,
                    has_garden: jardim,
                    has_gated_entry: entradaFechada,
                    has_green_house: estufa,
                    has_hot_tub_spa: hidro,
                    has_patio: patio,
                    has_pond: lago,
                    has_pool: piscina,
                    has_porch: varanda,
                    has_rv_parking: vagaTrailer,
                    has_sports_court: quadra,
                    has_sprinkler_system: aspercao,
                    is_water_front: beiraMar
                }
                let structure = {
                    building_name: nomeCondominio,
                    unit_level: andarDoImovel,
                    is_new_construction: novoImovel,
                    parking_total: nVagas,
                    has_parking: parseInt(nVagas) > 0,
                    stories: nAndares,
                    total_units: nUnidades,
                    total_buildings: nTorres,
                    exterior_features,
                    builder_name: construtora,
                }
                let contrato = {
                    data: contratoData,
                    matricula: matriculaImovel,
                    numeroContribuinte: numeroContribuinte ?? "",
                    cartorio: cartorioRegistroImovel,
                    existeTermo: termoDeVenda ? 'SIM' : 'NÃO',
                    arquivo: fileTermoDeVenda,
                    arquivoUrl: fileTermoVendaURL ?? "",
                    espelhoIPTUURL: fileIPTUURL ?? "",
                    matriculaURL: fileMatriculaURL ?? "",
                 };
 
                 let proprietario = {
                     fullName: ownerName,
                     cpf: ownerCPF,
                     email: ownerEmail,
                     tipo: ownerType,//PJ ou PF
                     phone: ownerPhone,
                     token: ownerToken,
                 };

                let json = {
                    _id: listing._id,
                    proprietario,
                    contrato,
                    status: 'PENDENTE',
                    matricula: contrato.matricula,
                    current_list_price: unmaskCurrency(valor),
                    list_category_id: listCategory,
                    list_category: translateListCategory(listCategory),
                    list_type_id: listType,
                    list_type: translateListType(listType),
                    list_status_id: listStatus,
                    list_status: translateListStatus(listStatus),
                    list_kw_uid: listingOwner ? parseInt(listingOwner.kwuid) : parseInt(user.kwuid),
                    prop_type_id: propType,
                    prop_type: translatePropType(propType),
                    prop_subtype_id: propSubType,
                    prop_subtype: translatePropSubType(propSubType),
                    list_address,
                    structure,
                    year_built: anoConstrucao,
                    total_bed: nQuartos,
                    half_bath: parseInt(nLavabos ?? 0),
                    full_bath: nBanheiros,
                    total_bath: String((parseInt(nBanheiros) + parseInt(nLavabos ?? 0))),
                    living_area: metragem,
                    lot_size_area: tamanhoTerreno,
                    list_desc: descricao,
                    contract_expiry_dt: expDate,
                    teamId: listingOwner ? listingOwner.teamId : user.teamId,
                    bairro,
                    iptuAnual: unmaskCurrency(iptuAnual),
                    condominioMensal: unmaskCurrency(condominioMensal),
                    ownerName,
                    ownerCPF,
                    ownerRG,
                    ownerEmail,
                    ownerPhone,
                    comissionSeller,
                    virtual_tours: virtualTours,
                    newListingAgentId: listingOwner?.kwuid,
                    market_center: listingOwnerMC?.kwId,
                    photos,
                    docs
                }
                console.log( json );
                let res4 = await axios.patch(BASE_URL + '/preListings/' + id, json, config);
                if ( !fileTermoVendaURL && (typeof fileTermoDeVenda.get === 'function' && fileTermoDeVenda.get('doc') !== undefined) )
                    await fileUpload( fileTermoDeVenda, 'docTermo/' + id)
                    //console.log( resTermo )
                if ( !fileMatriculaURL && (typeof fileMatricula.get === 'function' && fileMatricula.get('doc') !== undefined) )
                    await fileUpload( fileMatricula, 'docMatricula/' + id)
                    //console.log( resMatricula )
                if ( !fileIPTUURL && (typeof fileIPTU.get === 'function' && fileIPTU.get('doc') !== undefined) )
                    await fileUpload( fileIPTU, 'docIPTU/' + id)
                let res = await axios.post(BASE_URL + '/listings/', json, config);
                console.log( res.data );
                delete json._id;
                let res2 = await axios.patch(BASE_URL + '/listings/' + id, json, config);
                let res3 = await axios.delete(BASE_URL + '/preListings/' + id, config);
                console.log( res2 )
                alert("Listing enviado para aprovação do Market Center");
                //navigate(-1)
            } catch (e) {
                console.error(e);
                if (e?.response?.data?.msg)
                    alert(e?.response?.data?.msg)
                else {
                    alert('Erro de Conexão.')
                    logout();
                }
            }
        } catch (e) {
            console.error(e)
            alert('O endereço do imóvel não foi encontrado.');
            return;
        }

    }

    const deletePreListing = async ( ) => {
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        try {
            let results = await axios.delete(BASE_URL + '/preListings/' + id, config);
            let lst = results.data;
            console.log( lst )
            alert("PréListing removido com sucesso!")
            navigate(-1)
        } catch( e ) {
            alert( "Não foi possível remover o listing!" );
        }
    }


    const changeStatus = async (status) => {
        try {
            const config = {
                headers: { Authorization: `Bearer ${token}` }
            };
            let res = await axios.patch(BASE_URL + '/preListings/change_status/' + id, { status, motive: motivo }, config);
            switch (status) {
                case 'ATIVO': alert('Aprovado com sucesso!');
                    break;
                case 'REPROVADO': alert('Reprovado com sucesso!');
                    break;
                case 'PENDENTE': alert('Solicitação reenviada com sucesso!');
                    break;
            }
            navigate(-1)
        } catch (e) {
            console.error(e);
            if (e?.response?.data?.msg)
                alert(e?.response?.data?.msg)
            else {
                alert('Erro de Conexão.')
                logout();
            }
        }
    }

    const getCEP = async () => {
        setRua('')
        setBairro('')
        setEstado('')
        setCidade('')
        try {
            let res = await axios.get('https://viacep.com.br/ws/' + String(cep).split('-').join('') + '/json/')
            let data = res.data
            if (data.erro)
                alert('CEP não encontrado.')

            setRua(data.logradouro)
            setBairro(data.bairro)
            setEstado(data.uf)
            setCidade(data.localidade)
        } catch (e) {
            alert('CEP não encontrado.')
        }

    }

    const submitDuplicate = async (listing) => {
        try {
            const config = {
                headers: { Authorization: `Bearer ${token}` }
            };
            let res = await axios.post(BASE_URL + '/preListings/clone/' + id, { status, motive: motivo }, config);
            window.open(SOUKW_URL + '/listings/edit-pre-cadastro/' + res?.data?._id, '_blank')
        } catch (e) {
            console.error(e);
            if (e?.response?.data?.msg)
                alert(e?.response?.data?.msg)
            else {
                alert('Erro de Conexão.')
                logout();
            }
        }

    }
    return (
        <div style={{ marginRight: '80px', }}>
            <div style={{ position: 'relative', marginBottom: '50px' }}>
                <div style={{ position: 'absolute', left: '0' }}>
                    <button className='btn btnSecondary' onClick={() => navigate(-1)}>
                        Voltar
                    </button>
                </div>
                <div style={{ position: 'absolute', right: '300px' }}>
                    <Link to={'/listings/edit-docs-pre-cadastro/' + listing?._id} style={{ textDecoration: 'none' }}>
                        <button className='btn btnSecondary'>
                            {listingOwner?._id !== jwtUser._id && jwtUser.permissionLevel !== 'ADMIN' ? 'Ver Documentos' : 'Adicionar / Remover Documentos'}
                            <input type="file" style={{ display: 'none' }} accept="image/*"
                                onChange={(e) => setMainPhoto(e.target.files[0])}
                            />
                        </button>
                    </Link>
                </div>
                <div style={{ position: 'absolute', right: '0' }}>
                    <Link to={'/listings/edit-photos-pre-cadastro/' + listing?._id} style={{ textDecoration: 'none' }}>
                        <button className='btn btnPrimary'>
                            {listingOwner?._id !== jwtUser._id && jwtUser.permissionLevel !== 'ADMIN' ? 'Ver Fotos' : 'Adicionar / Remover Fotos'}
                            <input type="file" style={{ display: 'none' }} accept="image/*"
                                onChange={(e) => setMainPhoto(e.target.files[0])}
                            />
                        </button>
                    </Link>
                </div>
            </div>
            <br />
            
            <div>
                <button hidden  className='btn btnSecondary' onClick={() => navigate('/soukw/history/' + listing?._id)} style={{ marginRight: '5px' }}>
                    Ver Histórico
                </button>
                {(listingOwner?._id === jwtUser._id || (jwtUser.permissionLevel === 'ADMIN' || jwtUser.permissionLevel === 'SUPER' )) &&
                    <button className='btn btnPrimary' onClick={() => submitDuplicate(listing)}>
                        Duplicar Listing
                    </button>
                }
            </div>
            
            <br />
            <div className='card' style={{ padding: '20px', overflowY: 'scroll', height: '84vh' }}>
                <h6>ID</h6>
                <br />
                <TextField
                    variant='outlined'
                    color='secondary'
                    fullWidth
                    disabled
                    style={{ marginTop: '5px', marginBottom: '5px' }}
                    value={listing?._id}
                />
                <br />
                <br />
                {(!jwtUser.isMcAdmin && jwtUser.permissionLevel !== 'ADMIN') &&
                    <h6>Agente Vendedor</h6>
                }
                {(jwtUser.isMcAdmin || (jwtUser.permissionLevel === 'ADMIN' || jwtUser.permissionLevel === 'SUPER' )) &&
                     <Autocomplete
                         options={users}
                         getOptionLabel={(option) => option.fullName}
                         filterSelectedOptions
                         noOptionsText='Nenhum Usuário'
                         value={listingOwner}
                         onChange={(e, newValue) => {
                             setListingOwner(newValue)
                         }}
                         renderInput={(params) => (
                             <TextField
                                 {...params}
                                 label="Agente Vendedor"
                                 placeholder="Agente Vendedor"
                             />
                         )}
                     />
                }
                {(!jwtUser.isMcAdmin && jwtUser.permissionLevel !== 'ADMIN') &&
                    <TextField
                        variant='outlined'
                        color='secondary'
                        fullWidth
                        disabled
                        style={{ marginTop: '5px', marginBottom: '5px' }}
                        value={listingOwner?.fullName}
                    />
                }
                <TextField
                    variant='outlined'
                    color='secondary'
                    fullWidth
                    disabled
                    style={{ marginTop: '5px', marginBottom: '5px' }}
                    value={listingOwnerMC?.name}
                />
                <TextField
                    variant='outlined'
                    color='secondary'
                    fullWidth
                    disabled
                    style={{ marginTop: '5px', marginBottom: '5px' }}
                    value={listingTeam?.name}
                />
                <br />
                <br />
                <Divider />
                <br />
                <h6>Dados do Proprietário</h6>
                <br />
                <TextField
                    variant='outlined'
                    label='Nome Completo do Proprietário'
                    color='secondary'
                    fullWidth
                    style={{ marginTop: '5px', marginBottom: '5px' }}
                    onChange={(e) => setOwnerName(e.target.value)}
                    disabled
                    value={ownerName}
                />
                <TextField
                    variant='outlined'
                    label='CPF do Proprietário'
                    color='secondary'
                    fullWidth
                    style={{ marginTop: '5px', marginBottom: '5px' }}
                    onChange={(e) => setOwnerCPF(mask(e.target.value, cpfPattern))}
                    disabled
                    value={ownerCPF}
                />
                <TextField
                    variant='outlined'
                    label='RG do Proprietário'
                    color='secondary'
                    fullWidth
                    style={{ marginTop: '5px', marginBottom: '5px' }}
                    onChange={(e) => setOwnerRG(mask(e.target.value, rgPattern))}
                    disabled
                    value={ownerRG}
                />
                <TextField
                    variant='outlined'
                    label='Email do Proprietário'
                    color='secondary'
                    fullWidth
                    style={{ marginTop: '5px', marginBottom: '5px' }}
                    onChange={(e) => setOwnerEmail(e.target.value)}
                    disabled
                    value={ownerEmail}
                />
                <TextField
                    variant='outlined'
                    label='Telefone do Proprietário'
                    color='secondary'
                    type={'email'}
                    fullWidth
                    style={{ marginTop: '5px', marginBottom: '5px' }}
                    onChange={(e) => setOwnerPhone(e.target.value)}
                    disabled
                    value={ownerPhone}
                />
                <Autocomplete
                    options={['Pessoa Física', 'Pessoa Jurídica']}
                    getOptionLabel={(option) => option}
                    filterSelectedOptions
                    //noOptionsText='Nenhum Usuário'
                    disabled
                    value={ownerType}
                    onChange={(e, newValue) => {
                        setOwnerType(newValue)
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Tipo de Proprietário"
                            placeholder="Selecionar Pessoa Física ou Jurídica"
                        />
                    )}
                />
                <br />
                <br />
                <Divider />
                <br />
                <h6>Contrato</h6>
                <br />
                <br />
                <br />
                <Divider />
                    <h3>Contrato</h3>
                    <br/>
                    <TextField
                    variant='outlined'
                    label='Matrícula *'
                    color='secondary'
                    fullWidth
                    style={{ marginTop: '5px', marginBottom: '5px' }}
                    onChange={(e) => setMatriculaImovel(e.target.value)}
                    value={matriculaImovel}
                    disabled
                />
                <TextField
                    variant='outlined'
                    label='Número do Contribuinte *'
                    color='secondary'
                    fullWidth
                    style={{ marginTop: '5px', marginBottom: '5px' }}
                    onChange={(e) => setNumeroContribuinte(e.target.value)}
                    value={numeroContribuinte}
                    disabled
                />
                    <br/>
                    <TextField
                    variant='outlined'
                    label='Cartório de Registro *'
                    color='secondary'
                    fullWidth
                    style={{ marginTop: '5px', marginBottom: '5px' }}
                    onChange={(e) => setCartorioRegistroImovel(e.target.value)}
                    value={cartorioRegistroImovel}
                    disabled
                />
                <br/>

                    
                    <br />
                    <br/>
                    <Autocomplete
                            options={['email', 'whatsapp']}
                            getOptionLabel={(option) => option}
                            filterSelectedOptions
                            //noOptionsText='Nenhum Usuário'
                            value={ownerToken}
                            disabled
                            onChange={(e, newValue) => {
                                setOwnerToken(newValue)
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Meio de assinatura"
                                    placeholder="email ou whatsapp"
                                />
                            )}
                    />
                    <br />
                    <label>
                        Data do Contrato <br/>
                        <input id="date" 
                        type="date" 
                        value={contratoData} 
                        placeholder={contratoData}
                        onChange={
                            (e) => setContratoData( e.target.value ) }
                        disabled/>
                    </label>
                    <br/>
                    <label>
                    Expiração da Exclusividade <br/>
                        <input id="date" 
                        type="date" 
                        value={expDate} 
                        placeholder={expDate}
                        onChange={
                            (e) => setExpDate( e.target.value ) }
                        disabled/>
                    </label>
                    <br/>
                    <div style={{ display: 'inline-block' }}>
                    { fileIPTUURL ? <p><a href={fileIPTUURL} >Baixar Espelho IPTU</a></p> :
                        <p>
                            Espelho do IPTU:<br/>
                            <SingleFileUpload onFileUpload={onIPTUUpload} />
                        </p>
                        
                    }
                    </div>
                    <br/>
                    <div style={{ display: 'inline-block' }}>
                    { fileMatriculaURL ? <p><a href={fileMatriculaURL} >Baixar Comprovante de Matrícula do Imóvel</a></p> :
                        <p>
                            Comprovante de Matrícula<br/>
                            <SingleFileUpload onFileUpload={onMatriculaUpload} />
                        </p>
                        
                    }
                    </div>
                    <br />
                    <div style={{ display: 'inline-block' }}>
                    { fileTermoVendaURL ? <p><a href={fileTermoVendaURL} >Baixar Termo de venda</a></p> :
                        <p>
                            Carregar Termo de venda:<br/>
                            <SingleFileUpload onFileUpload={onTermoUpload} />
                        </p>
                        
                    }
                    </div>
                    <TextField
                        variant='outlined'
                        label='Valor *'
                        color='secondary'
                        fullWidth
                        disabled
                        style={{ marginTop: '5px', marginBottom: '5px' }}
                        onChange={(e) => setValor(maskCurrency(e.target.value))}
                        value={valor}
                    />
                    <br/>
                    
                    <TextField
                        variant='outlined'
                        label='Comissão ( % )'
                        color='secondary'
                        fullWidth
                        disabled
                        type={'number'}
                        style={{ marginTop: '5px', marginBottom: '5px' }}
                        onChange={(e) => e.target.value >= 0 && setComissionSeller(e.target.value)}
                        //disabled={listingOwner?._id !== jwtUser._id && jwtUser.permissionLevel !== 'ADMIN'}
                        value={comissionSeller}
                    />
                    <br />
                <Divider/>
                <h6>Endereço</h6>
                <br />
                <br />
                <TextField
                    variant='outlined'
                    label='CEP *'
                    color='secondary'
                    fullWidth
                    style={{ marginTop: '5px', marginBottom: '5px' }}
                    onChange={(e) => setCep(mask(e.target.value, cepPattern))}
                    value={cep}
                    disabled
                />
                <button disabled className='btn btnPrimary' onClick={listingOwner?._id !== jwtUser._id && jwtUser.permissionLevel !== 'ADMIN' ? '' : getCEP}>Puxar Endereço Pelo CEP</button>
                <br />
                <br />
                <TextField
                    variant='outlined'
                    label='Rua *'
                    color='secondary'
                    fullWidth
                    style={{ marginTop: '5px', marginBottom: '5px' }}
                    onChange={(e) => setRua(e.target.value)}
                    value={rua}
                    disabled
                />
                <TextField
                    variant='outlined'
                    label='Número *'
                    color='secondary'
                    fullWidth
                    style={{ marginTop: '5px', marginBottom: '5px' }}
                    onChange={(e) => setNumero(e.target.value)}
                    value={numero}
                    disabled={listingOwner?._id !== jwtUser._id && jwtUser.permissionLevel !== 'ADMIN'}
                />
                <TextField
                    variant='outlined'
                    label='Complemento'
                    color='secondary'
                    fullWidth
                    style={{ marginTop: '5px', marginBottom: '5px' }}
                    onChange={(e) => setCompl(e.target.value)}
                    value={compl}
                    disabled={listingOwner?._id !== jwtUser._id && jwtUser.permissionLevel !== 'ADMIN'}
                />
                <TextField
                    variant='outlined'
                    label='Bairro *'
                    color='secondary'
                    fullWidth
                    style={{ marginTop: '5px', marginBottom: '5px' }}
                    onChange={(e) => setBairro(e.target.value)}
                    value={bairro}
                    disabled
                />
                <TextField
                    variant='outlined'
                    label='Estado *'
                    color='secondary'
                    fullWidth
                    style={{ marginTop: '5px', marginBottom: '5px' }}
                    onChange={(e) => setEstado(e.target.value)}
                    value={estado}
                    disabled
                />
                <TextField
                    variant='outlined'
                    label='Cidade *'
                    color='secondary'
                    fullWidth
                    style={{ marginTop: '5px', marginBottom: '5px' }}
                    onChange={(e) => setCidade(e.target.value)}
                    value={cidade}
                    disabled
                />
                <br />
                <br />
                <Divider />
                <br />
                <h6>Tipo de Imóvel</h6>
                <br />
                <p>Categoria *</p>
                <TextField
                    variant='outlined'
                    select
                    color='secondary'
                    fullWidth
                    style={{ marginTop: '5px', marginBottom: '5px' }}
                    onChange={(e) => setListCategory(e.target.value)}
                    disabled
                    value={listCategory}
                >
                    <MenuItem value={0}>Outro</MenuItem>
                    <MenuItem value={1}>Locação</MenuItem>
                    <MenuItem value={2}>À Venda</MenuItem>
                    <MenuItem value={3}>Vendido</MenuItem>
                    <MenuItem value={4}>Fora do Mercado</MenuItem>
                </TextField>
                <p>Tipo de Cadastro *</p>
                <TextField
                    variant='outlined'
                    select
                    color='secondary'
                    fullWidth
                    style={{ marginTop: '5px', marginBottom: '5px' }}
                    disabled
                    onChange={(e) => setListType(e.target.value)}
                    value={listType}
                >
                    <MenuItem value={1}>Em Negociação</MenuItem>
                    <MenuItem value={2}>MLS Listing</MenuItem>
                    <MenuItem value={3}>Em Breve</MenuItem>
                    <MenuItem value={4}>Com Exclusividade</MenuItem>
                    <MenuItem value={5}>Sem Exclusividade</MenuItem>
                    <MenuItem value={6}>Reservado</MenuItem>
                </TextField>
                <p>Status *</p>
                <TextField
                    variant='outlined'
                    select
                    color='secondary'
                    fullWidth
                    style={{ marginTop: '5px', marginBottom: '5px' }}
                    onChange={(e) => setListStatus(e.target.value)}
                    value={listStatus}
                    disabled = {listingOwner?._id !== jwtUser._id && jwtUser.permissionLevel !== 'ADMIN'}
                >
                    <MenuItem value={1}>Ativo</MenuItem>
                    <MenuItem value={2}>Pendente</MenuItem>
                    <MenuItem value={3}>Vendido</MenuItem>
                    <MenuItem value={4}>Em Negociação</MenuItem>
                    <MenuItem value={6}>Pendências Contratuais</MenuItem>
                    <MenuItem value={12}>Cancelado (NÂO APARECE NO SITE)</MenuItem>
                    <MenuItem value={13}>Removido</MenuItem>
                    <MenuItem value={14}>Expirado</MenuItem>
                    <MenuItem value={15}>Alugado</MenuItem>
                    <MenuItem value={17}>Vendido</MenuItem>
                    <MenuItem value={18}>Não Disponível (NÂO APARECE NO SITE)</MenuItem> {/* OFF MARKET */}
                </TextField>
                <p>Tipo de Imóvel *</p>
                <TextField
                    variant='outlined'
                    select
                    color='secondary'
                    fullWidth
                    style={{ marginTop: '5px', marginBottom: '5px' }}
                    onChange={(e) => setPropType(e.target.value)}
                    value={propType}
                    disabled
                >
                    <MenuItem value={1}>Oportunidade de Investimento (NÂO APARECE NO SITE)</MenuItem>
                    {/* <MenuItem value={2}>Comercial (NÂO APARECE NO SITE)</MenuItem> */}
                    <MenuItem value={3}>Comercial</MenuItem>
                    <MenuItem value={4}>Rural</MenuItem>
                    <MenuItem value={5}>Terreno</MenuItem>
                    <MenuItem value={6}>Casas Modulares</MenuItem>
                    <MenuItem value={7}>Casa de Férias</MenuItem>
                    <MenuItem value={8}>Residencial</MenuItem>
                    <MenuItem value={9}>Oportunidade de Rentabilidade</MenuItem>
                    <MenuItem value={10}>Outro (NÂO APARECE NO SITE)</MenuItem>
                </TextField>
                <p>Subtipo de Imóvel *</p>
                <TextField
                    variant='outlined'
                    select
                    color='secondary'
                    fullWidth
                    style={{ marginTop: '5px', marginBottom: '5px' }}
                    onChange={(e) => setPropSubType(e.target.value)}
                    disabled
                    value={propSubType}
                >
                    <MenuItem value={1}>Apartamento</MenuItem>
                    <MenuItem value={2}>Vaga de Barco</MenuItem>
                    <MenuItem value={3}>Chalé</MenuItem>
                    <MenuItem value={4}>Condomínio</MenuItem>
                    <MenuItem value={5}>Vaga de Garagem</MenuItem>
                    <MenuItem value={6}>Duplex</MenuItem>
                    <MenuItem value={7}>Fazenda</MenuItem>
                    <MenuItem value={8}>Casa Modular</MenuItem>
                    <MenuItem value={9}>Casa Modular com Terreno</MenuItem>
                    <MenuItem value={10}>Casa Móvel</MenuItem>
                    <MenuItem value={11}>OYO</MenuItem>
                    <MenuItem value={12}>Quadruplex</MenuItem>
                    <MenuItem value={13}>Rancho</MenuItem>
                    <MenuItem value={14}>Casa Geminada</MenuItem>
                    <MenuItem value={15}>Casa Não Geminada</MenuItem>
                    <MenuItem value={16}>Cooperativa</MenuItem>
                    <MenuItem value={17}>Timeshare</MenuItem>
                    <MenuItem value={18}>Townhouse</MenuItem>
                    <MenuItem value={19}>Triplex</MenuItem>
                    <MenuItem value={20}>Agricultura (NÃO APARECE NO SITE)</MenuItem>
                    <MenuItem value={21}>Negócios (NÃO APARECE NO SITE)</MenuItem>
                    <MenuItem value={22}>Hotel-Motel (NÃO APARECE NO SITE)</MenuItem>
                    <MenuItem value={23}>Industrial (NÃO APARECE NO SITE)</MenuItem>
                    <MenuItem value={24}>Mixed-Use</MenuItem>
                    <MenuItem value={25}>Multi-Family</MenuItem>
                    <MenuItem value={26}>Comercial (NÃO APARECE NO SITE)</MenuItem>
                    <MenuItem value={27}>Loja (NÃO APARECE NO SITE)</MenuItem>
                    <MenuItem value={28}>Terreno Não Urbano</MenuItem>
                    <MenuItem value={29}>Galpão (NÃO APARECE NO SITE)</MenuItem>
                    <MenuItem value={30}>Comunidade Adulta</MenuItem>
                    <MenuItem value={31}>Casa de Férias</MenuItem>
                    <MenuItem value={32}>Outro</MenuItem>
                </TextField>
                <br />
                <br />
                <Divider />
                <br />
                <h6>Dados do Imóvel</h6>
                <br />
                <TextField
                    variant='outlined'
                    label='Metragem *'
                    color='secondary'
                    fullWidth
                    type={'number'}
                    style={{ marginTop: '5px', marginBottom: '5px' }}
                    onChange={(e) => e.target.value >= 0 && setMetragem(e.target.value)}
                    disabled={listingOwner?._id !== jwtUser._id && jwtUser.permissionLevel !== 'ADMIN'}
                    value={metragem}
                />
                <TextField
                    variant='outlined'
                    label='Nº de Quartos *'
                    color='secondary'
                    fullWidth
                    style={{ marginTop: '5px', marginBottom: '5px' }}
                    type={'number'}
                    onChange={(e) => e.target.value >= 0 && setNQuartos(e.target.value)}
                    disabled={listingOwner?._id !== jwtUser._id && jwtUser.permissionLevel !== 'ADMIN'}
                    value={nQuartos}
                />
                <TextField
                    variant='outlined'
                    label='Nº de Lavabos *'
                    color='secondary'
                    fullWidth
                    type={'number'}
                    style={{ marginTop: '5px', marginBottom: '5px' }}
                    onChange={(e) => e.target.value >= 0 && setNLavabos(e.target.value)}
                    disabled={listingOwner?._id !== jwtUser._id && jwtUser.permissionLevel !== 'ADMIN'}
                    value={nLavabos}
                />
                <TextField
                    variant='outlined'
                    label='Nº de Banheiros *'
                    color='secondary'
                    fullWidth
                    type={'number'}
                    style={{ marginTop: '5px', marginBottom: '5px' }}
                    onChange={(e) => e.target.value >= 0 && setNBanheiros(e.target.value)}
                    disabled={listingOwner?._id !== jwtUser._id && jwtUser.permissionLevel !== 'ADMIN'}
                    value={nBanheiros}
                />
                <br />
                <Divider />
                <br />
                <h6>Estrutura</h6>
                <br />
                <TextField
                    variant='outlined'
                    label='Nome do Condomínio'
                    color='secondary'
                    fullWidth
                    style={{ marginTop: '5px', marginBottom: '5px' }}
                    onChange={(e) => setNomeCondominio(e.target.value)}
                    disabled={listingOwner?._id !== jwtUser._id && jwtUser.permissionLevel !== 'ADMIN'}
                    value={nomeCondominio}
                />
                <TextField
                    variant='outlined'
                    label='Andar do Imóvel'
                    color='secondary'
                    fullWidth
                    type={'number'}
                    style={{ marginTop: '5px', marginBottom: '5px' }}
                    onChange={(e) => setAndarDoImovel(e.target.value)}
                    disabled={listingOwner?._id !== jwtUser._id && jwtUser.permissionLevel !== 'ADMIN'}
                    value={andarDoImovel}
                />
                <TextField
                    variant='outlined'
                    label='Estado do Imóvel'
                    select
                    color='secondary'
                    fullWidth
                    style={{ marginTop: '5px', marginBottom: '5px' }}
                    onChange={(e) => setNovoImovel(e.target.value)}
                    disabled={listingOwner?._id !== jwtUser._id && jwtUser.permissionLevel !== 'ADMIN'}
                    value={novoImovel}
                >
                    <MenuItem value={true}>Novo</MenuItem>
                    <MenuItem value={false}>Usado</MenuItem>
                </TextField>
                <TextField
                    variant='outlined'
                    label='Nº de Vagas'
                    color='secondary'
                    fullWidth
                    type={'number'}
                    style={{ marginTop: '5px', marginBottom: '5px' }}
                    onChange={(e) => e.target.value >= 0 && setNVagas(e.target.value)}
                    disabled={listingOwner?._id !== jwtUser._id && jwtUser.permissionLevel !== 'ADMIN'}
                    value={nVagas}
                />
                <TextField
                    variant='outlined'
                    label='Construtora'
                    color='secondary'
                    fullWidth
                    style={{ marginTop: '5px', marginBottom: '5px' }}
                    onChange={(e) => setConstrutora(e.target.value)}
                    disabled={listingOwner?._id !== jwtUser._id && jwtUser.permissionLevel !== 'ADMIN'}
                    value={construtora}
                />
                <TextField
                    variant='outlined'
                    label='Total de Unidades'
                    color='secondary'
                    fullWidth
                    type={'number'}
                    style={{ marginTop: '5px', marginBottom: '5px' }}
                    onChange={(e) => e.target.value >= 0 && setNUnidades(e.target.value)}
                    disabled={listingOwner?._id !== jwtUser._id && jwtUser.permissionLevel !== 'ADMIN'}
                    value={nUnidades}
                />
                <TextField
                    variant='outlined'
                    label='Nº de Andares'
                    color='secondary'
                    fullWidth
                    type={'number'}
                    style={{ marginTop: '5px', marginBottom: '5px' }}
                    onChange={(e) => e.target.value >= 0 && setNAndares(e.target.value)}
                    disabled={listingOwner?._id !== jwtUser._id && jwtUser.permissionLevel !== 'ADMIN'}
                    value={nAndares}
                />
                <TextField
                    variant='outlined'
                    label='Nº de Torres'
                    color='secondary'
                    fullWidth
                    type={'number'}
                    style={{ marginTop: '5px', marginBottom: '5px' }}
                    onChange={(e) => e.target.value >= 0 && setNTorres(e.target.value)}
                    disabled={listingOwner?._id !== jwtUser._id && jwtUser.permissionLevel !== 'ADMIN'}
                    value={nTorres}
                />
                <div style={{ display: 'inline-block' }}>
                    <Checkbox
                        color='primary'
                        checked={churrasqueira}
                        onChange={(e) => setChurrasqueira(e.target.checked)}
                        disabled={listingOwner?._id !== jwtUser._id && jwtUser.permissionLevel !== 'ADMIN'}
                    />Churrasqueira
                </div>
                <div style={{ display: 'inline-block' }}>
                    <Checkbox
                        color='primary'
                        checked={deck}
                        onChange={(e) => setDeck(e.target.checked)}
                        disabled={listingOwner?._id !== jwtUser._id && jwtUser.permissionLevel !== 'ADMIN'}
                    />Deck
                </div>
                <div style={{ display: 'inline-block' }}>
                    <Checkbox
                        color='primary'
                        checked={acessoDeficientes}
                        onChange={(e) => setAcessoDeficientes(e.target.checked)}
                        disabled={listingOwner?._id !== jwtUser._id && jwtUser.permissionLevel !== 'ADMIN'}
                    />Acesso Deficientes Físicos
                </div>
                <div style={{ display: 'inline-block' }}>
                    <Checkbox
                        color='primary'
                        checked={doca}
                        onChange={(e) => setDoca(e.target.checked)}
                        disabled={listingOwner?._id !== jwtUser._id && jwtUser.permissionLevel !== 'ADMIN'}
                    />Doca
                </div>
                <div style={{ display: 'inline-block' }}>
                    <Checkbox
                        color='primary'
                        checked={jardim}
                        onChange={(e) => setJardim(e.target.checked)}
                        disabled={listingOwner?._id !== jwtUser._id && jwtUser.permissionLevel !== 'ADMIN'}
                    />Jardim
                </div>
                <div style={{ display: 'inline-block' }}>
                    <Checkbox
                        color='primary'
                        checked={entradaFechada}
                        onChange={(e) => setEntradaFechada(e.target.checked)}
                        disabled={listingOwner?._id !== jwtUser._id && jwtUser.permissionLevel !== 'ADMIN'}
                    />Entrada Fechada
                </div>
                <div style={{ display: 'inline-block' }}>
                    <Checkbox
                        color='primary'
                        checked={estufa}
                        onChange={(e) => setEstufa(e.target.checked)}
                        disabled={listingOwner?._id !== jwtUser._id && jwtUser.permissionLevel !== 'ADMIN'}
                    />Estufa
                </div>
                <div style={{ display: 'inline-block' }}>
                    <Checkbox
                        color='primary'
                        checked={hidro}
                        onChange={(e) => setHidro(e.target.checked)}
                        disabled={listingOwner?._id !== jwtUser._id && jwtUser.permissionLevel !== 'ADMIN'}
                    />Hidromassagem
                </div>
                <div style={{ display: 'inline-block' }}>
                    <Checkbox
                        color='primary'
                        checked={patio}
                        onChange={(e) => setPatio(e.target.checked)}
                        disabled={listingOwner?._id !== jwtUser._id && jwtUser.permissionLevel !== 'ADMIN'}
                    />Pátio
                </div>
                <div style={{ display: 'inline-block' }}>
                    <Checkbox
                        color='primary'
                        checked={lago}
                        onChange={(e) => setLago(e.target.checked)}
                        disabled={listingOwner?._id !== jwtUser._id && jwtUser.permissionLevel !== 'ADMIN'}
                    />Laguinho
                </div>
                <div style={{ display: 'inline-block' }}>
                    <Checkbox
                        color='primary'
                        checked={piscina}
                        onChange={(e) => setPiscina(e.target.checked)}
                        disabled={listingOwner?._id !== jwtUser._id && jwtUser.permissionLevel !== 'ADMIN'}
                    />Piscina
                </div>
                <div style={{ display: 'inline-block' }}>
                    <Checkbox
                        color='primary'
                        checked={varanda}
                        onChange={(e) => setVaranda(e.target.checked)}
                        disabled={listingOwner?._id !== jwtUser._id && jwtUser.permissionLevel !== 'ADMIN'}
                    />Varanda
                </div>
                <div style={{ display: 'inline-block' }}>
                    <Checkbox
                        color='primary'
                        checked={vagaTrailer}
                        onChange={(e) => setVagaTrailer(e.target.checked)}
                        disabled={listingOwner?._id !== jwtUser._id && jwtUser.permissionLevel !== 'ADMIN'}
                    />Vaga para Trailer
                </div>
                <div style={{ display: 'inline-block' }}>
                    <Checkbox
                        color='primary'
                        checked={quadra}
                        onChange={(e) => setQuadra(e.target.checked)}
                        disabled={listingOwner?._id !== jwtUser._id && jwtUser.permissionLevel !== 'ADMIN'}
                    />Quadra
                </div>
                <div style={{ display: 'inline-block' }}>
                    <Checkbox
                        color='primary'
                        checked={aspercao}
                        onChange={(e) => setAspercao(e.target.checked)}
                        disabled={listingOwner?._id !== jwtUser._id && jwtUser.permissionLevel !== 'ADMIN'}
                    />Sistema de Irrigação
                </div>
                <div style={{ display: 'inline-block' }}>
                    <Checkbox
                        color='primary'
                        checked={beiraMar}
                        onChange={(e) => setBeiraMar(e.target.checked)}
                        disabled={listingOwner?._id !== jwtUser._id && jwtUser.permissionLevel !== 'ADMIN'}
                    />Beira do Mar
                </div>
                <br />
                <br />
                <Divider />
                <br />
                <h6>Detalhes</h6>
                <br />
                <TextField
                    variant='outlined'
                    label='Ano da Construção'
                    color='secondary'
                    fullWidth
                    type={'number'}
                    style={{ marginTop: '5px', marginBottom: '5px' }}
                    onChange={(e) => e.target.value >= 0 && setAnoConstrucao(e.target.value)}
                    disabled={listingOwner?._id !== jwtUser._id && jwtUser.permissionLevel !== 'ADMIN'}
                    value={anoConstrucao}
                />
                <TextField
                    variant='outlined'
                    label='Tamanho do Terreno'
                    color='secondary'
                    fullWidth
                    type={'number'}
                    style={{ marginTop: '5px', marginBottom: '5px' }}
                    onChange={(e) => setTamanhoTerreno(e.target.value)}
                    disabled={listingOwner?._id !== jwtUser._id && jwtUser.permissionLevel !== 'ADMIN'}
                    value={tamanhoTerreno}
                />
                <TextField
                    variant='outlined'
                    label='Descrição'
                    color='secondary'
                    fullWidth
                    style={{ marginTop: '5px', marginBottom: '5px' }}
                    onChange={(e) => setDescricao(String(e.target.value).replace('\n', ''))}
                    disabled={listingOwner?._id !== jwtUser._id && jwtUser.permissionLevel !== 'ADMIN'}
                    value={descricao}
                />
                <TextField
                    variant='outlined'
                    label='IPTU Anual ( R$ )'
                    color='secondary'
                    number
                    fullWidth
                    style={{ marginTop: '5px', marginBottom: '5px' }}
                    onChange={(e) => setIptuAnual(maskCurrency(e.target.value))}
                    disabled={listingOwner?._id !== jwtUser._id && jwtUser.permissionLevel !== 'ADMIN'}
                    value={iptuAnual}
                />
                <TextField
                    variant='outlined'
                    label='Condomínio Mensal ( R$ )'
                    color='secondary'
                    number
                    fullWidth
                    style={{ marginTop: '5px', marginBottom: '5px' }}
                    onChange={(e) => setCondominioMensal(maskCurrency(e.target.value))}
                    disabled={listingOwner?._id !== jwtUser._id && jwtUser.permissionLevel !== 'ADMIN'}
                    value={condominioMensal}
                />
                
                <br />
                <br />
                <Divider />
                <br />
                <h6>Vídeos / Tours Virtuais</h6>
                <br />
                <button
                    className='btn btnSecondary'
                    onClick={() => setVirtualTours([...virtualTours, {
                        "vt_short_desc": "",
                        "vt_updated_at": new Date().toISOString(),
                        "vt_url": "",
                        "vt_url_branded": true
                    }])}
                    style={{ marginTop: '5px', marginRight: '5px' }}
                >
                    Adicionar
                </button>
                {virtualTours.map((vt, i) => {
                    return (
                        <>
                            <br />
                            <br />
                            <label>Vídeo {i + 1}</label>
                            <TextField
                                variant='outlined'
                                label='Descrição'
                                color='secondary'
                                fullWidth
                                style={{ marginTop: '5px', marginBottom: '5px' }}
                                onChange={(e) => {
                                    let arr = [...virtualTours]
                                    arr[i].vt_short_desc = e.target.value
                                    setVirtualTours(arr)
                                }}
                                disabled={listingOwner?._id !== jwtUser._id && jwtUser.permissionLevel !== 'ADMIN'}
                                value={vt.vt_short_desc}
                            />
                            <br />
                            <TextField
                                variant='outlined'
                                label='URL'
                                color='secondary'
                                fullWidth
                                style={{ marginTop: '5px', marginBottom: '5px' }}
                                onChange={(e) => {
                                    let arr = [...virtualTours]
                                    arr[i].vt_url = e.target.value
                                    setVirtualTours(arr)
                                }}
                                disabled={listingOwner?._id !== jwtUser._id && jwtUser.permissionLevel !== 'ADMIN'}
                                value={vt.vt_url}
                            />
                            <button
                                className='btn btnSecondary'
                                style={{ marginTop: '5px', marginRight: '5px', borderColor: '#a7002f', color: '#a7002f' }}
                                onClick={() => {
                                    let arr = [...virtualTours]
                                    arr.splice(i, 1)
                                    setVirtualTours(arr)
                                }}
                            >
                                Remover Tour
                            </button>
                            {i !== virtualTours.length - 1 &&
                                <>
                                    <br />
                                    <br />
                                    <Divider />
                                    <br />
                                </>
                            }
                        </>
                    )
                })}
                {(listingOwner?._id === jwtUser._id || (jwtUser.permissionLevel === 'ADMIN' || jwtUser.permissionLevel === 'SUPER' )) &&
                    <div style={{ textAlign: 'right' }}>
                        <Divider />
                        <button
                            className='btn btnSecondary'
                            onClick={deletePreListing}
                            style={{ marginTop: '5px', marginRight: '5px', borderColor: '#a7002f', color: '#a7002f' }}
                        >
                            Remover PréListing
                        </button>
                
                        <button
                            className='btn btnPrimary'
                            onClick={submit}
                            style={{ marginTop: '5px', marginRight: '5px' }}
                        >
                            {listing?.status == 'REPROVADO' ? 'Solicitar Reavaliação' : 'Salvar Alterações'}
                        </button>
                        <button
                            className='btn btnPrimary'
                            onClick={submit2}
                            style={{ marginTop: '5px', marginRight: '5px' }}
                        >
                            {listing?.status == 'REPROVADO' ? 'Solicitar Reavaliação' : 'Criar Listing'}
                        </button>
                    </div>
                }
                <Divider style={{ marginTop: '25px', marginBottom: '25px' }} />
                {(jwtUser?.isMcAdmin || jwtUser?.isTeamAdmin || jwtUser?.permissionLevel === 'ADMIN' || jwtUser?.permissionLevel === 'SUPER' ) && listing?.status === 'PENDENTE' &&
                    <div style={{ textAlign: 'right' }}>
                        <button
                            className={reprovingMode ? 'btn btnSecondary' : 'btn btnPrimary'}
                            style={{ marginTop: '5px', marginBottom: '50px', marginRight: '5px', backgroundColor: reprovingMode ? '' : '#a7002f', borderColor: '#a7002f', color: reprovingMode ? '#a7002f' : '' }}
                            onClick={() => setReprovingMode(!reprovingMode)}
                        >
                            {reprovingMode ? 'Cancelar' : 'Reprovar'}
                        </button>
                        <button
                            className='btn btnPrimary'
                            onClick={() => changeStatus('ATIVO')}
                            style={{ marginTop: '5px', marginBottom: '50px', backgroundColor: '#00a75c', borderColor: '#00a75c' }}
                        >
                            Aprovar
                        </button>

                    </div>
                }
                <Zoom in={(jwtUser?.isMcAdmin || jwtUser?.isTeamAdmin || jwtUser?.permissionLevel === 'ADMIN' || jwtUser?.permissionLevel === 'SUPER' ) && reprovingMode}>
                    <div style={{ textAlign: 'right' }}>
                        <TextField
                            variant='outlined'
                            label='Motivo *'
                            color='secondary'
                            disabled={status !== 'PENDENTE'}
                            style={{ marginTop: '5px', marginBottom: '5px', width: '50%' }}
                            onChange={(e) => setMotivo(e.target.value)}
                            value={motivo}
                        />
                        <br />

                        {(jwtUser?.isMcAdmin || jwtUser?.isTeamAdmin || jwtUser.permissionLevel === 'ADMIN' || jwtUser.permissionLevel === 'SUPER' ) && status === 'PENDENTE' &&
                            <button
                                className={reprovingMode ? 'btn btnSecondary' : 'btn btnPrimary'}
                                style={{ marginTop: '5px', marginBottom: '50px', marginRight: '5px', backgroundColor: '#a7002f', borderColor: '#a7002f', color: 'white' }}
                                onClick={() => changeStatus('REPROVADO')}
                            >
                                Reprovar
                            </button>
                        }
                    </div>
                </Zoom>

            </div>
        </div>
    )
}
