import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AuthProvider from "./contexts/AuthContext";
import SetEmail from './pages/SetEmail';
import { ErrorBoundary } from "react-error-boundary";
import XMLFilesProvider from './contexts/XMLFilesProvider';
import listingInMemory from './libs/services/listingInMemory';
import XMLListingsProvider from './contexts/XMLListingsProvider';
import ProviderSandbox from './pages/ProviderSandBox';
function fallbackRender({ error, resetErrorBoundary }) {
  // Call resetErrorBoundary() to reset the error boundary and retry the render.

  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre style={{ color: "red" }}>{error.message}</pre>
    </div>
  );
}



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
   
    <Router forceRefresh>
      <AuthProvider>
        
        <XMLFilesProvider>

        <ErrorBoundary FallbackComponent={fallbackRender}>
          <Routes>
            <Route path='/set_email/:id' exact element={<SetEmail />} />
          </Routes>

          <App />
          </ErrorBoundary>
          </XMLFilesProvider>
      </AuthProvider>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
