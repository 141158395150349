import { Grid, IconButton, Zoom } from '@mui/material'
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import command from '../assets/imgs/video.jpeg'
import { AuthContext } from '../contexts/AuthContext';
import NewTreinamentoCollection from '../views/NewTreinamentoCollection';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import EditIcon from '@mui/icons-material/Edit';
import { BASE_URL, SOUKW_URL } from '../sharedComponents/Constants';

export default function TreinamentosAgents({ type }) {
    const { jwtUser, token, logout } = useContext(AuthContext);
    const [treinamentos, setTreinamentos] = useState([]);
    const [newTreinamentoModalOpen, setNewTreinamentoModalOpen] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [collectionEdit, setCollectionEdit] = useState(null);
    const navigate = useNavigate()
    const location = useLocation()

    const sortByKey = (jsObj) => {
        var sortedArray = [];

        // Push each JSON Object entry in array by [key, value]
        for (var i in jsObj) {
            sortedArray.push([i, jsObj[i]]);
        }

        // Run native sort function and returns sorted array.
        return sortedArray.sort();
    }
    useEffect(() => {

        async function getData() {
            try {
                const config = {
                    headers: { Authorization: `Bearer ${token}` }
                };

                let res = await axios.get(BASE_URL + '/treinamentos/collections?type=' + type, config);

                setTreinamentos(res.data?.sort((a, b) => parseFloat(a.order) - parseFloat(b.order)))
            }
            catch (e) {
                console.error(e);
                if (e?.response?.data?.msg)
                    alert(e?.response?.data?.msg)
                else {
                    alert('Erro de Conexão.')
                    logout();
                }
            }
        }
        getData()
    }, [])


    const openNewTreinamentoModal = () => {
        setCollectionEdit(null);
        setNewTreinamentoModalOpen(true);
    }
    const closeNewTreinamentoModal = () => {
        setNewTreinamentoModalOpen(false);
        setCollectionEdit(null);
    }
    const submitDelete = async (item) => {

        try {
            const config = {
                headers: { Authorization: `Bearer ${token}` }
            };
            let res = await axios.delete(BASE_URL + '/treinamentos/collections/' + item?._id, config);
            if (res.data)
                alert('Deletado com sucesso!');
            document.location.reload()
        } catch (e) {
            console.error(e);
            if (e?.response?.data?.msg)
                alert(e?.response?.data?.msg)
            else {
                alert('Erro de Conexão.')
                logout();
            }
        }
    }
    return (
        <div style={{ width: '100%' }}>
            {location.pathname != '/soukw/training/leadership' && location.pathname != '/soukw/training/agents' &&
                <button className='btn btnSecondary' onClick={() => navigate(-1)}>
                    Voltar
                </button>

            }
            <Grid item xs={12} >
                <br />
            </Grid>
            <Grid container spacing={0} style={{ height: '100%' }} justifyItems='flex-start' alignItems={'flex-start'}>
                <Grid item xs={12} sm={7} style={{ textAlign: 'left', }}>
                    <h1>{type === 0 ? 'Agentes' : 'Liderança'}</h1>
                </Grid>
                {jwtUser?.permissionLevel.match(/ADMIN|SUPER/) &&
                    <Grid item xs={12} sm={2} style={{ textAlign: 'right', }}>
                        <button className='btn btnSecondary' onClick={() => setEditMode(!editMode)}>{editMode ? 'Cancelar' : 'Editar'}</button>
                    </Grid>
                }
                {jwtUser?.permissionLevel.match(/ADMIN|SUPER/) &&
                    <Grid item xs={12} sm={2} style={{ textAlign: 'right', }}>
                        <button className='btn btnPrimary' onClick={openNewTreinamentoModal}>Nova Coleção</button>
                    </Grid>
                }
                <Grid item xs={1} >
                    <br />
                </Grid>
                <Grid item xs={12} >
                    <br />
                </Grid>
                <Grid item xs={12} >
                    <Grid container spacing={0} style={{ height: '100%' }} justifyItems='center' alignItems={'center'}>
                        {type === 0 &&
                            <Grid item xs={12} sm={6} md={3} lg={2.1}>
                                <Link to={type === 0 ? '/soukw/training/growth-calls-agents' : '/soukw/training/growth-calls-tl'}>
                                    <div style={{
                                        height: '200px',
                                        width: '200px',
                                        backgroundImage: `url(${command})`,
                                        backgroundSize: 'cover',
                                        cursor: 'pointer',
                                        position: 'relative'
                                    }}>
                                        <h1 style={{ fontSize: '1.1rem', position: 'absolute', left: '15%', top: '25%', color: '#ffffff', textTransform: 'capitalize', marginRight: '50px' }}>
                                            Growth Calls
                                        </h1>
                                    </div>
                                </Link>
                                <br />
                            </Grid>
                        }
                        {type === 1 &&
                            <Grid item xs={12} sm={6} md={3} lg={2.1}>
                                <Link to={'/soukw/training/growth-calls-tl'}>
                                    <div style={{
                                        height: '200px',
                                        width: '200px',
                                        backgroundImage: `url(${command})`,
                                        backgroundSize: 'cover',
                                        cursor: 'pointer',
                                        position: 'relative'
                                    }}>
                                        <h1 style={{ fontSize: '1.1rem', position: 'absolute', left: '15%', top: '25%', color: '#ffffff', textTransform: 'capitalize', marginRight: '50px' }}>
                                            Growth Calls Team Leader
                                        </h1>
                                    </div>
                                </Link>
                                <br />
                            </Grid>
                        }
                        {type === 1 &&
                            <Grid item xs={12} sm={6} md={3} lg={2.1}>
                                <Link to={'/soukw/training/growth-calls-op'}>
                                    <div style={{
                                        height: '200px',
                                        width: '200px',
                                        backgroundImage: `url(${command})`,
                                        backgroundSize: 'cover',
                                        cursor: 'pointer',
                                        position: 'relative'
                                    }}>
                                        <h1 style={{ fontSize: '1.1rem', position: 'absolute', left: '15%', top: '25%', color: '#ffffff', textTransform: 'capitalize', marginRight: '50px' }}>
                                            Growth Calls OP
                                        </h1>
                                    </div>
                                </Link>
                                <br />
                            </Grid>
                        }
                        {type === 3 &&
                            <Grid item xs={12} sm={6} md={3} lg={2.1}>
                                <Link to={'/soukw/training/growth-calls-regional'}>
                                    <div style={{
                                        height: '200px',
                                        width: '200px',
                                        backgroundImage: `url(${command})`,
                                        backgroundSize: 'cover',
                                        cursor: 'pointer',
                                        position: 'relative'
                                    }}>
                                        <h1 style={{ fontSize: '1.1rem', position: 'absolute', left: '15%', top: '25%', color: '#ffffff', textTransform: 'capitalize', marginRight: '50px' }}>
                                            Growth Calls
                                        </h1>
                                    </div>
                                </Link>
                                <br />
                            </Grid>
                        }
                        {
                            treinamentos?.map(t => {
                                return (
                                    <Grid key={t.name} item xs={12} sm={6} md={3} lg={2.1}>
                                        <Zoom in={editMode} style={{ zIndex: 1 }}>
                                            <div style={{ position: 'absolute' }}>
                                                <IconButton
                                                    onClick={() => submitDelete(t, true)}
                                                    style={{
                                                        backgroundColor: '#a7002f',
                                                        color: 'white',
                                                        zIndex: 1,
                                                        marginRight: '2px',
                                                        marginLeft: '2px'
                                                    }}><DeleteForeverRoundedIcon />
                                                </IconButton>
                                                <IconButton
                                                    onClick={() => setCollectionEdit(t)}
                                                    style={{
                                                        zIndex: 1,
                                                        backgroundColor: '#0099a7',
                                                        color: 'white',
                                                        marginRight: '2px',
                                                        marginLeft: '2px'
                                                    }}><EditIcon />
                                                </IconButton>
                                            </div>
                                        </Zoom>
                                        <Link to={'/soukw/training/videos/' + t._id}>
                                            <div style={{
                                                height: '200px',
                                                width: '200px',
                                                backgroundImage: `url(${command})`,
                                                backgroundSize: 'cover',
                                                cursor: 'pointer',
                                                position: 'relative'
                                            }}>
                                                <h1 style={{
                                                    fontSize: t.name.length < 25 ? '1.1rem' : '.9rem',
                                                    position: 'absolute', left: '15%', top: '25%', color: '#ffffff', textTransform: 'capitalize', marginRight: '50px'
                                                }}>
                                                    {t.name}
                                                </h1>
                                            </div>
                                        </Link>
                                        <br />
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                </Grid>
            </Grid>
            <NewTreinamentoCollection open={newTreinamentoModalOpen || collectionEdit} close={closeNewTreinamentoModal} leadershipOnly={type === 0 ? false : true} collection={collectionEdit} />
        </div>
    )
}
