import { Grid } from '@mui/material'
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext'
import Loading from '../views/Loading';
import UpdatesBoard from '../views/UpdatesBoard';
import { BASE_URL, SOUKW_URL } from '../sharedComponents/Constants';

export default function Home() {
    const { user, token, logout } = useContext(AuthContext);
    const navigate = useNavigate();
    const [total, setTotal] = useState([]);
    const [pending, setPending] = useState([]);
    const [rejected, setRejected] = useState([]);

    const [nome, setNome] = useState('');

    const [loading, setLoading] = useState(true);
    useEffect(() => {
        async function getData() {

            try {
                const config = {
                    headers: { Authorization: `Bearer ${token}` }
                };
                let resultsT = await axios.get(BASE_URL + '/listings/myListings?page=1', config);
                console.log(resultsT.data)
                let resultsP = await axios.get(BASE_URL + '/integracaoZapImoveis/getXMLSize', config);
                console.log( resultsP.data)
                let resultsR = await axios.get(BASE_URL + '/preListings/my?page=1', config);
                setTotal(resultsT.data?.totalItems);
                setPending(resultsP.data?.xmlSize);
                setRejected(resultsR.data?.totalItems);
                setLoading(false);
            } catch (e) {
                console.error(e);
                setLoading(false);
                if (e?.response?.data?.msg)
                    alert(e?.response?.data?.msg)
                else {
                    alert('Erro de Conexão.')
                    logout();
                }
            }
        }
        getData();
    }, [])

    if (loading)
        return <Loading />
    return (
        <Grid container spacing={0} style={{ height: '100%', marginRight: '75px', textAlign: 'center' }} justifyContent='center'>
            <Grid item xs={12} style={{ textAlign: 'left' }}>
                <h1>Bem Vindo, {user?.fullName}!</h1>
            </Grid>
            <Grid item xs={12}>
                <div><br /></div>
            </Grid>
            <Grid item xs={4}>
                <div className='card' style={{ width: '100%', paddingTop: '10px', paddingBottom: '15px', backgroundColor: 'white' }}>
                    <h1>{total}</h1>
                    <h6>Imóveis Legados</h6>
                    <button className='btn btnSecondary' onClick={() => navigate('/soukw/listings')} style={{ marginTop: '10px' }}>Ver Todos</button>
                </div>
            </Grid>
            <Grid item xs={4}>
                <div className='card' style={{ width: '100%', paddingTop: '10px', paddingBottom: '15px' }}>
                    <h1>{pending}</h1>
                    <h6>Imóveis no XML</h6>
                    <button className='btn btnSecondary' onClick={() => navigate('/soukw/portals')} style={{ marginTop: '10px' }}>Ver Todos</button>
                </div>
            </Grid>
            <Grid item xs={4}>
                <div className='card' style={{ width: '100%', paddingTop: '10px', paddingBottom: '15px' }}>
                    <h1>{rejected}</h1>
                    <h6>Imóveis Pré Cadastro</h6>
                    <button className='btn btnSecondary' onClick={() => navigate('/soukw/prelistings')} style={{ marginTop: '10px' }}>Ver Todos</button>
                </div>
            </Grid>
            <Grid item xs={12}>
                <br />
            </Grid>
            <Grid item xs={12}>
                <UpdatesBoard />
            </Grid>
        </Grid >
    )
}
