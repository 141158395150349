import axios from "axios";
import { BASE_URL } from "../../sharedComponents/Constants";


class ListingInMemory {

    listings = []

    constructor( listings = [] ) {

        if( listings.length > 0 )
            listings.forEach( ( lst ) => {
                if( this.getListing( lst._id ) )
                    this.updateListing( lst._id, lst )
                else
                    this.addListing( lst )
            } )
    }

    addListing( lst ) {
        if( this.getListing( lst._id ) )
            this.updateListing( lst._id, lst )
        else
            this.listings.push( {...lst} )
    }

    getListing( id ) {
        return this.listings.find( ( lst ) => lst._id === id )
    }

    deleteListing( id ) {
        const index = this.listings.findIndex( ( lst ) => lst._id === id )
        this.listings.splice( index, 1 )
    }

    clearListings( ) {
        this.listings = []
    }

    getListings( ) {
        return this.listings
    }

    updateListing( id, lst ) {
        const index = this.listings.findIndex( ( lst ) => lst._id === id )
        this.listings.splice( index, 1, {...lst} )
    }

    reloadListings( token, listings ) {
        //alert("Reloading: ", listings.map( el => el._id))
        this.clearListings( )
        this.token = token
        listings.forEach( ( lst ) => this.addListing( lst ) )
    }

    async saveListings(
        token, 
        planType = 'Padrão',
        locationType = 'Endereço Completo',
        bairroComercial = '',
        suites = 0,
        titulo = '',
        fileName = ''
        ) {
        this.token = token
        //alert("Saving listings")
        const config = {
            headers: { Authorization: `Bearer ${this.token}` }
        };
        let payload =  {
            locationType: locationType,
            planType: planType,
            Active: true,
            bairroComercial: bairroComercial,
            suites: suites,
            title: titulo,
            fileName: fileName
            }
        
        Object.keys(payload).forEach( key => {
            if( !payload[key] )
                delete payload[key]
        })

        let data = this.listings.map( ( lst ) => {
             //title = title ? title : `${listing.prop_type} com ${listing.bedrooms}.`
            return {
                ...payload,
                _id: lst._id,
            }
        } )
        //alert("Data is: ", this.listings)
        try{
            let result = await this.sendToAxios( `${BASE_URL}/integracaoZapImoveis/addListing`, data, {
                headers: { Authorization: `Bearer ${this.token}` }
            } )
            alert("Listings enviados para o XML" )
        } catch( err ) {
            console.log(  err )
            alert( err.response)
        }
    }

    async saveListing( token, addData) {
        this.token = token
        const config = {
            headers: { Authorization: `Bearer ${this.token}` }
        };

        let data =  {
            _id: addData.lst._id,
            locationType: addData.locationType ?? "Endereço Completo",
            planType: addData.planType ?? "Padrão",
            Active: true,
            bairroComercial: addData.bairroComercial,
            suites: addData.suites ?? 0,
            title: addData.titulo,
            fileName: addData.fileName
            }

            Object.keys(data).forEach( key => {
                if( !data[key] )
                    delete data[key]
            })

        try{
            console.log("Sending to create: ", [data])
            let result = await this.sendToAxios( `${BASE_URL}/integracaoZapImoveis/addListing`, [data], {
                headers: { Authorization: `Bearer ${this.token}` }
            } )
            console.log( result.data.message )
            return result
        } catch( err ) {
            console.log( err )
            alert(err.response)
        }
    }

    async getURL( fileName, token ) {
        this.token = token
        console.log( this.token )
        const config = {
            headers: { Authorization: `Bearer ${this.token}` }
        };
        try{
            let result = await axios.get(`${BASE_URL}/integracaoZapImoveis/getXMLPath/${fileName}`, {
                headers: { Authorization: `Bearer ${this.token}` }
            } )
            console.log( result )
            alert( result.data.path )
            return( result.data )
        } catch( err ) {
            console.log( err )
            alert("Erro ao gerar XML")
        }
    }

    async disableAdd( token, id, file ) {
        this.token = token
        console.log( this.token )
        const config = {
            headers: { Authorization: `Bearer ${this.token}` }
        };
        let url = `${BASE_URL}/integracaoZapImoveis/disableAdds`
        try{
            console.log("Sending to disable: ", {_id: id,
                fileName: file})
            await this.sendToAxios(url, [
                {_id: id,
                fileName: file}], config)
        } catch( err ) {
            console.log( err )
            alert("Erro ao desativar anúncio")
        }
    }

    async disableSelectedAdds( token, file ) {
        this.token = token
        console.log( this.token )
        const config = {
            headers: { Authorization: `Bearer ${this.token}` }
        };
        let listingsList = this.listings.map( ( lst ) => {
            //title = title ? title : `${listing.prop_type} com ${listing.bedrooms}.`
           return {
              fileName: file,
               _id: lst._id,
           }
       } )
       let url = `${BASE_URL}/integracaoZapImoveis/disableAdds`
        try{

            await this.sendToAxios(url, listingsList, config)

        } catch( err ) {
            console.log( err )
            alert("Erro ao desativar anúncio")
        }
    }

    async saveAllFilteredListings( ) {}

    async sendToAxios (url, data, config ) {

        console.log( data )
        try {
            
            let result = await axios.post( url, data, config );
            return result
        } catch ( error ) {

            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
                alert(error.response.data.error)
              } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
                alert(error.request)
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
                alert(error.message)
              }
        }
    }

    translateLocationType(locationType) {
        let typesList = {
            "location_types": [
                { "en": "Street", "pt": "Rua" },
                { "en": "Neighborhood", "pt": "Bairro" },
                { "en": "All", "pt": "Endereço Completo" },
            ]
        }
        let response = typesList.location_types.filter( item => item.en === locationType )
        if ( response.length > 0 )
            return response[0].pt
        else
            return locationType
    }
    
    translatePlanType(planType) {
        let typesList = {
            "plans": [
              { "en": "STANDARD", "pt": "Padrão" },
              { "en": "PREMIUM", "pt": "Destaque" },
              { "en": "SUPER_PREMIUM", "pt": "Super Destaque" },
              { "en": "PREMIERE_1", "pt": "Destaque Premium" },
              { "en": "PREMIERE_2", "pt": "Destaque Especial" }
            ]
          }
        let response = typesList.plans.filter( item => item.en === planType )
        if ( response.length > 0 ) {
           // alert( response[0].pt )
            return response[0].pt
        }  else {
            console.log( planType )
            return planType
        }
    }
}

export default new ListingInMemory( );