import { Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControlLabel, MenuItem, TextField } from '@mui/material'
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import Geocode from 'react-geocode';
import { AuthContext } from '../contexts/AuthContext';
import placeholder from '../assets/imgs/no-image.jpg'
import { BASE_URL, SOUKW_URL } from '../sharedComponents/Constants';

export default function NewUserModal({ open, close }) {
    const { token, user, logout } = useContext(AuthContext);

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [permissionLevel, setPermissionLevel] = useState('');
    const [permissionLevelTreinamentos, setPermissionLevelTreinamentos] = useState('');
    const [kwuid, setKwuid] = useState('');
    const [mc, setMc] = useState('');
    const [mcs, setMcs] = useState([]);
    const [team, setTeam] = useState([]);
    const [teams, setTeams] = useState([]);

    useEffect(() => {
        async function getData() {
            try {
                const config = {
                    headers: { Authorization: `Bearer ${token}` }
                };
                let results = await axios.get(BASE_URL + '/mcs/', config);
                setMcs(results.data.data)
            } catch (e) {
                console.error(e);
                if (e?.response?.data?.msg)
                    alert(e?.response?.data?.msg)
                else {
                    alert('Erro de Conexão.');
                    logout();
                }
            }
        }
        getData();
    }, []);

    useEffect(() => {
        async function getData() {
            if (mc) {
                try {
                    const config = {
                        headers: { Authorization: `Bearer ${token}` }
                    };
                    let results = await axios.get(BASE_URL + '/teams/by_mc_id/' + mc, config);
                    setTeams(results.data)
                } catch (e) {
                    console.error(e);
                    if (e?.response?.data?.msg)
                        alert(e?.response?.data?.msg)
                    else {
                        alert('Erro de Conexão.');
                        logout();
                    }
                }
            }
        }
        getData();

    }, [mc]);


    const submit = async () => {
        if (!name || !email || !password || !permissionLevel || !kwuid || !mc || !permissionLevelTreinamentos) {
            alert('Preencha todos os campos.');
            return;
        }
        try {
            const config = {
                headers: { Authorization: `Bearer ${token}` }
            };
            let json = { fullName: name, email, password, permissionLevel, kwuid, marketCenterId: mc, teamId: team??'', permissionLevelTreinamentos }
            let res = await axios.post(BASE_URL + '/auth/register', json, config);
            if (res.data)
                alert('Cadastro bem sucedido!');
            close();
            document.location.reload()
        } catch (e) {
            console.error(e);
            if (e?.response?.data?.msg)
                alert(e?.response?.data?.msg)
            else {
                alert('Erro de Conexão.')
                logout();
            }
        }
    }
    return (
        <div>
            <Dialog open={open} onClose={close} maxWidth='sm'>
                <DialogTitle>
                    <div style={{ width: '500px' }}>
                        <h1>Cadastrar Novo Usuário</h1>
                        <Divider style={{ width: '100%' }} />
                    </div>
                </DialogTitle>
                <DialogContent>
                    <h6>Dados de Acesso</h6>
                    <br />
                    <TextField
                        variant='outlined'
                        label='Nome Completo *'
                        color='secondary'
                        fullWidth
                        style={{ marginTop: '5px', marginBottom: '5px' }}
                        onChange={(e) => setName(e.target.value)}
                        value={name}
                    />
                    <TextField
                        variant='outlined'
                        label='Email *'
                        color='secondary'
                        fullWidth
                        style={{ marginTop: '5px', marginBottom: '5px' }}
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                    />
                    <TextField
                        variant='outlined'
                        label='Senha Inicial *'
                        color='secondary'
                        fullWidth
                        type='password'
                        style={{ marginTop: '5px', marginBottom: '5px' }}
                        onChange={(e) => setPassword(e.target.value)}
                        value={password}
                    />
                    <TextField
                        variant='outlined'
                        label='KWUID *'
                        color='secondary'
                        fullWidth
                        style={{ marginTop: '5px', marginBottom: '5px' }}
                        onChange={(e) => setKwuid(e.target.value)}
                        value={kwuid}
                    />
                    <TextField
                        variant='outlined'
                        select
                        label='Market Center *'
                        color='secondary'
                        fullWidth
                        style={{ marginTop: '5px', marginBottom: '5px' }}
                        onChange={(e) => setMc(e.target.value)}
                        value={mc}
                    >
                        {mcs?.map(marketCenter => {
                            return (
                                <MenuItem value={marketCenter._id}>{marketCenter.name}</MenuItem>
                            )
                        })}
                    </TextField>
                    <TextField
                        variant='outlined'
                        select
                        label='Time '
                        color='secondary'
                        fullWidth
                        disabled={teams?.length === 0}
                        style={{ marginTop: '5px', marginBottom: '5px' }}
                        onChange={(e) => {
                            // console.log(e.target.value)
                            setTeam(e.target.value)
                        }}
                        value={team}
                    >
                        <MenuItem value={-1}>Nenhum</MenuItem>
                        {teams?.map(t => {
                            return (
                                <MenuItem value={t._id}>{t.name}</MenuItem>
                            )
                        })}
                    </TextField>
                    <TextField
                        variant='outlined'
                        select
                        label='Função *'
                        color='secondary'
                        fullWidth
                        style={{ marginTop: '5px', marginBottom: '5px' }}
                        onChange={(e) => setPermissionLevel(e.target.value)}
                        value={permissionLevel}
                    >
                        {(user.permissionLevel === 'ADMIN' || user.permissionLevel === 'SUPER') &&
                            <MenuItem value='ADMIN'>Admin</MenuItem>
                        }

                        <MenuItem value='AGENT'>Agente</MenuItem>
                    </TextField>
                    <TextField
                        variant='outlined'
                        select
                        label='Permissão de Treinamentos *'
                        color='secondary'
                        fullWidth
                        style={{ marginTop: '5px', marginBottom: '5px' }}
                        onChange={(e) => setPermissionLevelTreinamentos(e.target.value)}
                        value={permissionLevelTreinamentos}
                    >
                        {user.permissionLevel !== 'AGENT' &&
                            <MenuItem value='LEADERSHIP'>Liderança</MenuItem>
                        }

                        <MenuItem value='AGENT'>Agente</MenuItem>
                    </TextField>

                </DialogContent>
                <DialogActions>
                    <button className='btn btnSecondary' onClick={close}>Cancelar</button>
                    <button className='btn btnPrimary' onClick={submit}>Cadastrar</button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
