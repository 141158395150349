/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable default-case */
import { Autocomplete,
    Grid,
    Checkbox,
    Dialog, 
    DialogActions, 
    DialogContent, 
    DialogTitle, 
    Divider,
    Switch, 
    FormControlLabel, 
    MenuItem, 
    TextField } from '@mui/material'
import TextModal from '../sharedComponents/listings/TextModal'
import React, { useContext, useEffect, useState } from 'react'
import Geocode from 'react-geocode';
import { AuthContext } from '../contexts/AuthContext';
import XMLFilesContext from '../contexts/XMLFilesContexts';
import { useNavigate } from 'react-router-dom';
import SingleFileUpload from '../sharedComponents/SingleFileUpload';
import listingInMemory from '../libs/services/listingInMemory';
import { BASE_URL, SOUKW_URL } from '../sharedComponents/Constants';

export default function DeleteLoteIntegracaoPortal({ open, close }) {
    const { token, user, logout, jwtUser } = useContext(AuthContext);
    const { files, 
        selectedFile,
        setSelectedFile,
        addFile,
        removeFile,
        updateFileList,
        xmlListings,
        updateXmlListings,
        saveXmlListings,
        deleteXmlListing,
        addXmlListing,
        clearXmlListings,
        reloadXmlListings,
        clearListings,
        deleteAdd,
        xmlCurrentFileSize,
        setXmlCurrentFileSize,
        listingsInsideXML,
        setListingsInsideXML,
        getXMLFileSize
        } = useContext( XMLFilesContext )

    const navigate = useNavigate();

    const [confirmation, setConfirmation] = useState('');
    const [locationType, setLocationType] = useState( '' );

    const submit = async () => {
        
        try{
            let res = await listingInMemory.disableSelectedAdds( token, selectedFile )
            let count = await getXMLFileSize( token )
            if( res )
                alert("listings atualizados com sucesso")
        } catch ( err ) {
            alert( err.message )
        } finally {
            setConfirmation( false )
            close( )
        }
    }

    return (
        <div>
            <Dialog open={open} onClose={close} maxWidth='sm'>
                <DialogTitle>
                    <div style={{ width: '500px' }}>
                        <h1>Deseja realmente deletar os anúncios selecionados?</h1>
                        <Divider style={{ width: '100%' }} />
                    </div>
                </DialogTitle>
                <DialogContent>

                <Grid container 
                    spacing={0} 
                    style={{ height: '100%' }} 
                    justifyItems='flex-start' 
                    alignItems={'flex-start'}
                >
                        <Grid item xs={8} sm={8} style={{paddingBottom: '1rem'}} >
                            <TextField
                                fullWidth
                                variant='standard'
                                value={confirmation}
                                onChange={ e => {
                                    console.log( e.target.value )

                                    setConfirmation( e.target.value )} }
                                label='Digite "confirmo" no campo abaixo'
                                placeholder='confirmo'
                            />
                        </Grid>
                    </Grid>
                    
                </DialogContent>
                <DialogActions>
                    <button className='btn btnSecondary' onClick={close}>Cancelar</button>
                    <button className='btn' onClick={submit}  disabled={confirmation === 'confirmo'?false:true}>Deletar</button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
