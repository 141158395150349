//let create a component to load the Listings data from the api and show it in a list usgin react-material
import { Chip, Grid, Switch, Fab, IconButton, Tooltip } from '@mui/material';
import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { maskCurrency } from '../../libs/utils';
import ListItemField from './ListItemField';
import { AuthContext } from '../../contexts/AuthContext';
import XMLFilesContext from '../../contexts/XMLFilesContexts';

import EditIntegracaoPortal from '../../views/EditIntegracaoPortalZAP';
//import {AddIcon, EditIcon} from '@mui/icons-material';
//import EditIcon from '@mui/icons-material/Edit';
import { Remove, Edit, Delete } from '@mui/icons-material';
import axios from 'axios';
import { BASE_URL, SOUKW_URL } from '../Constants';

const addColors = {
    'Padrao': 'info',
    'Destaque': 'success',
    'Super Destaque': 'warning',
    'Destaque Premium': 'error',
    'Destaque Especial': 'primary',
}

function ListingItem(props) {

    const { user, logout, token } = useContext(AuthContext);
    const { files, 
        selectedFile,
        setSelectedFile,
        addFile,
        removeFile,
        updateFileList,
        xmlListings,
        updateXmlListings,
        saveXmlListings,
        deleteXmlListing,
        addXmlListing,
        clearXmlListings,
        reloadXmlListings,
        clearListings,
        deleteAdd,
        xmlCurrentFileSize,
        setXmlCurrentFileSize,
        listingsInsideXML,
        setListingsInsideXML,
        getXMLFileSize
        } = useContext( XMLFilesContext )

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    console.log("Props: ", props)
    const [listing, setListing] = useState(props.listing);
    const listingMC = listing.integracaoZapImoveisMC?.filter( el => el.fileName === selectedFile && el.Active === true)
    const listingTime = listing.integracaoZapImoveisTime?.filter( el => el.fileName === selectedFile && el.Active === true)
    const listingAgente = listing.integracaoZapImoveisAgente?.filter( el => el.fileName === selectedFile && el.Active === true)

    const [editIntegracaoPortal, setEditIntegracaoPortalOpen] = useState(false);
    const [backgroundColor, setBackgroundColor] = useState(props.backgroundColor);
    const [listingSelecionado, setListingSelecionado] = useState( props.selected )
    const [showDeleteBtnMC, setShowDeleteBtnMC] = useState( listingMC.length > 0 )
    const [showDeleteBtnTM, setShowDeleteBtnTM] = useState( listingTime.length > 0 )
    const [showDeleteBtnAG, setShowDeleteBtnAG] = useState( listingAgente.length > 0 )

    const [addColorsMC, setAddColorsMC] = useState( addColors[`${listing.integracaoZapImoveisMC?.planType}`] ?? 'info' )
    const [addColorsTeam, setAddColorsTeam] = useState( addColors[`${listing.integracaoZapImoveisTeam?.planType}`] ?? 'info' )
    const [addColorsAgente, setAddColorsAgente] = useState( addColors[`${listing.integracaoZapImoveisAgente?.planType}`] ?? 'info' )



    const openEditIntegracaoPortal = ( ) => {
        setEditIntegracaoPortalOpen( true )
    }
    const closeEditIntegracaoPortal = () => {
        setEditIntegracaoPortalOpen(false)
    }

    const disbleAdd = async ( e, doc ) => {
        console.log( "Disabling Add" )
        console.log( e )
        await deleteAdd(listing._id, selectedFile, token)
            switch( doc ) {
                case( 'mc' ):
                    setShowDeleteBtnMC( false );
                    break;
                case( 'time' ):
                    setShowDeleteBtnTM( false );
                    break;
                case( 'agente' ):
                    setShowDeleteBtnAG( false );
                    break;
            }
        await getXMLFileSize( token )
    }

    useEffect( ( ) => {
        setAddColorsMC( addColors[`${listingMC[0]?.planType}`] ?? 'info' );
        setAddColorsTeam( addColors[`${listingTime[0]?.planType}`] ?? 'info' );
        setAddColorsAgente( addColors[`${listingAgente[0]?.planType}`] ?? 'info' );
    }, [addColorsMC, showDeleteBtnMC ])

    return ( 
        <Grid key={listing._id} item xs={12}>
         <div style={{ backgroundColor: backgroundColor, 
                        width: '95%', 
                    }}>
            <Grid container 
                justifyItems='flex-start' 
                alignItems={'center'} 
                style={{ cursor: 'pointer' }}
            > 
            <Grid item xs={1}>
                <Switch
                    checked={props.checked}
                    onChange={props.onChecked}/>
            </Grid>
            <Grid item xs={2}>
                { listing.photos !== null && listing.photos !== undefined  && (listing.photos.length > 0) ?  <p><img 
                    src={listing.photos?.length > 0
                    ? listing.photos[0]?.ph_url
                    : listing.photos[0].ph_label} width='125px' height='75px'
                    style={{ objectFit: 'cover' }} alt={"Sem fotos"}
                />{listing.mls_number}</p> : <img 
                src=""
                style={{ objectFit: 'cover' }} alt='Undefined'
                />}
            </Grid>
                <ListItemField size='2' data={`${listing.list_address?.full_street_address} ${listing.list_address?.unit_number ?? ""} - ${listing.list_address?.city}`} />
                <ListItemField size='1' data={listing.list_type_id === 4 ? "SIM" : "NÃO"} />
                <ListItemField size='1' data={new Date(listing.list_dt).toISOString( ).split('T')[0].split('-').reverse().join('-')} />
                <ListItemField size='1' data={maskCurrency(listing.current_list_price)} />
                <ListItemField size='2' data={`${listing.list_agent_office?.list_agent_full_name ?? 'Agente não encontrado'}`} />
                
                <Grid item xs={1}>

                    <Fab color="secondary" aria-label="edit" size="small" onClick={openEditIntegracaoPortal}>
                        <Edit />
                    </Fab>
                </Grid>
                <Grid item xs={1}>
                    
                    {showDeleteBtnMC && (console.log( "cor: ", addColorsMC ) || true )  &&
                    <div>
                        <Tooltip title={listingMC[0]?.planType}>
                            <Chip color={addColorsMC}  label="MC" />
                        </Tooltip>
                        <IconButton color="warning" aria-label="edit" size="small"  onClick={(e) => disbleAdd( e, 'mc' ) }>
                            <Delete />
                        </IconButton>
                        <br/>
                    </div>  }
                    {showDeleteBtnTM && (console.log( "cor: ", addColorsTeam ) || true ) &&
                    <div>
                        <Tooltip title={listingTime[0]?.planType}>
                            <Chip color={addColorsTeam} label="Time" />
                        </Tooltip>
                        <IconButton  color="warning" aria-label="edit" size="small"  onClick={(e) => disbleAdd( e, 'time' ) }>
                            <Delete />
                        </IconButton >
                        <br/>
                    </div>  }
                    {showDeleteBtnAG && (console.log( "cor: ",addColorsAgente ) || true) &&
                    <div>
                        <Tooltip title={listingAgente[0]?.planType} >
                            <Chip color={addColorsAgente} label="Ag." />
                        </Tooltip>
                        <IconButton color="warning" aria-label="edit" size="small" onClick={(e) => disbleAdd( e, 'agente' ) }>
                            <Delete />
                        </IconButton>
                        <br/>
                    </div>  }
                </Grid>
            </Grid>
        </div>
        <EditIntegracaoPortal open={editIntegracaoPortal} close={closeEditIntegracaoPortal} lst={listing} />
        </Grid >
        )
}

export default ListingItem;