// FileProvider.js
import React, { useState } from 'react';
import XMLFilesContext from './XMLFilesContexts';
import axios from 'axios';
import {BASE_URL} from '../libs/constants';
import listingInMemory from '../libs/services/listingInMemory';
import { getGrowthData, saveGrowthData, deleteGrowthData, updateGrowthData } from '../libs/services/fetchGrowthData';

    /**
 * Receives a listing object, returned from the API, and returns a reduced form of the object.
 * @param {Object} lst 
 * @returns {Object}
 */
    const manipulateData = (lst) => {
      //This function extracts the data from the listing and returns a more usable format,
      //with the interesting fields.
      lst.photos = [lst.photos.length > 0 ? lst.photos[0] : null];
      const {
          _id,
          list_address,
          list_type_id,
          list_dt,
          current_list_price,
          list_agent_office,
          mls_number,
          photos,
          integracaoZapImoveisMC,
          integracaoZapImoveisTeam,
          integracaoZapImoveisAgente
      } = lst;
  
      return {
          _id,
          list_address,
          list_type_id,
          list_dt,
          current_list_price,
          list_agent_office,
          mls_number,
          photos,
          integracaoZapImoveisMC,
          integracaoZapImoveisTeam,
          integracaoZapImoveisAgente
      }
  }
  
  const handleError = (error) => {
      // handle error using dedicated error handling service or middleware
      // e.g. send error to a logging service
      // e.g. display error message to the user
      alert(`Erro ao obter listings: ${error}: `);
  }


const XMLFilesProvider = ({ children }) => {
  const [files, setFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [xmlListings, setXmlListings] = useState([]);
  const [xmlCurrentFileSize, setXmlCurrentFileSize] = useState( 0 )
  const [listingsInsideXML, setListingsInsideXML] = useState( 0 )

  //Growth Provider data
  const [growthData, setGrowthData] = useState([]);
  const [growthDataLoaded, setGrowthDataLoaded] = useState(false);
  const [growthDataRows, setGrowthDataRows] = useState([]);
  const [dataSets, setDataSets] = useState([]);

  //functions to handle the XML file list
  const axiosConfig = ( token ) => { return {
      headers: { Authorization: `Bearer ${token}` }
      }
  };

  async function syncGrowthData(token) {
    const growthResult = await getGrowthData(token);
    setGrowthData(growthResult);
    setGrowthDataLoaded(true);
    setGrowthDataRows(growthResult.map( (item, index) => {
        return {
          id: index,
         ...item,
          dataEdicao: item.DataEdicao?.split('T')[0], // Format the date
        }
    }));
  }

  async function saveGrowthDataLine(token, line) {
    const lineReturn = await saveGrowthData(token, line);
    await syncGrowthData(token);
    return lineReturn;
  }

  async function deleteGrowthDataLine(token, line) {
    const lineReturn = await deleteGrowthData(token, line);
    await syncGrowthData(token);
    return lineReturn;
  }

  async function editGrowthDataLine(token, line) {
    const lineReturn = await updateGrowthData(token, line);
    await syncGrowthData(token);
    return lineReturn;
  }

  const updateFileList = async ( userData, token ) => {
    const user = { ...userData }
    const conf = axiosConfig( token )
    console.log( "updating files" )
    //const lfiles = []
    let result = {}
    console.log("permission level:" , user.permissionLevel)
    //alert(BASE_URL + '/mcs/getMCXMLs' + JSON.stringify(conf))
    switch( user.permissionLevel ) {
        
        case 'SUPER':
            result = await axios.get(`${BASE_URL}/mcs/getMCXMLs`, conf );
            console.log( "Super Files: ", result.data )
            //lfiles = result.data.concat();
            break;
        case 'ADMIN':
            result = await axios.get(`${BASE_URL}/mcs/getMCXMLs`, conf );
            console.log( "MCA Files: ", result.data )
            //lfiles = result.data.concat();
            break;
        case 'AGENT':
          if( user.isTeamLeader || user.isTeamAdmin ) { //lfiles = result.data.concat();
            result = await axios.get(`${BASE_URL}/teams/getTeamXMLs`, conf );
            console.log( "Team Files: ", result.data )
          } else {
            result = await axios.get(`${BASE_URL}/users/getUserXMLs`, conf );
            console.log( "Agente Files: ", result.data )
          }      
          break;
        case 'Agent':
          if( user.isTeamLeader || user.isTeamAdmin ) { //lfiles = result.data.concat();
            result = await axios.get(`${BASE_URL}/teams/getTeamXMLs`, conf );
            console.log( "Files: ", result.data )
          } else {
            result = await axios.get(`${BASE_URL}/users/getUserXMLs`, conf );
            console.log( "Files: ", result.data )
          }  
          //lfiles = result.data.concat();
          break;
        default:
            break;
    }
    setFiles( result.data )
    //return files
  }

  const addFile = async (fileName, token) => {
    console.log( "File name is: ", fileName )
    const conf = axiosConfig( token )
    let result = await axios.post(`${BASE_URL}/integracaoZapImoveis/createXMLFile`, {fileName}, conf );
    console.log(result)
    setFiles(prevFiles => [...prevFiles, fileName]);
  };

  const removeFile = (fileName, token) => {
    setFiles(prevFiles => prevFiles.filter(file => file !== fileName));
  };

  //Functions to deal with selected Listings

   /**
 * Fetches listings from API using a filterin URL and updates the listings context.
 * Handles error scenarios and updates the listings state.
 * 
 * @param {string} filterUrl - The filter to fetch listings from.
 * @returns {Promise<void>} - None. The function updates the listings context and state.
 */
   const updateXmlListings = async (filterUrl, token) => {

        const axiosConfig = {
            headers: { Authorization: `Bearer ${token}` }
        };

        let url = `${BASE_URL}${filterUrl}`
        try {
            let results = await axios.get(
                url,
                axiosConfig
            );
            if (results.status >= 200 && results.status < 300) {
                listingInMemory.reloadListings(results.data.data.map(manipulateData));
            } else {
                // Handle non-2xx statuses appropriately
                console.log(`Erro ao obter listings: ${BASE_URL}${filterUrl}: `, results.data.message);
                alert(`Erro ao obter listings: ${BASE_URL}${filterUrl}: `, results.data.message);
                // Handle error scenario
                // ...
            }
        } catch (error) {
            console.log(error);
            handleError(error);
        } finally {
            setXmlListings(listingInMemory.getListings());
        }
    }

    const saveXmlListings = async (
        token, 
        planType = 'Padrão',
        locationType = 'Endereço Completo',
        bairroComercial = '',
        suites = 0,
        titulo = '',
        fileName = ''
    ) => {

        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };

        let payload =  {
            locationType,
            planType,
            Active: true,
            bairroComercial,
            suites,
            title: titulo,
            fileName
        }

        Object.keys(payload).forEach( key => {
            if( !payload[key] )
                delete payload[key]
        })

        let data = xmlListings.map( ( lst ) => {
            return {
                ...payload,
                _id: lst._id,
            }
        } )
        const url = `${BASE_URL}/integracaoZapImoveis/addListing`
        try {
            let results = await axios.post( url, data, config );
            if (results.status >= 200 && results.status < 300) {
                alert("Anúncio adicionado com sucesso!")
            } else {
                // Handle non-2xx statuses appropriately
                console.log(`Erro ao salvar listings`, results.data.message);
                alert(`Erro ao salvar listings: `, results.data.message);
                // Handle error scenario
                // ...
            }
        } catch (error) {
            console.log(error);
            handleError(error);
        } finally {
            
        }
    }

    function addXmlListing ( lst ) {
        console.log("Adding listings: ", lst);
        let listing = manipulateData(lst);
        listingInMemory.addListing( listing );
        setXmlListings(listingInMemory.getListings());
    }

    const deleteXmlListing = ( id ) => {
        listingInMemory.deleteListing(id);
        setXmlListings(listingInMemory.getListings());
    }

    const clearXmlListings = ( ) => {
        listingInMemory.clearListings();
        setXmlListings(listingInMemory.getListings());
    }

    const reloadXmlListings = (lsts) => {
        let listings = lsts.map( (lst) => {
            return manipulateData(lst)
        } )
        listingInMemory.reloadListings(listings);
        setXmlListings(listingInMemory.getListings());
    }

    const clearListings = () => {
        listingInMemory.clearListings();
        setXmlListings(listingInMemory.getListings());
    }

    const deleteAdd = async (id, file, token) => {
        const axiosConfig = {
            headers: { Authorization: `Bearer ${token}` }
        };
        let url = `${BASE_URL}/integracaoZapImoveis/disableAdds`;
        let data = {
            _id: id,
            fileName: file
        }
        try {
            const results = await axios.post( url, [data], axiosConfig );
            if (results.status >= 200 && results.status < 300) {
                alert("Anúncio removido com sucesso!")
            } else {
                // Handle non-2xx statuses appropriately
                console.log(`Erro ao salvar listings`, results.data.message);
                alert(`Erro ao salvar listings: `, results.data.message);
            }
        } catch (error) {
            console.log(`Erro ao salvar listings`, error);
            alert(`Erro ao salvar listings: `, error);
        } finally {

        }
    }

const providerValues = {
    xmlListings,
    updateXmlListings,
    saveXmlListings,
    deleteXmlListing,
    addXmlListing,
    clearXmlListings,
    reloadXmlListings,
    clearListings,
    deleteAdd,
    growthData,
    dataSets,
    growthDataRows,
    growthDataLoaded,
    syncGrowthData,
    saveGrowthDataLine,
    deleteGrowthDataLine,
    editGrowthDataLine,
    setGrowthDataLoaded,
    setGrowthData,
    setGrowthDataRows,
    setDataSets,
    
}

//Fiunctions to get XMLSize

async function getXMLFileSize( token ) {
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };
    let xmlFileSize = 0
    if( selectedFile )
        xmlFileSize = await axios.get(BASE_URL + '/integracaoZapImoveis/getXMLFileSize/' + (selectedFile ?? '')  , config)
    let xmlSize = await axios.get(BASE_URL + '/integracaoZapImoveis/getXMLSize', config)
    console.log( 'AllXmlSize:' , xmlSize)
    console.log( 'xmlFileSize:' , xmlFileSize)
    setXmlCurrentFileSize( xmlFileSize.data.xmlSize )
    setListingsInsideXML( xmlSize.data.xmlSize )
}

const xmlFileProvider = {
    getXMLFileSize,
    setXmlCurrentFileSize,
    setListingsInsideXML,
    xmlCurrentFileSize,
    listingsInsideXML,
}

  return (
    <XMLFilesContext.Provider value={{ files,
        selectedFile,
        xmlListings,
        setSelectedFile,
        addFile,
        removeFile,
        updateFileList,
        ...providerValues,
        ...xmlFileProvider }}>
      {children}
    </XMLFilesContext.Provider>
  );
};

export default XMLFilesProvider;
