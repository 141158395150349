import { Grid, MenuItem, TextField, Zoom } from '@mui/material';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from "react-router-dom";
import { AuthContext } from '../contexts/AuthContext';
import { BASE_URL, SOUKW_URL } from '../sharedComponents/Constants';


export default function EditUser() {
    const { token, logout, jwtUser } = useContext(AuthContext);
    const { id } = useParams();
    let navigate = useNavigate();
    const [user, setUser] = useState([]);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [permissionLevel, setPermissionLevel] = useState('');
    const [permissionLevelTreinamentos, setPermissionLevelTreinamentos] = useState('');
    const [status, setStatus] = useState('');
    const [kwuid, setKwuid] = useState('');
    const [mc, setMc] = useState('');
    const [mcs, setMcs] = useState([]);
    const [team, setTeam] = useState([]);
    const [teams, setTeams] = useState([]);

    const [password, setPassword] = useState('');
    const [changingPass, setChangingPass] = useState(false);

    const userCanEdit = ( jwtUser ) => {
        return (jwtUser.permissionLevel === 'ADMIN' || jwtUser.permissionLevel === 'SUPER')
    }

    useEffect(() => {
        async function getData() {
            try {
                const config = {
                    headers: { Authorization: `Bearer ${token}` }
                };
                let mcsResults = await axios.get(BASE_URL + '/mcs/', config);
                setMcs(mcsResults.data.data)
                let results = await axios.get(BASE_URL + '/users/user/' + id, config);
                let usr = results.data;
                setUser(usr)
                console.log(usr)
                setName(usr.fullName)
                setStatus(usr.status)
                setEmail(usr.email)
                setKwuid(usr.kwuid)
                setMc(usr.marketCenterId)
                setTeam(usr.teamId)
                setPermissionLevel(usr.permissionLevel)
                setPermissionLevelTreinamentos(usr.permissionLevelTreinamentos);
            } catch (e) {
                console.error(e);
                if (e?.response?.data?.msg)
                    alert(e?.response?.data?.msg)
                else {
                    alert('Erro de Conexão.')
                    //logout();
                }
            }
        }
        getData();
    }, []);


    useEffect(() => {
        async function getData() {
            if (mc) {
                try {
                    const config = {
                        headers: { Authorization: `Bearer ${token}` }
                    };
                    let results = await axios.get(BASE_URL + '/teams/by_mc_id/' + mc, config);
                    setTeams(results.data)
                } catch (e) {
                    console.error(e);
                    if (e?.response?.data?.msg)
                        alert(e?.response?.data?.msg)
                    else {
                        alert('Erro de Conexão.');
                        logout();
                    }
                }
            }
        }
        getData();

    }, [mc]);

    const submit = async () => {
        if (!name || !email || !permissionLevel || !kwuid || !mc || !status || !permissionLevelTreinamentos) {
            alert('Preencha todos os campos.');
            return;
        }
        try {
            const config = {
                headers: { Authorization: `Bearer ${token}` }
            };
            let json = { fullName: name, email, permissionLevel, kwuid, marketCenterId: mc, status, teamId: team, permissionLevelTreinamentos }
            let res = await axios.patch(BASE_URL + '/users/' + id, json, config);
            if (res.data)
                alert('Usuário editado com sucesso!');
            navigate(-1);
        } catch (e) {
            console.error(e);
            if (e?.response?.data?.msg)
                alert(e?.response?.data?.msg)
            else {
                alert('Erro de Conexão.')
                logout();
            }
        }
    }
    const submitPass = async () => {
        if (!password) {
            alert('Preencha a nova senha.');
            return;
        }
        try {
            const config = {
                headers: { Authorization: `Bearer ${token}` }
            };
            let json = { password }
            let res = await axios.patch(BASE_URL + '/auth/password-change/' + id, json, config);
            if (res.data)
                alert('Senha alterada com sucesso!');
            navigate(-1);
        } catch (e) {
            console.error(e);
            if (e?.response?.data?.msg)
                alert(e?.response?.data?.msg)
            else {
                alert('Erro de Conexão.')
                logout();
            }
        }
    }
    return (
        <Grid container spacing={0} style={{ height: '100%' }} justifyItems='center' alignItems={'center'}>
            <Grid item xs={12} sm={10} style={{ textAlign: 'left', }}>
                <button className='btn btnSecondary' onClick={() => navigate(-1)}>
                    Voltar
                </button>
            </Grid>
            <div>
                <button className='btn btnPrimary' onClick={() => navigate('/soukw/users/history/' + user?._id)}>
                    Ver Histórico
                </button>
            </div>
            <Grid item xs={12}>
                <br />
            </Grid>
            <Grid item xs={12} sm={10} style={{ textAlign: 'left', }}>
                <h1>Editar Usuário {name}</h1>
            </Grid>
            <Grid item xs={12} >
                <br />
            </Grid>
            <Grid container justifyContent={'center'} className='card' style={{ padding: '20px', marginRight: '50px' }}>
                <Grid item xs={12}>
                    <h6>Dados de Acesso</h6>
                </Grid>
                <Grid item xs={12}>
                    <br />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        variant='outlined'
                        label='Nome Completo *'
                        color='secondary'
                        fullWidth
                        style={{ marginTop: '5px', marginBottom: '5px' }}
                        onChange={(e) => setName(e.target.value)}
                        value={name}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        variant='outlined'
                        label='Email *'
                        color='secondary'
                        fullWidth
                        style={{ marginTop: '5px', marginBottom: '5px' }}
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        variant='outlined'
                        label='KWUID *'
                        color='secondary'
                        fullWidth
                        style={{ marginTop: '5px', marginBottom: '5px' }}
                        onChange={(e) => setKwuid(e.target.value)}
                        value={kwuid}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        variant='outlined'
                        select
                        label='Status *'
                        color='secondary'
                        fullWidth
                        disabled={!userCanEdit(jwtUser)}
                        style={{ marginTop: '5px', marginBottom: '5px' }}
                        onChange={(e) => setStatus(e.target.value)}
                        value={status}
                    >
                        <MenuItem value={'INATIVO'}>Inativo</MenuItem>
                        <MenuItem value={'ATIVO'}>Ativo</MenuItem>
                    </TextField>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        variant='outlined'
                        select
                        label='Market Center *'
                        color='secondary'
                        fullWidth
                        style={{ marginTop: '5px', marginBottom: '5px' }}
                        onChange={(e) => setMc(e.target.value)}
                        value={mc}
                    >
                        {mcs?.map(marketCenter => {
                            return (
                                <MenuItem value={marketCenter._id}>{marketCenter.name}</MenuItem>
                            )
                        })}
                    </TextField>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        variant='outlined'
                        select
                        label='Time *'
                        color='secondary'
                        fullWidth
                        style={{ marginTop: '5px', marginBottom: '5px' }}
                        onChange={(e) => setTeam(e.target.value)}
                        value={team}
                    >
                        {teams?.map(t => {
                            return (
                                <MenuItem value={t._id}>{t.name}</MenuItem>
                            )
                        })}
                    </TextField>
                </Grid>
                <TextField
                    variant='outlined'
                    select
                    label='Função *'
                    color='secondary'
                    fullWidth
                    style={{ marginTop: '5px', marginBottom: '5px' }}
                    disabled={!userCanEdit(jwtUser)}
                    onChange={(e) => setPermissionLevel(e.target.value)}
                    value={permissionLevel}
                >
                    {   jwtUser.permissionLevel === 'SUPER' &&
                        <MenuItem value='SUPER'>Super</MenuItem>}

                    <MenuItem value='ADMIN'>Admin</MenuItem>

                    <MenuItem value='AGENT'>Agente</MenuItem>
                </TextField>

                <TextField
                    variant='outlined'
                    select
                    label='Permissão de Treinamentos *'
                    color='secondary'
                    fullWidth
                    style={{ marginTop: '5px', marginBottom: '5px' }}
                    disabled={!userCanEdit(jwtUser)}
                    onChange={(e) => setPermissionLevelTreinamentos(e.target.value)}
                    value={permissionLevelTreinamentos}
                >
                    <MenuItem value='LEADERSHIP'>Liderança</MenuItem>
                    <MenuItem value='AGENT'>Agente</MenuItem>
                </TextField>

                <Grid item xs={12} style={{ textAlign: 'right' }}>
                    <button className='btn btnSecondary' onClick={() => setChangingPass(!changingPass)} style={{ marginRight: '5px' }}>
                        {!changingPass ? 'Alterar Senha' : 'Cancelar Alteração de Senha'}
                    </button>
                    <button className='btn btnPrimary' onClick={submit}>Salvar Usuário</button>
                </Grid>

            </Grid>
            <Grid container justifyContent={'right'} style={{ padding: '20px', marginRight: '50px' }}>
                <Grid item xs={12} style={{ textAlign: 'right' }}>
                    <Zoom in={changingPass} style={{ marginTop: '30px' }}>
                        <TextField
                            variant='outlined'
                            label='Nova Senha'
                            color='secondary'
                            type={'password'}
                            style={{ marginTop: '5px', marginBottom: '5px' }}
                            onChange={(e) => setPassword(e.target.value)}
                            value={password}
                        />
                    </Zoom>
                </Grid>
                <Grid item xs={12} style={{ textAlign: 'right' }}>
                    <Zoom in={changingPass}>
                        <button className='btn btnPrimary' onClick={submitPass}>Alterar</button>
                    </Zoom>
                </Grid>
            </Grid>
        </Grid>
    )
}
