const maskCurrency = (num = '.') => {
    let initial = String(num).split('.').join('').replace(/[^0-9.]/g, '');
    let formattedNum = initial;

    let format = [
       num,
       num,
       num,
       [formattedNum.slice(0, 1), '.', formattedNum.slice(1)].join(''),
       [formattedNum.slice(0, 2), '.', formattedNum.slice(2)].join(''),
       [formattedNum.slice(0, 3), '.', formattedNum.slice(3)].join(''),
       [formattedNum.slice(0, 1), '.', formattedNum.slice(1, 4), '.', formattedNum.slice(4)].join(''),
       [formattedNum.slice(0, 2), '.', formattedNum.slice(2, 5), '.', formattedNum.slice(5)].join(''),
       [formattedNum.slice(0, 3), '.', formattedNum.slice(3, 6), '.', formattedNum.slice(6)].join('')
    ]

    formattedNum = format[ initial.length - 1]
    return 'R$ ' + String(formattedNum).replace(/[^0-9.]/g, '').slice(0, 10)
}

const unmaskCurrency = (str) => {
    return String(str).replace(/[^0-9]/g, '');
}

module.exports = {
    maskCurrency,
    unmaskCurrency
}