import { useState, useContext, useEffect } from 'react';
import { LineChart } from '@mui/x-charts/LineChart';
import { Grid, Divider, Chip } from '@mui/material';
import XMLFilesContext from '../../contexts/XMLFilesContexts';
import { parseISO, startOfWeek, endOfWeek, eachDayOfInterval, eachWeekOfInterval, format, formatDate, lightFormat } from 'date-fns';


export default function RegionalChart( props ) {
    const [rendered, setRendered] = useState(false);
    const [lastYear, setLastYear] = useState([]);
    console.log("Regional Chart");
    //const [contatosMonth, setContatosMonth] = useState([]);
    const{title, series, labels} = {...props}
    const { 
      growthData,
      dataSets,
      growthDataRows,
      growthDataLoaded,
      setGrowthDataLoaded,
      setGrowthData,
      setGrowthDataRows,
      syncGrowthData,
      saveGrowthDataLine,
      setDataSets  } = useContext(XMLFilesContext);

    function createDataSets( ) {
        const currentData = new Date(Date.now());
        const pastTwelveMonths = new Date( Date.now() - 365 * 24 * 60 * 60 * 1000); // days * hours * minutes * seconds * milliseconds
        const currentYear = currentData.getFullYear();
       
        const currentMonth = currentData.getMonth() + 1;//+ 1; //Alterarar para + 1 antes de colocar em produção
        const previousMonth = currentData.getMonth();
        const currentDay = currentData.getDate();
  
        const lastMonth = pastTwelveMonths.getMonth() - 1;
        const lastYear = pastTwelveMonths.getFullYear();

        //Montar as semanas
        const lastEightWeeks = new Date( Date.now() - 60 * 24 * 60 * 60 * 1000);
        const startingWeeksFrom  = startOfWeek( parseISO( lastEightWeeks.toISOString() ), {weekStartsOn: 1} );
        const endingWeeksFrom = startOfWeek( parseISO( currentData.toISOString() ), { weekStartsOn: 1} );
        const weekArray = eachWeekOfInterval( {start: startingWeeksFrom, end: endingWeeksFrom}).map( (week) => {
            return lightFormat(week, 'yyyy-MM-dd')
        })


        const marketCenters = ['KW Frema', 'KW Fortes Guimaraes', 'KW ComVC', 'KW Vale'];

        const agentInitialSummary = {contratacoes: 0, reunioes: 0, contatos: 0}

        const agentSummaryInitialState = { 
          'R0': {...agentInitialSummary}, 
          'R1': {...agentInitialSummary}, 
          'R2': {...agentInitialSummary} 
        }


        const currentMonthData = ( MC ) => {
            const currentMonthData = growthDataRows.filter(
                (row) => row.month === currentMonth &&
                row.year === currentYear &&
                row.marketCenter === MC
            );
            
            const currentMonthDaySummaryObjects = [];
            const currentMonthAgentLevelSummary = {...agentSummaryInitialState }
            //Os DataSets que vão para os gráficos
            const contratacoesMonthDataSet = [];
            const reunioesMonthDataSet = [];
            const contatosMonthDataSet = [];

            //Fill currentMonthDaySummary with zeroes
            for( let i = 0; i < currentData.getDate(); i++ ) {
                currentMonthDaySummaryObjects[i] = {
                  contratacoes: 0,
                  reunioes: 0,
                  contatos: 0
                }
            }

            currentMonthData.forEach( el => {
                //console.log('El for Daily summary: ', el)
                currentMonthDaySummaryObjects[el.day - 1] = {
                  contratacoes: currentMonthDaySummaryObjects[el.day - 1]?.contratacoes === undefined ?
                    el.contratacoes : currentMonthDaySummaryObjects[el.day - 1]?.contratacoes + el.contratacoes,
                  reunioes: currentMonthDaySummaryObjects[el.day - 1]?.reunioes === undefined ?
                    el.reunioes : currentMonthDaySummaryObjects[el.day - 1]?.reunioes + el.reunioes,
                  contatos: currentMonthDaySummaryObjects[el.day - 1]?.contatos === undefined ?
                    el.contatos : currentMonthDaySummaryObjects[el.day - 1]?.contatos + el.contatos
                };
                //console.log('currentMonthDaySummaryObjects: ', currentMonthDaySummaryObjects)
                //console.log('Daily summary: ', currentMonthDaySummaryObjects[el.day])
                currentMonthAgentLevelSummary[el.nivelAgente] = {
                  contratacoes: currentMonthAgentLevelSummary[el.nivelAgente].contratacoes + el.contratacoes,
                  reunioes: currentMonthAgentLevelSummary[el.nivelAgente].reunioes + el.reunioes,
                  contatos: currentMonthAgentLevelSummary[el.nivelAgente].contatos + el.contatos,
                }
                //console.log('Daily summary: ', currentMonthDaySummaryObjects[el.day])
              }) 

            Object.keys(currentMonthDaySummaryObjects).forEach( day => {

                //console.log( currentMonthDaySummaryObjects );
      
              contratacoesMonthDataSet.push(currentMonthDaySummaryObjects[day].contratacoes);
              reunioesMonthDataSet.push(currentMonthDaySummaryObjects[day].reunioes);
              contatosMonthDataSet.push(currentMonthDaySummaryObjects[day].contatos);
              
            })

            const currentMonthTotalSums = {
                contratacoes: currentMonthAgentLevelSummary.R0.contratacoes + 
                    currentMonthAgentLevelSummary.R1.contratacoes + 
                    currentMonthAgentLevelSummary.R2.contratacoes,
                reunioes: currentMonthAgentLevelSummary.R0.reunioes + 
                    currentMonthAgentLevelSummary.R1.reunioes + 
                    currentMonthAgentLevelSummary.R2.reunioes,
                contatos: currentMonthAgentLevelSummary.R0.contatos + 
                    currentMonthAgentLevelSummary.R1.contatos + 
                    currentMonthAgentLevelSummary.R2.contatos
            }

            console.log("Size of series: ", MC);
            console.log("contratacoesMonthDataSet: ", contratacoesMonthDataSet.length)
            console.log("reunioesMonthDataSet: ", reunioesMonthDataSet.length)
            console.log("contatosMonthDataSet: ", contatosMonthDataSet.length)
            console.log("currentMonthAgentLevelSummary: ", currentMonthAgentLevelSummary.length)
            console.log("currentMonthDaySummaryObjects: ", currentMonthDaySummaryObjects.length)
            console.log("currentMonthTotalSums: ", currentMonthTotalSums.length)
            console.log("currentMonthDaySummaryObjects: ", currentMonthDaySummaryObjects)
            return {
                contratacoesMonthDataSet,
                reunioesMonthDataSet,
                contatosMonthDataSet,
                currentMonthAgentLevelSummary,
                currentMonthDaySummaryObjects,
                currentMonthTotalSums
            }
        }

        const lastTwelveMonthData = ( MC ) => {
            const lastTwelveMonthsData = growthDataRows.filter((row) => 
                ((row.year === currentYear && row.month <= currentMonth) || 
                ( row.year === lastYear && row.month >= lastMonth )) &&
                row.marketCenter === MC
            ); 

            const lastTwelveMonthsSummaryObjects = [];
            const lastTwelveMonthsAgentLevelSummary = {...agentSummaryInitialState }
            const contratacoesLastTwelveMonthsDataSet = [];
            const reunioesLastTwelveMonthsDataSet = [];
            const contatosLastTwelveMonthsDataSet = [];
            const weeklyAgentLevelSummary = {...agentSummaryInitialState}
            const weeklyDataSummary = {...agentInitialSummary }
            const contratacoesWeeklyDataSet = [];
            const reunioesWeeklyDataSet = [];
            const contatosWeeklyDataSet = [];

            const weekDataSet = weekArray.map( week => {
                console.log('Week for dataset: ' + week)
                return { [`${week}`]: [{...weeklyDataSummary}, {...weeklyAgentLevelSummary}]}
              });

            lastTwelveMonthsData.forEach( el => {

                weekArray.forEach( (week, index, weeks) => {
        
                    if( week ){
                      
                      if( index < weekArray.length - 1 ) {
                        
                        const [year, month, day] = week.split( '-' );
                        const [nyear, nmonth, nday] = weeks[index + 1].split( '-' );
                       // console.log("CURRENT WEEK:", year, month, day)
                       // console.log("NEXT WEEK:", nyear, nmonth, nday)
                        if( (parseInt(el.year) >= parseInt(year) && parseInt(el.year) <= parseInt(nyear)) ) { //checa o ano
                          if( parseInt(nmonth) > parseInt(month) ) { //Semana com a virade de mes
                            if( el.month === parseInt(nmonth) && el.day < parseInt(nday) ) {
                              weekDataSet[index][`${week}`][0].reunioes += el.reunioes;
                              weekDataSet[index][`${week}`][0].contatos += el.contatos;
                              weekDataSet[index][`${week}`][0].contratacoes += el.contratacoes;
                              weekDataSet[index][`${week}`][1][`${el.nivelAgente}`].reunioes += el.reunioes;
                              weekDataSet[index][`${week}`][1][`${el.nivelAgente}`].contatos += el.contatos;
                              weekDataSet[index][`${week}`][1][`${el.nivelAgente}`].contratacoes += el.contratacoes;
                            } else if( el.month === parseInt(month) && el.day >= parseInt(day)) {
                              weekDataSet[index][`${week}`][0].reunioes += el.reunioes;
                              weekDataSet[index][`${week}`][0].contatos += el.contatos;
                              weekDataSet[index][`${week}`][0].contratacoes += el.contratacoes;
                              weekDataSet[index][`${week}`][1][`${el.nivelAgente}`].reunioes += el.reunioes;
                              weekDataSet[index][`${week}`][1][`${el.nivelAgente}`].contatos += el.contatos;
                              weekDataSet[index][`${week}`][1][`${el.nivelAgente}`].contratacoes += el.contratacoes;
                            }
            
                          }
                          else if ( parseInt(el.month) >= parseInt(month) && parseInt(el.month) <= parseInt(nmonth) 
                              && ( parseInt(el.day) >= parseInt(day) && parseInt(el.day) < parseInt( nday ) )) {
                        //    console.log("index is: ", index)
                        //    console.log("Week is: ", week)
                           
                            //console.log( "Current day: ", el.day, el.month, el.year );
                        //    console.log("My el is: ", el );
                            weekDataSet[index][`${week}`][0].reunioes += el.reunioes;
                            weekDataSet[index][`${week}`][0].contatos += el.contatos;
                            weekDataSet[index][`${week}`][0].contratacoes += el.contratacoes;
                            weekDataSet[index][`${week}`][1][`${el.nivelAgente}`].reunioes += el.reunioes;
                            weekDataSet[index][`${week}`][1][`${el.nivelAgente}`].contatos += el.contatos;
                            weekDataSet[index][`${week}`][1][`${el.nivelAgente}`].contratacoes += el.contratacoes;
                        //    console.log('Week dataset is: ', weekDataSet[index])
                          }
            
                        }
                      }
                    }})

                lastTwelveMonthsSummaryObjects[`${el.month}/${el.year}`] = {
                  contratacoes: lastTwelveMonthsSummaryObjects[`${el.month}/${el.year}`]?.contratacoes === undefined ?
                   el.contratacoes : lastTwelveMonthsSummaryObjects[`${el.month}/${el.year}`]?.contratacoes + el.contratacoes,
                  reunioes: lastTwelveMonthsSummaryObjects[`${el.month}/${el.year}`]?.reunioes === undefined ?
                   el.reunioes : lastTwelveMonthsSummaryObjects[`${el.month}/${el.year}`].reunioes + el.reunioes,
                  contatos: lastTwelveMonthsSummaryObjects[`${el.month}/${el.year}`]?.contatos === undefined ? 
                   el.contatos : lastTwelveMonthsSummaryObjects[`${el.month}/${el.year}`].contatos + el.contatos
                } 
                //console.log( "Nivel Agente: ", el.nivelAgente, " Contratacoes: ", el.contratacoes, " Reunioes: ", el.reunioes )
                lastTwelveMonthsAgentLevelSummary[el.nivelAgente] = {
                  contratacoes: lastTwelveMonthsAgentLevelSummary[el.nivelAgente].contratacoes === undefined ?
                   el.contratacoes : lastTwelveMonthsAgentLevelSummary[el.nivelAgente].contratacoes + el.contratacoes,
                  reunioes: lastTwelveMonthsAgentLevelSummary[el.nivelAgente].reunioes === undefined ?
                   el.reunioes : lastTwelveMonthsAgentLevelSummary[el.nivelAgente].reunioes + el.reunioes,
                  contatos: lastTwelveMonthsAgentLevelSummary[el.nivelAgente].contatos === undefined ?
                   el.contatos : lastTwelveMonthsAgentLevelSummary[el.nivelAgente].contatos + el.contatos
                }
              })
        
              Object.keys(lastTwelveMonthsSummaryObjects).forEach(  month => {
               // console.log("MONTH is: ", month)
                contratacoesLastTwelveMonthsDataSet.push(lastTwelveMonthsSummaryObjects[month].contratacoes);
                reunioesLastTwelveMonthsDataSet.push(lastTwelveMonthsSummaryObjects[month].reunioes);
                contatosLastTwelveMonthsDataSet.push(lastTwelveMonthsSummaryObjects[month].contatos);
              })

              const lastTwelveMonthTotalSums = {
                contratacoes: lastTwelveMonthsAgentLevelSummary.R0.contratacoes + 
                    lastTwelveMonthsAgentLevelSummary.R1.contratacoes + 
                    lastTwelveMonthsAgentLevelSummary.R2.contratacoes,
                reunioes: lastTwelveMonthsAgentLevelSummary.R0.reunioes + 
                    lastTwelveMonthsAgentLevelSummary.R1.reunioes + 
                    lastTwelveMonthsAgentLevelSummary.R2.reunioes,
                contatos: lastTwelveMonthsAgentLevelSummary.R0.contatos + 
                    lastTwelveMonthsAgentLevelSummary.R1.contatos + 
                    lastTwelveMonthsAgentLevelSummary.R2.contatos,
              }

              //weekArray.pop( );
              weekArray.forEach( (week, index, array) => {
                contratacoesWeeklyDataSet.push( weekDataSet[index][`${week}`][0].contratacoes);
                reunioesWeeklyDataSet.push( weekDataSet[index][`${week}`][0].reunioes);
                contatosWeeklyDataSet.push( weekDataSet[index][`${week}`][0].contatos);
              })

              console.log( 'MC Week ' + contratacoesWeeklyDataSet)
              console.log( 'MC Week ' + reunioesWeeklyDataSet)
              console.log( 'MC Week ' + contatosWeeklyDataSet)

              return {
                contratacoesWeeklyDataSet,
                reunioesWeeklyDataSet,
                contatosWeeklyDataSet,
                weekArray,
                lastTwelveMonthsSummaryObjects,
                lastTwelveMonthsAgentLevelSummary,
                contratacoesLastTwelveMonthsDataSet,
                reunioesLastTwelveMonthsDataSet,
                contatosLastTwelveMonthsDataSet,
                lastTwelveMonthTotalSums
              }
        }

        
        return marketCenters.map( (MC) => {
            //console.log( "The big dataSet is: ", {...lastTwelveMonthData( MC), name: MC})
            return {...lastTwelveMonthData( MC), ...currentMonthData( MC ),name: MC, weekArray}
        })
    }

    
    const lastYearGraphData = createDataSets();
    console.log("The bigger even dataset is:", lastYearGraphData[0].contatosLastTwelveMonthsDataSet );

    useEffect( () => {
        setLastYear(createDataSets() )
        console.log("The bigger even dataset is:", lastYear );
    }, []);

    useEffect( () => {
        setLastYear(createDataSets() )
        console.log("The bigger even dataset is:", lastYear );
    }, [growthDataRows]);

      return(
        <>
<Divider>
        <Chip label="Contatos" size="small" />
</Divider>
        <Grid container spacing={3} justifyItems='flex-start' alignItems={'flex-start'}>
            <Grid item xs={4} sm={4} style={{ textAlign: 'left', }}>
                <h3>Contatos - 12 meses</h3>
                <LineChart
                    width={500}
                    height={300}
                    series={ lastYearGraphData.map( (el, index) => {
                        return {data: el.contatosLastTwelveMonthsDataSet, label: el.name === 'KW Fortes Guimaraes' ? 'KW FG' : el.name, id: index}
                    }) }
                    xAxis={[{ scaleType: 'point', 
                        data: Object.keys(lastYearGraphData.filter( el => el.name === 'KW Frema')[0].lastTwelveMonthsSummaryObjects) }]}
                />
            </Grid>
            <Grid item xs={4} sm={4} style={{ textAlign: 'left', }}>
                <h3>Contatos - Últimas Semanas</h3>
                <LineChart
                    width={500}
                    height={300}
                    series={ lastYearGraphData.map( (el, index) => {
                        return {
                        data: el.contatosWeeklyDataSet.slice(0, el.contatosWeeklyDataSet.length - 1),
                        label: el.name  === 'KW Fortes Guimaraes' ? 'KW FG' : el.name,
                        id: index}
                    })}
                    xAxis={[{ scaleType: 'point', 
                        data: lastYearGraphData[0].weekArray.slice(0, lastYearGraphData[0].weekArray.length - 1) }]}
                />
            </Grid>
            <Grid item xs={4} sm={4} style={{ textAlign: 'left', }}>
                    <table>
                        <tr><th>Contatos</th></tr>
                        <tr><th>MC</th><th>Histórico</th><th>Mês Atual</th></tr>

                            {lastYearGraphData.map( (el, index) => {
                                    return <tr>
                                        <th>{el.name === 'KW Fortes Guimaraes' ? 'KW FG' : el.name }</th>
                                        <td>{el.lastTwelveMonthTotalSums.contatos - el.currentMonthTotalSums.contatos}</td>
                                        <td>{el.currentMonthTotalSums.contatos}</td>
                                    </tr>
                                    
                                })
                            }

                    </table>
                {/* <h3>Totais - Histórico</h3>
                {lastYearGraphData.map( (el, index) => {
                            return <p>
                            {el.name}: {el.lastTwelveMonthTotalSums.contatos - el.currentMonthTotalSums.contatos}
                            </p>
                        })
                    }
                <h3>Totais - Mês Corrente</h3>
                {lastYearGraphData.map( (el, index) => {
                            return <p>
                            {el.name}: {el.currentMonthTotalSums.contatos}
                            </p>
                        })
                    } */}

            </Grid>
        </Grid>
<Divider>
        <Chip label="Reuniões" size="small" />
</Divider>
            <Grid container spacing={5} justifyItems='flex-start' alignItems={'flex-start'}>
                <Grid item xs={4} sm={4} style={{ textAlign: 'left', }}>
                    <h3>Reuniões - 12 meses </h3>
                    <LineChart
                        width={500}
                        height={300}
                        series={ lastYearGraphData.map( (el, index) => {
                            return {data: el.reunioesLastTwelveMonthsDataSet, label: el.name === 'KW Fortes Guimaraes' ? 'KW FG' : el.name, id: index}
                        }) }
                        xAxis={[{ scaleType: 'point', 
                            data: Object.keys(lastYearGraphData.filter( el => el.name === 'KW Frema')[0].lastTwelveMonthsSummaryObjects) }]}
                    />
                </Grid>
                <Grid item xs={4} sm={4} style={{ textAlign: 'left', }}>
                    <h3>Reuniões - Últimas Semanas</h3>
                    <LineChart
                    width={500}
                    height={300}
                    series={ lastYearGraphData.map( (el, index) => {
                        return {
                        data: el.reunioesWeeklyDataSet.slice(0, el.reunioesWeeklyDataSet.length - 1),
                        label: el.name  === 'KW Fortes Guimaraes' ? 'KW FG' : el.name,
                        id: index}
                    })}
                    xAxis={[{ scaleType: 'point', 
                        data: lastYearGraphData[0].weekArray.slice(0, lastYearGraphData[0].weekArray.length - 1) }]}
                />
                </Grid>
                <Grid item xs={4} sm={4} style={{ textAlign: 'left', }}>
                    <table>
                        <th>Reuniões</th>
                        <tr><th>MC</th><th>Histórico</th><th>Mês Atual</th></tr>

                            {lastYearGraphData.map( (el, index) => {
                                    return <tr>
                                        <th>{el.name === 'KW Fortes Guimaraes' ? 'KW FG' : el.name }</th>
                                        <td>{el.lastTwelveMonthTotalSums.reunioes - el.currentMonthTotalSums.reunioes}</td>
                                        <td>{el.currentMonthTotalSums.reunioes}</td>
                                    </tr>
                                    
                                })
                            }

                    </table>
                    {/* <h3>Totais - Histórico</h3>
                    {lastYearGraphData.map( (el, index) => {
                                return <p>
                                {el.name}: {el.lastTwelveMonthTotalSums.reunioes - el.currentMonthTotalSums.reunioes}
                                </p>
                            })
                        }
                    <h3>Totais - Mês Corrente</h3>
                    {lastYearGraphData.map( (el, index) => {
                                return <p>
                                {el.name}: {el.currentMonthTotalSums.reunioes}
                                </p>
                            })
                        } */}

                </Grid>
            </Grid>
<Divider>
        <Chip label="Contratações" size="small" />
</Divider>
            <Grid container spacing={5} justifyItems='flex-start' alignItems={'flex-start'}>
                <Grid item xs={4} sm={4} style={{ textAlign: 'left', }}>
                    <h3>Contratações - 12 meses </h3>
                    <LineChart
                        width={500}
                        height={300}
                        series={ lastYearGraphData.map( (el, index) => {
                            return {data: el.contratacoesLastTwelveMonthsDataSet, label: el.name === 'KW Fortes Guimaraes' ? 'KW FG' : el.name, id: index}
                        }) }
                        xAxis={[{ scaleType: 'point', 
                            data: Object.keys(lastYearGraphData.filter( el => el.name === 'KW Frema')[0].lastTwelveMonthsSummaryObjects) }]}
                    />
                </Grid>
                <Grid item xs={4} sm={4} style={{ textAlign: 'left', }}>
                    <h3>Contratações - Últimas Semanas</h3>
                    <LineChart
                    width={500}
                    height={300}
                    series={ lastYearGraphData.map( (el, index) => {
                        return {
                        data: el.contratacoesWeeklyDataSet.slice(0,  el.contratacoesWeeklyDataSet.length - 1),
                        label: el.name  === 'KW Fortes Guimaraes' ? 'KW FG' : el.name,
                        id: index}
                    })}
                    xAxis={[{ scaleType: 'point', 
                        data: lastYearGraphData[0].weekArray.slice(0, lastYearGraphData[0].weekArray.length - 1) }]}
                />
                </Grid>
                <Grid item xs={4} sm={4} style={{ textAlign: 'left', }}>
                <table>
                    <thead>
                        <tr><th>Contratações</th></tr>
                        <tr>
                            <th>MC</th>
                            <th>Histórico</th>
                            <th>Mês Atual</th>
                        </tr>
                    </thead>
                    <tbody>
                        {lastYearGraphData.map( (el, index) => {
                            return <tr>
                                <th>{el.name === 'KW Fortes Guimaraes' ? 'KW FG' : el.name }</th>
                                <td>{el.lastTwelveMonthTotalSums.contratacoes - el.currentMonthTotalSums.contratacoes}</td>
                                <td>{el.currentMonthTotalSums.contratacoes}</td>
                            </tr>
                        })
                    }
                    </tbody>
                </table>
                {/* <h3>Totais - Histórico</h3>
                {lastYearGraphData.map( (el, index) => {
                            return <p>
                            {el.name}: {el.lastTwelveMonthTotalSums.contratacoes - el.currentMonthTotalSums.contratacoes}
                            </p>
                        })
                    }
                <h3>Totais - Mês Corrente</h3>
                {lastYearGraphData.map( (el, index) => {
                            return <p>
                            {el.name}: {el.currentMonthTotalSums.contratacoes}
                            </p>
                        })
                    } */}

            </Grid>
            </Grid>
<Divider>
        <Chip label="Reuniões/Contatos" size="small" />
</Divider>
            <Grid container spacing={5} justifyItems='flex-start' alignItems={'flex-start'}>
                <Grid item xs={4} sm={4} style={{ textAlign: 'left', }}>
                    <h3>Reuniões/Contatos (%) - 12 meses </h3>
                    <LineChart
                    width={500}
                    height={300}
                        series={ lastYearGraphData.map( (el, index) => {
                            return {data: el.reunioesLastTwelveMonthsDataSet.map( ( reunioes, index) => {
                                return Math.round((reunioes / el.contatosLastTwelveMonthsDataSet[index] ) * 100)
                            }), label: el.name === 'KW Fortes Guimaraes' ? 'KW FG' : el.name, id: index}
                        }) }
                        xAxis={[{ scaleType: 'point', 
                            data: Object.keys(lastYearGraphData.filter( el => el.name === 'KW Frema')[0].lastTwelveMonthsSummaryObjects) }]}
                    />
                </Grid>
                <Grid item xs={4} sm={4} style={{ textAlign: 'left', }}>
                    <h3>Reuniões/Contatos (%) - Últimas Semanas</h3>
                    <LineChart
                        width={500}
                        height={300}
                        series={ lastYearGraphData.map( (el, index) => {
                            return {data: el.contatosWeeklyDataSet.map( (contatos, index) => {
                                if( contatos === 0)
                                    return 0
                                if( Number.isNaN(el.reunioesWeeklyDataSet[index] / contatos) )
                                    return 0
                                return Math.round((el.reunioesWeeklyDataSet[index] / contatos ) * 100)
                            }).slice(0, el.contatosWeeklyDataSet.length -1 ), label: el.name === 'KW Fortes Guimaraes' ? 'KW FG' : el.name, id: index}
                        }) }
                        xAxis={[{ scaleType: 'point', 
                            data: lastYearGraphData[0].weekArray.slice(0, lastYearGraphData[0].weekArray.length - 1)  }]}
                    />
                </Grid>
                <Grid item xs={4} sm={4} style={{ textAlign: 'left', }}>
                    <table>
                        <thead>
                            <tr><th>Reuniões/Contatos</th></tr>
                            <tr>
                                <th>MC</th><th>Histórico</th><th>Mês Atual</th>
                            </tr>
                        </thead>
                        <tbody>
                            {lastYearGraphData.map( (el, index) => {
                                return <tr>
                                    <th>{el.name === 'KW Fortes Guimaraes' ? 'KW FG' : el.name }</th>
                                    <td>{
                                        (((el.lastTwelveMonthTotalSums.reunioes - el.currentMonthTotalSums.reunioes)/
                                        (el.lastTwelveMonthTotalSums.contatos - el.currentMonthTotalSums.contatos))*100).toFixed(1)
                                        }%
                                    </td>
                                    <td>{((el.currentMonthTotalSums.reunioes / el.currentMonthTotalSums.contatos)*100).toFixed(1)}%</td>
                                </tr>
                            })
                        }
                        </tbody>
                    </table>
                    {/* <h3>Totais - Histórico</h3>
                    {lastYearGraphData.map( (el, index) => {
                                return <p>
                                {el.name}: {
                                (((el.lastTwelveMonthTotalSums.reunioes - el.currentMonthTotalSums.reunioes)/
                                (el.lastTwelveMonthTotalSums.contatos - el.currentMonthTotalSums.contatos))*100).toFixed(1)
                                }%
                                </p>
                            })
                        }
                    <h3>Totais - Mês Corrente</h3>
                    {lastYearGraphData.map( (el, index) => {
                                return <p>
                                {el.name}: {((el.currentMonthTotalSums.reunioes / el.currentMonthTotalSums.contatos)*100).toFixed(1)}%
                                </p>
                            })
                        } */}
                </Grid>
                </Grid>
<Divider>
        <Chip label="Contratações/Reuniões" size="small" />
</Divider>
            <Grid container spacing={5} justifyItems='flex-start' alignItems={'flex-start'}>
                <Grid item xs={4} sm={4} style={{ textAlign: 'left', }}>
                    <h3>Contratações/Reuniões (%) - 12 meses </h3>
                    <LineChart
                width={500}
                height={300}
                        series={ lastYearGraphData.map( (el, index) => {
                            return {data: el.reunioesLastTwelveMonthsDataSet.map( ( reunioes, index) => {
                                return Math.round((el.contratacoesLastTwelveMonthsDataSet[index] / reunioes) * 100)
                            }), label: el.name === 'KW Fortes Guimaraes' ? 'KW FG' : el.name, id: index}
                        }) }
                        xAxis={[{ scaleType: 'point', 
                            data: Object.keys(lastYearGraphData.filter( el => el.name === 'KW Frema')[0].lastTwelveMonthsSummaryObjects) }]}
                    />
                </Grid>
                {/* <Grid item xs={2} sm={2} style={{ textAlign: 'left', }}>
                    
                    <table>
                        <th>Totais - Histórico</th>
                    {lastYearGraphData.map( (el, index) => {
                                return <tr>
                                <th>{el.name}</th><td> {
                                (((el.lastTwelveMonthTotalSums.contratacoes - el.currentMonthTotalSums.contratacoes)/
                                (el.lastTwelveMonthTotalSums.reunioes - el.currentMonthTotalSums.reunioes))*100).toFixed(1)
                                }%</td>
                                </tr>
                            })
                        }
                    </table>
                </Grid> */}

                    <Grid item xs={4} sm={4} style={{ textAlign: 'left', }}>
                        <h3>Contratações/Reuniões (%) - Últimas Semanas</h3>
                        <LineChart
                            width={500}
                            height={300}
                            series={ lastYearGraphData.map( (el, index) => {
                                return {data: el.contatosWeeklyDataSet.map( (contatos, index) => {
                                    if( el.reunioesWeeklyDataSet[index] === 0)
                                        return 0
                                    if( Number.isNaN(el.contratacoesWeeklyDataSet[index] / el.reunioesWeeklyDataSet[index]) )
                                        return 0
                                    return Math.round((el.contratacoesWeeklyDataSet[index] / el.reunioesWeeklyDataSet[index] ) * 100)
                                }).slice(0, el.contatosWeeklyDataSet.length -1 ), label: el.name === 'KW Fortes Guimaraes' ? 'KW FG' : el.name, id: index}
                            }) }
                            xAxis={[{ scaleType: 'point', 
                                data: lastYearGraphData[0].weekArray.slice(0, lastYearGraphData[0].weekArray.length - 1)  }]}
                        />
                    </Grid>
                    <Grid item xs={4} sm={4} style={{ textAlign: 'left', }}>
                        <table>
                        <th>Contratações/Reuniões</th>
                        <tr><th>MC</th><th>Histórico</th><th>Mês Atual</th></tr>
                        {lastYearGraphData.map( (el, index) => {
                                    return <tr style={{borderBottom: '1px solid'}}>
                                        <th>{el.name === 'KW Fortes Guimaraes' ? 'KW FG' : el.name }</th> 
                                        <td> {
                                            (((el.lastTwelveMonthTotalSums.contratacoes - el.currentMonthTotalSums.contratacoes)/
                                            (el.lastTwelveMonthTotalSums.reunioes - el.currentMonthTotalSums.reunioes))*100).toFixed(1)
                                            }%
                                        </td>
                                        <td>{((el.currentMonthTotalSums.contratacoes / el.currentMonthTotalSums.reunioes)*100).toFixed(1)}%</td>
                                    </tr>
                                })
                            }
                        </table>

                    </Grid>
                </Grid>

        </>
      )
}