import { Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControlLabel, MenuItem, TextField } from '@mui/material'
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import Geocode from 'react-geocode';
import { AuthContext } from '../contexts/AuthContext';
import placeholder from '../assets/imgs/no-image.jpg'
import { BASE_URL, SOUKW_URL } from '../sharedComponents/Constants';

export default function NewTreinamentoVideo({ open, close, collectionId, treinamento }) {
    const { token, user, logout } = useContext(AuthContext);

    const [name, setName] = useState('');
    const [url, setUrl] = useState('');
    const [order, setOrder] = useState(0);
    const [role, setRole] = useState('');

    useEffect(() => {
        setName(treinamento ? treinamento.name : '');
        setUrl(treinamento ? treinamento.url : '');
        setOrder(treinamento ? treinamento.order : 0);
        setRole(treinamento ? treinamento.role : '');

    }, [treinamento])

    const submit = async () => {
        if (!name || !url || order !== order) {
            alert('Preencha todos os campos.');
            return;
        }
        try {
            const config = {
                headers: { Authorization: `Bearer ${token}` }
            };

            let col = await axios.get(BASE_URL + '/treinamentos/collections/by_id/' + collectionId, config);
            console.log(col.data)
            let leadershipOnly = col.data?.leadershipOnly

            if (!treinamento) {
                let json = { name, url, leadershipOnly, order, role, collectionId }
                let res = await axios.post(BASE_URL + '/treinamentos/collections/videos', json, config);
                if (res.data)
                    alert('Cadastro bem sucedido!');
            } else {
                let json = { _id: treinamento?._id, name, url, leadershipOnly, order, role, collectionId }
                let res = await axios.patch(BASE_URL + '/treinamentos/collections/videos/' + treinamento?._id, json, config);
                if (res.data)
                    alert('Editado com sucesso!');
            }
            close();
            document.location.reload()
        } catch (e) {
            console.error(e);
            if (e?.response?.data?.msg)
                alert(e?.response?.data?.msg)
            else {
                alert('Erro de Conexão.')
                logout();
            }
        }
    }
    return (
        <div>
            <Dialog open={open} onClose={close} maxWidth='sm'>
                <DialogTitle>
                    <div style={{ width: '500px' }}>
                        <h1>{treinamento ? 'Editar' : 'Cadastrar Novo'} Vídeo</h1>
                        <Divider style={{ width: '100%' }} />
                    </div>
                </DialogTitle>
                <DialogContent>
                    <h6>Dados do Vídeo</h6>
                    <br />
                    <TextField
                        variant='outlined'
                        label='Nome *'
                        color='secondary'
                        fullWidth
                        style={{ marginTop: '5px', marginBottom: '5px' }}
                        onChange={(e) => setName(e.target.value)}
                        value={name}
                    />
                    <TextField
                        variant='outlined'
                        label='URL *'
                        color='secondary'
                        fullWidth
                        style={{ marginTop: '5px', marginBottom: '5px' }}
                        onChange={(e) => setUrl(e.target.value)}
                        value={url}
                    />
                    <TextField
                        variant='outlined'
                        label='Função *'
                        select
                        color='secondary'
                        fullWidth
                        style={{ marginTop: '5px', marginBottom: '5px' }}
                        onChange={(e) => setRole(e.target.value)}
                        value={role}
                    >
                        <MenuItem value={-1}>Todos</MenuItem>
                        <MenuItem value={0}>Agente</MenuItem>
                        <MenuItem value={1}>Team Leader</MenuItem>
                        <MenuItem value={2}>MC OP</MenuItem>
                        <MenuItem value={3}>Regional</MenuItem>
                    </TextField>
                    <TextField
                        variant='outlined'
                        label='Ordem *'
                        color='secondary'
                        type={'number'}
                        fullWidth
                        style={{ marginTop: '5px', marginBottom: '5px' }}
                        onChange={(e) => setOrder(e.target.value)}
                        value={order}
                    />

                </DialogContent>
                <DialogActions>
                    <button className='btn btnSecondary' onClick={close}>Cancelar</button>
                    <button className='btn btnPrimary' onClick={submit}>{treinamento ? 'Editar' : 'Cadastrar'}</button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
