import { Grid, TextField } from '@mui/material'
import love from '../assets/imgs/love.png'
import kw from '../assets/imgs/kwred.png'
import React, { useContext, useState } from 'react'
import axios from 'axios'
import { AuthContext } from '../contexts/AuthContext'
import { ForgotPassword } from './ForgotPassword'
import { BASE_URL, SOUKW_URL } from '../sharedComponents/Constants'

export default function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [render, setRender] = useState(true)
    const { setToken, setUser, setJWTUser } = useContext(AuthContext)

    const submit = async () => {
        try {
            let res = await axios.post(BASE_URL + '/auth/login',
                { email, password });
            setToken(res.data?.token)
            setUser(res.data?.user);
            setJWTUser(res.data?.jwtUser);

            // Sets expire date
            let date = new Date()
            date = new Date(new Date(date).setHours(date.getHours() + 4));
            localStorage.setItem("token", JSON.stringify({
                token: res.data?.token,
                userId: res.data?.user._id,
                expiration: date,
                jwtUser: res.data?.jwtUser
            }));
        } catch (e) {
            console.error(e);
            if (e?.response?.data?.msg)
                alert(e?.response?.data?.msg)
            else {
                alert('Erro de Conexão.');
            }
        }
    }

    const handleRender = () => {
        setRender(false)
    }

    return (
        <div className="bgImage" style={{
            height: '100vh',
            backgroundColor: '#eceff4',
            overflow: 'hidden'
        }}>
            {
                render
                    ? <Grid container
                        alignItems={'center'}
                        justifyContent={'center'}
                        style={{ height: '100vh' }}
                    >
                        <div className='cardLogin' style={{
                            width: 'auto',
                            height: '500px',
                            display: 'flex',
                            flexDirection: 'row'
                        }}>

                            <div xs={4} style={{
                                borderRadius: '15px 0px 0px 15px',
                                backgroundImage: `url(${love})`,
                                width: '400px',
                                height: '100%',
                                backgroundSize: "cover"
                            }}>
                            </div>
                            <div style={{
                                width: '300px',
                                marginLeft: '50px',
                                marginRight: '50px'
                            }}>

                                <img src={kw}
                                    style={{
                                        width: '3.225rem',
                                        padding: '2.6rem 0px 1.6rem 0px'
                                    }}
                                    alt='logo'
                                />

                                <h1>
                                    Bem vindo ao SouKW
                                </h1>
                                <p style={{ paddingBottom: '0.785rem' }}>
                                    Faça login com sua conta Kwsp para ter acesso ao <strong>SouKW.</strong>
                                </p>

                                <TextField
                                className='textFieldLogin'
                                    label='Email'
                                    fullWidth
                                    type={'email'}
                                    onChange={(e) => setEmail(e.target.value)}
                                    value={email}
                                    style={{ paddingBottom: '1.5rem' }}
                                />

                                <TextField
                                    label='Senha'
                                    fullWidth
                                    type={'password'}
                                    onChange={(e) => setPassword(e.target.value)}
                                    value={password}
                                    style={{ paddingBottom: '1.5rem' }}
                                />

                                <div className='btn-login'
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}>
                                    <button className='buttonLogin' onClick={submit}>Entrar</button>
                                    <button onClick={handleRender}
                                        style={{
                                            marginLeft: '0.8rem',
                                            textDecoration: 'none',
                                            paddingTop: '0.5rem',
                                            border: 'none',
                                            backgroundColor: 'transparent',
                                            cursor: 'pointer'
                                        }}>
                                        <strong style={{ color: '​#1C5CBC', fontSize: '0.85rem' }}>Esqueceu a senha</strong>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Grid>
                    : <ForgotPassword />
            }
        </div>
    );
}
