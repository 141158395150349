import React from 'react';

function SingleFileUpload({ onFileUpload }) {
  const handleSubmit = (e, file) => {
    e.preventDefault();
    const formData = new FormData();
    console.log( e.target.files[0] );
    formData.set("file", file);
/**
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.error) {
        console.error('Error reading file:', reader.error);
        return;
      }

      const encoder = new TextEncoder();
      const fileData = {
        name: file.name,
        type: file.type,
        file: encoder.encode(reader.result).toString('base64')
      };
      console.log( fileData );
**/
      console.log( formData.file );
      onFileUpload(formData);
  };

  const handleFileChange = (e) => {
    console.log( "selected file" )
    //const file = e.target.files[0];
    const formData = new FormData();
    formData.append('doc', e.target.files[0]);
    //formData.append('name', e.target.files[0].name);
    console.log( e.target.files[0] );

    console.log( formData.entries() )
    onFileUpload(formData);
  };

  return (
    <form >
      <label>
        <input type="file" onChange={handleFileChange} /><span>      </span>
      </label>
    </form>
  );
}

export default SingleFileUpload;