import { Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControlLabel, MenuItem, TextField } from '@mui/material'
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import Geocode from 'react-geocode';
import { AuthContext } from '../contexts/AuthContext';
import placeholder from '../assets/imgs/no-image.jpg'
import { BASE_URL, SOUKW_URL } from '../sharedComponents/Constants';

export default function NewTreinamentoCommand({ open, close, treinamento }) {
    const { token, user, logout } = useContext(AuthContext);

    const [name, setName] = useState(treinamento ? treinamento.name : '');
    const [url, setUrl] = useState(treinamento ? treinamento.url : '');
    const [leadershipOnly, setLeadershipOnly] = useState(treinamento ? treinamento.leadershipOnly : false);

    useEffect(() => {
        setName(treinamento ? treinamento.name : '');
        setUrl(treinamento ? treinamento.url : '');
        setLeadershipOnly(treinamento ? treinamento.leadershipOnly : false);

    }, [treinamento])

    const submit = async () => {
        if (!name || !url) {
            alert('Preencha todos os campos.');
            return;
        }
        try {
            const config = {
                headers: { Authorization: `Bearer ${token}` }
            };

            if (treinamento) {
                let json = { _id: treinamento?._id, name, url, leadershipOnly }
                let res = await axios.patch(BASE_URL + '/treinamentos/command/' + treinamento?._id, json, config);
                if (res.data)
                    alert('Editado com sucesso!');
            }
            else {
                let json = { name, url, leadershipOnly }
                let res = await axios.post(BASE_URL + '/treinamentos/command/', json, config);
                if (res.data)
                    alert('Cadastrado com sucesso!');
            }
            close();
            document.location.reload()
        } catch (e) {
            console.error(e);
            if (e?.response?.data?.msg)
                alert(e?.response?.data?.msg)
            else {
                alert('Erro de Conexão.')
                logout();
            }
        }
    }
    return (
        <div>
            <Dialog open={open} onClose={close} maxWidth='sm'>
                <DialogTitle>
                    <div style={{ width: '500px' }}>
                        <h1>{treinamento ? 'Editar' : 'Cadastrar Novo'} Treinamento</h1>
                        <Divider style={{ width: '100%' }} />
                    </div>
                </DialogTitle>
                <DialogContent>
                    <h6>Dados do Treinamento</h6>
                    <br />
                    <TextField
                        variant='outlined'
                        label='Nome *'
                        color='secondary'
                        fullWidth
                        style={{ marginTop: '5px', marginBottom: '5px' }}
                        onChange={(e) => setName(e.target.value)}
                        value={name}
                    />
                    <TextField
                        variant='outlined'
                        label='URL *'
                        color='secondary'
                        fullWidth
                        style={{ marginTop: '5px', marginBottom: '5px' }}
                        onChange={(e) => setUrl(e.target.value)}
                        value={url}
                    />
                    <TextField
                        variant='outlined'
                        select
                        label='Permissionamento *'
                        color='secondary'
                        fullWidth
                        style={{ marginTop: '5px', marginBottom: '5px' }}
                        onChange={(e) => setLeadershipOnly(e.target.value)}
                        value={leadershipOnly}
                    >
                        <MenuItem value={false}>Todos</MenuItem>
                        <MenuItem value={true}>Somente Liderança</MenuItem>
                    </TextField>

                </DialogContent>
                <DialogActions>
                    <button className='btn btnSecondary' onClick={close}>Cancelar</button>
                    <button className='btn btnPrimary' onClick={submit}>{treinamento ? 'Editar' : 'Cadastrar'}</button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
