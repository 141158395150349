import { Autocomplete, Checkbox, Switch, Grid, MenuItem, TextField } from '@mui/material'
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import { SOUKW_URL, BASE_URL } from '../sharedComponents/Constants';


export default function EditMc() {
    const { token, user, logout } = useContext(AuthContext);
    let { id } = useParams()
    const navigate = useNavigate();

    const [regions, setRegions] = useState([]);

    const [mcName, setMcName] = useState('');
    const [mcKwId, setMcKwId] = useState('');
    const [mcRegionId, setMcRegionId] = useState('');
    const [admins, setAdmins] = useState([]);
    const [addPermissions, setAddPermissions] = useState('');
    const [teamLeaders, setTeamLeaders] = useState([]);
    const [divideViewByTeams, setDivideViewByTeams] = useState(false);

    const [users, setUsers] = useState([]);

    const [mc, setMc] = useState(null);

    useEffect(() => {
        async function getData() {
            try {
                const config = {
                    headers: { Authorization: `Bearer ${token}` }
                };
                let regionsRes = await axios.get(BASE_URL + '/regions/', config);
                console.log("Regions:", regionsRes.data )
                setRegions(regionsRes.data);
                let userRes = await axios.get(BASE_URL + '/users/by_mc/' + id, config);
                console.log( "Users:", userRes.data )
                setUsers(userRes.data);
                let mcRes = await axios.get(BASE_URL + '/mcs/' + id, config);
                console.log( "MC:", mcRes.data )
                let mc = mcRes.data
                setMc(mc);
                setMcName(mc?.name);
                setMcKwId(mc?.kwId);
                setMcRegionId(mc?.regionId);
                setDivideViewByTeams(mc?.divideViewByTeams);
                setAddPermissions( mc?.addPermissions )

                let promiseArr = []
                mc?.admins?.map(admId => {
                    promiseArr.push(axios.get(BASE_URL + '/users/user/' + admId, config))
                })
                let admsRes = await Promise.all(promiseArr);
                let adminObjs = admsRes.map(a => a.data)
                setAdmins(adminObjs);
                let tlPromiseArr = []
                mc?.teamLeaders?.map(tlId => {
                    tlPromiseArr.push(axios.get(BASE_URL + '/users/user/' + tlId, config))
                })
                let tlsRes = await Promise.all(tlPromiseArr);
                let tlsObjs = tlsRes.map(a => a.data)
                console.log( "tlsObjs: ",tlsObjs )
                setTeamLeaders(tlsObjs);
            } catch (e) {
                console.error(e);
                if (e?.response?.data?.msg)
                    alert(e?.response?.data?.msg)
                else {
                    alert('Erro de Conexão.')
                    logout();
                }
            }
        }
        getData();
    }, []);

    const submitMc = async () => {
        try {
            const config = {
                headers: { Authorization: `Bearer ${token}` }
            };
            let adminIds = admins.map(a => a._id)
            let teamLeaderIds = teamLeaders.map(a => a._id)
            console.log(teamLeaderIds)
            let json = {
                name: mcName,
                kwId: mcKwId,
                regionId: mcRegionId,
                hasReviewsOnListings: true,
                divideViewByTeams: divideViewByTeams,
                admins: adminIds,
                teamLeaders: teamLeaderIds,
                addPermissions: addPermissions
            }
            let res = await axios.patch(BASE_URL + '/mcs/' + id, json, config);
            alert('Market Center editado com sucesso!');
            navigate(-1);
        } catch (e) {
            console.error(e);
            if (e?.response?.data?.msg)
                alert(e?.response?.data?.msg)
            else {
                alert('Erro de Conexão.')
                logout();
            }
        }
    }
    return (
        <div>
            <button className='btn btnSecondary' onClick={() => navigate(-1)}>
                Voltar
            </button>
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    <br />
                </Grid>
                <Grid item xs={12}>
                    <h1>Editar Market Center</h1>
                </Grid>
                <Grid item xs={12}>
                    <br />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        label='Nome do MC *'
                        variant='outlined'
                        fullWidth
                        onChange={(e) => setMcName(e.target.value)}
                        value={mcName}
                    />
                </Grid>
                <Grid item xs={12}>
                    <br />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label='KWUID do MC *'
                        variant='outlined'
                        fullWidth
                        onChange={(e) => setMcKwId(e.target.value)}
                        value={mcKwId}
                    />
                </Grid>
                <Grid item xs={12}>
                    <br />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label='Região *'
                        select
                        variant='outlined'
                        fullWidth
                        onChange={(e) => setMcRegionId(e.target.value)}
                        value={mcRegionId}
                    >
                        {
                            regions?.map(r => {
                                return (
                                    <MenuItem value={r._id}>{r.name}</MenuItem>
                                )
                            })
                        }
                    </TextField>
                </Grid>
                <Grid item xs={12}>
                    <br />
                </Grid>
                <Grid item xs={12}>
                    <Autocomplete
                        multiple
                        options={users}
                        getOptionLabel={(option) => option?.fullName ?? ""}
                        defaultValue={admins}
                        filterSelectedOptions
                        noOptionsText='Nenhum Usuário'
                        value={admins}
                        onChange={(e, newValue) => {
                            setAdmins(newValue)
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="MCAs"
                                placeholder="MCAs"
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12}>
                    <br />
                </Grid>
                <Grid item xs={12}>
                    <Autocomplete
                        multiple
                        options={users}
                        getOptionLabel={(option) => option.fullName}
                        defaultValue={teamLeaders}
                        filterSelectedOptions
                        noOptionsText='Nenhum Usuário'
                        value={teamLeaders}
                        onChange={(e, newValue) => {
                            setTeamLeaders(newValue)
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Team Leaders"
                                placeholder="Team Leaders"
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12}>
                    <br />
                </Grid>
                <Grid item xs={12}>
                    <div style={{ display: 'inline-block' }}>
                        <Checkbox
                            color='primary'
                            checked={divideViewByTeams}
                            onChange={(e) => setDivideViewByTeams(e.target.checked)}
                        />Dividir visualização por times
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <br />
                </Grid>
                <Grid item xs={12} >
                    <label>Escolher quem pode criar anúncio
                    <TextField
                        variant='outlined'
                        select
                        color='secondary'
                        fullWidth
                        style={{ marginTop: '5px', marginBottom: '5px' }}
                        helperText="Permissão de Anúncio"
                        onChange={(e) => setAddPermissions(e.target.value)}
                        value={addPermissions}
                    >
                        <MenuItem value={'AGENTE'}>Todos Agentes</MenuItem>
                        <MenuItem value={'TEAM'}>Apenas Times e MCA</MenuItem>
                        <MenuItem value={'MC'}>Apenas MCA</MenuItem>
                    </TextField>
                   </label>
                </Grid>
                <Grid item xs={12}>
                    <br />
                </Grid>
                <button className='btn btnPrimary' onClick={submitMc}>
                    Editar Market Center
                </button>
            </Grid>
        </div>
    )
}