import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../contexts/AuthContext';
import axios from 'axios';
import XMLFilesContext from '../../contexts/XMLFilesContexts';
import { BASE_URL, SOUKW_URL } from '../../sharedComponents/Constants';
import { Chip, Grid } from '@mui/material';

export default function XmlFileSize( ) {
   
    const {
        files,
        selectedFile,
        setSelectedFile,
        addFile,
        removeFile,
        updateFileList,
        getXMLFileSize,
        xmlCurrentFileSize,
        setXmlCurrentFileSize,
        listingsInsideXML,
        setListingsInsideXML
        } = useContext( XMLFilesContext );
    const { user, logout, token, jwtUser } = useContext(AuthContext);

    useEffect( ( ) => {
        getXMLFileSize( token )
    }, [xmlCurrentFileSize, listingsInsideXML, selectedFile])

    return (
        <>
            <Grid item xs={4} sm={4} style={{ textAlign: 'left', }}>
                <Chip label={`Listings Anunciados: ${listingsInsideXML} / No XML Selecionado: ${xmlCurrentFileSize}`}/>  
            </Grid> 

        </>            
    )

}