import { useState } from 'react';
import { TextField, MenuItem } from '@mui/material';

const PropertyTypeForm = ({
  listCategory, setListCategory,
  listType, setListType,
  listStatus, setListStatus,
  propType, setPropType,
  propSubType, setPropSubType
}) => {

  return (
    <>
      <h6>Tipo de Imóvel</h6>
      <TextField
        variant='outlined'
        select
        label='Categoria *'
        color='secondary'
        fullWidth
        style={{ marginTop: '5px', marginBottom: '5px' }}
        onChange={(e) => setListCategory(e.target.value)}
        value={listCategory}
      >
        <MenuItem value={0}>Outro</MenuItem>
        <MenuItem value={1}>Locação</MenuItem>
        <MenuItem value={2}>À Venda</MenuItem>
        <MenuItem value={3}>Vendido</MenuItem>
        <MenuItem value={4}>Fora do Mercado</MenuItem>
      </TextField>
      <TextField
        variant='outlined'
        select
        label='Tipo de Cadastro *'
        color='secondary'
        fullWidth
        style={{ marginTop: '5px', marginBottom: '5px' }}
        onChange={(e) => setListType(e.target.value)}
        value={listType}
      >
        <MenuItem value={4}>Com Exclusividade</MenuItem>
        <MenuItem value={5}>Sem Exclusividade</MenuItem>
      </TextField>
      <TextField
        variant='outlined'
        select
        label='Status *'
        color='secondary'
        fullWidth
        style={{ marginTop: '5px', marginBottom: '5px' }}
        onChange={(e) => setListStatus(e.target.value)}
        value={listStatus}
      >
        <MenuItem value={1}>Ativo</MenuItem>
        <MenuItem value={2}>Pendente</MenuItem>
        <MenuItem value={3}>Vendido</MenuItem>
        <MenuItem value={4}>Em Negociação</MenuItem>
        <MenuItem value={6}>Pendências Contratuais</MenuItem>
        <MenuItem value={12}>Cancelado</MenuItem>
        <MenuItem value={13}>Removido</MenuItem>
        <MenuItem value={14}>Expirado</MenuItem>
        <MenuItem value={15}>Alugado</MenuItem>
        <MenuItem value={17}>Vendido</MenuItem>
        <MenuItem value={18}>Não Disponível</MenuItem>
      </TextField>
      <TextField
            variant='outlined'
            select
            label='Tipo de Imóvel *'
            color='secondary'
            fullWidth
            style={{ marginTop: '5px', marginBottom: '5px' }}
            onChange={(e) => setPropType(e.target.value)}
            value={propType}
        >
            <MenuItem value={1}>Oportunidade de Investimento</MenuItem>
            <MenuItem value={2}>Comercial</MenuItem>
            <MenuItem value={3}>Interesse Mútuo</MenuItem>
            <MenuItem value={4}>Rural</MenuItem>
            <MenuItem value={5}>Terreno</MenuItem>
            <MenuItem value={6}>Casas Modulares</MenuItem>
            <MenuItem value={7}>Casa de Férias</MenuItem>
            <MenuItem value={8}>Residencial</MenuItem>
            <MenuItem value={9}>Oportunidade de Rentabilidade</MenuItem>
            <MenuItem value={10}>Outro</MenuItem>
        </TextField>
        <TextField
            variant='outlined'
            select
            label='Subtipo de Imóvel *'
            color='secondary'
            fullWidth
            style={{ marginTop: '5px', marginBottom: '5px' }}
            onChange={(e) => setPropSubType(e.target.value)}
            value={propSubType}
        >
            <MenuItem value={1}>Apartamento</MenuItem>
            <MenuItem value={2}>Vaga de Barco</MenuItem>
            <MenuItem value={3}>Chalé</MenuItem>
            <MenuItem value={4}>Condomínio</MenuItem>
            <MenuItem value={5}>Vaga de Garagem</MenuItem>
            <MenuItem value={6}>Duplex</MenuItem>
            <MenuItem value={7}>Fazenda</MenuItem>
            <MenuItem value={8}>Casa Modular</MenuItem>
            <MenuItem value={9}>Casa Modular com Terreno</MenuItem>
            <MenuItem value={10}>Casa Móvel</MenuItem>
            <MenuItem value={11}>OYO</MenuItem>
            <MenuItem value={12}>Quadruplex</MenuItem>
            <MenuItem value={13}>Rancho</MenuItem>
            <MenuItem value={14}>Casa Geminada</MenuItem>
            <MenuItem value={15}>Casa Não Geminada</MenuItem>
            <MenuItem value={16}>Cooperativa</MenuItem>
            <MenuItem value={17}>Timeshare</MenuItem>
            <MenuItem value={18}>Townhouse</MenuItem>
            <MenuItem value={19}>Triplex</MenuItem>
            <MenuItem value={20}>Agricultura</MenuItem>
            <MenuItem value={21}>Negócios</MenuItem>
            <MenuItem value={22}>Hotel-Motel</MenuItem>
            <MenuItem value={23}>Industrial</MenuItem>
            <MenuItem value={24}>Mixed-Use</MenuItem>
            <MenuItem value={25}>Multi-Family</MenuItem>
            <MenuItem value={26}>Comercial</MenuItem>
            <MenuItem value={27}>Loja</MenuItem>
            <MenuItem value={28}>Terreno Não Urbano</MenuItem>
            <MenuItem value={29}>Galpão</MenuItem>
            <MenuItem value={30}>Comunidade Adulta</MenuItem>
            <MenuItem value={31}>Casa de Férias</MenuItem>
            <MenuItem value={32}>Outro</MenuItem>
        </TextField>
    </>

)}

export default PropertyTypeForm;
