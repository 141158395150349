import { Grid } from "@mui/material";
import dataLand from '../assets/imgs/dataLandLogo.png'
import eEmovel from '../assets/imgs/eEmovelLogo.png'
import quires from '../assets/imgs/quiresLogo.png'

export default function IMPage() {
    return (
        <>
            {/* ===== DATA LAND ===== */}
            <Grid container
                xs={8}
                md={8}
                lg={5}
                paddingTop={1}
            >
                <div className="imGrid" >
                    <div className="imCard">
                        <img className="imLogo" src={dataLand} />
                        <div className="dataLandInfo">
                            <h1 className="imH1">
                                Data Land
                            </h1>
                            <p className="imP">
                                Precifique o seu imóvel utilizando o valor real transacionado,
                                combinado com o modelo digital das cidades, que conta com aspectos urbanos,
                                mercadológicos e legais
                            </p>
                        </div>
                    </div>
                </div>
                <div className="gridButton">
                    <div className="imGrid">
                        <Grid item xs={10}
                            style={{
                                marginTop: '1rem',
                                marginLeft: '3.5rem'
                            }}>
                            <a target='_blank' href="https://drive.google.com/file/d/1SI5mJx2F54BKo0VQb8b65rvltMOKqFV-/view?usp=sharing">
                                <button className='btn btnSecondary'
                                >
                                    Termo de Parceria
                                </button>
                            </a>
                        </Grid>

                        <Grid item xs={2}
                            style={{
                                marginTop: '1rem'
                            }}>
                            <a href="https://app.dataland.ai/hub/auth/login" target='_blank'>
                                <button className='btn btnPrimary'
                                >
                                    Acessar
                                </button>
                            </a>
                        </Grid>
                    </div>
                </div>
            </Grid>
            <div style={{
                width: '4rem'
            }}>
            </div>

            {/* ===== EEMOVEL ===== */}
            <Grid container
                xs={8}
                md={8}
                lg={5}
                paddingTop={1}
            >
                <div className="imGrid" >
                    <div className="imCard" >
                        <img className="imLogo" src={eEmovel} />
                        <div className="eemovelInfo">
                            <h1 className="imH1">
                                EEmovel Inteligência Imobiliária
                            </h1>
                            <p className="imP">
                                Auxilie a tomada de decisões e gere insights que potencializarão seu negócio.
                                Transforme o jeito de fazer negócios por meio de tecnologia e informação.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="gridButton">
                    <div className="imGrid">
                        <Grid item xs={10}
                            style={{
                                marginTop: '1rem',
                                marginLeft: '3.5rem'
                            }}>
                            <a target='_blank' href="https://drive.google.com/file/d/1YcoXPoD4qNFOEMa-r4YPdqOw67gRSm-l/view?usp=sharing">
                                <button className='btn btnSecondary'
                                >
                                    Termo de Parceria
                                </button>
                            </a>
                        </Grid>

                        <Grid item xs={1}
                            style={{
                                marginTop: '1rem'
                            }}>
                            <a href="https://brokers.eemovel.com.br/login" target='_blank'>
                                <button className='btn btnPrimary'
                                >
                                    Acessar
                                </button>
                            </a>
                        </Grid>
                    </div>
                </div>
            </Grid>

            {/* ======= QUIRES ======= */}
            <Grid container
                xs={8}
                md={8}
                lg={5}
                paddingTop={3}
            >
                <div className="imGrid" >
                    <div className="imCard">
                        <img className="imLogo" src={quires} />
                        <div className="dataLandInfo">
                            <h1 className="imH1">
                                Quires
                            </h1>
                            <p className="imP">
                                Monitore o fluxo de escaneamentos e visualizações de placas dos imóveis e eleve a inteligência comercial e geográfica a partir disso,
                                dimensionando a demanda por diferentes tipos de produtos imobiliários.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="gridButton">
                    <div className="imGrid">
                        <Grid item xs={10}
                            style={{
                                marginTop: '1rem',
                                marginLeft: '3.5rem'
                            }}>
                            <a target='_blank' href="https://drive.google.com/file/d/1dq1eRS3ofLSoUDR_sWxgqVQHYzIebBQn/view?usp=sharing">
                                <button className='btn btnSecondary'
                                >
                                    Termo de Parceria
                                </button>
                            </a>
                        </Grid>

                        <Grid item xs={2}
                            style={{
                                marginTop: '1rem'
                            }}>
                            <a href="https://quires.com.br/login" target='_blank'>
                                <button className='btn btnPrimary'
                                >
                                    Acessar
                                </button>
                            </a>
                        </Grid>
                    </div>
                </div>
            </Grid>
            <div style={{
                width: '4rem'
            }}>
            </div>
        </>
    )
}