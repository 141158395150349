import { useState, useContext } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { ptBR } from "@mui/material/locale";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import SpreadSheet from "../sharedComponents/spreadsheet/Growth";
import MCChart from "../sharedComponents/growthCharts/MCChart";
import RegionalChart from "../sharedComponents/growthCharts/RegionalChart";
import { AuthContext } from "../contexts/AuthContext";
function PlanilhaGrowth() {
  const [value, setValue] = useState('1');
  const { user, logout, token, jwtUser } = useContext(AuthContext);

  console.log( user )
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

const contatos = [50, 30, 10, 20, 80, 100, 30];
const reunioes = [15, 18, 20, 2, 7, 10, 13];
const conversoes = [10, 2, 5, 2, 3, 10, 4]
const xLabels = [
  'Jan/2023',
  'Fev/2023',
  'Mar/2023',
  'Abr/2023',
  'Mai/2023',
  'Jun/2023',
  'Jul/2023',
];

const series = [{data: contatos, label: 'contatos'}, {data: reunioes, label:'reuniões'}, {data: conversoes, label: 'conversões'}]

  const theme = createTheme({
      palette: {
        primary: {
          main: "#373f51",
        },
        secondary: { main: "#0099a7" },
      },
      ptBR,
    });

  

    return (
        <ThemeProvider theme={theme}>
          <Grid container 
                    spacing={0} 
                    style={{ height: '100%' }} 
                    justifyItems='flex-start' 
                    alignItems={'flex-start'} >
            <TabContext value={value}>
              <Grid item xs={12} sm={12} sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleChange} aria-label="lab API tabs example">
                  <Tab label="Planilha Growth" value="1" />
                  <Tab label="Gráficos MC" value="2" />
                  <Tab label="Gráficos Regional" value="3" />
                </TabList>
              </Grid>
              <Grid item xs={12} sm={12}>
                <TabPanel value="1">
                  <SpreadSheet />
                </TabPanel>
                <TabPanel value="2">
                  <Grid item xs={12} sm={12}>
                    <MCChart title={"mensal"} series={series} labels={xLabels}/>
                  </Grid>
                </TabPanel>
                <TabPanel value="3">
                  <Grid item xs={12} sm={12}> 
                    <RegionalChart />
                  </Grid>
                </TabPanel>
              </Grid>
            </TabContext>
          </Grid>
           
        </ThemeProvider >
    )
}

export default PlanilhaGrowth;