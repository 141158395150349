import { useState, useContext, useEffect } from 'react';
import { LineChart } from '@mui/x-charts/LineChart';
import { Grid,  } from '@mui/material';
import XMLFilesContext from '../../contexts/XMLFilesContexts';
import { parseISO, startOfWeek, endOfWeek, eachDayOfInterval, eachWeekOfInterval, format, formatDate, lightFormat } from 'date-fns';

export default function MCChart( props ) {
  const [rendered, setRendered] = useState(false);
  //const [contatosMonth, setContatosMonth] = useState([]);
  const{title, series, labels} = {...props}
  const { 
    growthData,
    dataSets,
    growthDataRows,
    growthDataLoaded,
    setGrowthDataLoaded,
    setGrowthData,
    setGrowthDataRows,
    syncGrowthData,
    saveGrowthDataLine,
    setDataSets  } = useContext(XMLFilesContext);

    function calculateDataSets( ) {
      setRendered(false);
      const currentData = new Date(Date.now());
      
      const pastTwelveMonths = new Date( Date.now() - 365 * 24 * 60 * 60 * 1000); // days * hours * minutes * seconds * milliseconds
      const currentYear = currentData.getFullYear();
     
      const currentMonth = currentData.getMonth() + 1;//+ 1; //Alterarar para + 1 antes de colocar em produção
      const previousMonth = currentData.getMonth();
      const currentDay = currentData.getDate();

      const lastMonth = pastTwelveMonths.getMonth() - 1;
      const lastYear = pastTwelveMonths.getFullYear();

      //Montar as semanas
      const lastEightWeeks = new Date( Date.now() - 60 * 24 * 60 * 60 * 1000);
      const startingWeeksFrom  = startOfWeek( parseISO( lastEightWeeks.toISOString() ), {weekStartsOn: 1} );
      const endingWeeksFrom = startOfWeek( parseISO( currentData.toISOString() ), { weekStartsOn: 1} );
      const weekArray = eachWeekOfInterval( {start: startingWeeksFrom, end: endingWeeksFrom}).map( (week) => {
        return lightFormat(week, 'yyyy-MM-dd')
      })

      
      console.log('Weeks are: ' + weekArray )


      // console.log( "Growth Data Rows: ", growthDataRows );
      // console.log( "Current Month: ", currentMonth);
      // console.log("Current Year: ", currentYear);

      //Obtem os datasets brutos para cada gráfico - mes atual, ano corrente e ultimos 12 meses
      const currentMonthData = growthDataRows.filter((row) => row.month === currentMonth && row.year === currentYear);
      const previousMonthData = growthDataRows.filter((row) => row.month === previousMonth && row.year === currentYear);
      const currentYearData = growthDataRows.filter((row) => row.year === currentYear);
      const lastTwelveMonthsData = growthDataRows.filter((row) => 
        (row.year === currentYear && row.month <= currentMonth) || 
        ( row.year === lastYear && row.month >= lastMonth ) ) 

      //Constantes dos sumários por nível de agente
      const currentMonthDaySummaryObjects = [];
      const previousMonthDaySummaryObjects = [];
      const currentYearSummaryObjects = [];
      const lastTwelveMonthsSummaryObjects = [];

      const agentInitialSummary = {contratacoes: 0, reunioes: 0, contatos: 0}
      const agentSummaryInitialState = { 
        'R0': {...agentInitialSummary}, 
        'R1': {...agentInitialSummary}, 
        'R2': {...agentInitialSummary} 
      }

      const currentMonthAgentLevelSummary = {...agentSummaryInitialState }
      const previousMonthAgentLevelSummary = {...agentSummaryInitialState }
      const lastTwelveMonthsAgentLevelSummary = {...agentSummaryInitialState }
      const currentYearAgentLevelSummary = {...agentSummaryInitialState }
      const weeklyAgentLevelSummary = {...agentSummaryInitialState}
      const weeklyDataSummary = {...agentInitialSummary }

      //Os DataSets que vão para os gráficos
      const contratacoesMonthDataSet = [];
      const reunioesMonthDataSet = [];
      const contatosMonthDataSet = [];
      const contratacoesPreviousMonthDataSet = [];
      const reunioesPreviousMonthDataSet = [];
      const contatosPreviousMonthDataSet = [];
      const contratacoesCurrentYearDataSet = [];
      const reunioesCurrentYearDataSet = [];
      const contatosCurrentYearDataSet = [];
      const contratacoesLastTwelveMonthsDataSet = [];
      const reunioesLastTwelveMonthsDataSet = [];
      const contatosLastTwelveMonthsDataSet = [];
      const contratacoesWeeklyDataSet = [];
      const reunioesWeeklyDataSet = [];
      const contatosWeeklyDataSet = [];
      const weekDataSet = weekArray.map( week => {
        console.log('Week for dataset: ' + week)
        return { [`${week}`]: [{...weeklyDataSummary}, {...weeklyAgentLevelSummary}]}
      });
      
      console.log("WeekDataset is: ", weekDataSet )
      //Gera os datasets do gráfico de mes corrente
      //console.log( "Current Month Data: ", currentMonthData );
      //Fill currentMonthDaySummary with zeroes
      for( let i = 0; i < currentData.getDate(); i++ ) {
        currentMonthDaySummaryObjects[i] = {
          contratacoes: 0,
          reunioes: 0,
          contatos: 0
        }
    }
      currentMonthData.forEach( el => {
        //console.log('El for Daily summary: ', el)
        currentMonthDaySummaryObjects[el.day - 1] = {
          contratacoes: currentMonthDaySummaryObjects[el.day - 1]?.contratacoes === undefined ?
            el.contratacoes : currentMonthDaySummaryObjects[el.day - 1]?.contratacoes + el.contratacoes,
          reunioes: currentMonthDaySummaryObjects[el.day - 1]?.reunioes === undefined ?
            el.reunioes : currentMonthDaySummaryObjects[el.day - 1]?.reunioes + el.reunioes,
          contatos: currentMonthDaySummaryObjects[el.day - 1]?.contatos === undefined ?
            el.contatos : currentMonthDaySummaryObjects[el.day - 1]?.contatos + el.contatos
        };
        //console.log('currentMonthDaySummaryObjects: ', currentMonthDaySummaryObjects)
        //console.log('Daily summary: ', currentMonthDaySummaryObjects[el.day])
        currentMonthAgentLevelSummary[el.nivelAgente] = {
          contratacoes: currentMonthAgentLevelSummary[el.nivelAgente].contratacoes + el.contratacoes,
          reunioes: currentMonthAgentLevelSummary[el.nivelAgente].reunioes + el.reunioes,
          contatos: currentMonthAgentLevelSummary[el.nivelAgente].contatos + el.contatos,
        }
        //console.log('Daily summary: ', currentMonthDaySummaryObjects[el.day])
      }) 

      for( let i = 0; i < 30; i++ ) {
        previousMonthDaySummaryObjects[i] = {
          contratacoes: 0,
          reunioes: 0,
          contatos: 0
        }
    }

      previousMonthData.forEach( el => {
        //console.log('El for Daily summary: ', el)
        previousMonthDaySummaryObjects[el.day - 1] = {
          contratacoes: previousMonthDaySummaryObjects[el.day - 1]?.contratacoes === undefined ?
            el.contratacoes : previousMonthDaySummaryObjects[el.day - 1]?.contratacoes + el.contratacoes,
          reunioes: previousMonthDaySummaryObjects[el.day - 1]?.reunioes === undefined ?
            el.reunioes : previousMonthDaySummaryObjects[el.day - 1]?.reunioes + el.reunioes,
          contatos: previousMonthDaySummaryObjects[el.day - 1]?.contatos === undefined ?
            el.contatos : previousMonthDaySummaryObjects[el.day - 1]?.contatos + el.contatos
        };
        //console.log('currentMonthDaySummaryObjects: ', currentMonthDaySummaryObjects)
        //console.log('Daily summary: ', currentMonthDaySummaryObjects[el.day])
        previousMonthAgentLevelSummary[el.nivelAgente] = {
          contratacoes: previousMonthAgentLevelSummary[el.nivelAgente].contratacoes + el.contratacoes,
          reunioes: previousMonthAgentLevelSummary[el.nivelAgente].reunioes + el.reunioes,
          contatos: previousMonthAgentLevelSummary[el.nivelAgente].contatos + el.contatos,
        }
        //console.log('previousMonthAgentLevelSummary: ', previousMonthAgentLevelSummary)
      }) 



      Object.keys(currentMonthDaySummaryObjects).forEach( day => {

          //console.log( currentMonthDaySummaryObjects );

        contratacoesMonthDataSet.push(currentMonthDaySummaryObjects[day].contratacoes);
        reunioesMonthDataSet.push(currentMonthDaySummaryObjects[day].reunioes);
        contatosMonthDataSet.push(currentMonthDaySummaryObjects[day].contatos);
      })

      Object.keys(previousMonthDaySummaryObjects).forEach( day => {

        //console.log( currentMonthDaySummaryObjects );

        contratacoesPreviousMonthDataSet.push(previousMonthDaySummaryObjects[day].contratacoes);
        reunioesPreviousMonthDataSet.push(previousMonthDaySummaryObjects[day].reunioes);
        contatosPreviousMonthDataSet.push(previousMonthDaySummaryObjects[day].contatos);
      })

      //console.log( "Previous Month: ", previousMonthDaySummaryObjects )
      //console.log("Previous Month Data", previousMonthData.filter( el => el.day == 2 ))
      //Current Year calculations
      //console.log( "Current Year Data: ", currentYearData );
      currentYearData.forEach( el => {
       // console.log( "Current Year Data el: ", el );
       // console.log( "currentYearSummaryObjects: ", currentYearSummaryObjects );
       //console.log( "Element DATA is:", el.day, el.month, el.year)
       weekArray.forEach( (week, index, weeks) => {
        
        if( week ){
          
          if( index < weekArray.length - 1 ) {
            
            const [year, month, day] = week.split( '-' );
            const [nyear, nmonth, nday] = weeks[index + 1].split( '-' );
            console.log("CURRENT WEEK:", year, month, day)
            console.log("NEXT WEEK:", nyear, nmonth, nday)
            if( (parseInt(el.year) >= parseInt(year) && parseInt(el.year) <= parseInt(nyear)) ) { //checa o ano
              if( parseInt(nmonth) > parseInt(month) ) { //Semana com a virade de mes
                if( el.month === parseInt(nmonth) && el.day < parseInt(nday) ) {
                  weekDataSet[index][`${week}`][0].reunioes += el.reunioes;
                  weekDataSet[index][`${week}`][0].contatos += el.contatos;
                  weekDataSet[index][`${week}`][0].contratacoes += el.contratacoes;
                  weekDataSet[index][`${week}`][1][`${el.nivelAgente}`].reunioes += el.reunioes;
                  weekDataSet[index][`${week}`][1][`${el.nivelAgente}`].contatos += el.contatos;
                  weekDataSet[index][`${week}`][1][`${el.nivelAgente}`].contratacoes += el.contratacoes;
                } else if( el.month === parseInt(month) && el.day >= parseInt(day)) {
                  weekDataSet[index][`${week}`][0].reunioes += el.reunioes;
                  weekDataSet[index][`${week}`][0].contatos += el.contatos;
                  weekDataSet[index][`${week}`][0].contratacoes += el.contratacoes;
                  weekDataSet[index][`${week}`][1][`${el.nivelAgente}`].reunioes += el.reunioes;
                  weekDataSet[index][`${week}`][1][`${el.nivelAgente}`].contatos += el.contatos;
                  weekDataSet[index][`${week}`][1][`${el.nivelAgente}`].contratacoes += el.contratacoes;
                }

              }
              else if ( parseInt(el.month) >= parseInt(month) && parseInt(el.month) <= parseInt(nmonth) 
                  && ( parseInt(el.day) >= parseInt(day) && parseInt(el.day) < parseInt( nday ) )) {
                console.log("index is: ", index)
                console.log("Week is: ", week)
               
                //console.log( "Current day: ", el.day, el.month, el.year );
                console.log("My el is: ", el );
                weekDataSet[index][`${week}`][0].reunioes += el.reunioes;
                weekDataSet[index][`${week}`][0].contatos += el.contatos;
                weekDataSet[index][`${week}`][0].contratacoes += el.contratacoes;
                weekDataSet[index][`${week}`][1][`${el.nivelAgente}`].reunioes += el.reunioes;
                weekDataSet[index][`${week}`][1][`${el.nivelAgente}`].contatos += el.contatos;
                weekDataSet[index][`${week}`][1][`${el.nivelAgente}`].contratacoes += el.contratacoes;
                console.log('Week dataset is: ', weekDataSet[index])
              }

            }
          }
        }})

       
        currentYearSummaryObjects[`${el.month}`] = {
          contratacoes: 
            currentYearSummaryObjects[`${el.month}`]?.contratacoes === undefined ?
              el.contratacoes : currentYearSummaryObjects[`${el.month}`]?.contratacoes + el.contratacoes,
          reunioes: currentYearSummaryObjects[`${el.month}`]?.reunioes === undefined ?
            el.reunioes  :  currentYearSummaryObjects[`${el.month}`].reunioes + el.reunioes,
          contatos: currentYearSummaryObjects[`${el.month}`]?.contatos === undefined ? 
            el.contatos : currentYearSummaryObjects[`${el.month}`].contatos + el.contatos
        }

        currentYearAgentLevelSummary[el.nivelAgente] = {
          contratacoes: currentYearAgentLevelSummary[el.nivelAgente].contratacoes + el.contratacoes,
          reunioes: currentYearAgentLevelSummary[el.nivelAgente].reunioes + el.reunioes,
          contatos: currentYearAgentLevelSummary[el.nivelAgente].contatos + el.contatos,
        }
      })
     // console.log("CurrentYearSummaryObject:", currentYearSummaryObjects)
      Object.keys(currentYearSummaryObjects).forEach( month => {
        //console.log("MONTH is: ", month)
        contratacoesCurrentYearDataSet.push(currentYearSummaryObjects[month].contratacoes);
        reunioesCurrentYearDataSet.push(currentYearSummaryObjects[month].reunioes);
        contatosCurrentYearDataSet.push(currentYearSummaryObjects[month].contatos);
      })

      //ultimos 12 meses calculations
      lastTwelveMonthsData.forEach( el => {
        lastTwelveMonthsSummaryObjects[`${el.month}/${el.year}`] = {
          contratacoes: lastTwelveMonthsSummaryObjects[`${el.month}/${el.year}`]?.contratacoes === undefined ?
           el.contratacoes : lastTwelveMonthsSummaryObjects[`${el.month}/${el.year}`]?.contratacoes + el.contratacoes,
          reunioes: lastTwelveMonthsSummaryObjects[`${el.month}/${el.year}`]?.reunioes === undefined ?
           el.reunioes : lastTwelveMonthsSummaryObjects[`${el.month}/${el.year}`].reunioes + el.reunioes,
          contatos: lastTwelveMonthsSummaryObjects[`${el.month}/${el.year}`]?.contatos === undefined ? 
           el.contatos : lastTwelveMonthsSummaryObjects[`${el.month}/${el.year}`].contatos + el.contatos
        } 
        //console.log( "Nivel Agente: ", el.nivelAgente, " Contratacoes: ", el.contratacoes, " Reunioes: ", el.reunioes )
        lastTwelveMonthsAgentLevelSummary[el.nivelAgente] = {
          contratacoes: lastTwelveMonthsAgentLevelSummary[el.nivelAgente].contratacoes === undefined ?
           el.contratacoes : lastTwelveMonthsAgentLevelSummary[el.nivelAgente].contratacoes + el.contratacoes,
          reunioes: lastTwelveMonthsAgentLevelSummary[el.nivelAgente].reunioes === undefined ?
           el.reunioes : lastTwelveMonthsAgentLevelSummary[el.nivelAgente].reunioes + el.reunioes,
          contatos: lastTwelveMonthsAgentLevelSummary[el.nivelAgente].contatos === undefined ?
           el.contatos : lastTwelveMonthsAgentLevelSummary[el.nivelAgente].contatos + el.contatos
        }
      })

      Object.keys(lastTwelveMonthsSummaryObjects).forEach(  month => {
       // console.log("MONTH is: ", month)
        contratacoesLastTwelveMonthsDataSet.push(lastTwelveMonthsSummaryObjects[month].contratacoes);
        reunioesLastTwelveMonthsDataSet.push(lastTwelveMonthsSummaryObjects[month].reunioes);
        contatosLastTwelveMonthsDataSet.push(lastTwelveMonthsSummaryObjects[month].contatos);
      })

      weekArray.pop( );
      weekArray.forEach( (week, index, array) => {
        contratacoesWeeklyDataSet.push( weekDataSet[index][`${week}`][0].contratacoes);
        reunioesWeeklyDataSet.push( weekDataSet[index][`${week}`][0].reunioes);
        contatosWeeklyDataSet.push( weekDataSet[index][`${week}`][0].contatos);
      })

      console.log("DATASET WEEKLY", weekDataSet)

      //console.log( contratacoesMonthDataSet )
      setDataSets({
        contratacoesWeeklyDataSet,
        reunioesWeeklyDataSet,
        contatosWeeklyDataSet,
        contratacoesMonthDataSet,
        reunioesMonthDataSet,
        contatosMonthDataSet,
        contratacoesPreviousMonthDataSet,
        reunioesPreviousMonthDataSet,
        contatosPreviousMonthDataSet,
        contratacoesCurrentYearDataSet,
        reunioesCurrentYearDataSet,
        contatosCurrentYearDataSet,
        contratacoesLastTwelveMonthsDataSet,
        reunioesLastTwelveMonthsDataSet,
        contatosLastTwelveMonthsDataSet,
        currentMonthAgentLevelSummary,
        lastTwelveMonthsAgentLevelSummary,
        currentYearAgentLevelSummary,
        previousMonthAgentLevelSummary,
        //Eixos X dos gráficos
        monthDaysAxis: Object.keys(currentMonthDaySummaryObjects).map( 
          day => `${+day < 9 ? "0" + (+day + 1) : +day + 1}/${currentMonth < 10 ? "0" + currentMonth : currentMonth }` ),
        previousMonthDaysAxis: Object.keys(previousMonthDaySummaryObjects).map( 
          day => `${+day < 9 ? "0" + (+day + 1) : +day + 1}/${previousMonth < 10 ? "0" + previousMonth : previousMonth }` ),
        currentYearAxis: Object.keys(currentYearSummaryObjects).map( 
          month => `${+month < 9 ? "0" + (+month) : +month}/${currentYear}` ),
        lastTwelveMonthsAxis: Object.keys(lastTwelveMonthsSummaryObjects),
        weekAxix: weekArray
      })
      //alert( dataSets.lastTwelveMonthsAxis )
      //setContatosMonth(contatosMonthDataSet);
      //console.log("Current Month Data is: ", currentMonthDaySummaryObjects);

       //console.log("Current Month Data by agente Level is: ", currentMonthAgentLevelSummary);
       console.log("DataSet:", [...weekArray])
       console.log("DataSet Agent Level Summary:", contratacoesWeeklyDataSet)
       console.log("DataSet Agent Level Summary:", reunioesWeeklyDataSet)
       console.log("DataSet Agent Level Summary:", contatosWeeklyDataSet)
       console.log("Dataset is:", dataSets)
       setRendered(true);
    }

    useEffect( () => {
      calculateDataSets();
    }, [ ] );

    useEffect( () => {
      calculateDataSets();
      //console.log("Data Sets: ", dataSets);
    }, [growthDataRows] );

  return <Grid container spacing={1}
      justifyItems='flex-start' 
      alignItems={'flex-start'}>
      
        <Grid item xs={12} sm={10} style={{ textAlign: 'left', }}>
          
        { rendered &&
        <div>
        <Grid container spacing={5} justifyItems='flex-start' alignItems={'flex-start'}>
          <Grid item xs={5} sm={5} style={{ textAlign: 'left', }}>
            <h3>Últimas Semanas</h3>
            <LineChart
              width={500}
              height={300}
              series={ [{data: dataSets.contatosWeeklyDataSet, label: 'contatos'},
              {data: dataSets.reunioesWeeklyDataSet, label: 'reuniões'},
              {data: dataSets.contratacoesWeeklyDataSet, label: 'contratações'}
            ] }
            xAxis={[{ scaleType: 'point', data: dataSets.weekAxix, label: 'week'}]}
            />
              {/* xAxis={[{ scaleType: 'point', data: ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i']}]} */}

          </Grid>
          <Grid item xs={1} sm={1}></Grid>
          <Grid item xs={6} sm={5} style={{ textAlign: 'left', padding: '5px', margin: '10px'}}>
            <h3>Resumo - Mês Atual</h3>
            <Grid container spacing={2} justifyItems='flex-start' alignItems={'flex-start'}>
              <Grid item xs={2} sm={2} style={{ textAlign: 'left', padding: '5px', margin: '10px'}}>
                  <h4>Atividade</h4>
                  <p>Contatos:</p>
                  <p>Reuniões:</p>
                  <p>Contratações:</p>
                </Grid>
              <Grid item xs={1} sm={1} style={{ textAlign: 'left', padding: '5px', margin: '10px'}}>
                <h4>R0</h4>
                <p>{dataSets.currentMonthAgentLevelSummary.R0.contatos}</p>
                <p>{dataSets.currentMonthAgentLevelSummary.R0.reunioes}</p>
                <p>{dataSets.currentMonthAgentLevelSummary.R0.contratacoes}</p>
              </Grid>
              <Grid item xs={1} sm={1} style={{ textAlign: 'left', padding: '5px', margin: '10px'}}>
                <h4>R1</h4>
                <p>{dataSets.currentMonthAgentLevelSummary.R1.contatos}</p>
                <p>{dataSets.currentMonthAgentLevelSummary.R1.reunioes}</p>
                <p>{dataSets.currentMonthAgentLevelSummary.R1.contratacoes}</p>
              </Grid>
              <Grid item xs={1} sm={1} style={{ textAlign: 'left', padding: '5px', margin: '10px'}}>
                <h4>R2</h4>
                <p>{dataSets.currentMonthAgentLevelSummary.R2.contatos}</p>
                <p>{dataSets.currentMonthAgentLevelSummary.R2.reunioes}</p>
                <p>{dataSets.currentMonthAgentLevelSummary.R2.contratacoes}</p>
              </Grid>
              <Grid item xs={2} sm={2} style={{ textAlign: 'left', padding: '5px', margin: '10px'}}>
                <h4>Totais</h4>
                <p>{dataSets.currentMonthAgentLevelSummary.R2.contatos
                  + dataSets.currentMonthAgentLevelSummary.R1.contatos
                  + dataSets.currentMonthAgentLevelSummary.R0.contatos}</p>
                <p>{dataSets.currentMonthAgentLevelSummary.R2.reunioes
                + dataSets.currentMonthAgentLevelSummary.R1.reunioes
                + dataSets.currentMonthAgentLevelSummary.R0.reunioes}</p>
                <p>{dataSets.currentMonthAgentLevelSummary.R2.contratacoes
                + dataSets.currentMonthAgentLevelSummary.R1.contratacoes
                + dataSets.currentMonthAgentLevelSummary.R0.contratacoes}</p>
              </Grid>
              <Grid item xs={1} sm={1} style={{ textAlign: 'left', padding: '5px'}}>
                <h4>Taxa</h4>
                <p>-</p>
                <p>{((dataSets.currentMonthAgentLevelSummary.R2.reunioes
                + dataSets.currentMonthAgentLevelSummary.R1.reunioes
                + dataSets.currentMonthAgentLevelSummary.R0.reunioes)
                / (dataSets.currentMonthAgentLevelSummary.R2.contatos
                  + dataSets.currentMonthAgentLevelSummary.R1.contatos
                  + dataSets.currentMonthAgentLevelSummary.R0.contatos)).toFixed(3)}</p>
                <p>{((dataSets.currentMonthAgentLevelSummary.R2.contratacoes
                + dataSets.currentMonthAgentLevelSummary.R1.contratacoes
                + dataSets.currentMonthAgentLevelSummary.R0.contratacoes)
                / (dataSets.currentMonthAgentLevelSummary.R2.reunioes
                  + dataSets.currentMonthAgentLevelSummary.R1.reunioes
                  + dataSets.currentMonthAgentLevelSummary.R0.reunioes)).toFixed(3)}</p>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={5} justifyItems='flex-start' alignItems={'flex-start'}>
        <Grid item xs={5} sm={5} style={{ textAlign: 'left', }}>
            <h3>Mês Anterior ( {((new Date( Date.now() )).getMonth()) < 10 ? `0${(new Date( Date.now() )).getMonth()}/${(new Date( Date.now() )).getFullYear()}` : `${(new Date( Date.now() )).getMonth() + 1}/${(new Date( Date.now() )).getFullYear()}`} )</h3>
            <LineChart
              width={500}
              height={300}
              series={ [{data: dataSets.contatosPreviousMonthDataSet, label: 'contatos'},
              {data: dataSets.reunioesPreviousMonthDataSet, label: 'reuniões'},
              {data: dataSets.contratacoesPreviousMonthDataSet, label: 'contratações'} ] }
              xAxis={[{ scaleType: 'point', data: dataSets.previousMonthDaysAxis}]}
            />
          </Grid>
          <Grid item xs={1} sm={1}></Grid>
          <Grid item xs={6} sm={5} style={{ textAlign: 'left', padding: '5px', margin: '10px'}}>
            <h3>Resumo</h3>
            <Grid container spacing={2} justifyItems='flex-start' alignItems={'flex-start'}>
              <Grid item xs={2} sm={2} style={{ textAlign: 'left', padding: '5px', margin: '10px'}}>
                  <h4>Atividade</h4>
                  <p>Contatos:</p>
                  <p>Reuniões:</p>
                  <p>Contratações:</p>
                </Grid>
              <Grid item xs={1} sm={1} style={{ textAlign: 'left', padding: '5px', margin: '10px'}}>
                <h4>R0</h4>
                <p>{dataSets.previousMonthAgentLevelSummary?.R0.contatos}</p>
                <p>{dataSets.previousMonthAgentLevelSummary?.R0.reunioes}</p>
                <p>{dataSets.previousMonthAgentLevelSummary?.R0.contratacoes}</p>
              </Grid>
              <Grid item xs={1} sm={1} style={{ textAlign: 'left', padding: '5px', margin: '10px'}}>
                <h4>R1</h4>
                <p>{dataSets.previousMonthAgentLevelSummary?.R1.contatos}</p>
                <p>{dataSets.previousMonthAgentLevelSummary?.R1.reunioes}</p>
                <p>{dataSets.previousMonthAgentLevelSummary?.R1.contratacoes}</p>
              </Grid>
              <Grid item xs={1} sm={1} style={{ textAlign: 'left', padding: '5px', margin: '10px'}}>
                <h4>R2</h4>
                <p>{dataSets.previousMonthAgentLevelSummary?.R2.contatos}</p>
                <p>{dataSets.previousMonthAgentLevelSummary?.R2.reunioes}</p>
                <p>{dataSets.previousMonthAgentLevelSummary?.R2.contratacoes}</p>
              </Grid>
              <Grid item xs={2} sm={2} style={{ textAlign: 'left', padding: '5px', margin: '10px'}}>
                <h4>Totais</h4>
                <p>{dataSets.previousMonthAgentLevelSummary?.R2.contatos
                  + dataSets.previousMonthAgentLevelSummary?.R1.contatos
                  + dataSets.previousMonthAgentLevelSummary?.R0.contatos}</p>
                <p>{dataSets.previousMonthAgentLevelSummary?.R2.reunioes
                + dataSets.previousMonthAgentLevelSummary?.R1.reunioes
                + dataSets.previousMonthAgentLevelSummary?.R0.reunioes}</p>
                <p>{dataSets.previousMonthAgentLevelSummary?.R2.contratacoes
                + dataSets.previousMonthAgentLevelSummary?.R1.contratacoes
                + dataSets.previousMonthAgentLevelSummary?.R0.contratacoes}</p>
              </Grid>
              <Grid item xs={1} sm={1} style={{ textAlign: 'left', padding: '5px'}}>
                <h4>Taxa</h4>
                <p>-</p>
                <p>{((dataSets.previousMonthAgentLevelSummary?.R2.reunioes
                + dataSets.previousMonthAgentLevelSummary?.R1.reunioes
                + dataSets.previousMonthAgentLevelSummary?.R0.reunioes)
                / (dataSets.previousMonthAgentLevelSummary?.R2.contatos
                  + dataSets.previousMonthAgentLevelSummary?.R1.contatos
                  + dataSets.previousMonthAgentLevelSummary?.R0.contatos)).toFixed(3)}</p>
                <p>{((dataSets.previousMonthAgentLevelSummary?.R2.contratacoes
                + dataSets.previousMonthAgentLevelSummary?.R1.contratacoes
                + dataSets.previousMonthAgentLevelSummary?.R0.contratacoes)
                / (dataSets.previousMonthAgentLevelSummary?.R2.reunioes
                  + dataSets.previousMonthAgentLevelSummary?.R1.reunioes
                  + dataSets.previousMonthAgentLevelSummary?.R0.reunioes)).toFixed(3)}</p>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={5} justifyItems='flex-start' alignItems={'flex-start'}>
          <Grid item xs={5} sm={5} style={{ textAlign: 'left', }}>
            <h3>Ano Corrente {(new Date( Date.now() )).getFullYear() }</h3>
            <LineChart
              width={500}
              height={300}
              series={ [{data: dataSets.contatosCurrentYearDataSet, label: 'contatos'},
              {data: dataSets.reunioesCurrentYearDataSet, label:'reuniões'},
              {data: dataSets.contratacoesCurrentYearDataSet, label: 'contratações'} ] }
              xAxis={[{ scaleType: 'point', data: dataSets.currentYearAxis}]}
            /> 
          </Grid>
          <Grid item xs={1} sm={1}></Grid>
          <Grid item xs={6} sm={5} >
            <h3>Resumo</h3>
            <Grid container justifyItems='flex-start' alignItems={'flex-start'}>
              <Grid item xs={3} sm={3} style={{ textAlign: 'left', padding: '5px'}}>
                  <h4>Atividade</h4>
                  <p>Contatos:</p>
                  <p>Reuniões:</p>
                  <p>Contratações:</p>
                </Grid>
              <Grid item xs={1} sm={1} >
                <h4>R0</h4>
                <p>{dataSets.currentYearAgentLevelSummary.R0.contatos}</p>
                <p>{dataSets.currentYearAgentLevelSummary.R0.reunioes}</p>
                <p>{dataSets.currentYearAgentLevelSummary.R0.contratacoes}</p>
              </Grid>
              <Grid item xs={1} sm={1}>
                <h4>R1</h4>
                <p>{dataSets.currentYearAgentLevelSummary.R1.contatos}</p>
                <p>{dataSets.currentYearAgentLevelSummary.R1.reunioes}</p>
                <p>{dataSets.currentYearAgentLevelSummary.R1.contratacoes}</p>
              </Grid>
              <Grid item xs={1} sm={1} >
                <h4>R2</h4>
                <p>{dataSets.currentYearAgentLevelSummary.R2.contatos}</p>
                <p>{dataSets.currentYearAgentLevelSummary.R2.reunioes}</p>
                <p>{dataSets.currentYearAgentLevelSummary.R2.contratacoes}</p>
              </Grid>
              <Grid item xs={2} sm={2} style={{ textAlign: 'left', padding: '5px'}}>
                <h4>Totais</h4>
                <p>{dataSets.currentYearAgentLevelSummary.R2.contatos
                  + dataSets.currentYearAgentLevelSummary.R1.contatos
                  + dataSets.currentYearAgentLevelSummary.R0.contatos}</p>
                <p>{dataSets.currentYearAgentLevelSummary.R2.reunioes
                + dataSets.currentYearAgentLevelSummary.R1.reunioes
                + dataSets.currentYearAgentLevelSummary.R0.reunioes}</p>
                <p>{dataSets.currentYearAgentLevelSummary.R2.contratacoes
                + dataSets.currentYearAgentLevelSummary.R1.contratacoes
                + dataSets.currentYearAgentLevelSummary.R0.contratacoes}</p>
              </Grid>
              <Grid item xs={1} sm={1} style={{ textAlign: 'left', padding: '5px'}}>
                <h4>Taxa</h4>
                <p>-</p>
                <p>{((dataSets.currentYearAgentLevelSummary.R2.reunioes
                + dataSets.currentYearAgentLevelSummary.R1.reunioes
                + dataSets.currentYearAgentLevelSummary.R0.reunioes)
                / (dataSets.currentYearAgentLevelSummary.R2.contatos
                  + dataSets.currentYearAgentLevelSummary.R1.contatos
                  + dataSets.currentYearAgentLevelSummary.R0.contatos)).toFixed(3)}</p>
                <p>{((dataSets.currentYearAgentLevelSummary.R2.contratacoes
                + dataSets.currentYearAgentLevelSummary.R1.contratacoes
                + dataSets.currentYearAgentLevelSummary.R0.contratacoes)
                / (dataSets.currentYearAgentLevelSummary.R2.reunioes
                  + dataSets.currentYearAgentLevelSummary.R1.reunioes
                  + dataSets.currentYearAgentLevelSummary.R0.reunioes)).toFixed(3)}</p>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        
        <Grid container spacing={5} justifyItems='flex-start' alignItems={'flex-start'}>
          <Grid item xs={5} sm={5} style={{ textAlign: 'left', }}>
            <h3>Últimos 12 meses</h3>
            <LineChart
              width={500}
              height={300}
              series={ [{data: dataSets.contatosLastTwelveMonthsDataSet, label: 'contatos'},
              {data: dataSets.reunioesLastTwelveMonthsDataSet, label:'reuniões'},
              {data: dataSets.contratacoesLastTwelveMonthsDataSet, label: 'contratações'} ] }
              xAxis={[{ scaleType: 'point', data: dataSets.lastTwelveMonthsAxis}]}
            />
          </Grid>
          <Grid item xs={1} sm={1}></Grid>
          <Grid item xs={5} sm={5} style={{ textAlign: 'left', padding: '5px'}}>
            <h3>Resumo</h3>
            <Grid container  justifyItems='flex-start' alignItems={'flex-start'}>
              <Grid item xs={3} sm={3} style={{ textAlign: 'left', padding: '5px'}}>
                  <h4>Atividade</h4>
                  <p>Contatos:</p>
                  <p>Reuniões:</p>
                  <p>Contratações:</p>
                </Grid>
              <Grid item xs={1} sm={1} style={{ textAlign: 'left', padding: '5px'}}>
                <h4>R0</h4>
                <p>{dataSets.lastTwelveMonthsAgentLevelSummary.R0.contatos}</p>
                <p>{dataSets.lastTwelveMonthsAgentLevelSummary.R0.reunioes}</p>
                <p>{dataSets.lastTwelveMonthsAgentLevelSummary.R0.contratacoes}</p>
              </Grid>
              <Grid item xs={1} sm={1} style={{ textAlign: 'left', padding: '5px'}}>
                <h4>R1</h4>
                <p>{dataSets.lastTwelveMonthsAgentLevelSummary.R1.contatos}</p>
                <p>{dataSets.lastTwelveMonthsAgentLevelSummary.R1.reunioes}</p>
                <p>{dataSets.lastTwelveMonthsAgentLevelSummary.R1.contratacoes}</p>
              </Grid>
              <Grid item xs={1} sm={1} style={{ textAlign: 'left', padding: '5px'}}>
                <h4>R2</h4>
                <p>{dataSets.lastTwelveMonthsAgentLevelSummary.R2.contatos}</p>
                <p>{dataSets.lastTwelveMonthsAgentLevelSummary.R2.reunioes}</p>
                <p>{dataSets.lastTwelveMonthsAgentLevelSummary.R2.contratacoes}</p>
              </Grid>
              <Grid item xs={2} sm={2} style={{ textAlign: 'left', padding: '5px'}}>
                <h4>Totais</h4>
                <p>{dataSets.lastTwelveMonthsAgentLevelSummary.R2.contatos
                  + dataSets.lastTwelveMonthsAgentLevelSummary.R1.contatos
                  + dataSets.lastTwelveMonthsAgentLevelSummary.R0.contatos}</p>
                <p>{dataSets.lastTwelveMonthsAgentLevelSummary.R2.reunioes
                + dataSets.lastTwelveMonthsAgentLevelSummary.R1.reunioes
                + dataSets.lastTwelveMonthsAgentLevelSummary.R0.reunioes}</p>
                <p>{dataSets.lastTwelveMonthsAgentLevelSummary.R2.contratacoes
                + dataSets.lastTwelveMonthsAgentLevelSummary.R1.contratacoes
                + dataSets.lastTwelveMonthsAgentLevelSummary.R0.contratacoes}</p>
              </Grid>
              <Grid item xs={1} sm={1} style={{ textAlign: 'left', padding: '5px'}}>
                <h4>Taxa</h4>
                <p>-</p>
                <p>{((dataSets.lastTwelveMonthsAgentLevelSummary.R2.reunioes
                + dataSets.lastTwelveMonthsAgentLevelSummary.R1.reunioes
                + dataSets.lastTwelveMonthsAgentLevelSummary.R0.reunioes)
                / (dataSets.lastTwelveMonthsAgentLevelSummary.R2.contatos
                  + dataSets.lastTwelveMonthsAgentLevelSummary.R1.contatos
                  + dataSets.lastTwelveMonthsAgentLevelSummary.R0.contatos)).toFixed(3)}</p>
                <p>{((dataSets.lastTwelveMonthsAgentLevelSummary.R2.contratacoes
                + dataSets.lastTwelveMonthsAgentLevelSummary.R1.contratacoes
                + dataSets.lastTwelveMonthsAgentLevelSummary.R0.contratacoes)
                / (dataSets.lastTwelveMonthsAgentLevelSummary.R2.reunioes
                  + dataSets.lastTwelveMonthsAgentLevelSummary.R1.reunioes
                  + dataSets.lastTwelveMonthsAgentLevelSummary.R0.reunioes)).toFixed(3)}</p>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        </div>
        }
      </Grid>
      
    </Grid>

  
}