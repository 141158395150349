/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable default-case */
import { Autocomplete, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControlLabel, MenuItem, TextField } from '@mui/material'
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import Geocode from 'react-geocode';
import { AuthContext } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import SingleFileUpload from '../sharedComponents/SingleFileUpload';
import {
    translateListCategory,
    translateListType,
    translateListStatus,
    translatePropType,
    translatePropSubType} from '../sharedComponents/listings/translatings';
import TextModal from '../sharedComponents/listings/TextModal';
import {
    DATE_VALIDATION,
    CEL_PHONE_VALIDATION,
    FULL_NAME_VALIDATION,
    EMAIL_VALIDATION,
    UF_VALIDATOR,
    GENERAL_NAME_VALIDATOR,
    VALIDA_CPF
} from '../libs/validators'
import { BASE_URL, SOUKW_URL } from '../sharedComponents/Constants';

export default function NewListingModal({ open, close }) {
    const { token, user, logout, jwtUser } = useContext(AuthContext);
    const navigate = useNavigate();

    const [users, setUsers] = useState([]);


    //Proprietário:
    const [ownerName, setOwnerName] = useState( '' );
    const [ownerCPF, setOwnerCPF] = useState( '' );
    const [ownerEmail, setOwnerEmail] = useState( '' );
    const [ownerType, setOwnerType] = useState( 'Pessoa Física' ); //PJ ou PF
    const [ownerPhone, setOwnerPhone] = useState( '' );
    const [ownerToken, setOwnerToken] = useState( 'email' ); //Assinatura por e-mail ou whatsapp
    const [matriculaImovel, setMatriculaImovel] = useState( '' ); //Matricula do imovel
    const [ contratoData, setContratoData] = useState( '' );
    const [termoDeVenda, setTermoDeVenda] = useState( false );
    const [fileTermoDeVenda, setFileTermoDeVenda] = useState( {} );

    const getOwner = ( value ) => {
        setOwnerName( value );
        console.log(`Owner: ${ownerName}`);
    }

    const onFileUpload = ( fileObject ) => {
        /* File Object format:
            const fileData = {
            name: file.name,
            type: file.type,
            data: encoder.encode(reader.result).toString('base64')
          };*/
        setFileTermoDeVenda( fileObject )
    }

    const [cep, setCep] = useState('');
    const [rua, setRua] = useState('');
    const [numero, setNumero] = useState('');
    const [compl, setCompl] = useState('');
    const [geoloc, setGeoloc] = useState('');
    const [bairro, setBairro] = useState('');
    const [cidade, setCidade] = useState('');
    const [estado, setEstado] = useState('');

    const [listCategory, setListCategory] = useState(null);
    const [listType, setListType] = useState(null);
    const [listStatus, setListStatus] = useState(null);
    const [propType, setPropType] = useState(null);
    const [propSubType, setPropSubType] = useState(null);

    const [metragem, setMetragem] = useState('');
    const [nQuartos, setNQuartos] = useState('');
    const [nLavabos, setNLavabos] = useState('');
    const [nBanheiros, setNBanheiros] = useState('');
    const [valor, setValor] = useState('');

    const [nomeCondominio, setNomeCondominio] = useState('');
    const [andarDoImovel, setAndarDoImovel] = useState('');
    const [novoImovel, setNovoImovel] = useState('');
    const [nVagas, setNVagas] = useState('');
    const [construtora, setConstrutora] = useState('');
    const [nUnidades, setNUnidades] = useState('');
    const [nAndares, setNAndares] = useState('');
    const [nTorres, setNTorres] = useState('');

    const [churrasqueira, setChurrasqueira] = useState(false);
    const [deck, setDeck] = useState(false);
    const [acessoDeficientes, setAcessoDeficientes] = useState(false);
    const [doca, setDoca] = useState(false);
    const [jardim, setJardim] = useState(false);
    const [entradaFechada, setEntradaFechada] = useState(false);
    const [estufa, setEstufa] = useState(false);
    const [hidro, setHidro] = useState(false);
    const [patio, setPatio] = useState(false);
    const [lago, setLago] = useState(false);
    const [piscina, setPiscina] = useState(false);
    const [varanda, setVaranda] = useState(false);
    const [vagaTrailer, setVagaTrailer] = useState(false);
    const [quadra, setQuadra] = useState(false);
    const [aspercao, setAspercao] = useState(false);
    const [beiraMar, setBeiraMar] = useState(false);

    const [anoConstrucao, setAnoConstrucao] = useState('');
    const [tamanhoTerreno, setTamanhoTerreno] = useState('');
    const [descricao, setDescricao] = useState('');

    const [listingOwner, setListingOwner] = useState(user);

    useEffect(() => {
        async function getData() {
            const config = {
                headers: { Authorization: `Bearer ${token}` }
            };
            if (jwtUser?.permissionLevel === 'ADMIN') {
                let users = await axios.get(BASE_URL + '/users/', config);
                setUsers(users.data)
            } else if (jwtUser.isMcAdmin) {
                let users = await axios.get(BASE_URL + '/users/by_mc/' + jwtUser?.mcId, config);
                setUsers(users.data)
            }
        }
        getData()
    }, [BASE_URL, jwtUser.isMcAdmin, jwtUser?.mcId, jwtUser?.permissionLevel, token])

    const maskCurrency = (num = '.') => {
        let initial = String(num).split('.').join('').replace(/[^0-9.]/g, '');
        let formattedNum = initial;
        switch (initial.length) {
            case 1: formattedNum = num;
                break;
            case 2: formattedNum = num;
                break;
            case 3: formattedNum = num;
                break;
            case 4: formattedNum = [formattedNum.slice(0, 1), '.', formattedNum.slice(1)].join('');
                break;
            case 5: formattedNum = [formattedNum.slice(0, 2), '.', formattedNum.slice(2)].join('');
                break;
            case 6: formattedNum = [formattedNum.slice(0, 3), '.', formattedNum.slice(3)].join('');
                break;
            case 7: formattedNum = [formattedNum.slice(0, 1), '.', formattedNum.slice(1, 4), '.', formattedNum.slice(4)].join('');
                break;
            case 8: formattedNum = [formattedNum.slice(0, 2), '.', formattedNum.slice(2, 5), '.', formattedNum.slice(5)].join('');
                break;
            case 9: formattedNum = [formattedNum.slice(0, 3), '.', formattedNum.slice(3, 6), '.', formattedNum.slice(6)].join('');
                break;

        }
        return 'R$ ' + String(formattedNum).replace(/[^0-9.]/g, '').slice(0, 10)
    }
    const unmaskCurrency = (str) => {
        return String(str).replace(/[^0-9]/g, '');
    }
    const submit = async () => {
        try {
            Geocode.setApiKey('AIzaSyBG0z5RFyaogJihcAmL8A2GmazXLK7lCXc');
            Geocode.setLanguage("pt-br");
            Geocode.setRegion("br");
            Geocode.setLocationType('ROOFTOP');
            let preciseGeo = await Geocode.fromAddress(rua + ', ' + numero + ' - ' + bairro + ', ' + cidade + ', ' + estado + ' - ' + cep)
            try {
                const config = {
                    headers: { Authorization: `Bearer ${token}` }
                };
                let mcRes = listingOwner ? await axios.get(BASE_URL + '/mcs/' + listingOwner.marketCenterId, config) : await axios.get(BASE_URL + '/mcs/my/data', config);
                let regionRes = await axios.get(BASE_URL + '/regions/data', config);
                let mc = mcRes.data;
                let region = regionRes.data;


                //Cria contrato
                let contrato = {
                   data: contratoData,
                   existeTermo: termoDeVenda ? 'SIM' : 'NÃO',
                   arquivo: fileTermoDeVenda,
                };
                let proprietario = {
                    fullName: ownerName,
                    cpf: ownerCPF,
                    email: ownerEmail,
                    tipo: ownerType,//PJ ou PF
                    phone: ownerPhone,
                    token: ownerToken,
                };

                let list_address = {
                    street_number: numero,
                    street_name: rua,
                    unit_number: compl,
                    city: cidade,
                    state_prov: estado,
                    country: region?.countryCode,
                    postal_code: cep,
                    coordinates_gp: {
                        lat: preciseGeo?.results[0]?.geometry?.location?.lat,
                        lon: preciseGeo?.results[0]?.geometry?.location?.lng,
                    },
                    coordinates_gs: {
                        coordinates: [
                            preciseGeo?.results[0]?.geometry?.location?.lng,
                            preciseGeo?.results[0]?.geometry?.location?.lat,
                        ],
                        type: "Point"
                    },

                }
                let exterior_features = {
                    has_barbecue_area: churrasqueira,
                    has_deck: deck,
                    has_disabled_access: acessoDeficientes,
                    has_dock: doca,
                    has_garden: jardim,
                    has_gated_entry: entradaFechada,
                    has_green_house: estufa,
                    has_hot_tub_spa: hidro,
                    has_patio: patio,
                    has_pond: lago,
                    has_pool: piscina,
                    has_porch: varanda,
                    has_rv_parking: vagaTrailer,
                    has_sports_court: quadra,
                    has_sprinkler_system: aspercao,
                    is_water_front: beiraMar
                }
                let structure = {
                    building_name: nomeCondominio,
                    unit_level: andarDoImovel,
                    is_new_construction: novoImovel,
                    parking_total: nVagas,
                    has_parking: parseInt(nVagas) > 0,
                    stories: nAndares,
                    total_units: nUnidades,
                    total_buildings: nTorres,
                    exterior_features,
                    builder_name: construtora,
                }
                let json = {
                    proprietario,
                    contrato,
                    source_system_name: "KW Sourced",
                    market_center: mc.kwId,
                    kww_region: region.kwId,
                    mls_id: region.mlsId,
                    mls_name: region.mlsName,
                    currency_code: region.currencyCode,
                    manual_entry: true,
                    kw_agent: true,
                    list_kw_uid: listingOwner ? parseInt(listingOwner.kwuid) : parseInt(user.kwuid),
                    kw_mc: true,
                    kw_listing: true,
                    is_kww_listing: true,
                    version: "2.0.0",
                    current_list_price: unmaskCurrency(valor),
                    list_category_id: listCategory,
                    list_category: translateListCategory(listCategory),
                    list_type_id: listType,
                    list_type: translateListType(listType),
                    list_status_id: listStatus,
                    list_status: translateListStatus(listStatus),
                    prop_type_id: propType,
                    prop_type: translatePropType(propType),
                    prop_subtype_id: propSubType,
                    prop_subtype: translatePropSubType(propSubType),
                    list_address,
                    structure,
                    bairro,
                    year_built: anoConstrucao,
                    total_bed: nQuartos,
                    half_bath: nLavabos,
                    full_bath: nBanheiros,
                    total_bath: String((parseInt(nBanheiros) + parseInt(nLavabos))),
                    living_area: metragem,
                    living_area_units: region.units,
                    lot_size_area: tamanhoTerreno,
                    lot_size_units: region.units,
                    list_desc: descricao.replace(/\n/,/\#\s\s\#/),
                    teamId: listingOwner ? listingOwner.teamId : user.teamId,
                    fileTermoDeVenda
                }
                console.log('****************** UNITS:' + region.units)
                console.log(json)
                let res = await axios.post(BASE_URL + '/listings', json, config);
                if (res.data)
                    alert('Cadastro bem sucedido! Agora adicione as imagens do listing.');
                navigate('/listings/edit-listing/' + res.data._id)

            } catch (e) {
                console.error(e);
                if (e?.response?.data?.msg)
                    alert(e?.response?.data?.msg)
                else {
                    alert('Erro de Conexão.')
                    //logout();
                }
            }

        } catch (e) {
            alert('O endereço do imóvel não foi encontrado.');
            return;
        }

    }

    const getCEP = async () => {
        setRua('')
        setBairro('')
        setEstado('')
        setCidade('')
        try {
            let res = await axios.get('https://viacep.com.br/ws/' + cep + '/json/')
            let data = res.data

            setRua(data.logradouro)
            setBairro(data.bairro)
            setEstado(data.uf)
            setCidade(data.localidade)
        } catch (e) {
            alert('CEP não encontrado.')
        }

    }
    return (
        <div>
            <Dialog open={open} onClose={close} maxWidth='sm'>
                <DialogTitle>
                    <div style={{ width: '500px' }}>
                        <h1>Cadastrar Novo Listing</h1>
                        <Divider style={{ width: '100%' }} />
                    </div>
                </DialogTitle>
                <DialogContent>
                    <br />
                    {(jwtUser.isMcAdmin || (jwtUser.permissionLevel === 'ADMIN' || jwtUser.permissionLevel === 'SUPER' )) &&
                        <Autocomplete
                            options={users}
                            getOptionLabel={(option) => option.fullName}
                            filterSelectedOptions
                            noOptionsText='Nenhum Usuário'
                            value={listingOwner}
                            onChange={(e, newValue) => {
                                setListingOwner(newValue)
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Agente Vendedor"
                                    placeholder="Agente Vendedor"
                                />
                            )}
                        />
                    }
                    <br />
                    <br />

                    <h6>Proprietário Principal</h6>
                    <br />
                    <br />
                    <TextModal
                        label='Nome do Proprietário'
                        holder='João dos Santos Silva'
                        errorMsg='Informar nome completo (apenas caracteres latinos).'
                        validator={(str) => { 
                            console.log( FULL_NAME_VALIDATION.test(str) )
                            return !FULL_NAME_VALIDATION.test(str)
                        }}
                        f={ setOwnerName }
                    />
                    <TextModal
                        label='CPF'
                        holder='CPF do Proprietário'
                        errorMsg='CPF inválido!'
                        validator={(str) => { 
                            return !VALIDA_CPF(str)
                        }}
                        f={ setOwnerCPF }
                    />
                    <TextModal
                        label='E-Mail'
                        holder='E-Mail do Proprietário'
                        errorMsg='E-Mail inválido!'
                        validator={(str) => { 
                            return !EMAIL_VALIDATION.test( str )
                        }}
                        f={ setOwnerEmail }
                    />
                    <TextField
                        variant='outlined'
                        label='Telefone do Proprietário'
                        color='secondary'
                        fullWidth
                        style={{ marginTop: '5px', marginBottom: '5px' }}
                        onChange={(e) => setOwnerPhone(e.target.value)}
                        value={ownerPhone}
                    />
                    <br/>
                    <Autocomplete
                            options={['Pessoa Física', 'Pessoa Jurídica']}
                            getOptionLabel={(option) => option}
                            filterSelectedOptions
                            //noOptionsText='Nenhum Usuário'
                            value={ownerType}
                            onChange={(e, newValue) => {
                                setOwnerType(newValue)
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Tipo de Proprietário"
                                    placeholder="Selecionar Pessoa Física ou Jurídica"
                                />
                            )}
                    />
                    <br/>
                    <br/>
                    <h3>Contrato</h3>
                    <br/>

                    <TextField
                        variant='outlined'
                        label='Matricula do Imóvel'
                        color='secondary'
                        fullWidth
                        style={{ marginTop: '5px', marginBottom: '5px' }}
                        onChange={(e) => setMatriculaImovel(e.target.value)}
                        value={matriculaImovel}
                    />
                    <br />
                    <br/>
                    <Autocomplete
                            options={['email', 'whatsapp']}
                            getOptionLabel={(option) => option}
                            filterSelectedOptions
                            //noOptionsText='Nenhum Usuário'
                            value={ownerToken}
                            onChange={(e, newValue) => {
                                setOwnerToken(newValue)
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Meio de assinatura"
                                    placeholder="email ou whatsapp"
                                />
                            )}
                    />
                    <br />
                    <br/>
                    <label>
                        Data do Contrato <br/>
                        <input id="date" type="date" value={contratoData} onChange={
                            (e) => setContratoData( e.target.value ) }/>
                    </label>
                    <br/>
                    <br />
                    <div style={{ display: 'inline-block' }}>
                     <p>Carregar Termo de Venda?<Checkbox
                            color='primary'
                            checked={termoDeVenda}
                            onChange={(e) =>{
                                let val = !termoDeVenda
                                setTermoDeVenda( val )}}
                        /></p>
                        { termoDeVenda && <SingleFileUpload onFileUpload={onFileUpload} />}
                    </div>
                    <br/>
                    <br/>
                    <h6>Endereço</h6>
                    <br />
                    <TextField
                        variant='outlined'
                        label='CEP *'
                        color='secondary'
                        fullWidth
                        style={{ marginTop: '5px', marginBottom: '5px' }}
                        onChange={(e) => setCep(e.target.value)}
                        value={cep}
                    />
                    <button className='btn btnPrimary' onClick={getCEP}>Puxar Endereço Pelo CEP</button>
                    <br />
                    <br />
                    <TextField
                        variant='outlined'
                        label='Rua *'
                        color='secondary'
                        fullWidth
                        style={{ marginTop: '5px', marginBottom: '5px' }}
                        onChange={(e) => setRua(e.target.value)}
                        value={rua}
                    />
                    <TextField
                        variant='outlined'
                        label='Número *'
                        color='secondary'
                        fullWidth
                        style={{ marginTop: '5px', marginBottom: '5px' }}
                        onChange={(e) => setNumero(e.target.value)}
                        value={numero}
                    />
                    <TextField
                        variant='outlined'
                        label='Complemento'
                        color='secondary'
                        fullWidth
                        style={{ marginTop: '5px', marginBottom: '5px' }}
                        onChange={(e) => setCompl(e.target.value)}
                        value={compl}
                    />
                    <TextField
                        variant='outlined'
                        label='Bairro *'
                        color='secondary'
                        fullWidth
                        style={{ marginTop: '5px', marginBottom: '5px' }}
                        onChange={(e) => setBairro(e.target.value)}
                        value={bairro}
                    />
                    <TextField
                        variant='outlined'
                        label='Estado *'
                        color='secondary'
                        fullWidth
                        style={{ marginTop: '5px', marginBottom: '5px' }}
                        onChange={(e) => setEstado(e.target.value)}
                        value={estado}
                    />
                    <TextField
                        variant='outlined'
                        label='Cidade *'
                        color='secondary'
                        fullWidth
                        style={{ marginTop: '5px', marginBottom: '5px' }}
                        onChange={(e) => setCidade(e.target.value)}
                        value={cidade}
                    />
                    <br />
                    <br />
                    <Divider />
                    <br />
                    <h6>Tipo de Imóvel</h6>
                    <br />
                    <TextField
                        variant='outlined'
                        select
                        label='Categoria *'
                        color='secondary'
                        fullWidth
                        style={{ marginTop: '5px', marginBottom: '5px' }}
                        onChange={(e) => setListCategory(e.target.value)}
                        value={listCategory}
                    >
                        <MenuItem value={0}>Outro</MenuItem>
                        <MenuItem value={1}>Locação</MenuItem>
                        <MenuItem value={2}>À Venda</MenuItem>
                        <MenuItem value={3}>Vendido</MenuItem>
                        <MenuItem value={4}>Fora do Mercado</MenuItem>
                    </TextField>
                    <TextField
                        variant='outlined'
                        select
                        label='Tipo de Cadastro *'
                        color='secondary'
                        fullWidth
                        style={{ marginTop: '5px', marginBottom: '5px' }}
                        onChange={(e) => setListType(e.target.value)}
                        value={listType}
                    >

                        <MenuItem value={4}>Com Exclusividade</MenuItem>
                        <MenuItem value={5}>Sem Exclusividade</MenuItem>

                    </TextField>
                    <TextField
                        variant='outlined'
                        select
                        label='Status *'
                        color='secondary'
                        fullWidth
                        style={{ marginTop: '5px', marginBottom: '5px' }}
                        onChange={(e) => setListStatus(e.target.value)}
                        value={listStatus}
                    >
                        <MenuItem value={1}>Ativo</MenuItem>
                        <MenuItem value={2}>Pendente</MenuItem>
                        <MenuItem value={3}>Vendido</MenuItem>
                        <MenuItem value={4}>Em Negociação</MenuItem>
                        <MenuItem value={6}>Pendências Contratuais</MenuItem>
                        <MenuItem value={12}>Cancelado</MenuItem>
                        <MenuItem value={13}>Removido</MenuItem>
                        <MenuItem value={14}>Expirado</MenuItem>
                        <MenuItem value={15}>Alugado</MenuItem>
                        <MenuItem value={17}>Vendido</MenuItem>
                        <MenuItem value={18}>Não Disponível</MenuItem>
                    </TextField>
                    <TextField
                        variant='outlined'
                        select
                        label='Tipo de Imóvel *'
                        color='secondary'
                        fullWidth
                        style={{ marginTop: '5px', marginBottom: '5px' }}
                        onChange={(e) => setPropType(e.target.value)}
                        value={propType}
                    >
                        <MenuItem value={1}>Oportunidade de Investimento</MenuItem>
                        <MenuItem value={2}>Comercial</MenuItem>
                        <MenuItem value={3}>Interesse Mútuo</MenuItem>
                        <MenuItem value={4}>Rural</MenuItem>
                        <MenuItem value={5}>Terreno</MenuItem>
                        <MenuItem value={6}>Casas Modulares</MenuItem>
                        <MenuItem value={7}>Casa de Férias</MenuItem>
                        <MenuItem value={8}>Residencial</MenuItem>
                        <MenuItem value={9}>Oportunidade de Rentabilidade</MenuItem>
                        <MenuItem value={10}>Outro</MenuItem>
                    </TextField>
                    <TextField
                        variant='outlined'
                        select
                        label='Subtipo de Imóvel *'
                        color='secondary'
                        fullWidth
                        style={{ marginTop: '5px', marginBottom: '5px' }}
                        onChange={(e) => setPropSubType(e.target.value)}
                        value={propSubType}
                    >
                        <MenuItem value={1}>Apartamento</MenuItem>
                        <MenuItem value={2}>Vaga de Barco</MenuItem>
                        <MenuItem value={3}>Chalé</MenuItem>
                        <MenuItem value={4}>Condomínio</MenuItem>
                        <MenuItem value={5}>Vaga de Garagem</MenuItem>
                        <MenuItem value={6}>Duplex</MenuItem>
                        <MenuItem value={7}>Fazenda</MenuItem>
                        <MenuItem value={8}>Casa Modular</MenuItem>
                        <MenuItem value={9}>Casa Modular com Terreno</MenuItem>
                        <MenuItem value={10}>Casa Móvel</MenuItem>
                        <MenuItem value={11}>OYO</MenuItem>
                        <MenuItem value={12}>Quadruplex</MenuItem>
                        <MenuItem value={13}>Rancho</MenuItem>
                        <MenuItem value={14}>Casa Geminada</MenuItem>
                        <MenuItem value={15}>Casa Não Geminada</MenuItem>
                        <MenuItem value={16}>Cooperativa</MenuItem>
                        <MenuItem value={17}>Timeshare</MenuItem>
                        <MenuItem value={18}>Townhouse</MenuItem>
                        <MenuItem value={19}>Triplex</MenuItem>
                        <MenuItem value={20}>Agricultura</MenuItem>
                        <MenuItem value={21}>Negócios</MenuItem>
                        <MenuItem value={22}>Hotel-Motel</MenuItem>
                        <MenuItem value={23}>Industrial</MenuItem>
                        <MenuItem value={24}>Mixed-Use</MenuItem>
                        <MenuItem value={25}>Multi-Family</MenuItem>
                        <MenuItem value={26}>Comercial</MenuItem>
                        <MenuItem value={27}>Loja</MenuItem>
                        <MenuItem value={28}>Terreno Não Urbano</MenuItem>
                        <MenuItem value={29}>Galpão</MenuItem>
                        <MenuItem value={30}>Comunidade Adulta</MenuItem>
                        <MenuItem value={31}>Casa de Férias</MenuItem>
                        <MenuItem value={32}>Outro</MenuItem>
                    </TextField>
                    <br />
                    <br />
                    <Divider />
                    <br />
                    <h6>Dados do Imóvel</h6>
                    <br />
                    <TextField
                        variant='outlined'
                        label='Metragem *'
                        color='secondary'
                        fullWidth
                        type={'number'}
                        style={{ marginTop: '5px', marginBottom: '5px' }}
                        onChange={(e) => setMetragem(e.target.value)}
                        value={metragem}
                    />
                    <TextField
                        variant='outlined'
                        label='Nº de Quartos *'
                        color='secondary'
                        fullWidth
                        style={{ marginTop: '5px', marginBottom: '5px' }}
                        type={'number'}
                        onChange={(e) => setNQuartos(e.target.value)}
                        value={nQuartos}
                    />
                    <TextField
                        variant='outlined'
                        label='Nº de Lavabos *'
                        color='secondary'
                        fullWidth
                        type={'number'}
                        style={{ marginTop: '5px', marginBottom: '5px' }}
                        onChange={(e) => setNLavabos(e.target.value)}
                        value={nLavabos}
                    />
                    <TextField
                        variant='outlined'
                        label='Nº de Banheiros *'
                        color='secondary'
                        fullWidth
                        type={'number'}
                        style={{ marginTop: '5px', marginBottom: '5px' }}
                        onChange={(e) => setNBanheiros(e.target.value)}
                        value={nBanheiros}
                    />
                    <TextField
                        variant='outlined'
                        label='Valor *'
                        color='secondary'
                        fullWidth
                        style={{ marginTop: '5px', marginBottom: '5px' }}
                        onChange={(e) => setValor(maskCurrency(e.target.value))}
                        value={valor}
                    />
                    <br />
                    <br />
                    <Divider />
                    <br />
                    <h6>Estrutura</h6>
                    <br />
                    <TextField
                        variant='outlined'
                        label='Nome do Condomínio'
                        color='secondary'
                        fullWidth
                        style={{ marginTop: '5px', marginBottom: '5px' }}
                        onChange={(e) => setNomeCondominio(e.target.value)}
                        value={nomeCondominio}
                    />
                    <TextField
                        variant='outlined'
                        label='Andar do Imóvel'
                        color='secondary'
                        fullWidth
                        type={'number'}
                        style={{ marginTop: '5px', marginBottom: '5px' }}
                        onChange={(e) => setAndarDoImovel(e.target.value)}
                        value={andarDoImovel}
                    />
                    <TextField
                        variant='outlined'
                        label='Estado do Imóvel'
                        select
                        color='secondary'
                        fullWidth
                        style={{ marginTop: '5px', marginBottom: '5px' }}
                        onChange={(e) => setNovoImovel(e.target.value)}
                        value={novoImovel}
                    >
                        <MenuItem value={true}>Novo</MenuItem>
                        <MenuItem value={false}>Usado</MenuItem>
                    </TextField>
                    <TextField
                        variant='outlined'
                        label='Nº de Vagas'
                        color='secondary'
                        fullWidth
                        type={'number'}
                        style={{ marginTop: '5px', marginBottom: '5px' }}
                        onChange={(e) => setNVagas(e.target.value)}
                        value={nVagas}
                    />
                    <TextField
                        variant='outlined'
                        label='Construtora'
                        color='secondary'
                        fullWidth
                        style={{ marginTop: '5px', marginBottom: '5px' }}
                        onChange={(e) => setConstrutora(e.target.value)}
                        value={construtora}
                    />
                    <TextField
                        variant='outlined'
                        label='Total de Unidades'
                        color='secondary'
                        fullWidth
                        type={'number'}
                        style={{ marginTop: '5px', marginBottom: '5px' }}
                        onChange={(e) => setNUnidades(e.target.value)}
                        value={nUnidades}
                    />
                    <TextField
                        variant='outlined'
                        label='Nº de Andares'
                        color='secondary'
                        fullWidth
                        type={'number'}
                        style={{ marginTop: '5px', marginBottom: '5px' }}
                        onChange={(e) => setNAndares(e.target.value)}
                        value={nAndares}
                    />
                    <TextField
                        variant='outlined'
                        label='Nº de Torres'
                        color='secondary'
                        fullWidth
                        type={'number'}
                        style={{ marginTop: '5px', marginBottom: '5px' }}
                        onChange={(e) => setNTorres(e.target.value)}
                        value={nTorres}
                    />
                    <div style={{ display: 'inline-block' }}>
                        <Checkbox
                            color='primary'
                            checked={churrasqueira}
                            onChange={(e) => setChurrasqueira(e.target.checked)}
                        />Churrasqueira
                    </div>
                    <div style={{ display: 'inline-block' }}>
                        <Checkbox
                            color='primary'
                            checked={deck}
                            onChange={(e) => setDeck(e.target.checked)}
                        />Deck
                    </div>
                    <div style={{ display: 'inline-block' }}>
                        <Checkbox
                            color='primary'
                            checked={acessoDeficientes}
                            onChange={(e) => setAcessoDeficientes(e.target.checked)}
                        />Acesso Deficientes Físicos
                    </div>
                    <div style={{ display: 'inline-block' }}>
                        <Checkbox
                            color='primary'
                            checked={doca}
                            onChange={(e) => setDoca(e.target.checked)}
                        />Doca
                    </div>
                    <div style={{ display: 'inline-block' }}>
                        <Checkbox
                            color='primary'
                            checked={jardim}
                            onChange={(e) => setJardim(e.target.checked)}
                        />Jardim
                    </div>
                    <div style={{ display: 'inline-block' }}>
                        <Checkbox
                            color='primary'
                            checked={entradaFechada}
                            onChange={(e) => setEntradaFechada(e.target.checked)}
                        />Entrada Fechada
                    </div>
                    <div style={{ display: 'inline-block' }}>
                        <Checkbox
                            color='primary'
                            checked={estufa}
                            onChange={(e) => setEstufa(e.target.checked)}
                        />Estufa
                    </div>
                    <div style={{ display: 'inline-block' }}>
                        <Checkbox
                            color='primary'
                            checked={hidro}
                            onChange={(e) => setHidro(e.target.checked)}
                        />Hidromassagem
                    </div>
                    <div style={{ display: 'inline-block' }}>
                        <Checkbox
                            color='primary'
                            checked={patio}
                            onChange={(e) => setPatio(e.target.checked)}
                        />Pátio
                    </div>
                    <div style={{ display: 'inline-block' }}>
                        <Checkbox
                            color='primary'
                            checked={lago}
                            onChange={(e) => setLago(e.target.checked)}
                        />Laguinho
                    </div>
                    <div style={{ display: 'inline-block' }}>
                        <Checkbox
                            color='primary'
                            checked={piscina}
                            onChange={(e) => setPiscina(e.target.checked)}
                        />Piscina
                    </div>
                    <div style={{ display: 'inline-block' }}>
                        <Checkbox
                            color='primary'
                            checked={varanda}
                            onChange={(e) => setVaranda(e.target.checked)}
                        />Varanda
                    </div>
                    <div style={{ display: 'inline-block' }}>
                        <Checkbox
                            color='primary'
                            checked={vagaTrailer}
                            onChange={(e) => setVagaTrailer(e.target.checked)}
                        />Vaga para Trailer
                    </div>
                    <div style={{ display: 'inline-block' }}>
                        <Checkbox
                            color='primary'
                            checked={quadra}
                            onChange={(e) => setQuadra(e.target.checked)}
                        />Quadra
                    </div>
                    <div style={{ display: 'inline-block' }}>
                        <Checkbox
                            color='primary'
                            checked={aspercao}
                            onChange={(e) => setAspercao(e.target.checked)}
                        />Sistema de Irrigação
                    </div>
                    <div style={{ display: 'inline-block' }}>
                        <Checkbox
                            color='primary'
                            checked={beiraMar}
                            onChange={(e) => setBeiraMar(e.target.checked)}
                        />Beira do Mar
                    </div>
                    <br />
                    <br />
                    <Divider />
                    <br />
                    <h6>Detalhes</h6>
                    <br />
                    <TextField
                        variant='outlined'
                        label='Ano da Construção'
                        color='secondary'
                        fullWidth
                        type={'number'}
                        style={{ marginTop: '5px', marginBottom: '5px' }}
                        onChange={(e) => setAnoConstrucao(e.target.value)}
                        value={anoConstrucao}
                    />
                    <TextField
                        variant='outlined'
                        label='Tamanho do Terreno'
                        color='secondary'
                        fullWidth
                        type={'number'}
                        style={{ marginTop: '5px', marginBottom: '5px' }}
                        onChange={(e) => setTamanhoTerreno(e.target.value)}
                        value={tamanhoTerreno}
                    />
                    <TextField
                        label='Descrição'
                        color='secondary'
                        fullWidth
                        multiline
                        minRows={3}
                        maxRows={3}
                        style={{ marginTop: '5px', marginBottom: '5px' }}
                        onChange={(e) => setDescricao(String(e.target.value))}
                        value={descricao}
                    />
                    {/* <br />
                    <br />
                    <Divider />
                    <br />
                    <h6>Fotos</h6>
                    <br />
                    {mainPhoto &&
                        <>
                            <img src={URL.createObjectURL(mainPhoto)} style={{ width: '150px', height: '150px', objectFit: 'cover' }} />
                            <br />
                        </>
                    }
                    {
                        !mainPhoto &&
                        <>
                            <img src={placeholder} style={{ width: '150px', height: '150px', objectFit: 'cover' }} />
                            <br />
                        </>
                    }
                    <label className='btn btnSecondary'>
                        Foto Principal
                        <input type="file" style={{ display: 'none' }} accept="image/*"
                            onChange={(e) => setMainPhoto(e.target.files[0])}
                        />
                    </label>
                    <Divider style={{ marginTop: '5px', marginBottom: '5px' }} />
                    {[...photos].map(photo => {
                        return <img src={URL.createObjectURL(photo)} style={{ width: '132px', height: '132px', objectFit: 'cover' }} />
                    })}
                    <br />
                    {
                        photos.length === 0 &&
                        <>
                            <img src={placeholder} style={{ width: '132px', height: '132px', objectFit: 'cover' }} />
                            <img src={placeholder} style={{ width: '132px', height: '132px', objectFit: 'cover' }} />
                            <img src={placeholder} style={{ width: '132px', height: '132px', objectFit: 'cover' }} />
                            <img src={placeholder} style={{ width: '132px', height: '132px', objectFit: 'cover' }} />
                            <br />
                        </>
                    }
                    <label className='btn btnSecondary'>
                        Outras Fotos
                        <input type="file" style={{ display: 'none' }} accept="image/*" multiple
                            onChange={(e) => setPhotos(e.target.files)}
                        />
                    </label> */}

                </DialogContent>
                <DialogActions>
                    <button className='btn btnSecondary' onClick={close}>Cancelar</button>
                    <button className='btn btnPrimary' onClick={submit}>Cadastrar</button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
