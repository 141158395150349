import { Autocomplete, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControlLabel, Grid, MenuItem, TextField } from '@mui/material'
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../contexts/AuthContext';
import { BASE_URL, SOUKW_URL } from '../sharedComponents/Constants';

export default function NewTeamModal({ open, close, mcs }) {
    const { token, user, logout } = useContext(AuthContext);
    const [teamName, setTeamName] = useState('');
    const [teamMcId, setTeamMcId] = useState('');
    const [admins, setAdmins] = useState([]);

    const [users, setUsers] = useState([]);

    useEffect(() => {
        async function getData() {
            try {
                const config = {
                    headers: { Authorization: `Bearer ${token}` }
                };
                let userRes = await axios.get(BASE_URL + '/users/by_mc/' + teamMcId, config);
                setUsers(userRes.data);

            } catch (e) {
                console.error(e);
                if (e?.response?.data?.msg)
                    alert(e?.response?.data?.msg)
                else {
                    alert('Erro de Conexão.')
                    logout();
                }
            }
        }
        if (teamMcId)
            getData();
    }, [teamMcId]);

    const submitTeam = async () => {
        try {
            const config = {
                headers: { Authorization: `Bearer ${token}` }
            };

            let adminIds = admins.map(a => a._id)
            let res = await axios.post
                (
                    BASE_URL + '/teams/',
                    {
                        name: teamName,
                        marketCenterId: teamMcId,
                        admins: adminIds,
                    },
                    config
                );
            alert('Cadastro de time bem sucedido!');
            document.location.reload();
        } catch (e) {
            console.error(e);
            if (e?.response?.data?.msg)
                alert(e?.response?.data?.msg)
            else {
                alert('Erro de Conexão.')
                logout();
            }
        }
    }
    return (
        <div>
            <Dialog open={open} onClose={close} maxWidth='sm'>
                <DialogTitle>
                    <div style={{ width: '500px' }}>
                        <h1>Cadastrar Time</h1>
                        <Divider style={{ width: '100%' }} />
                    </div>
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <h6>Cadastrar Time</h6>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12}>
                            <br />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                label='Nome do Time *'
                                variant='outlined'
                                fullWidth
                                onChange={(e) => setTeamName(e.target.value)}
                                value={teamName}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <br />
                        </Grid>
                        <Grid item xs={12}>
                            <br />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label='MC *'
                                select
                                variant='outlined'
                                fullWidth
                                onChange={(e) => setTeamMcId(e.target.value)}
                                value={teamMcId}
                            >
                                {
                                    mcs?.map(mc => {
                                        return (
                                            <MenuItem value={mc._id}>{mc.name}</MenuItem>
                                        )
                                    })
                                }
                            </TextField>
                        </Grid>

                        <Grid item xs={12}>
                            <br />
                        </Grid>
                        <Grid item xs={12}>
                            <Autocomplete
                                multiple
                                options={users}
                                getOptionLabel={(option) => option.fullName}
                                filterSelectedOptions
                                value={admins}
                                disabled={!teamMcId}
                                noOptionsText='Nenhum Usuário'
                                onChange={(e, newValue) => setAdmins(newValue)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Administradores"
                                        placeholder="Administradores"
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <button className='btn btnSecondary' onClick={close}>Cancelar</button>
                    <button className='btn btnPrimary' onClick={submitTeam}>
                        Cadastrar Time
                    </button>
                </DialogActions>
            </Dialog>
        </div>
    )
}