import { Grid } from "@mui/material";
import revolutiLogo from '../assets/imgs/revolutiLogo.png'


export default function SuporteJuridico() {
    return (
        <>
            {/* ===== PibeImob ===== */}
            <Grid container
                xs={8}
                md={6}
                lg={6}
                paddingTop={1}
            >
                <div className="imGrid" >
                    <div className="imCard">
                        <img className="imLogo" src={revolutiLogo} />
                        <div className="dataLandInfo">
                            <h1 className="imH1">
                                Revoluti
                            </h1>
                            <p className="imP">
                                A Revoluti é uma startup que está com gás total e muito comprometimento para agilizar o processo das transações,
                                então disponibilizo abaixo todos os termos acordados da parceria!
                            </p>
                        </div>
                    </div>
                </div>
                <div className="gridButton">
                    <div className="imGrid">
                        <Grid item xs={10}
                            style={{
                                marginTop: '1rem',
                                marginLeft: '3.5rem'
                            }}>
                            <a target='_blank' href="https://drive.google.com/file/d/1fPILyJSYRPqPxhkwkOHYYmv0Ah632y_Z/view?usp=sharing">
                                <button className='btn btnSecondary'
                                >
                                    Termo de Parceria
                                </button>
                            </a>
                        </Grid>

                        <Grid item xs={2}
                            style={{
                                marginTop: '1rem'
                            }}>
                            <a href="https://app.revoluti.com.br/login" target='_blank'>
                                <button className='btn btnPrimary'
                                >
                                    Acessar
                                </button>
                            </a>
                        </Grid>
                    </div>
                </div>
            </Grid>
            <div style={{
                width: '4rem'
            }}>
            </div>

        </>
    )
}