import { Divider, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Slider, TextField, Zoom } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../contexts/AuthContext';
import NewListingModal from '../views/NewListingModal';
import placeholder from '../assets/imgs/no-image.jpg'
import axios from 'axios';
import { Link } from 'react-router-dom';
import Loading from '../views/Loading';
import LazyLoad from "react-lazyload";
import { Box } from '@mui/system';
import FilterListingModal from '../views/FilterListingModal';
import NewListingCaptacao from '../views/NewListingCaptacao';
import { BASE_URL, SOUKW_URL } from '../sharedComponents/Constants';

export default function Listings() {
    const [newListingCaptacaoOpen, setNewListingCaptacaoOpen] = useState(false);
    const { user, logout, token } = useContext(AuthContext);
    const [newListingModalOpen, setNewListingModalOpen] = useState(false);
    const [listings, setListings] = useState([]);
    const [users, setUsers] = useState([]);
    const [mcs, setMcs] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(1);
    const [filteringMode, setFilteringMode] = useState(false);
    const [enderecoFilter, setEnderecoFilter] = useState('');
    const [bairroFilter, setBairroFilter] = useState('');
    const [cityFilter, setCityFilter] = useState('');
    const [condominioFilter, setCondominioFilter] = useState('');
    const [incorporadoraFilter, setIncorporadoraFilter] = useState('');
    const [metragemFilter, setMetragemFilter] = useState([1, 1000]);
    const [valorFilter, setValorFilter] = useState([1000, 50000000]);
    const [quartosFilter, setQuartosFilter] = useState(1);
    const [banheirosFilter, setBanheirosFilter] = useState(1);
    const [vagasFilter, setVagasFilter] = useState(0);
    const [filters, setFilters] = useState({});
    const [loading, setLoading] = useState(true);

    const lastFilter = {};

    function deepEqual(obj1, obj2) {
        if (obj1 === obj2) {
            return true;
        }

        if (typeof obj1 !== "object" || obj1 === null || typeof obj2 !== "object" || obj2 === null) {
            return false;
        }

        let keys1 = Object.keys(obj1);
        let keys2 = Object.keys(obj2);

        if (keys1.length !== keys2.length) {
            return false;
        }

        for (let key of keys1) {
            if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
                return false;
            }
        }

        return true;
    }


    function openNewListingModal() {
        setNewListingModalOpen(true)
    }

    function closeNewListingModal() {
        setNewListingModalOpen(false)
    }

    function openFilterListingModal() {
        setFilteringMode(true)
    }

    function closeFilterListingModal() {
        setFilteringMode(false)
    }

    const openNewListingCaptacao = () => {
        setNewListingCaptacaoOpen(true)
    }
    const closeNewListingCaptacao = () => {
        setNewListingCaptacaoOpen(false)
    }

    useEffect(() => {
        const params = new URLSearchParams(window.location.search) // id=123
        //let page = params.get('page')
        if (!params.get('page'))
            // page = 1
            setPage(1)
    }, [])

    /**
    useEffect(() => {
        console.log("Atualizando Listings.js")
        console.log('filters', filters)
        async function getData() {
            try {
                setPage(1)
            } catch (e) {
                console.error(e);
                setLoading(false);
                if (e?.response?.data?.msg)
                    alert(e?.response?.data?.msg)
                else {
                    alert('Erro de Conexão.')
                    logout();
                }
            }
        }
        getData();
    }, [filters])*/

    useEffect(() => {
        console.log("page: ", page)
        console.log('filters: ', filters)
        async function getData() {
            setLoading(true);
            try {
                const config = {
                    headers: { Authorization: `Bearer ${token}` }
                };
                //changed here
                let url = BASE_URL + '/listings/myListings?page=' + page
                if (filters.city)
                    url = url + '&city=' + filters.city
                if (filters.bairro)
                    url = url + '&neighbourhood=' + filters.bairro
                if (filters.address)
                    url = url + '&address=' + filters.address
                if (filters.condominio)
                    url = url + '&condominio=' + filters.condominio
                if (filters.construtora)
                    url = url + '&construtora=' + filters.construtora
                if (filters.valorMin && filters.valorMin !== 1000)
                    url = url + '&valorMin=' + filters.valorMin
                if (filters.valorMax && filters.valorMax !== 50000000)
                    url = url + '&valorMax=' + filters.valorMax
                if (filters.metragemMin && filters.metragemMin !== 1)
                    url = url + '&metragemMin=' + filters.metragemMin
                if (filters.metragemMax && filters.metragemMax !== 1000)
                    url = url + '&metragemMax=' + filters.metragemMax
                if (filters.quartos && parseInt(filters.quartos) !== 1)
                    url = url + '&quartos=' + filters.quartos
                if (filters.banheiros && parseInt(filters.banheiros) !== 1)
                    url = url + '&banheiros=' + filters.banheiros
                if (filters.vagas && parseInt(filters.vagas) !== 0)
                    url = url + '&vagas=' + filters.vagas

                console.log(url)
                let results = await axios.get(
                    url,
                    config
                );
                console.log(url)
                setListings(results.data?.data?.reverse());
                console.log(results.data?.data)
                setTotal(results.data?.totalItems)
                setTotalPages(results.data?.totalPages)
                setPage(results.data?.page)
                setLoading(false);
            } catch (e) {
                console.error(e);
                setLoading(false);
                if (e?.response?.data?.msg)
                    alert(e?.response?.data?.msg)
                else {
                    alert('Erro de Conexão.')
                    logout();
                }
            }
        }
        getData();
        console.log(page)
    }, [filters, page])

    useEffect(() => {
        async function getData() {
            try {
                const config = {
                    headers: { Authorization: `Bearer ${token}` }
                };
                let usersResults = await axios.get(BASE_URL + '/users', config)
                let mcsResults = await axios.get(BASE_URL + '/mcs', config)
                setUsers(usersResults.data)
                setMcs(mcsResults.data.data)

            } catch (e) {
                console.error(e);
                if (e?.response?.data?.msg)
                    alert(e?.response?.data?.msg)
                else {
                    alert('Erro de Conexão.')
                    logout();
                }

            }
        }
        getData()
    }, [])


    const maskCurrency = (num = '.') => {
        let initial = String(num).split('.').join('').replace(/[^0-9.]/g, '');
        let formattedNum = initial;
        switch (initial.length) {
            case 1: formattedNum = num;
                break;
            case 2: formattedNum = num;
                break;
            case 3: formattedNum = num;
                break;
            case 4: formattedNum = [
                formattedNum.slice(0, 1), '.', formattedNum.slice(1)].join('');
                break;
            case 5: formattedNum = [
                formattedNum.slice(0, 2), '.', formattedNum.slice(2)].join('');
                break;
            case 6: formattedNum = [
                formattedNum.slice(0, 3), '.', formattedNum.slice(3)].join('');
                break;
            case 7: formattedNum = [
                formattedNum.slice(0, 1), '.', formattedNum.slice(1, 4), '.',
                formattedNum.slice(4)].join('');
                break;
            case 8: formattedNum = [
                formattedNum.slice(0, 2), '.', formattedNum.slice(2, 5), '.',
                formattedNum.slice(5)].join('');
                break;
            case 9: formattedNum = [
                formattedNum.slice(0, 3), '.', formattedNum.slice(3, 6), '.',
                formattedNum.slice(6)].join('');
                break;
            default:
        }
        return 'R$ ' + String(formattedNum)
            .replace(/[^0-9.]/g, '')
            .slice(0, 10)
    }

    return (
        <Grid container spacing={0} style={{ height: '100%' }}
            justifyItems='flex-start' alignItems={'flex-start'}>
            <Grid item xs={12} sm={10} style={{ textAlign: 'left', }}>
                <h1>Listings Legados</h1>
            </Grid>
            {/* <Grid item xs={12} sm={2} style={{ textAlign: 'left', }}>
                <button className='btn btnPrimary'
                    onClick={openNewListingCaptacao}
                >
                    Pré Cadastro
                </button>
            </Grid> */}
            <Grid item xs={12} >
                <Grid container
                    spacing={0}
                    style={{ height: '100%' }}
                    justifyItems='flex-start'
                    alignItems={'flex-start'}
                >
                    <Grid item xs={12} sm={12} style={{ paddingBottom: '1rem' }} >
                        <button className='btn btnSecondary' onClick={() => {
                            setEnderecoFilter('')
                            setBairroFilter('')
                            setCityFilter('')
                            if (filteringMode)
                                setFilters({})
                            setFilteringMode(!filteringMode)
                        }}>
                            {filteringMode ? 'Cancelar' : 'Filtrar'}
                        </button>
                        {Object.keys(filters).length > 0 &&
                            <button className='btn btnSecondary' onClick={() => {
                                setFilters({})
                                setPage(1)
                            }}>
                                Limpar Filtros
                            </button>
                        }
                    </Grid>
                    <Zoom in={false} mountOnEnter unmountOnExit>
                        <div>
                            <Grid item xs={12}>
                                <TextField
                                    placeholder='Filtrar Endereço...'
                                    onChange={(e) => {
                                        console.log(e.target.value)
                                        setEnderecoFilter(e.target.value)
                                    }}
                                    value={enderecoFilter}
                                    style={{ marginRight: '5px' }}
                                />
                                <TextField
                                    placeholder='Filtrar Bairro...'
                                    onChange={(e) => setBairroFilter(e.target.value)}
                                    value={bairroFilter}
                                    style={{ marginRight: '5px' }}
                                />
                                <TextField
                                    placeholder='Filtrar Cidade...'
                                    onChange={(e) => setCityFilter(e.target.value)}
                                    value={cityFilter}
                                    style={{ marginRight: '5px' }}
                                />
                                <TextField
                                    placeholder='Filtrar Nome do Condomínio...'
                                    onChange={(e) => setCondominioFilter(e.target.value)}
                                    value={condominioFilter}
                                    style={{ marginRight: '5px' }}
                                />
                                <TextField
                                    placeholder='Filtrar Construtora...'
                                    onChange={(e) => setIncorporadoraFilter(e.target.value)}
                                    value={incorporadoraFilter}
                                    style={{ marginRight: '5px' }}
                                />
                                Valor:
                                <Box sx={{ width: 1000 }}>
                                    <Slider
                                        value={valorFilter}
                                        onChange={(e) => setValorFilter(e.target.value)}
                                        color='secondary'
                                        valueLabelDisplay="on"
                                        valueLabelFormat={s => maskCurrency(s)}
                                        min={100}
                                        max={1000000000}
                                    />
                                </Box>
                                Metragem:
                                <Box sx={{ width: 300 }}>
                                    <Slider
                                        value={metragemFilter}
                                        onChange={(e) => setMetragemFilter(e.target.value)}
                                        color='secondary'
                                        valueLabelDisplay="on"
                                        min={1}
                                        max={100000}
                                        disabled
                                    />
                                </Box>
                                <FormControl>
                                    Quartos:
                                    <RadioGroup
                                        value={quartosFilter}
                                        onChange={(e) => setQuartosFilter(e.target.value)}
                                    >
                                        <FormControlLabel value={1} control={<Radio />} label="1+" />
                                        <FormControlLabel value={2} control={<Radio />} label="2+" />
                                        <FormControlLabel value={3} control={<Radio />} label="3+" />
                                        <FormControlLabel value={4} control={<Radio />} label="4+" />
                                    </RadioGroup>
                                </FormControl>
                                <FormControl>
                                    Banheiros:
                                    <RadioGroup
                                        value={banheirosFilter}
                                        onChange={(e) => setBanheirosFilter(e.target.value)}
                                    >
                                        <FormControlLabel value={1} control={<Radio />} label="1+" />
                                        <FormControlLabel value={2} control={<Radio />} label="2+" />
                                        <FormControlLabel value={3} control={<Radio />} label="3+" />
                                        <FormControlLabel value={4} control={<Radio />} label="4+" />
                                    </RadioGroup>
                                </FormControl>
                                <FormControl>
                                    Vagas:
                                    <RadioGroup
                                        value={vagasFilter}
                                        onChange={(e) => setVagasFilter(e.target.value)}
                                    >
                                        <FormControlLabel value={0} control={<Radio />} label="0+" />
                                        <FormControlLabel value={1} control={<Radio />} label="1+" />
                                        <FormControlLabel value={2} control={<Radio />} label="2+" />
                                        <FormControlLabel value={3} control={<Radio />} label="3+" />
                                        <FormControlLabel value={4} control={<Radio />} label="4+" />
                                    </RadioGroup>
                                </FormControl>
                                <button className='btn btnPrimary'
                                    style={{ marginRight: '5px' }} onClick={() => {
                                        let json = {
                                            address: enderecoFilter,
                                            bairro: bairroFilter,
                                            city: cityFilter,
                                            condominio: condominioFilter,
                                            construtora: incorporadoraFilter,
                                            valorMin: valorFilter[0],
                                            valorMax: valorFilter[1],
                                            metragemMin: metragemFilter[0],
                                            metragemMax: metragemFilter[1],
                                            quartos: quartosFilter,
                                            banheiros: banheirosFilter,
                                            vagas: vagasFilter,
                                        }
                                        if (enderecoFilter === '')
                                            delete json.address
                                        if (bairroFilter === '')
                                            delete json.bairro
                                        if (cityFilter === '')
                                            delete json.city
                                        if (valorFilter === [1000, 50000000]) {
                                            delete json.valorMin
                                            delete json.valorMax
                                        }
                                        if (metragemFilter === [1, 10000]) {
                                            delete json.metragemMin
                                            delete json.metragemMax
                                        }
                                        if (quartosFilter === '')
                                            delete json.quartos
                                        if (banheirosFilter === '')
                                            delete json.banheiros
                                        if (vagasFilter === '')
                                            delete json.vagas

                                        if (json == {})
                                            return
                                        console.log("filtering json: ")
                                        console.log(json)
                                        setFilters(json)
                                    }}>
                                    Filtrar
                                </button>
                                <Zoom in={Object.keys(filters) > 0}>
                                    <button className='btn btnSecondary' onClick={() => {
                                        setEnderecoFilter('')
                                        setBairroFilter('')
                                        setCityFilter('')
                                        setValorFilter('')
                                        setMetragemFilter([1, 10000])
                                        setFilters({})
                                        setFilteringMode(!filteringMode)
                                    }}>
                                        Limpar Filtros
                                    </button>

                                </Zoom>
                            </Grid>
                        </div>
                    </Zoom>
                </Grid>
            </Grid>
            <Grid container justifyItems='flex-start' alignItems={'center'} style={{
                width: '95%',
                cursor: 'pointer',
                padding: '5px',
                borderTop: '2px  solid #d9d9d9',
                borderRight: '2px  solid #d9d9d9',
                borderLeft: '2px  solid #d9d9d9',
                borderRadius: '5px 5px 0px 0px'
            }}>
                <Grid item lg={1.5} xs={1.8}>
                    Foto
                </Grid>

                <Grid item lg={2} xs={1.8}>
                    Endereço
                </Grid>
                <Grid item lg={2} xs={2.2}>
                    Condomínio
                </Grid>
                <Grid item lg={1.5} xs={1.5}>
                    Valor
                </Grid>
                <Grid item lg={1} xs={1}>
                    Criado Em
                </Grid>
                <Grid item lg={1} xs={1}>
                    Status
                </Grid>
                <Grid item lg={1.5} xs={1.2}>
                    Agente
                </Grid>
                <Grid item xs={1}>
                    Market Center
                </Grid>
            </Grid>
            {
                loading ? <Loading /> :
                    listings?.map((listing, i) => {
                        console.log(listing._id)
                        return (<Grid key={listing._id} item xs={12}>
                            <Divider style={{ width: '95%' }} />
                            <div style={{
                                backgroundColor: i % 2
                                    ? 'white'
                                    : '#fafafa',
                                width: '95%',
                            }}
                            >
                                <Link
                                    to={'/soukw/listings/editlistings/' + listing._id}
                                    style={{ textDecoration: 'none', color: '#373f51' }}
                                >
                                    <Grid container
                                        justifyItems='flex-start'
                                        alignItems={'center'}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <Grid item lg={1.5} xs={1.8}>
                                            <LazyLoad height={75} once placeholder={<Loading />}>
                                                <img
                                                    src={listing.photos?.length > 0
                                                        ? listing.photos[0]?.ph_url
                                                        : placeholder} width='125px' height='75px'
                                                    style={{ objectFit: 'cover' }} alt=''
                                                />
                                            </LazyLoad>
                                        </Grid>
                                        <Grid item lg={2} xs={1.8}>
                                            {listing.list_address?.street_name},
                                            {listing.list_address?.street_number}
                                            - {listing.list_address?.city}
                                        </Grid>
                                        <Grid item lg={2} xs={2.2}>
                                            {listing.structure?.building_name}
                                        </Grid>
                                        <Grid item lg={1.5} xs={1.5}>
                                            {maskCurrency(listing.current_list_price)}
                                        </Grid>
                                        <Grid item lg={1} xs={1}>
                                            {new Date(listing.createdAt)
                                                .toLocaleDateString('pt-br')}
                                        </Grid>
                                        <Grid item lg={1} xs={1}>
                                            {listing.status}
                                        </Grid>
                                        <Grid item lg={1.5} xs={1.2}>
                                            {listing.newListingAgentId !== -1
                                                ? users?.find(u => String(u.kwuid) ===
                                                    String(listing.newListingAgentId))?.fullName
                                                : users?.find(u => String(u.kwuid) ===
                                                    String(listing.list_kw_uid))?.fullName}
                                        </Grid>
                                        <Grid item xs={1}>
                                            {mcs?.find(mc => String(mc.kwId) ===
                                                String(listing.market_center))?.name}
                                        </Grid>
                                    </Grid>
                                </Link>
                            </div>
                            <Divider style={{ width: '95%' }} />
                        </Grid >)
                    })
            }
            {
                listings.length === 0 && <Grid item xs={12} style={{
                    textAlign: 'center',
                    marginTop: '100px',
                }}
                >
                    <h2 style={{ fontWeight: 'normal' }}>
                        Nenhum listing encontrado.
                    </h2>
                </Grid>

            }
            <Grid item xs={12}>
                <br />
            </Grid>
            <Grid item xs={12}>
                <Zoom in={page > 1}>
                    <Link to={'/soukw/listings?page=' + String(parseInt(page) - 1)}
                        style={{
                            textDecoration: 'none',
                            color: '#373f51'
                        }}
                    >
                        <button className='btn btnSecondary' onClick={() => {
                            setLoading(true)
                            setPage(parseInt(page) - 1)
                        }}>Anterior</button>
                    </Link>
                </Zoom>
                <span style={{ margin: '5px' }}>
                    Página {page} de {totalPages}
                </span>
                <Zoom in={page < totalPages}>
                    <Link to={'/soukw/listings?page=' + String(parseInt(page) + 1)}
                        style={{
                            textDecoration: 'none',
                            color: '#373f51'
                        }}
                    >
                        <button className='btn btnSecondary' onClick={() => {
                            setLoading(true)
                            setPage(parseInt(page) + 1)
                        }}>Próxima</button>
                    </Link>
                </Zoom>
            </Grid>
            <NewListingCaptacao open={newListingCaptacaoOpen} close={closeNewListingCaptacao} />
            <FilterListingModal open={filteringMode} close={closeFilterListingModal} setFilters={setFilters} />
        </Grid >
    )
}
