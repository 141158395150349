import { Grid } from "@mui/material";
import linkedinLogo from '../assets/imgs/linkedinLogo.png'
import tomorowAgencyLogo from '../assets/imgs/tomorrowAgencyLogo.png'
import alphaComunicacoesLogo from '../assets/imgs/alphaLogo.png'
import juanMikzerLogo from '../assets/imgs/juanMikzerLogo.png'
import mb2bLogo from '../assets/imgs/mb2bLogo.png'

export default function MarketingImob() {
    return (
        <>
            {/* ===== MB2B - Assessoria em Marketing ===== */}
            <Grid container
                xs={8}
                md={8}
                lg={5}
                paddingTop={1}
            >
                <div className="imGrid" >
                    <div className="imCard">
                        <img className="imLogo" src={mb2bLogo} />
                        <div className="dataLandInfo">
                            <h1 className="imH1">
                                MB2B Assessoria
                            </h1>
                            <p className="imP">
                                A MB2B é uma empresa de consultoria de marketing focada no desenvolvimento de
                                estratégias inovadoras com um impacto real no crescimento de empresas e startups.

                            </p>
                        </div>
                    </div>
                </div>
                <div className="gridButton">
                    <div className="imGrid">
                        <Grid item xs={10}
                            style={{
                                marginTop: '1rem',
                                marginLeft: '3.5rem'
                            }}>
                            <a target='_blank' href="https://drive.google.com/file/d/1UE2y56JgNUYgo3hRl5DoT48BzLefcmmY/view?usp=sharing">
                                <button className='btn btnSecondary'
                                >
                                    Termo de Parceria
                                </button>
                            </a>
                        </Grid>

                        <Grid item xs={2}
                            style={{
                                marginTop: '1rem'
                            }}>
                            <a href="https://www.mb2b.co/" target='_blank'>
                                <button className='btn btnPrimary'
                                >
                                    Acessar
                                </button>
                            </a>
                        </Grid>
                    </div>
                </div>
            </Grid>
            <div style={{
                width: '4rem'
            }}>
            </div>

            {/* ===== Tomorrow Agency ===== */}
            <Grid container
                xs={8}
                md={8}
                lg={5}
                paddingTop={1}
            >
                <div className="imGrid" >
                    <div className="imCard">
                        <img className="imLogo" src={tomorowAgencyLogo} />
                        <div className="dataLandInfo">
                            <h1 className="imH1">
                                Tomorrow Agency
                            </h1>
                            <p className="imP">
                                A agência Tomorrow é uma agência de redes sociais e assessoria de marketing digital especializada em performance,
                                com objetivo na entrega de resultados, atrelada às estratégias e metas dos clientes.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="gridButton">
                    <div className="imGrid">
                        <Grid item xs={10}
                            style={{
                                marginTop: '1rem',
                                marginLeft: '3.5rem'
                            }}>
                            <a target='_blank' href="https://drive.google.com/file/d/1mcDgk3rrQDCrX1ZJMFPcDdV1AMThyIWA/view?usp=sharing">
                                <button className='btn btnSecondary'
                                >
                                    Termo de Parceria
                                </button>
                            </a>
                        </Grid>

                        <Grid item xs={2}
                            style={{
                                marginTop: '1rem'
                            }}>
                            <a href="https://www.tomorrowagency.com.br/" target='_blank'>
                                <button className='btn btnPrimary'
                                >
                                    Acessar
                                </button>
                            </a>
                        </Grid>
                    </div>
                </div>
            </Grid>

            {/* ===== Alpha Comunicacoes ===== */}
            <Grid container
                xs={8}
                md={8}
                lg={5}
                paddingTop={1}
            >
                <div className="imGrid" >
                    <div className="imCard">
                        <img className="imLogo" src={alphaComunicacoesLogo} />
                        <div className="dataLandInfo">
                            <h1 className="imH1">
                                Alpha Comunicações
                            </h1>
                            <p className="imP">
                                A Alpha Comunicações é uma mini agência especialista em redes sociais indicada para gestão de perfis e/ou
                                mentorias individuais de construção de identidade nas Redes Sociais em geral.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="gridButton">
                    <div className="imGrid">
                        <Grid item xs={10}
                            style={{
                                marginTop: '1rem',
                                marginLeft: '3.5rem'
                            }}>
                            <a target='_blank' href="https://drive.google.com/file/d/1cVS-mJavxNNFYGtkqz5z9acSMeHja_zW/view?usp=sharing">
                                <button className='btn btnSecondary'
                                >
                                    Termo de Parceria
                                </button>
                            </a>
                        </Grid>

                        <Grid item xs={2}
                            style={{
                                marginTop: '1rem'
                            }}>
                            <a href="https://linktr.ee/priscila.fariasmk" target='_blank'>
                                <button className='btn btnPrimary'
                                >
                                    Acessar
                                </button>
                            </a>
                        </Grid>
                    </div>
                </div>
            </Grid>
            <div style={{
                width: '4rem'
            }}>
            </div>

            {/* ===== Juan Mikzer ===== */}
            <Grid container
                xs={8}
                md={8}
                lg={5}
                paddingTop={1}
            >
                <div className="imGrid" >
                    <div className="imCard">
                        <img className="imLogo" src={juanMikzerLogo} />
                        <div className="dataLandInfo">
                            <h1 className="imH1">
                                Juan Mikzer
                            </h1>
                            <p className="imP">
                                Profissional homologado para criação de artes gráficas em geral,
                                especialmente logotipos Keller Williams para Market Centers e Times Associados.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="gridButton">
                    <div className="imGrid">
                        <Grid item xs={10}
                            style={{
                                marginTop: '1rem',
                                marginLeft: '3.5rem'
                            }}>
                            <a target='_blank' href="https://drive.google.com/file/d/1eQcdYXZgu5KGaYnduufkrbeVRgTwcf3k/view?usp=sharing">
                                <button className='btn btnSecondary'
                                >
                                    Termo de Parceria
                                </button>
                            </a>
                        </Grid>

                        <Grid item xs={2}
                            style={{
                                marginTop: '1rem'
                            }}>
                            <a href="https://api.whatsapp.com/send?phone=55119758894&text=Ol%C3%A1,%20sou%20agente%20da%20Keller%20Williams.%20Gostaria%20de%20fazer%20um%20or%C3%A7amento!" target='_blank'>
                                <button className='btn btnPrimary'
                                >
                                    Acessar
                                </button>
                            </a>
                        </Grid>
                    </div>
                </div>
            </Grid>
            <div style={{
                width: '4rem'
            }}>
            </div>

        </>
    )
}