import { TextField } from '@mui/material'
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import Geocode from 'react-geocode';
import { AuthContext } from '../contexts/AuthContext';
import { BASE_URL, SOUKW_URL } from '../sharedComponents/Constants';

export default function Import() {
    const { token, user, logout } = useContext(AuthContext);
    const [json, setJson] = useState('')
    const [userJson, setUserJson] = useState('')


    const translateListCategory = (category) => {
        switch (category) {
            case 0:
                return 'Other';
            case 1:
                return 'For Rent';
            case 2:
                return 'For Sale';
            case 3:
                return 'Sold';
            case 4:
                return 'Off Market';
        }
    }
    const translateListType = (type) => {
        switch (type) {
            case 1:
                return 'Prospective';
            case 2:
                return 'MLS Listing';
            case 3:
                return 'Coming Soon';
            case 4:
                return 'Exclusive';
            case 5:
                return 'Open Listing';
            case 6:
                return 'KW Reserve';
        }
    }
    const translateListStatus = (status) => {
        switch (status) {
            case 1:
                return 'Active';
            case 2:
                return 'Pending';
            case 3:
                return 'Sold';
            case 4:
                return 'Active Under Contract';
            case 6:
                return 'Pending Contingent';
            case 12:
                return 'Cancelled';
            case 13:
                return 'Delete';
            case 14:
                return 'Expired';
            case 15:
                return 'Leased';
            case 17:
                return 'Closed';
            case 18:
                return 'Off Market';
        }
    }
    const translatePropType = (propType) => {
        switch (propType) {
            case 1:
                return 'Business Opportunity';
            case 2:
                return 'Commercial';
            case 3:
                return 'Common Interest';
            case 4:
                return 'Farm And Agriculture';
            case 5:
                return 'Lots And Land';
            case 6:
                return 'Manufactured In Park';
            case 7:
                return 'Vacation Ownership';
            case 8:
                return 'Residential';
            case 9:
                return 'Residential Income';
            case 10:
                return 'Other';
        }
    }
    const translatePropSubType = (propSubType) => {
        switch (propSubType) {
            case 1:
                return 'Apartment';
            case 2:
                return 'Boat Slip';
            case 3:
                return 'Cabin';
            case 4:
                return 'Condominium';
            case 5:
                return 'Deeded Parking';
            case 6:
                return 'Duplex';
            case 7:
                return 'Farm';
            case 8:
                return 'Manufactured Home';
            case 9:
                return 'Manufactured On Land';
            case 10:
                return 'Mobile Home';
            case 11:
                return 'Own Your Own';
            case 12:
                return 'Quadruplex';
            case 13:
                return 'Ranch';
            case 14:
                return 'Single Family Attached';
            case 15:
                return 'Single Family Detached';
            case 16:
                return 'Stock Cooperative';
            case 17:
                return 'Timeshare';
            case 18:
                return 'Townhouse';
            case 19:
                return 'Triplex';
            case 20:
                return 'Agriculture';
            case 21:
                return 'Business';
            case 22:
                return 'Hotel-Motel';
            case 23:
                return 'Industrial';
            case 24:
                return 'Mixed Use';
            case 25:
                return 'Multi-Family';
            case 26:
                return 'Office';
            case 27:
                return 'Retail';
            case 28:
                return 'Unimproved Land';
            case 29:
                return 'Warehouse';
            case 30:
                return 'Adult Community';
            case 31:
                return 'Vacation Home';
            case 32:
                return 'Other';
        }
    }
    const submit = async () => {
        try {
            let j = JSON.parse(json);

            Geocode.setApiKey('AIzaSyBG0z5RFyaogJihcAmL8A2GmazXLK7lCXc');
            Geocode.setLanguage("pt-br");
            Geocode.setRegion("br");
            Geocode.setLocationType('ROOFTOP');

            let promisesArr = []
            j.excel?.map(async data => {

                let rua = data.RUA
                let numero = data.NUMERO
                let bairro = data.BAIRRO
                let cidade = data.CIDADE
                let estado = data.ESTADO
                let cep = data.CEP
                let compl = data.COMPL
                let preciseGeo = await Geocode.fromAddress(rua + ', ' + numero + ' - ' + bairro + ', ' + cidade + ', ' + estado + ' - ' + cep)

                let listCategory = 2
                let listType = 4
                let listStatus = 1
                let propType = 8
                let propSubType = 1

                let metragem = data.METRAGEM
                let nQuartos = data.QUARTOS
                let nBanheiros = data.BANHEIROS
                let nLavabos = data.LAVABOS
                let valor = data.VALOR

                let nomeCondominio = data.CONDOMINIO
                let andarDoImovel = data.ANDAR
                let novoImovel = true;
                let nVagas = data.VAGAS;
                let construtora = data.CONSTRUTORA
                let nUnidades = data.UNIDADES
                let nAndares = data.ANDARES;
                let nTorres = data.TORRES;

                let churrasqueira = false;
                let deck = false;
                let acessoDeficientes = false;
                let doca = false;
                let jardim = false;
                let entradaFechada = false;
                let estufa = false
                let hidro = false;
                let patio = false;
                let lago = false;
                let piscina = false;
                let varanda = false;
                let vagaTrailer = false;
                let quadra = false;
                let aspercao = false;
                let beiraMar = false

                let anoConstrucao = data.ANO;
                let tamanhoTerreno = data.TERRENO;

                let quartoText = nQuartos + (nQuartos == 1 ? ' quarto e ' : ' quartos e ')
                let banheirosText = nBanheiros + (nBanheiros == 1 ? ' banheiro.' : ' banheiros.')
                let descricao = 'Empreendimento localizado na ' + preciseGeo.results[0].formatted_address + '. Metragem de ' + metragem + 'm2, ' + quartoText + banheirosText;


                let list_address = {
                    street_number: numero,
                    street_name: rua,
                    unit_number: compl,
                    city: cidade,
                    state_prov: estado,
                    country: 'BR',
                    postal_code: cep,
                    coordinates_gp: {
                        lat: preciseGeo?.results[0]?.geometry?.location?.lat,
                        lon: preciseGeo?.results[0]?.geometry?.location?.lng,
                    },
                    coordinates_gs: {
                        coordinates: [
                            preciseGeo?.results[0]?.geometry?.location?.lng,
                            preciseGeo?.results[0]?.geometry?.location?.lat,
                        ],
                        type: "Point"
                    },

                }
                let exterior_features = {
                    has_barbecue_area: churrasqueira,
                    has_deck: deck,
                    has_disabled_access: acessoDeficientes,
                    has_dock: doca,
                    has_garden: jardim,
                    has_gated_entry: entradaFechada,
                    has_green_house: estufa,
                    has_hot_tub_spa: hidro,
                    has_patio: patio,
                    has_pond: lago,
                    has_pool: piscina,
                    has_porch: varanda,
                    has_rv_parking: vagaTrailer,
                    has_sports_court: quadra,
                    has_sprinkler_system: aspercao,
                    is_water_front: beiraMar
                }
                let structure = {
                    building_name: nomeCondominio,
                    unit_level: andarDoImovel,
                    is_new_construction: novoImovel,
                    parking_total: nVagas,
                    has_parking: parseInt(nVagas) > 0,
                    stories: nAndares,
                    total_units: nUnidades,
                    total_buildings: nTorres,
                    exterior_features,
                    builder_name: construtora,
                }
                let jsonToGo = {
                    source_system_name: "KW Sourced",
                    market_center: '50389',
                    kww_region: '50379',
                    mls_id: 'KWW_BRA_SP',
                    mls_name: 'Keller Williams Brazil Sao Paulo',
                    currency_code: 'BRL',
                    manual_entry: true,
                    kw_agent: true,
                    list_kw_uid: '822488',
                    kw_mc: true,
                    kw_listing: true,
                    is_kww_listing: true,
                    version: "2.0.0",
                    current_list_price: valor,
                    list_category_id: listCategory,
                    list_category: translateListCategory(listCategory),
                    list_type_id: listType,
                    list_type: translateListType(listType),
                    list_status_id: listStatus,
                    list_status: translateListStatus(listStatus),
                    prop_type_id: propType,
                    prop_type: translatePropType(propType),
                    prop_subtype_id: propSubType,
                    prop_subtype: translatePropSubType(propSubType),
                    list_address,
                    structure,
                    bairro,
                    year_built: anoConstrucao,
                    total_bed: nQuartos,
                    half_bath: nLavabos,
                    full_bath: nBanheiros,
                    total_bath: String(parseInt(nBanheiros) + parseInt(nLavabos)),
                    living_area: metragem,
                    living_area_units: 'm²',
                    lot_size_area: tamanhoTerreno,
                    lot_size_units: 'm²',
                    list_desc: descricao,
                    teamId: '',
                }
                const config = {
                    headers: { Authorization: `Bearer ${token}` }
                };
                promisesArr.push(axios.post(BASE_URL + '/listings', jsonToGo, config));
            });
            try {
                let res = await Promise.all(promisesArr);
                console.log(res);
            } catch (e) {
                alert('erro no envio')
                console.error(e)
            }
            console.log(promisesArr)
        } catch (e) {
            alert('Didnt parse json')
            console.error(e)
        }
    }
    const userSubmit = async () => {
        try {
            let j = JSON.parse(userJson);
            let promisesArr = []
            j.excel?.map(async data => {
                const config = {
                    headers: { Authorization: `Bearer ${token}` }
                };
                promisesArr.push(axios.post(BASE_URL + '/auth/register', data, config));

            })
            try {
                let results = await Promise.all(promisesArr);
                console.log(results)
            } catch (e) {
                alert('erro no request')
                console.error(e)
            }
        } catch (e) {
            alert('problem with json')
            console.error(e)
        }
    }
    return (
        <div>
            Import
            <br />
            <TextField
                minRows={5}
                maxRows={10}
                onChange={(e) => setJson(e.target.value)}
                value={json} />
            <br />
            <button onClick={submit}>enviar</button>

            <br />
            <br />

            <br />
            User Import
            <br />
            <TextField
                minRows={5}
                maxRows={10}
                onChange={(e) => setUserJson(e.target.value)}
                value={userJson} />
            <br />
            <button onClick={userSubmit}>enviar</button>
        </div>
    )
}
