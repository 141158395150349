import React, { useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import logo from '../assets/imgs/kw.png'
import Solicitacao from '../assets/imgs/solicitacao.jpeg'
import { useLocation } from 'react-router-dom'
import { AuthContext } from '../contexts/AuthContext';
import { Button, Drawer } from '@mui/material';
import { Box,  } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import sair from '../assets/imgs/sair.jpg'
import placeholder from '../assets/imgs/no-image.jpg'

export default function Navbar() {
    const location = useLocation();
    const navigate = useNavigate();
    const { user, logout, jwtUser } = useContext(AuthContext);

    // Drawer
    const [state, setState] = React.useState({ right: false, });
    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event.type === 'keydown' && (
                event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    // Drawer Contents
    const list = (anchor) => (
        <Box
            sx={{ width: '22.5rem', backgroundColor: '#282B33', height: '100%' }}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <header className='header-drawer' style={{
                backgroundColor: '#3D424D',
                height: '4.56rem',
                display: 'flex',
                flexDirection: 'row'
            }}>
                <h1 className='title-header-drawer' style={{
                    color: '#ffff',
                    padding: '1rem',
                    fontSize: '20px',
                    lineHeight: '20px'
                }}>
                    <strong>
                        Ajuda & <br />
                        Informações
                    </strong>
                </h1>
                <CloseIcon sx={{
                    paddingLeft: '10.5rem',
                    height: '4.5rem',
                    color: 'lightgray'
                }} />
            </header>
            <body className='body-drawer' style={{
                padding: '1rem',
                backgroundColor: '#282B33',
            }}>
                <p className='text-drawer'
                    style={{
                        color: 'lightgray',
                        paddingBottom: '16px'
                    }}
                >
                    Atalhos para materiais de ensino e apoio ao uso do sistema!
                </p>

                <p className='text-drawer'
                    style={{
                        color: 'lightgray',
                        paddingBottom: '16px'
                    }}
                >
                    Tire suas dúvidas sobre a Keller Williams.
                    Navegue pela Central de Ajuda da Sou KW ou converse
                    com suporte para assistência imediata.
                </p>
                <p className='text-drawer'
                    style={{
                        color: 'lightgray',
                        paddingBottom: '16px'
                    }}
                >
                    Construído por agentes, para agentes, somos o número 1.
                    Ocupando o primeiro lugar em número de agentes, unidades
                    e volume de vendas, nossa força de vendas é reconhecida.
                    Lar do agente habilitado para tecnologia, nosso modelo de
                    negócios equipa os agentes com uma vantagem tecnológica e
                    a capacidade de oferecer aos clientes o que eles desejarem.
                </p>
                {/* <p className='text-drawer'
                    style={{
                        color: 'lightgray',
                        padding: '1px',
                        display: 'flex',
                        flexDirection: 'row'
                    }}
                >
                    <HelpOutlineIcon sx={{ color: '#58D8F5' }}/>
                    &nbsp;&nbsp;Suporte Sou Kw: Suporte@kwsp.com.br
                </p> */}


                <div className='icon-faq-drawer'
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        paddingTop: 15,
                        paddingBottom: 15,
                        columnGap: '20px',

                    }}

                >
                    <Button variant='contained' style={{
                        backgroundColor: "#67552A",

                    }} >
                        <a href='https://app.soukw.com.br/ajuda' style={{ textDecoration: 'none' }}>
                            <span className='text-faq-drawer'
                                style={{
                                    color: '#ffff',
                                    marginLeft: '0.765rem',
                                    fontSize: '0.82rem'
                                }}>
                                Videos Tutoriais
                            </span>
                        </a>
                    </Button>

                    <a href='https://app.soukw.com.br/solicitacoes/kwsp?fazer=abrir' style={{ textDecoration: 'none', paddingTop: 15 }}>
                        <img src={Solicitacao} style={{ width: 15 }} />

                        <span className='text-faq-drawer'
                            style={{
                                color: '#58D8F5',
                                marginLeft: '0.765rem',
                                fontSize: '1.2rem'

                            }}
                        >
                            Solicitação Market Center
                        </span>

                    </a>


                    <a href='https://answers.kw.com/hc/en-us' style={{ textDecoration: 'none', paddingTop: 15 }}>
                        <SupportAgentIcon sx={{ color: '#58D8F5', fontSize: '1.4rem', width: 20 }} />
                        <span className='text-faq-drawer'
                            style={{
                                color: '#58D8F5',
                                marginLeft: '0.765rem',
                                fontSize: '1.2rem'
                            }}
                        >
                            FAQ Command KWW
                        </span>
                    </a>
                </div>
            </body>
        </Box>
    );

    return (
        <div className='navbar'>
            <div className='navbar-box'>
                <img src={logo} className='navbar-logo' />
            </div>



            <Link to={'/command'} className='navbar-item' style={{ marginRight: '20px', color: location.pathname.includes('/command') ? 'white' : 'gray' }}>
                Command
            </Link>

            <Link to={'/soukw'} className='navbar-item' style={{ marginRight: '20px', color: location.pathname.includes('/soukw') ? 'white' : 'gray' }}>
                SouKW
            </Link>

            <Link to={'/pipeimob'} className='navbar-item' style={{ marginRight: '20px', color: location.pathname.includes('/pipeimob') ? 'white' : 'gray' }}>
                PipeImob
            </Link>

            <Link to={'/parcerias'} className='navbar-item' style={{ marginRight: '20px', color: location.pathname.includes('/parceria') ? 'white' : 'gray' }}>
                Parcerias
            </Link>

            <img src={sair} alt='Deslogar' className='navbar-item' style={{ position: 'absolute', top: '25px', width: '20px', right: '10px', color: 'gray' }} onClick={logout} />

            {['right'].map((anchor) => (
                <React.Fragment
                    key={anchor} className='navbar-item'
                    
                >
                    <Button onClick={toggleDrawer(anchor, true)} style={{ position: 'absolute', top: '10px', right: '285px', objectFit: 'cover'}} >
                        <HelpOutlineIcon sx={{ color: 'gray' }} />
                    </Button>
                    <Drawer
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                    >
                        {list(anchor)}
                    </Drawer>
                </React.Fragment>
            ))}


            <img src={user.photo?.length > 0 ? user.photo : placeholder} width='40px' height='40px'
                style={{ position: 'absolute', top: '10px', right: '250px', objectFit: 'cover', borderRadius: 200 / 2 }} alt='' />

            <a href='https://app.soukw.com.br/negocios/kwsp?perfil=abrir' target='_self' className='navbar-item' style={{ position: 'absolute', top: '20px', right: '50px', color: 'gray' }}>
                {user?.fullName}
            </a>
        </div>
    )
}
