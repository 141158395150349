import { Autocomplete, Box, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, FormControlLabel, Grid, MenuItem, Radio, RadioGroup, Slider, TextField } from '@mui/material'
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../contexts/AuthContext';
import { BASE_URL, SOUKW_URL } from '../sharedComponents/Constants';

export default function FilterListingModal({ open, close, setFilters }) {
    const { token, user, logout } = useContext(AuthContext);
    const [teamName, setTeamName] = useState('');
    const [teamMcId, setTeamMcId] = useState('');

    const [filteringMode, setFilteringMode] = useState(false);
    const [enderecoFilter, setEnderecoFilter] = useState('');
    const [bairroFilter, setBairroFilter] = useState('');
    const [cityFilter, setCityFilter] = useState('');
    const [condominioFilter, setCondominioFilter] = useState('');
    const [incorporadoraFilter, setIncorporadoraFilter] = useState('');
    const [metragemFilter, setMetragemFilter] = useState([1, 1000]);
    const [valorFilter, setValorFilter] = useState([1000, 50000000]);
    const [quartosFilter, setQuartosFilter] = useState(1);
    const [banheirosFilter, setBanheirosFilter] = useState(1);
    const [vagasFilter, setVagasFilter] = useState(0);

    const [users, setUsers] = useState([]);

    const maskCurrency = (num = '.') => {
        let initial = String(num).split('.').join('').replace(/[^0-9.]/g, '');
        let formattedNum = initial;
        switch (initial.length) {
            case 1: formattedNum = num;
                break;
            case 2: formattedNum = num;
                break;
            case 3: formattedNum = num;
                break;
            case 4: formattedNum = [formattedNum.slice(0, 1), '.', formattedNum.slice(1)].join('');
                break;
            case 5: formattedNum = [formattedNum.slice(0, 2), '.', formattedNum.slice(2)].join('');
                break;
            case 6: formattedNum = [formattedNum.slice(0, 3), '.', formattedNum.slice(3)].join('');
                break;
            case 7: formattedNum = [formattedNum.slice(0, 1), '.', formattedNum.slice(1, 4), '.', formattedNum.slice(4)].join('');
                break;
            case 8: formattedNum = [formattedNum.slice(0, 2), '.', formattedNum.slice(2, 5), '.', formattedNum.slice(5)].join('');
                break;
            case 9: formattedNum = [formattedNum.slice(0, 3), '.', formattedNum.slice(3, 6), '.', formattedNum.slice(6)].join('');
                break;

        }
        let s = 'R$ ' + String(formattedNum).replace(/[^0-9.]/g, '').slice(0, 10)
        return num >= 10000000 ? s + '+' : s
    }
    const submit = async () => {

        let json = {
            address: enderecoFilter,
            bairro: bairroFilter,
            city: cityFilter,
            condominio: condominioFilter,
            construtora: incorporadoraFilter,
            valorMin: valorFilter[0],
            valorMax: valorFilter[1],
            metragemMin: metragemFilter[0],
            metragemMax: metragemFilter[1],
            quartos: quartosFilter,
            banheiros: banheirosFilter,
            vagas: vagasFilter,
        }
        if (enderecoFilter === '')
            delete json.address
        if (bairroFilter === '')
            delete json.bairro
        if (cityFilter === '')
            delete json.city
        if (incorporadoraFilter === '')
            delete json.construtora
        if (condominioFilter === '')
            delete json.condominio
        if (valorFilter === [1000, 10000000]) {
            delete json.valorMin
            delete json.valorMax
        }
        if (metragemFilter === [1, 1000]) {
            delete json.metragemMin
            delete json.metragemMax
        }
        if (quartosFilter === '')
            delete json.quartos
        if (banheirosFilter === '')
            delete json.banheiros
        if (vagasFilter === '')
            delete json.vagas

        if (json == {})
            return
        console.log("Estou no modal de filtro")
        console.log( json )
        setFilters(json)
        close()
    }
    return (
        <div>
            <Dialog open={open} onClose={close} maxWidth='sm'>
                <DialogTitle>
                    <div style={{ width: '500px' }}>
                        <h1>Filtrar Listings</h1>
                        <Divider style={{ width: '100%' }} />
                    </div>
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={0} alignItems={'center'} justifyContent={'center'} >
                        <Grid item xs={12}>
                            <br />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                placeholder='Filtrar Endereço...'
                                label='Filtrar Endereço...'
                                onChange={(e) => setEnderecoFilter(e.target.value)}
                                value={enderecoFilter}
                                fullWidth
                                style={{ marginRight: '5px' }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <br />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                placeholder='Filtrar Bairro...'
                                label='Filtrar Bairro...'
                                onChange={(e) => setBairroFilter(e.target.value)}
                                value={bairroFilter}
                                fullWidth
                                style={{ marginRight: '5px' }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <br />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                placeholder='Filtrar Cidade...'
                                label='Filtrar Cidade...'
                                onChange={(e) => setCityFilter(e.target.value)}
                                value={cityFilter}
                                fullWidth
                                style={{ marginRight: '5px' }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <br />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                placeholder='Filtrar Nome do Condomínio...'
                                label='Filtrar Nome do Condomínio...'
                                onChange={(e) => setCondominioFilter(e.target.value)}
                                value={condominioFilter}
                                fullWidth
                                style={{ marginRight: '5px' }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <br />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                placeholder='Filtrar Construtora...'
                                label='Filtrar Construtora...'
                                onChange={(e) => setIncorporadoraFilter(e.target.value)}
                                value={incorporadoraFilter}
                                fullWidth
                                style={{ marginRight: '5px' }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <br />
                        </Grid>
                        <Grid item xs={12}>
                            Valor:
                        </Grid>
                        <Grid item xs={12}>
                            <br />
                        </Grid>
                        <Grid item xs={12}>
                            <br />
                        </Grid>
                        <Grid item xs={10} style={{ textAlign: 'center' }}>
                            <Box sx={{ width: '100%', textAlign: 'center' }} alignItems='center' justifyContent={'center'} display='flex'>
                                <Slider
                                    value={valorFilter}
                                    onChange={(e) => setValorFilter(e.target.value)}
                                    color='secondary'
                                    valueLabelDisplay="on"
                                    valueLabelFormat={s => maskCurrency(s)}
                                    min={1000}
                                    max={100000000}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <br />
                        </Grid>
                        <Grid item xs={12}>
                            Metragem:
                        </Grid>
                        <Grid item xs={12}>
                            <br />
                        </Grid>
                        <Grid item xs={12}>
                            <br />
                        </Grid>
                        <Grid item xs={10} style={{ textAlign: 'center' }}>
                            <Box sx={{ width: '100%', textAlign: 'center' }} alignItems='center' justifyContent={'center'} display='flex'>
                                <Slider
                                    value={metragemFilter}
                                    onChange={(e) => setMetragemFilter(e.target.value)}
                                    color='secondary'
                                    valueLabelDisplay="on"
                                    valueLabelFormat={s => s + 'm²'}
                                    min={1}
                                    max={99999}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <br />
                        </Grid>
                        <Grid item xs={12}>
                            Quartos:
                        </Grid>
                        <Grid item xs={11}>
                            <FormControl>
                                <RadioGroup
                                    row
                                    value={quartosFilter}
                                    onChange={(e) => setQuartosFilter(e.target.value)}
                                >
                                    <FormControlLabel value={1} control={<Radio />} label="1+" />
                                    <FormControlLabel value={2} control={<Radio />} label="2+" />
                                    <FormControlLabel value={3} control={<Radio />} label="3+" />
                                    <FormControlLabel value={4} control={<Radio />} label="4+" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <br />
                        </Grid>
                        <Grid item xs={12}>
                            Banheiros:
                        </Grid>
                        <Grid item xs={11}>
                            <FormControl>
                                <RadioGroup
                                    row
                                    value={banheirosFilter}
                                    onChange={(e) => setBanheirosFilter(e.target.value)}
                                >
                                    <FormControlLabel value={1} control={<Radio />} label="1+" />
                                    <FormControlLabel value={2} control={<Radio />} label="2+" />
                                    <FormControlLabel value={3} control={<Radio />} label="3+" />
                                    <FormControlLabel value={4} control={<Radio />} label="4+" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <br />
                        </Grid>
                        {/* <Grid item xs={12}>
                            Vagas:
                        </Grid>
                        <Grid item xs={11}>
                            <FormControl>
                                <RadioGroup
                                    value={vagasFilter}
                                    row
                                    onChange={(e) => setVagasFilter(e.target.value)}
                                >
                                    <FormControlLabel value={0} control={<Radio />} label="0+" />
                                    <FormControlLabel value={1} control={<Radio />} label="1+" />
                                    <FormControlLabel value={2} control={<Radio />} label="2+" />
                                    <FormControlLabel value={3} control={<Radio />} label="3+" />
                                    <FormControlLabel value={4} control={<Radio />} label="4+" />
                                </RadioGroup>
                            </FormControl>
                        </Grid> */}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <button className='btn btnSecondary' onClick={close}>Cancelar</button>
                    <button className='btn btnPrimary' onClick={submit}>
                        Filtrar
                    </button>
                </DialogActions>
            </Dialog>
        </div >
    )
}