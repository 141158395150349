import { Grid, TextField } from '@mui/material'
import React, { useContext, useEffect, useState, useReducer } from 'react'
import ModalBlankLine from '../../sharedComponents/listings/ModalBlankLine';
import { maskCurrency } from '../../libs/utils';

export default function TextModal( props ) {
    const { title, dispatchFunction, filtro, initialValue } = props

    const [value, setValue] = useState( initialValue )
    
    return <Grid item xs={12}>
        <TextField
            {...props}
            placeholder={title}
            label={`Filtrar por ${title}`}
            onChange={(e) => {
                dispatchFunction({type: filtro, payload: e.target.value })
                setValue( e.target.value )
            }}
            value={value}
            fullWidth
            style={{ marginRight: '5px' }}
        />
    </Grid>
}