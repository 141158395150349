import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import {BASE_URL} from '../sharedComponents/Constants'

export const AuthContext = React.createContext();
export default function AuthProvider({ children }) {

    const navigate = useNavigate();
    const [token, setToken] = useState(null);
    const [user, setUser] = useState(null);
    const [mc, setMc] = useState(null);
    const [region, setRegion] = useState(null);
    const [jwtUser, setJWTUser] = useState(null);
    const [loading, setLoading] = useState(true);

    console.log( jwtUser )

    const logout = () => {
        localStorage.clear();
        setToken(null);
        setUser(null);
        setMc(null);
        setRegion(null);
        setJWTUser(null);
        setLoading(false);
        navigate('/');
    };
    return (
        <AuthContext.Provider value={{ token, user, mc, region, jwtUser, loading, setToken, setUser, logout, setMc, setRegion, setJWTUser, setLoading }}>
            {children}
        </AuthContext.Provider>
    )
}
