import { Grid } from "@mui/material";
import pipeImobLogo from '../assets/imgs/pipeImobLogo.png'


export default function GetsaoImobiliaria() {
    return (
        <>
            {/* ===== PibeImob ===== */}
            <Grid container
                xs={8}
                md={8}
                lg={6}
                paddingTop={1}
            >
                <div className="imGrid" >
                    <div className="imCard">
                        <img className="imLogo" src={pipeImobLogo} />
                        <div className="dataLandInfo">
                            <h1 className="imH1">
                                PipeImob
                            </h1>
                            <p className="imP">
                                O PipeImob é uma plataforma TRM (Transaction Relationship Management) focada na transação.
                                Ela tem como objetivo trazer segurança,
                                rapidez e transparência para todos os participantes da transação de compra e venda imobiliária no pós-venda.


                            </p>
                        </div>
                    </div>
                </div>
                <div className="gridButton">
                    <div className="imGrid">
                        <Grid item xs={10}
                            style={{
                                marginTop: '1rem',
                                marginLeft: '3.5rem'
                            }}>
                            <a target='_blank' href="https://drive.google.com/file/d/1SdiFfbWiTwoC9_d9h9p7d4mrqi0Rk7Ep/view?usp=sharing">
                                <button className='btn btnSecondary'
                                >
                                    Termo de Parceria
                                </button>
                            </a>
                        </Grid>

                        <Grid item xs={2}
                            style={{
                                marginTop: '1rem'
                            }}>
                            <a href="https://app.soukw.com.br/login" target='_blank'>
                                <button className='btn btnPrimary'
                                >
                                    Acessar
                                </button>
                            </a>
                        </Grid>
                    </div>
                </div>
            </Grid>
            <div style={{
                width: '4rem'
            }}>
            </div>

        </>
    )
}