import { Autocomplete, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControlLabel, Grid, MenuItem, TextField } from '@mui/material'
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import { BASE_URL, SOUKW_URL } from '../sharedComponents/Constants';

export default function EditTeam() {
    const { token, user, logout } = useContext(AuthContext);
    let { id } = useParams()
    const navigate = useNavigate();

    const [mcs, setMcs] = useState([]);

    const [teamName, setTeamName] = useState('');
    const [teamMcId, setTeamMcId] = useState('');
    const [admins, setAdmins] = useState([]);
    const [addPermissions, setAddPermissions] = useState('');
    const [users, setUsers] = useState([]);

    const [team, setTeam] = useState(null);

    useEffect(() => {
        async function getData() {
            try {
                const config = {
                    headers: { Authorization: `Bearer ${token}` }
                };
                let mcsRes = await axios.get(BASE_URL + '/mcs/', config);
                setMcs(mcsRes.data.data);
                console.log( mcsRes )
                let teamRes = await axios.get(BASE_URL + '/teams/' + id, config);
                let team = teamRes.data
                console.log(team)
                setTeam(team);
                setTeamName(team?.name);
                setTeamMcId(team?.marketCenterId);
                setAddPermissions(team?.addPermissions)
                let promiseArr = []
                team?.admins?.map(admId => {
                    promiseArr.push(axios.get(BASE_URL + '/users/user/' + admId, config))
                })
                let admsRes = await Promise.all(promiseArr);
                console.log( admsRes );
                let adminObjs = admsRes.map(a => a.data)
                setAdmins(adminObjs);
            } catch (e) {
                console.error(e);
                if (e?.response?.data?.msg)
                    alert(e?.response?.data?.msg)
                else {
                    alert('Erro de Conexão.')
                    logout();
                }
            }
        }
        getData();
    }, []);

    useEffect(() => {
        async function getData() {
            try {
                const config = {
                    headers: { Authorization: `Bearer ${token}` }
                };
                let userRes = await axios.get(BASE_URL + '/users/by_mc/' + teamMcId, config);
                setUsers(userRes.data);

            } catch (e) {
                console.error(e);
                if (e?.response?.data?.msg)
                    alert(e?.response?.data?.msg)
                else {
                    alert('Erro de Conexão.')
                    logout();
                }
            }
        }
        if (teamMcId)
            getData();
    }, [teamMcId]);

    const submitTeam = async () => {
        try {
            const config = {
                headers: { Authorization: `Bearer ${token}` }
            };
            let adminIds = admins.map(a => a._id)
            let res = await axios.patch(
                BASE_URL + '/teams/' + id,
                {
                    name: teamName,
                    marketCenterId: teamMcId,
                    admins: adminIds,
                    addPermissions
                },
                config
            );
            alert('Time editado com sucesso!');
            navigate(-1);
        } catch (e) {
            console.error(e);
            if (e?.response?.data?.msg)
                alert(e?.response?.data?.msg)
            else {
                alert('Erro de Conexão.')
                logout();
            }
        }
    }
    return (
        <div>
            <button className='btn btnSecondary' onClick={() => navigate(-1)}>
                Voltar
            </button>
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    <br />
                </Grid>
                <Grid item xs={12}>
                    <h1>Editar Time</h1>
                </Grid>
                <Grid item xs={12}>
                    <br />
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <Grid item xs={12}>
                    <br />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        label='Nome do Time *'
                        variant='outlined'
                        fullWidth
                        onChange={(e) => setTeamName(e.target.value)}
                        value={teamName}
                    />
                </Grid>
                <Grid item xs={12}>
                    <br />
                </Grid>
                <Grid item xs={12}>
                    <br />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label='MC *'
                        select
                        variant='outlined'
                        fullWidth
                        onChange={(e) => setTeamMcId(e.target.value)}
                        value={teamMcId}
                    >
                        {
                            mcs?.map(mc => {
                                return (
                                    <MenuItem value={mc._id}>{mc.name}</MenuItem>
                                )
                            })
                        }
                    </TextField>
                </Grid>

                <Grid item xs={12}>
                    <br />
                </Grid>
                <Grid item xs={12}>
                    <Autocomplete
                        multiple
                        options={users}
                        getOptionLabel={(option) => option?.fullName ?? "" }
                        filterSelectedOptions
                        value={admins}
                        disabled={!teamMcId}
                        noOptionsText='Nenhum Usuário'
                        onChange={(e, newValue) => setAdmins(newValue)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Administradores"
                                placeholder="Administradores"
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12}>
                    <br />
                </Grid>
                <Grid item xs={12} >
                    <label>Escolher quem pode criar anúncio
                    <TextField
                        variant='outlined'
                        select
                        color='secondary'
                        fullWidth
                        style={{ marginTop: '5px', marginBottom: '5px' }}
                        helperText="Permissão de Anúncio"
                        onChange={(e) => setAddPermissions(e.target.value)}
                        value={addPermissions}
                    >
                        <MenuItem value={'AGENTE'}>Todos Agentes</MenuItem>
                        <MenuItem value={'TEAM'}>Apenas Team Admins</MenuItem>
                    </TextField>
                   </label>
                </Grid>
                <Grid item xs={12}>
                    <br />
                </Grid>
                <button className='btn btnPrimary' onClick={submitTeam}>
                    Editar Time
                </button>
            </Grid>
        </div>
    )
}