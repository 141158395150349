import { Grid } from "@mui/material";
import zapierLogo from '../assets/imgs/zapierLogo.png'

export default function Leads() {
    return (
        <>
            {/* ===== Zapier ===== */}
            <Grid container
                xs={9}
                md={8}
                lg={7}
                paddingTop={1}
            >
                <div className="imGrid" >
                    <div className="imCard">
                        <img className="imLogo" src={zapierLogo} />
                        <div className="dataLandInfo" style={{paddingBottom: '2rem'}}>
                            <h1 className="imH1">
                                Zapier
                            </h1>
                            <p className="imP">
                                O Zapier é uma plataforma de automação que permite que aplicativos diferentes se comuniquem entre si e executem tarefas automatizadas.
                                Ele atua como um intermediário entre diversos aplicativos da web,
                                permitindo que você crie integrações personalizadas e automatizações sem a necessidade de escrever código.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="gridButton">
                    <div className="imGrid">
                        <Grid item xs={10}
                            style={{
                                marginTop: '1rem',
                                marginLeft: '3.5rem'
                            }}>
                            <a target='_blank' href="https://drive.google.com/file/d/1W-XgvCrTvcOha48kWAN6b-cV1DWdh7of/view?usp=sharing">
                                <button className='btn btnSecondary'
                                >
                                    Termo de Parceria
                                </button>
                            </a>
                        </Grid>

                        <Grid item xs={2}
                            style={{
                                marginTop: '1rem'
                            }}>
                            <a href="https://zapier.com/app/login?" target='_blank'>
                                <button className='btn btnPrimary'
                                >
                                    Acessar
                                </button>
                            </a>
                        </Grid>
                    </div>
                </div>
            </Grid>
            <div style={{
                width: '4rem'
            }}>
            </div>
        </>
    )
}