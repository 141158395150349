/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable default-case */
import { Autocomplete,
    Grid,
    Checkbox,
    Dialog, 
    DialogActions, 
    DialogContent, 
    DialogTitle, 
    Divider,
    Switch, 
    FormControlLabel, 
    MenuItem, 
    TextField } from '@mui/material'
import TextModal from '../sharedComponents/listings/TextModal'
import React, { useContext, useEffect, useState, useReducer } from 'react'
import Geocode from 'react-geocode';
import { AuthContext } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import SingleFileUpload from '../sharedComponents/SingleFileUpload';
import listingInMemory from '../libs/services/listingInMemory';
import { BASE_URL, SOUKW_URL } from '../sharedComponents/Constants';
import XMLFilesContext from '../contexts/XMLFilesContexts';

export default function EditIntegracaoPortal({ open, close, lst, listings=[] }) {
    const { token, user, logout, jwtUser } = useContext(AuthContext);
    const { files, 
        selectedFile,
        setSelectedFile,
        addFile,
        removeFile,
        updateFileList,
        xmlListings,
        updateXmlListings,
        saveXmlListings,
        deleteXmlListing,
        addXmlListing,
        clearXmlListings,
        reloadXmlListings,
        clearListings,
        deleteAdd,
        xmlCurrentFileSize,
        setXmlCurrentFileSize,
        listingsInsideXML,
        setListingsInsideXML,
        getXMLFileSize
        } = useContext( XMLFilesContext )
    const navigate = useNavigate();
    console.log( user );
    console.log(lst);
    let addObject
    let keys = Object.keys(lst)
    
    switch( user.permissionLevel ) {
        case('ADMIN'):
            addObject = ( keys.indexOf( 'integracaoZapImoveisMC' ) > -1) ? lst['integracaoZapImoveisMC'] : null
            break
        case('SUPER'):
            addObject = ( keys.indexOf( 'integracaoZapImoveisMC' ) > -1) ? lst['integracaoZapImoveisMC'] : null
            break
        case( 'TEAMLIDER' ):
            addObject = ( keys.indexOf( 'integracaoZapImoveisTime' ) > -1) ? lst['integracaoZapImoveisTime'] : null 
            break
        default:
            addObject = ( keys.indexOf( 'integracaoZapImoveisAgente' ) > -1) ? lst['integracaoZapImoveisAgente'] : null
    }

    if( addObject !== undefined && addObject!== null ) {
        if( addObject.planType !== null && addObject.planType !== null )
            addObject.planType = listingInMemory.translatePlanType( addObject.planType )
        if( addObject.locationType !== null && addObject.locationType !== null )
            addObject.locationType = listingInMemory.translateLocationType( addObject.locationType )
    }
    
    const initialAddStates = {
        planType: addObject ? addObject.planType : '',
        locationType: addObject ? addObject.locationType : '',
        title: addObject ? (addObject.title || '') : '',
        suites: addObject ? (addObject.suites || 0) : 0,
        bairroComercial: addObject ? (addObject.bairroComercial || '') : '',
        lst: lst,
        listings: listings !== undefined ? listings : [],
    }

    function statesReducer (state, action ) {
        //Actions: planType; locationType; title; suites; bairroComercial; lst; listings
        if( Object.keys(initialAddStates).indexOf( action.type ) === -1 )
            throw new Error( 'Estado inválido para anúncio' )
        let actualState = { ...state };
        console.log( actualState )
        console.log( action )
        actualState[`${action.type}`] = action.payload;
        return actualState
    }

    const [addStates, addDispatch] = useReducer( statesReducer, initialAddStates );

    console.log(`Editing listing ${lst._id}`)

    const submit = async () => {
        try{
            let res = await listingInMemory.saveListing(token, {...addStates, fileName: selectedFile})
            let count = await getXMLFileSize( token )
            if( res )
                alert("listing atualizado com sucesso")
        } catch ( err ) {
            alert( err.message )
        } finally {
            close( )
        }
    }

    return (
        <div>
            <Dialog open={open} onClose={close} maxWidth='sm'>
                <DialogTitle>
                    <div style={{ width: '500px' }}>
                        <h1>Informacoes do Anúncio</h1>
                        <Divider style={{ width: '100%' }} />
                    </div>
                </DialogTitle>
                <DialogContent>

                <Grid container 
                    spacing={0} 
                    style={{ height: '100%' }} 
                    justifyItems='flex-start' 
                    alignItems={'flex-start'}
                >
                    <Grid item xs={8} sm={8} style={{paddingBottom: '1rem'}} >
                        <TextField
                            fullWidth
                            variant='standard'
                            value={addStates.title}
                            onChange={ e => {
                                console.log( e.target.value )
                                addDispatch({ type: 'title', payload: e.target.value })
                            }}
                            label='Título da Publicação'
                            placeholder='APto de 2 Quartos'
                        />
                     </Grid>
                     <Grid item xs={8} sm={8} style={{paddingBottom: '1rem'}} >
                        <TextField
                            fullWidth
                            type='number'
                            variant='standard'
                            value={addStates.suites}
                            onChange={ e => {
                                if( e.target.value < 0 )
                                    e.target.value *= -1;
                                console.log( e.target.value )
                                addDispatch({ type: 'suites', payload: e.target.value })
                            }}
                            label='Suítes'
                        />
                     </Grid>
                     <Grid item xs={8} sm={8} style={{paddingBottom: '1rem'}} >
                        <TextField
                            fullWidth
                            variant='standard'
                            value={addStates.bairroComercial}
                            onChange={ e => {
                                console.log( e.target.value )
                                addDispatch({ type: 'bairroComercial', payload: e.target.value })
                            } }
                            label='Bairro Comercial'
                            placeholder='Ex: Vila Mariana'
                        />
                     </Grid>
                    <Grid item xs={8} sm={8} style={{paddingBottom: '1rem'}} >
                     <Autocomplete
                        options={['Rua', 'Bairro', 'Endereço Completo']}
                        getOptionLabel={(option) => option}
                        filterSelectedOptions
                        value={addStates.locationType}
                        onChange={ (e, newValue) => addDispatch({ type: 'locationType', payload: newValue })}
                        renderInput={(params) => (
                        <TextField
                            {...params}
                            variant='standard'
                            label='Exibir Endereço nos Portais'
                            placeholder='Bairro, Rua, etc'
                        />
                        )}
                    />
                     </Grid>
                    <Grid item xs={8} sm={8} style={{paddingBottom: '1rem'}} >
                     <Autocomplete
                        options={['Padrão', 'Destaque', 'Super Destaque', 'Destaque Premium', 'Destaque Especial']}
                        getOptionLabel={(option) => option}
                        filterSelectedOptions
                        value={addStates.planType}
                        defaultValue={'Padrão'}
                        onChange={ (e, newValue) => addDispatch({ type: 'planType', payload: newValue })}
                        renderInput={(params) => (
                        <TextField
                            {...params}
                            variant='standard'
                            label='Tipo de Publicação'
                            placeholder='Padrão, Destaque, etc'
                        />
                        )}
                    />
                     </Grid>
                    
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <button className='btn btnSecondary'  onClick={close}>Cancelar</button>
                    <button className='btn btnPrimary'  onClick={submit}>Cadastrar</button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
