import { Grid, Chip, Switch } from '@mui/material';
import React, {useContext, useState} from 'react';
import listingInMemory from '../../libs/services/listingInMemory';
import { AuthContext } from '../../contexts/AuthContext';
import XMLFilesContext from '../../contexts/XMLFilesContexts';
import XmlFileSize from './XmlFileSize';

export default function ListingPageHeader( props ) {
    const {token } = useContext(AuthContext);
    const {files, selectedFile, setSelectedFile, addFile, removeFile, updateFileList} = useContext( XMLFilesContext )
    let {pageTitle, actionFunction, xmlSize} = {...props};


    return <Grid container justifyItems='flex-start' alignItems={'center'} >
    <Grid item xs={9} sm={3} style={{ textAlign: 'left', }}>
        <h1>{ pageTitle }</h1>
    </Grid>
    <Grid item xs={3} sm={2} style={{ textAlign: 'left', }}>
            <button className='btn btnPrimary' 
                onClick={async () => {await listingInMemory.getURL( selectedFile, token )}}
            >
                Obter Link do XML
            </button>
        </Grid>
    <Grid item xs={3} sm={2} style={{ textAlign: 'left', }}>
        <button className='btn btnPrimary' 
            onClick={actionFunction}
        >
            Editar Em Lote
        </button>
    
    </Grid>
        <XmlFileSize />
    <Grid item xs={3} sm={2} style={{ textAlign: 'left', }}>
        
    
    </Grid>
    </Grid>
}
