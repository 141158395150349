import { Divider, Grid, TextField } from '@mui/material'
import axios from 'axios';
import { useContext, useState } from 'react';
import kw from '../assets/imgs/kwlogo.png'
import { AuthContext } from '../contexts/AuthContext';
import Login from './Login';
import { BASE_URL, SOUKW_URL } from '../sharedComponents/Constants';

export function ForgotPassword() {
    const [email, setEmail] = useState('');
    const [render, setRender] = useState(true);
    //const { BASE_URL } = useContext(AuthContext);

    const submit = async () => {
        try {
            let json = {email}
            // let res = await axios.post(BASE_URL + '' + json,);
            let res = await axios.post(BASE_URL + '/auth/forgotPassword', json);
            if (res.data)
                alert('Link enviado para o email informado!');
        } catch (e) {
            console.error(e);
            if (e?.response?.data?.msg)
                alert(e?.response?.data?.msg);
            else {
                alert('Erro de Conexão.');
            }
        }
    }

    const handleRender = () => {
        setRender(false)
    }

    return(<div style={{ 
            height: '100vh',
            backgroundColor: '#F7F7F7'
        }}> 
        { render
           ? <Grid container 
                textAlign ={'center'}
                alignContent={'center'}
                style={{ 
                    height: '100vh', 
                    display: 'flex', 
                    flexDirection: 'column', 
                    alignItems: 'center'
                }}
            >
                <img xs={4} src={kw} 
                    style={{ 
                        width: '13rem',
                        height: '6.5rem', 
                        padding: '3.8rem 0px 2.6rem 0px'
                    }} 
                    alt='logo'
                />
            <h1 style={{
                color: '#0099a7',
                fontSize: '2.6rem',
                paddingBottom: '1rem'
            }}>Redefina sua senha</h1>
            <p>Insira seus dados para encontrar sua conta</p>
            <Divider style={{
                lineHeight: '0.5em',
                width: '36.4%',
                color: 'gray',
                paddingBottom: '1rem'
            }}>
                Informe seu email kwsp
            </Divider>

            <TextField
                label='Email'
                helperText='exemplo@kwsp.com.br'
                type={'email'}
                style={{
                    width: '36.4%',
                    paddingBottom: '0.65rem'
                }}
                onChange={(e) => setEmail(e.target.value)}
            />

            <button className='btnForgotPass' onClick={submit}>
                ENVIAR
            </button>
            <button className='btnReturnLogin' onClick={handleRender}>
                Retornar ao Login
            </button>

            </Grid>
            : <Login/>
        }
        </div>
    )
}