import { Divider, Grid, IconButton, Zoom } from '@mui/material'
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../contexts/AuthContext';
import NewTreinamentoGrowthCall from '../views/NewTreinamentoGrowthCall';
import bg from '../assets/imgs/video.jpeg'
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import { useNavigate, useParams } from 'react-router-dom';
import { BASE_URL, SOUKW_URL } from '../sharedComponents/Constants';

export default function TreinamentosGrowthCallsMonth({ type }) {
    const { jwtUser, token, logout } = useContext(AuthContext);
    const [newTreinamentoModalOpen, setNewTreinamentoModalOpen] = useState(false);
    const [calls, setCalls] = useState([]);
    const [types, setTypes] = useState([]);
    const [editMode, setEditMode] = useState(false);
    const [callEdit, setCallEdit] = useState(null);
    const { year, month } = useParams()
    const navigate = useNavigate()

    const openNewTreinamentoModal = () => {
        setCallEdit(null)
        setNewTreinamentoModalOpen(true);
    }
    const closeNewTreinamentoModal = () => {
        setNewTreinamentoModalOpen(false);
        setCallEdit(null)
    }

    const convertMonth = (month) => {
        let n = -1;
        month = String(month)?.toUpperCase()
        switch (month) {
            case 'JANEIRO': n = 0;
                break;
            case 'FEVEREIRO': n = 1;
                break;
            case 'MARÇO': n = 2;
                break;
            case 'ABRIL': n = 3;
                break;
            case 'MAIO': n = 4;
                break;
            case 'JUNHO': n = 5;
                break;
            case 'JULHO': n = 6;
                break;
            case 'AGOSTO': n = 7;
                break;
            case 'SETEMBRO': n = 8;
                break;
            case 'OUTUBRO': n = 9;
                break;
            case 'NOVEMBRO': n = 10;
                break;
            case 'DEZEMBRO': n = 11;
                break;
        }
        return n
    }
    const convertType = (type) => {
        let n = '';
        switch (type) {
            case 0: n = 'AGENTE';
                break;
            case 1: n = 'TEAM LEADER';
                break;
            case 2: n = 'MC OP';
                break;
            case 3: n = 'REGIONAL';
                break;
        }
        return n
    }
    const compare = (prop) => {
        return function (a, b) {
            if (a[prop] > b[prop]) {
                return 1;
            } else if (a[prop] < b[prop]) {
                return -1;
            }
            return 0;
        }
    }
    useEffect(() => {
        async function getData() {
            try {
                const config = {
                    headers: { Authorization: `Bearer ${token}` }
                };
                const params = new URLSearchParams(window.location.search) // id=123
                let type = params.get('type')
                let res = await axios.get(BASE_URL + '/treinamentos/growth-calls/by_date?year=' + year + '&month=' + convertMonth(month) + '&type=' + type, config);
                let calls = res.data
                let typesArr = []
                calls.map(call => {
                    if (!typesArr.includes(call.type))
                        typesArr.push(call.type);
                })
                setCalls(calls.sort().sort(compare('day')))
                setTypes(typesArr.sort().reverse())
            }
            catch (e) {
                console.error(e);
                if (e?.response?.data?.msg)
                    alert(e?.response?.data?.msg)
                else {
                    alert('Erro de Conexão.')
                    logout();
                }
            }
        }
        getData()
    }, [])
    const submitDelete = async (item) => {

        try {
            const config = {
                headers: { Authorization: `Bearer ${token}` }
            };
            let res = await axios.delete(BASE_URL + '/soukw/training/growth-calls/' + item?._id, config);
            if (res.data)
                alert('Deletado com sucesso!');
            navigate(-1)
        } catch (e) {
            console.error(e);
            if (e?.response?.data?.msg)
                alert(e?.response?.data?.msg)
            else {
                alert('Erro de Conexão.')
                logout();
            }
        }
    }

    return (
        <div style={{ width: '100%' }}>
            <Grid container spacing={0} style={{ height: '100%' }} justifyItems='flex-start' alignItems={'flex-start'}>

                <button className='btn btnSecondary' onClick={() => navigate(-1)}>
                    Voltar
                </button>
                <Grid item xs={12} >
                    <br />
                </Grid>
                <Grid item xs={12} sm={7} style={{ textAlign: 'left', }}>
                    <h1>Growth Calls {String(month)?.toUpperCase()}</h1>
                </Grid>
                {jwtUser?.permissionLevel.match(/ADMIN|SUPER/) &&
                    <Grid item xs={12} sm={2} style={{ textAlign: 'right', }}>
                        <button className='btn btnSecondary' onClick={() => setEditMode(!editMode)}>{editMode ? 'Cancelar' : 'Editar'}</button>
                    </Grid>
                }
                {jwtUser?.permissionLevel.match(/ADMIN|SUPER/) &&
                    <Grid item xs={12} sm={2} style={{ textAlign: 'right', }}>
                        <button className='btn btnPrimary' onClick={openNewTreinamentoModal}>Novo Vídeo</button>
                    </Grid>
                }
                <Grid item xs={1} >
                    <br />
                </Grid>
                <Grid item xs={12} >
                    <br />
                </Grid>
                <Grid item xs={12} >
                    <Grid container spacing={0} style={{ height: '100%' }} justifyItems='center' alignItems={'center'}>
                        {
                            types?.map((type, i) => {
                                return (
                                    <Grid item xs={12} key={i}>

                                        <Grid container spacing={0} style={{ height: '100%' }} justifyItems='center' alignItems={'center'}>
                                            <Grid item xs={12}>
                                                <h1>{convertType(type)}</h1>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container spacing={0} style={{ height: '100%' }} justifyItems='center' alignItems={'center'}>
                                                    {calls?.filter(c => c.type == type).map((call, index) => {
                                                        return (
                                                            <Grid key={index} item xs={12} sm={6} md={3} lg={2.1}>
                                                                <Zoom in={editMode} style={{ zIndex: 1 }}>
                                                                    <div style={{ position: 'absolute' }}>
                                                                        <IconButton
                                                                            onClick={() => submitDelete(call)}
                                                                            style={{
                                                                                backgroundColor: '#a7002f',
                                                                                color: 'white',
                                                                                zIndex: 1,
                                                                                marginRight: '2px',
                                                                                marginLeft: '2px'
                                                                            }}><DeleteForeverRoundedIcon />
                                                                        </IconButton>
                                                                        <IconButton
                                                                            onClick={() => setCallEdit(call)}
                                                                            style={{
                                                                                zIndex: 1,
                                                                                backgroundColor: '#0099a7',
                                                                                color: 'white',
                                                                                marginRight: '2px',
                                                                                marginLeft: '2px'
                                                                            }}><EditIcon />
                                                                        </IconButton>
                                                                    </div>
                                                                </Zoom>
                                                                <a href={call.url} target='_blank'>
                                                                    <div style={{
                                                                        height: '200px',
                                                                        width: '200px',
                                                                        backgroundImage: `url(${bg})`,
                                                                        backgroundSize: 'cover',
                                                                        cursor: 'pointer',
                                                                        position: 'relative'
                                                                    }}>
                                                                        <h1 style={{ fontSize: '1.1rem', position: 'absolute', left: '15%', top: '25%', color: '#ffffff', textTransform: 'capitalize', marginRight: '50px' }}>
                                                                            <span style={{ color: '#ce011f', }}>
                                                                                {convertType(call.type)}
                                                                                <br />
                                                                            </span>
                                                                            <span>
                                                                                {new Date(call.year + '/' + (call.month + 1) + '/' + call.day).toLocaleDateString('pt-br')}
                                                                            </span>
                                                                        </h1>
                                                                    </div>
                                                                </a>
                                                                <br />
                                                            </Grid>
                                                        )
                                                    })}
                                                    <Grid item xs={12}>
                                                        <br />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Divider />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <br />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                </Grid>
            </Grid>
            <NewTreinamentoGrowthCall open={newTreinamentoModalOpen || callEdit} close={closeNewTreinamentoModal} call={callEdit} />
        </div>
    )
}
