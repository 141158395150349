/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable default-case */
import { Autocomplete,
    Grid,
    Checkbox,
    Dialog, 
    DialogActions, 
    DialogContent, 
    DialogTitle, 
    Divider,
    Switch, 
    FormControlLabel, 
    MenuItem, 
    TextField } from '@mui/material'
import TextModal from '../sharedComponents/listings/TextModal'
import React, { useContext, useEffect, useState } from 'react'
import Geocode from 'react-geocode';
import { AuthContext } from '../contexts/AuthContext';
import XMLFilesContext from '../contexts/XMLFilesContexts';
import { useNavigate } from 'react-router-dom';
import SingleFileUpload from '../sharedComponents/SingleFileUpload';
import listingInMemory from '../libs/services/listingInMemory';
import { BASE_URL, SOUKW_URL } from '../sharedComponents/Constants';

export default function EditIntegracaoPortalZAP({ open, close }) {
    const { token, user, logout, jwtUser } = useContext(AuthContext);
    const { files, 
        selectedFile,
        setSelectedFile,
        addFile,
        removeFile,
        updateFileList,
        xmlListings,
        updateXmlListings,
        saveXmlListings,
        deleteXmlListing,
        addXmlListing,
        clearXmlListings,
        reloadXmlListings,
        clearListings,
        deleteAdd,
        xmlCurrentFileSize,
        setXmlCurrentFileSize,
        listingsInsideXML,
        setListingsInsideXML,
        getXMLFileSize
        } = useContext( XMLFilesContext )

    const navigate = useNavigate();

    const [planType, setPlanType] = useState('');
    const [locationType, setLocationType] = useState( '' );
    const [title, setTitle] = useState( '' );
    const [suites, setSuites] = useState( '' );
    const [bairroComercial, setBairroComercial] = useState( '' );



    const submit = async () => {
        try{
            //alert("The selected file is: " + selectedFile)
            let res = await saveXmlListings(token, planType, locationType, bairroComercial, suites, title, selectedFile) 
            let count = await getXMLFileSize( token )
            if( res )
                alert(`Listings atualizados com sucesso: ${res.data.message}. Aquivo escolhido: ${selectedFile} com tamanho: ${count}`)
        } catch ( err ) {
            alert( err )
        } finally {
            close( )
            setPlanType( '' )
            setLocationType( '' )
            setTitle( '' )
            setSuites( '' )
        }
    }

    return (
        <div>
            <Dialog open={open} onClose={close} maxWidth='sm'>
                <DialogTitle>
                    <div style={{ width: '500px' }}>
                        <h1>Informacoes do Anúncio</h1>
                        <Divider style={{ width: '100%' }} />
                    </div>
                </DialogTitle>
                <DialogContent>

                <Grid container 
                    spacing={0} 
                    style={{ height: '100%' }} 
                    justifyItems='flex-start' 
                    alignItems={'flex-start'}
                >
                    <Grid item xs={8} sm={8} style={{paddingBottom: '1rem'}} >
                        <TextField
                            fullWidth
                            variant='standard'
                            value={title}
                            onChange={ e => {
                                console.log( e.target.value )
                                setTitle( e.target.value )} }
                            label='Título da Publicação'
                            placeholder='APto de 2 Quartos'
                        />
                     </Grid>
                     <Grid item xs={8} sm={8} style={{paddingBottom: '1rem'}} >
                        <TextField
                            fullWidth
                            type='number'
                            variant='standard'
                            value={suites}
                            onChange={ e => {
                                if( e.target.value < 0 )
                                    e.target.value *= -1;
                                console.log( e.target.value )
                                setSuites( e.target.value )} }
                            label='Suítes'
                        />
                     </Grid>
                     <Grid item xs={8} sm={8} style={{paddingBottom: '1rem'}} >
                        <TextField
                            fullWidth
                            variant='standard'
                            value={bairroComercial}
                            onChange={ e => {
                                console.log( e.target.value )
                                setBairroComercial( e.target.value )} }
                            label='Bairro Comercial'
                            placeholder='Ex: Vila Mariana'
                        />
                     </Grid>
                     <Grid item xs={8} sm={8} style={{paddingBottom: '1rem'}} >
                     <Autocomplete
                        options={['Rua', 'Bairro', 'Endereço Completo']}
                        getOptionLabel={(option) => option}
                        filterSelectedOptions
                        value={locationType}
                        onChange={ (e, newValue) => setLocationType(newValue)}
                        renderInput={(params) => (
                        <TextField
                            {...params}
                            variant='standard'
                            label='Exibir Endereço nos Portais'
                            placeholder='Bairro, Rua, etc'
                        />
                        )}
                    />
                     </Grid>
                    <Grid item xs={8} sm={8} style={{paddingBottom: '1rem'}} >
                     <Autocomplete
                        options={['Padrão', 'Destaque', 'Super Destaque', 'Destaque Premium', 'Destaque Especial']}
                        getOptionLabel={(option) => option}
                        filterSelectedOptions
                        value={planType}
                        onChange={ (e, newValue) => setPlanType(newValue)}
                        renderInput={(params) => (
                        <TextField
                            {...params}
                            variant='standard'
                            label='Tipo de Publicação'
                            placeholder='Padrão, Destaque, etc'
                        />
                        )}
                    />
                    </Grid>
                    
                </Grid>
                </DialogContent>
                <DialogActions>
                    <button className='btn btnSecondary'  onClick={close}>Cancelar</button>
                    <button className='btn btnPrimary'  onClick={submit}>Cadastrar</button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
