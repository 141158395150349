/* eslint-disable no-unused-vars */
import { Divider, Grid, Zoom } from '@mui/material'
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import NewUserModal from '../views/newUserModal'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import FilterUsersModal from '../views/FilterUsersModal';
import { BASE_URL, SOUKW_URL } from '../sharedComponents/Constants';

export default function Users() {
    const { token, user } = useContext(AuthContext);
    const [users, setUsers] = useState([]);
    const [newUserModalOpen, setNewUserModalOpen] = useState(false);
    const [mcs, setMcs] = useState([]);
    const [teams, setTeams] = useState([]);
    const [previousOrder, setPreviousOrder] = useState('');
    const [orderedBy, setOrderedBy] = useState({ key: 'createdAt', incrementing: true });

    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [filters, setFilters] = useState({});
    const [filteringMode, setFilteringMode] = useState(false);
    const [totalPages, setTotalPages] = useState(0);


    const openNewUserModal = () => {
        setNewUserModalOpen(true);
    }
    const closeNewUserModal = () => {
        setNewUserModalOpen(false);
    }

    function closeFilterListingModal() {
        setFilteringMode(false)
    }

    useEffect(() => {
        const params = new URLSearchParams(window.location.search) // id=123
        let _page = params.get('page')
        if (!_page)
            _page = 1
        setPage(_page)
    }, [])

    useEffect(() => {
        async function getData() {
            try {
                const config = {
                    headers: { Authorization: `Bearer ${token}` }
                };
                //let result = await axios.get(BASE_URL + '/users?page=' + page + '&itemsPerPage=10', config);
                //console.log( result.data );
                //setTotalPages( result )
                let results = await axios.get(BASE_URL + '/users/my', config);
                console.log(results.data)
                //setUsers(results.data)
                setUsers(results.data)
                let mcsResults = await axios.get(BASE_URL + '/mcs/', config);
                setMcs(mcsResults.data.data);
                let teams = await axios.get(BASE_URL + '/teams/', config);
                setTeams(teams.data);
            } catch (e) {
                console.error(e);
                if (e?.response?.data?.msg)
                    alert(e?.response?.data?.msg)
                else {
                    alert('Erro de Conexão.')
                    //logout();
                }
            }
        }
        getData();
    }, []);

    useEffect(() => {
        if (orderedBy.key === '') return;

        let ordered = users
        if (orderedBy.incrementing)
            ordered = users?.sort((a, b) =>
                a[orderedBy.key].localeCompare(b[orderedBy.key]))
        else
            ordered = users?.sort((a, b) =>
                b[orderedBy.key].localeCompare(a[orderedBy.key]))

        setPreviousOrder(orderedBy.key)
        setUsers([...ordered])

    }, [orderedBy])


    return (
        <Grid container spacing={0}
            style={{ height: '100%' }}
            justifyItems='flex-start'
            alignItems={'flex-start'}
        >
            <Grid item xs={12} sm={10} style={{ textAlign: 'left', }}>
                <h1 style={{ marginBottom: '1rem' }}>Usuários</h1>

                {/* <button className='btn btnSecondary' onClick={() => {
                            if (filteringMode)
                                setFilters({})
                            setFilteringMode(!filteringMode)
                }}>
                    Filtrar Usuário
                </button> */}
            </Grid>
            <Grid item xs={12} sm={2} style={{ textAlign: 'left' }}>
                {(user?.permissionLevel === 'SUPER') &&
                    <button className='btn btnPrimary' onClick={openNewUserModal}>
                        Cadastrar Usuário
                    </button>
                }
            </Grid>
            <Grid item xs={12} >
                <br />
            </Grid>

            <Grid item xs={12}>
                <Divider style={{ width: '95%' }} />
                <div style={{
                    backgroundColor: 'white',
                    width: '95%',
                    padding: '5px'
                }}
                >
                    <div style={{
                        textDecoration: 'none',
                        color: '#373f51'
                    }}
                    >
                        <Grid container
                            justifyItems='flex-start'
                            alignItems={'center'}
                            style={{
                                cursor: 'pointer',
                                paddingTop: '8px',
                                paddingBottom: '8px'
                            }}
                        >
                            <Grid item xs={3}>
                                <span onClick={() => {
                                    setOrderedBy({
                                        key: 'fullName',
                                        incrementing: orderedBy.key !==
                                            'fullName' ? true : !orderedBy.incrementing
                                    })
                                }}
                                >
                                    Nome
                                    {
                                        orderedBy.key === 'fullName' &&
                                        <KeyboardArrowUpIcon style={{
                                            marginTop: '5px',
                                            color: 'gray',
                                            transform: orderedBy.incrementing ?
                                                'rotate(180deg)' : 'rotate(0deg)'
                                        }}
                                        />
                                    }
                                </span>
                            </Grid>
                            <Grid item xs={1}>
                                <span onClick={() => {
                                    setOrderedBy({
                                        key: 'status',
                                        incrementing: orderedBy.key !==
                                            'status' ? true : !orderedBy.incrementing
                                    })
                                }}
                                >
                                    Status
                                    {
                                        orderedBy.key === 'status' &&
                                        <KeyboardArrowUpIcon style={{
                                            marginTop: '5px',
                                            color: 'gray',
                                            transform: orderedBy.incrementing ?
                                                'rotate(180deg)' : 'rotate(0deg)'
                                        }}
                                        />
                                    }
                                </span>
                            </Grid>
                            <Grid item xs={1}>
                                <span onClick={() => {
                                    setOrderedBy({
                                        key: 'kwuid',
                                        incrementing: orderedBy.key !==
                                            'kwuid' ? true : !orderedBy.incrementing
                                    })
                                }}
                                >
                                    KWUID
                                    {
                                        orderedBy.key === 'kwuid' &&
                                        <KeyboardArrowUpIcon style={{
                                            marginTop: '5px',
                                            color: 'gray',
                                            transform: orderedBy.incrementing ?
                                                'rotate(180deg)' : 'rotate(0deg)'
                                        }}
                                        />
                                    }
                                </span>
                            </Grid>
                            <Grid item xs={1}>
                                <span onClick={() => {
                                    setOrderedBy({
                                        key: 'permissionLevel',
                                        incrementing: orderedBy.key !==
                                            'permissionLevel' ? true : !orderedBy.incrementing
                                    })
                                }}
                                >
                                    Função
                                    {
                                        orderedBy.key === 'permissionLevel' &&
                                        <KeyboardArrowUpIcon style={{
                                            marginTop: '5px',
                                            color: 'gray',
                                            transform: orderedBy.incrementing ?
                                                'rotate(180deg)' : 'rotate(0deg)'
                                        }}
                                        />
                                    }
                                </span>
                            </Grid>
                            <Grid item xs={2}>
                                <span>
                                    MC
                                </span>
                            </Grid>
                            <Grid item xs={2}>
                                <span>
                                    Time
                                </span>
                            </Grid>
                            <Grid item xs={2}>
                                <span onClick={() => {
                                    setOrderedBy({
                                        key: 'createdAt',
                                        incrementing: orderedBy.key !==
                                            'createdAt' ? true : !orderedBy.incrementing
                                    })
                                }}
                                >
                                    Criado em
                                    {
                                        orderedBy.key === 'createdAt' &&
                                        <KeyboardArrowUpIcon style={{
                                            marginTop: '5px',
                                            color: 'gray',
                                            transform: orderedBy.incrementing ?
                                                'rotate(180deg)' : 'rotate(0deg)'
                                        }}
                                        />
                                    }
                                </span>
                            </Grid>
                        </Grid>
                    </div>
                </div>
                <Divider style={{ width: '95%' }} />
            </Grid >
            {users?.map((user, i) => {
                return (
                    <Grid key={user._id} item xs={12}>
                        <Divider style={{ width: '95%' }} />
                        <div style={{
                            backgroundColor: i % 2
                                ? 'white'
                                : '#fafafa',
                            width: '95%',
                            padding: '5px'
                        }}
                        >
                            <Link to={'/soukw/users/edituser/' + user._id}
                                style={{
                                    textDecoration: 'none',
                                    color: '#373f51'
                                }}
                            >
                                <Grid container
                                    justifyItems='flex-start'
                                    alignItems={'center'}
                                    style={{
                                        cursor: 'pointer',
                                        paddingTop: '8px',
                                        paddingBottom: '8px'
                                    }}
                                >
                                    <Grid item xs={3}>
                                        {user.fullName}
                                    </Grid>
                                    <Grid item xs={1}>
                                        {String(user.status)
                                            .charAt(0) + String(user.status)
                                                .slice(1).toLowerCase()}
                                    </Grid>
                                    <Grid item xs={1}>
                                        {user.kwuid}
                                    </Grid>
                                    <Grid item xs={1}>
                                        {String(user.permissionLevel)
                                            .charAt(0) + String(user.permissionLevel)
                                                .slice(1).toLowerCase()}
                                    </Grid>
                                    <Grid item xs={2}>
                                        {mcs?.find(mc => mc._id === user.marketCenterId)?.name}
                                    </Grid>
                                    <Grid item xs={2}>
                                        {teams?.find(t => t._id === user.teamId)?.name}
                                    </Grid>
                                    <Grid item xs={2}>
                                        {new Date(user.createdAt).toLocaleDateString('pt-br')}
                                    </Grid>
                                </Grid>
                            </Link>
                        </div>
                        <Divider style={{ width: '95%' }} />
                    </Grid >)
            })}
            <Grid item xs={12}>
                <Zoom in={page > 1}>
                    <Link to={'/users?page=' + String(parseInt(page) - 1)}
                        style={{
                            textDecoration: 'none',
                            color: '#373f51'
                        }}
                    >
                        <button className='btn btnSecondary'
                            onClick={() => {
                                setLoading(true)
                                setPage(parseInt(page) - 1)
                            }}
                        >
                            Anterior
                        </button>
                    </Link>
                </Zoom>
                <span style={{ margin: '5px' }}>
                    Página {page} de {totalPages}
                </span>
                <Zoom in={page < totalPages}>
                    <Link to={'/users?page=' + String(parseInt(page) + 1)}
                        style={{
                            textDecoration: 'none',
                            color: '#373f51'
                        }}
                    >
                        <button className='btn btnSecondary'
                            onClick={() => {
                                setLoading(true)
                                setPage(parseInt(page) + 1)
                            }}
                        >
                            Próxima
                        </button>
                    </Link>
                </Zoom>
            </Grid>
            <NewUserModal open={newUserModalOpen} close={closeNewUserModal} />
            <FilterUsersModal open={filteringMode} close={closeFilterListingModal} setFilters={setFilters} />
        </Grid>
    )
}
