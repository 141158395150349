import { Grid } from '@mui/material';
import React from 'react';
import ListItemField from './ListItemField';

export default function ListingListHeader(props) {
    let headers = new Array( ...props.headers);
    console.log(headers)
        return <Grid container justifyItems='flex-start' alignItems={'center'} style={{
                width: '95%',
                cursor: 'pointer',
                padding: '5px',
                borderTop: '2px  solid #d9d9d9', 
                borderRight: '2px  solid #d9d9d9', 
                borderLeft: '2px  solid #d9d9d9',
                borderRadius: '5px 5px 0px 0px'
            }}>
                { headers.map((el, index) => {
                console.log(el);
                return <ListItemField size={el.size} data={el.text} />
                })}
            </Grid>
}
